import { useRef, useEffect } from "react";

import { mapboxgl, isMapboxSupported } from "../../../services/mapbox";
import Text from "../../../ui/Text";

const SingleSiteMap = (props) => {
  let wasteMapRef = useRef(null);
  const { lon, lat } = props;
  let map;

  useEffect(() => {
    if (isMapboxSupported) {
      if (lon) {
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/satellite-v9",
          center: [lon, lat],
          zoom: 17,
        });

        new mapboxgl.Marker()
          .setLngLat([lon, lat])
          .setPopup(new mapboxgl.Popup().setHTML(`Lon: ${lon} Lat: ${lat}`))
          .addTo(map);
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [lon, lat, map]);

  if (isMapboxSupported) {
    return (
      <div
        ref={(elem) => (wasteMapRef = elem)}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 20px",
          flex: 1,
        }}
      />
    );
  } else {
    return <Text>Map is not supported in this browser.</Text>;
  }
};

export default SingleSiteMap;
