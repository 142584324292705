import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { IMovementsWithIssues } from "../../../graphql/queries/getMovementsWithIssues";
import Text from "../../../ui/Text";
import { formatDate } from "../../../aux/formatDate";
import TrText from "../../../i18n/TrText";

const AlertsTable = ({ data }: { data: IMovementsWithIssues | undefined }) => {
  return (
    <>
      {(data?.totalCount || 0) === 0 ? (
        <Text>
          <TrText message="alerts_table.no_issues" />
        </Text>
      ) : (
        <Table variant="striped" style={{ marginTop: 10, width: "100%" }}>
          <Thead>
            <Tr>
              <Th pl={1} textAlign="left">
                <TrText message="table_headings.date" />
              </Th>
              <Th>
                <TrText message="table_headings.site_name" />
              </Th>
              <Th>
                <TrText message="table_headings.description" />
              </Th>
              <Th pr={1} textAlign="right">
                <TrText message="table_headings.status" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.edges?.map((node) => {
              const m = node.node;
              return (
                <Tr key={m.movementId}>
                  <Td pr={0} pl={1} pt={1} pb={1}>
                    <Text>{formatDate(new Date(m.producerTimestamp))}</Text>
                  </Td>
                  <Td pr={0} pl={0} pt={1} pb={1}>
                    <Text>{m.producerName}</Text>
                  </Td>
                  <Td pr={0} pl={0} pt={1} pb={1}>
                    <Text>
                      {m.receiverValidationError} {m.carrierValidationError}
                    </Text>
                  </Td>
                  <Td textAlign="right" pr={1} pl={0} pt={1} pb={1}>
                    <Text>
                      <TrText message="alerts_table.new" />
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default AlertsTable;
