import { ScatterplotLayer } from "@deck.gl/layers";

import rectangleShader from "./rectangle-layer-fragment";

export default class RectangleLayer extends ScatterplotLayer {
  getShaders() {
    // use object.assign to make sure we don't overwrite existing fields like `vs`, `modules`...
    return Object.assign({}, super.getShaders(), {
      fs: rectangleShader,
    });
  }
}
