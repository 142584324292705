import React from "react";
import { TranslateOptions } from "i18n-js/typings/typing";
import translate from "./translate";

interface ITrTextProps {
  message: string;
  options?: TranslateOptions | undefined;
}
/*
  To translate to the current locale:
    <TrText message="greeting" />

  To override current locale with another:
    <TrText message="greeting" options={ locale: 'fr-FR' }  />

  Also supports interpolation:
    <TrText message="hello" options={ locale: 'fr-FR', name: 'Max' }  /> --> 'Hello Max'

  Styling:
    Don't pass React Native stylesheet ids in -- flatten them first using getStyle() in our stylesheet, e.g.:
      <TrText message="{{brand}} is cool" style={getStyle('coolBorder')} />
    Instead of:
      <TrText message="{{brand}} is cool" style={s.coolBorder} />

  Text Transformation:
    text-transform css property is not supported by React Native
    Instead we pass in a function that transforms text
*/
const TrText = (props: ITrTextProps) => {
  const { message, options } = props;
  const translation = translate(message, options);
  if (translation) {
    return <>{translation}</>;
  }
  return (
    <span style={{ color: "yellow", backgroundColor: "red" }}>
      !! MISSING TRANSLATION !!
    </span>
  );
};

export default TrText;
