import { gql } from "@apollo/client";

export const GET_FILTERS = gql`
  query GetFilters {
    filters {
      id
      name
      params
    }
  }
`;

export interface IFilters {
  id: string;
  name: string;
  params: object;
}

export interface IFiltersResult {
  filters: IFilters[];
}
