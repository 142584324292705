import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Flex,
  Input,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverArrow,
  Button,
  Popover,
  PopoverFooter,
} from "@chakra-ui/react";

import Label from "../../../ui/Label";
import { colors } from "../../../ui/theme";
import { GET_FILTERS } from "../../../graphql/queries/getFilters";
import {
  CREATE_FILTER,
  ICreateFilterResponse,
  ICreateFilterParams,
} from "../../../graphql/mutations/createFilter";
import ButtonPrimary from "../../../ui/ButtonPrimary";
import { useFiltersFromQueryParams } from "./SimpleFilterSummary/SimpleFilterSummary";

export const SaveFilter = () => {
  const filter = useFiltersFromQueryParams();

  const initialFocusRef = useRef();

  const [filterName, setFilterName] = useState("");
  const [filterNameErrorMessage, setFilterNameErrorMessage] = useState("");
  const [filterNameSuccess, setFilterNameSuccess] = useState("");
  const [shouldButtonStayDisabled, setShouldButtonStayDisabled] =
    useState(false);

  const isSaveFilterDisabled = () => {
    // Disable when nothing is selected
    if (
      !filter.startDate &&
      !filter.endDate &&
      !filter.wasteCodes &&
      !filter.poiIds &&
      !filter.receiverPoiIds &&
      filter.hasIssues === undefined &&
      !filter.carrierName &&
      !filter.poiGroupIds &&
      !filter.supplierIds
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [createFilter, { loading: createFilterLoading }] = useMutation<
    ICreateFilterResponse,
    ICreateFilterParams
  >(CREATE_FILTER, {
    refetchQueries: [{ query: GET_FILTERS }],
    variables: {
      name: filterName.trim(),
      params: {
        startDate: filter.startDate,
        endDate: filter.endDate,
        wasteCodes: filter.wasteCodes,
        poiIds: filter.poiIds,
        receiverPoiIds: filter.receiverPoiIds,
        poiGroupIds: filter.poiGroupIds,
        hasIssues: filter.hasIssues,
        supplierIds: filter.supplierIds,
        carrierName: filter.carrierName,
      },
    },
    onCompleted: (data) => {
      if (data?.createFilter.errors?.length === 0) {
        setFilterNameSuccess("Filter saved successfully");
        setShouldButtonStayDisabled(true);
        setTimeout(() => {
          setFilterName("");
          setFilterNameErrorMessage("");
          setFilterNameSuccess("");
          setShouldButtonStayDisabled(false);
          // @ts-ignore
          document?.getElementById("close-save-filter").click();
        }, 2000);
      } else {
        data?.createFilter.errors?.forEach((err) => {
          if (err === "Filter name already exists") {
            setFilterName(filterName.trim());
            setFilterNameErrorMessage("Filter name already exists");
          } else {
            setTimeout(() => {
              setFilterName("");
              setFilterNameErrorMessage("");
              setFilterNameSuccess("");
              // @ts-ignore
              document?.getElementById("close-save-filter").click();
            }, 1000);
          }
        });
      }
    },
  });

  return (
    <Popover
      // @ts-ignore
      initialFocusRef={initialFocusRef}
      placement="bottom-start"
      closeOnBlur={true}
      onClose={() => {
        setFilterName("");
        setFilterNameErrorMessage("");
      }}
      w="400px"
    >
      <PopoverTrigger>
        <Button
          fontSize="sm"
          p={2}
          disabled={isSaveFilterDisabled()}
          variant="link"
        >
          Save filter
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="#fff"
        color={colors.new.text.dark[500]}
        borderBottomRightRadius="10px"
        w="400px"
        borderTopRadius="10px"
        _focus={{
          boxShadow: `0px 0px 20px 4px rgba(0, 0, 0, 0.08)`,
        }}
        _focusVisible={{
          outline: `2px solid ${colors.new.grey[500]}`,
        }}
      >
        <PopoverHeader
          color={colors.new.grey[700]}
          fontWeight="bold"
          border="0"
          borderTopRadius="10px"
          bg={colors.new.grey[500]}
        >
          Save current filter settings
        </PopoverHeader>
        <PopoverArrow bg={colors.new.grey[500]} />
        <PopoverCloseButton id="close-save-filter" />
        <PopoverBody p={0}>
          <Flex px={3} mt={4}>
            <Label forHtml="filter-name">
              <b>Filter name</b> (min. 4 characters)
            </Label>
          </Flex>
          <Flex px={3} mb={4} alignItems="center">
            <Input
              id="filter-name"
              placeholder="Type name of the filter"
              value={filterName}
              onChange={(event) => {
                setFilterNameErrorMessage("");
                setFilterNameSuccess("");
                setFilterName(event.target.value);
              }}
              borderColor={
                filterNameErrorMessage
                  ? `${colors.new.feedback.red}`
                  : `${colors.new.background_light[500]}`
              }
              border={
                filterNameErrorMessage
                  ? `2px solid ${colors.new.feedback.red}`
                  : `2px solid ${colors.new.background_light[500]}`
              }
              color={filterNameErrorMessage && `${colors.new.feedback.red}`}
              _focus={{
                border: `1px solid ${colors.new.primary[500]}`,
              }}
              w="100%"
            />
            <Flex justifyContent="flex-end" ml={2}>
              <ButtonPrimary
                isDisabled={
                  filterName.length <= 3 ||
                  createFilterLoading ||
                  filterNameErrorMessage.length > 0 ||
                  shouldButtonStayDisabled
                }
                onClick={createFilter}
              >
                {createFilterLoading ? "Saving..." : "Save"}
              </ButtonPrimary>
            </Flex>
          </Flex>

          {filterNameErrorMessage && (
            <PopoverFooter
              fontSize="sm"
              mt={4}
              borderTop={`2px solid colors.new.grey[500]`}
              color={colors.new.feedback.red}
            >
              * Filter name already exists
            </PopoverFooter>
          )}
          {filterNameSuccess && (
            <PopoverFooter
              fontSize="sm"
              mt={4}
              borderTop={`2px solid colors.new.grey[500]`}
            >
              {filterNameSuccess}
            </PopoverFooter>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
