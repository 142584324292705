import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      avatarUrl
      email
      roles
      availableUploadAccounts {
        id
        name
        logoUrl
      }
      account {
        id
        name
        logoUrl
        countryCodes
        distanceUnits
        isBroker
        users {
          id
          name
          email
          roles
          isInvited
        }
      }
    }
  }
`;

export type Role = "member" | "admin" | "uploader";
export type DistanceUnits = "miles" | "kilometers";

export interface IUser {
  id: string;
  name: string;
  email: string;
  roles: Role[];
  isInvited: boolean;
}

export interface IAccount {
  id: string;
  name: string;
  logoUrl: string;
  users: IUser[] | null;
  countryCodes: string[];
  distanceUnits: DistanceUnits;
  isBroker: boolean;
}

export interface ICurrentUser {
  id: string;
  name: string;
  avatarUrl: string | null | undefined;
  email: string;
  roles: Role[];
  account: IAccount;
  availableUploadAccounts: IAccount[];
}

export interface ICurrentUserResult {
  currentUser: ICurrentUser;
}

// Is the current account a broker account?
export const IS_BROKER_ACCOUNT = gql`
  query IsBrokerAccount {
    currentUser {
      id
      account {
        isBroker
      }
    }
  }
`;

export interface IIsBrokerAccountResult {
  currentUser: IIsBrokerAccount;
}
export interface IIsBrokerAccount {
  id: string;
  account: {
    id: string;
    account: {
      isBroker: boolean;
    };
  };
}
