import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const WASTE_END_FATES_QUERY = gql`
  query GetWasteEndFates(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    wasteEndFates(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      month
      totalWeight
      incinerationWeight
      landfillWeight
      divertedFromLandfillWeight
      longTermStorageWeight
      recoveryWeight
      transferWeight
      treatmentWeight
      unknownWeight
    }
  }
`;

export interface IWasteEndFates {
  month: Date;
  totalWeight: number;
  incinerationWeight: number;
  landfillWeight: number;
  divertedFromLandfillWeight: number;
  longTermStorageWeight: number;
  recoveryWeight: number;
  transferWeight: number;
  treatmentWeight: number;
  unknownWeight: number;
}

export interface IWasteEndFatesResult {
  wasteEndFates: IWasteEndFates[];
}

export type IWasteEndFatesParams = IFilters;
