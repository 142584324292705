import { Flex } from "@chakra-ui/react";

import { colors } from "../../../../ui/theme";

const UploadHeader = () => {
  return (
    <Flex
      as="header"
      w="100%"
      mb={0}
      px={0}
      bg={colors.new.background_light[500]}
      h="72px"
      borderBottom={`1px solid ${colors.new.grey[500]}`}
    />
  );
};

export default UploadHeader;
