import { Box, Flex } from "@chakra-ui/react";

import H3 from "../../ui/H3";
import Text from "../../ui/Text";
import { IRowWarning, IRowWarnings } from "../../graphql/queries/getMovements";
import Card from "../../ui/Card";

const RowWarnings = ({
  warnings,
  originalFilename,
}: {
  warnings: IRowWarnings;
  originalFilename: string;
}) => {
  return (
    <Card>
      <Box p={4} height="100%">
        <Flex mb={4} w="100%" justifyContent="space-between">
          <H3 size="md">WARNINGS</H3>
          <Text>
            <b>Filename:</b> {originalFilename ?? "--"}
          </Text>
        </Flex>
        {warnings?.warnings?.map((w: IRowWarning) => (
          <Flex key={`${w.code}-${w.row}`} direction="column" mb={4}>
            <Flex>
              <Text>
                <b>
                  ({w.section} {w.code})
                </b>
              </Text>
              <Text style={{ marginLeft: 20 }}>
                <b>Row:</b> {w?.row} <b style={{ marginLeft: 20 }}>Column:</b>{" "}
                {w?.column}
              </Text>
            </Flex>
            <Flex mt={1}>
              <Text>
                <b>Message:</b> {w?.message}
              </Text>
              {w.value && (
                <Text style={{ marginLeft: 20 }}>
                  <b>Value:</b> {w.value}
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </Box>
    </Card>
  );
};

export default RowWarnings;
