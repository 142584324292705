import { gql } from "@apollo/client";

export const CREATE_UPLOAD = gql`
  mutation createUpload(
    $originalFilename: String!
    $accountId: String!
    $uploadedByAccountId: String!
  ) {
    createMovementsUpload(
      input: {
        originalFilename: $originalFilename
        accountId: $accountId
        uploadedByAccountId: $uploadedByAccountId
      }
    ) {
      errors
      upload {
        filename
        uploadUrl
      }
    }
  }
`;

export interface ICreateUploadParams {
  originalFilename: string;
  accountId: string;
  uploadedByAccountId: string;
}

export interface ICreateUploadResponse {
  createMovementsUpload: {
    errors?: string[];
    upload?: {
      filename: string;
      uploadUrl: string;
    };
  };
}
