import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const MOVEMENTS_GEO_SUMMARY_QUERY = gql`
  query GetGeoMovementsSummary(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    movementsGeoSummary(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      poiGroupIds: $poiGroupIds
      supplierIds: $supplierIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      producerName
      producerAddress
      producerId
      producerPoiLat
      producerPoiLng
      receiverId
      receiverPermit
      receiverName
      receiverPoiLat
      receiverPoiLng
      weightInKgs
      wasteCodes
      activitiesCount
    }
  }
`;

export interface IMovementsGeoSummary {
  producerName: string;
  producerAddress: string;
  producerId: string;
  producerPoiLat: number;
  producerPoiLng: number;
  receiverId: string;
  receiverName: string;
  receiverPoiLat: number;
  receiverPoiLng: number;
  weightInKgs: number;
  wasteCodes: string;
  activitiesCount: number;
}

export interface IMovementsGeoSummaryResult {
  movementsGeoSummary: IMovementsGeoSummary[];
}

export type IMovementsGeoSummaryParams = IFilters;
