import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ICellErrorsSummary } from "../../../graphql/queries/getMovementsUploads";
import TrText from "../../../i18n/TrText";

export interface IUICellErrorSummary extends ICellErrorsSummary {
  column: string;
}
const CellErrorsSummaries = ({
  summaries,
  maxRows,
}: {
  summaries: IUICellErrorSummary[];
  maxRows: number;
}) => {
  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>
              <TrText message="table_headings.count" />
            </Th>
            <Th>
              <TrText message="table_headings.column" />
            </Th>
            <Th>
              <TrText message="table_headings.message" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {summaries.slice(0, maxRows).map((s) => (
            <Tr key={s.column}>
              <Td>{s.count}</Td>
              <Td>{s.column}</Td>
              <Td>{s.message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {summaries.length > maxRows && (
        <p>
          <TrText
            message="upload.errors_count"
            options={{ count: summaries.length - maxRows }}
          />
        </p>
      )}
    </>
  );
};

export default CellErrorsSummaries;
