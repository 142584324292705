import { useParams, useHistory } from "react-router-dom";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { Icon } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";

import Text from "../../ui/Text";
import LocationChart from "./LocationChart";
import { ProducerCard } from "./components/ProducerCard";
import { MaterialCard } from "./components/MaterialCard";
import { CarrierCard } from "./components/CarrierCard";
import { ReceiverCard } from "./components/ReceiverCard";
import {
  SINGLE_MOVEMENT_QUERY,
  ISingleMovementResult,
  IMovementParams,
} from "./../../graphql/queries/getMovements";
import GhostButton from "../../ui/GhostButton";
import { colors } from "../../ui/theme";
import { ActivityDetailsCard } from "./components/ActivityDetailsCard";
import RowWarnings from "./RowWarnings";
import { getFormattedDistanceForUser } from "../../aux/userUtils";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../graphql/queries/getCurrentUser";
import TrText from "../../i18n/TrText";

const WasteMovementDetails = () => {
  const history = useHistory();

  let { movementId } = useParams<{ movementId: string }>();
  const { data, loading } = useQuery<ISingleMovementResult, IMovementParams>(
    SINGLE_MOVEMENT_QUERY,
    {
      variables: { movementId: movementId },
    }
  );
  const { data: currentUserResult } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);
  const currentUser = currentUserResult?.currentUser;

  if (data && data.movement) {
    const m = data.movement;
    return (
      <>
        <Flex
          align="flex-start"
          justify="flex-start"
          direction={["column"]}
          p={8}
        >
          <Box mb={4} ml={-5}>
            <GhostButton onClick={history.goBack}>
              <Flex direction="row" align="center" justify="flex-start">
                <Icon
                  w={6}
                  h={6}
                  mr={4}
                  as={MdArrowBack}
                  color={colors.new.text.blue[500]}
                />
                <Text style={{ textDecoration: "underline" }}>
                  <TrText message="app.go_back" />
                </Text>
              </Flex>
            </GhostButton>
          </Box>

          <Flex
            align="flex-start"
            justify="flex-start"
            direction={["column", "column", "column", "row"]}
            w="100%"
          >
            <Box
              w={["100%", "100%", "100%", "50%"]}
              mr={[0, 0, 0, 4]}
              h={[null, null, null, 500]}
            >
              <ActivityDetailsCard
                sourceMovementId={m.sourceMovementId}
                distance={getFormattedDistanceForUser(
                  m.primaryMovementDistanceMiles,
                  m.primaryMovementDistanceKms,
                  currentUser
                )}
                filename={
                  m.movementsUpload.originalFilename
                    ? m.movementsUpload.originalFilename
                    : "--"
                }
                sheetRowNumber={m.sheetRowNumber ? m.sheetRowNumber : 0}
                auhorityMovementId={m?.authorityMovementId ?? "--"}
                auhorityMovementDescription={
                  m?.authorityMovementDescription ?? "--"
                }
                supplier={m?.supplierAccountName}
              />
            </Box>

            <Box
              w={["100%", "100%", "100%", "50%"]}
              h={500}
              ml={[0, 0, 0, 4]}
              mt={[4, 4, 4, 0]}
            >
              <LocationChart
                produceLon={m.producerPoiLng || m.producerLng}
                produceLat={m.producerPoiLat || m.producerLat}
                receiverLon={m.receiverPoiLng || m.receiverLng}
                receiverLat={m.receiverPoiLat || m.receiverLat}
                routeGeojson={m?.routeGeojson ? m.routeGeojson : null}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          direction={["column", "column", "column", "row"]}
          px={8}
          mb={8}
        >
          <Box w={["100%", "100%", "100%", "25%"]} mr={4}>
            <ProducerCard movement={m} />
          </Box>

          <Box
            w={["100%", "100%", "100%", "25%"]}
            ml={[0, 0, 0, 4]}
            mr={4}
            mt={[4, 4, 4, 0]}
          >
            <MaterialCard movement={m} />
          </Box>

          <Box
            w={["100%", "100%", "100%", "25%"]}
            ml={[0, 0, 0, 4]}
            mr={4}
            mt={[4, 4, 4, 0]}
          >
            <CarrierCard movement={m} />
          </Box>

          <Box
            w={["100%", "100%", "100%", "25%"]}
            ml={[0, 0, 0, 4]}
            mt={[4, 4, 4, 0]}
          >
            <ReceiverCard movement={m} />
          </Box>
        </Flex>
        <Flex width="100%" direction="row" px={8}>
          {m.warnings && (
            <RowWarnings
              warnings={m.warnings}
              originalFilename={m.movementsUpload?.originalFilename}
            ></RowWarnings>
          )}
        </Flex>
      </>
    );
  } else if (loading) {
    return (
      <Flex p={4}>
        <Text>
          <TrText message="app.loading" />
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex p={4}>
        <Text>
          <TrText message="app.no_data" />
        </Text>
      </Flex>
    );
  }
};
export default WasteMovementDetails;
