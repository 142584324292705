/**
 *  Validates that latitude is between -90 and 90, inclusive.
 *  -90 <= latitude <= 90
 * */
export function isLatitudeValid(latitude: number) {
  if (typeof latitude !== "number") return false;
  if (latitude >= -90 && latitude <= 90) return true;
  return false;
}

/**
 *  Validates that longitude is between -180 and 180, inclusive.
 *  -180 <= longitude <= 180
 * */
export function isLongitudeValid(longitude: number) {
  if (typeof longitude !== "number") return false;
  if (longitude >= -180 && longitude <= 180) return true;
  return false;
}
