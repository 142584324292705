import { As, Button as ChakraButton } from "@chakra-ui/react";

import { colors } from "./../theme";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  rightIcon?: React.ReactElement;
  as?: As;
  href?: string;
  type?: "submit" | "reset" | "button";
};

const ButtonPrimary = (props: Props) => {
  const {
    children,
    onClick,
    isDisabled,
    isLoading,
    rightIcon,
    as,
    href,
    type,
  } = props;
  return (
    <ChakraButton
      as={as}
      href={href}
      type={type}
      color={colors.new.text.light[500]}
      bg={colors.new.primary[500]}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      _disabled={{
        background: "#CCCBC8",
        cursor: "not-allowed",
        _hover: { background: "#CCCBC8" },
      }}
      _hover={{ background: "#089860" }}
      rightIcon={rightIcon}
      borderRadius={6}
      fontSize="sm"
    >
      {children}
    </ChakraButton>
  );
};

export default ButtonPrimary;
