import {
  Flex,
  Box,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ExpandedIndex,
} from "@chakra-ui/react";

import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import UploadDetails from "./../../UploadDetails";
import { colors } from "../../../../ui/theme";
import {
  IMovementUploadsEdge,
  UploadStatusType,
} from "../../../../graphql/queries/getMovementsUploads";
import TrText from "../../../../i18n/TrText";
import { useState } from "react";

const getUploadStatusBadge = (status: UploadStatusType, deleted: boolean) => {
  if (deleted) {
    return <Badge colorScheme="blue">Deleted</Badge>;
  }
  switch (status) {
    case "waiting_for_upload":
      return <Badge colorScheme="grey">UPLOADING</Badge>;
    case "queued_for_processing":
      return <Badge colorScheme="yellow">QUEUED</Badge>;
    case "processing":
      return <Badge colorScheme="yellow">PROCESSING</Badge>;
    case "completed":
      return <Badge colorScheme="green">UPLOADED</Badge>;
    case "completed_with_warnings":
      return <Badge colorScheme="orange">UPLOADED WITH WARNINGS</Badge>;
    case "failed":
      return <Badge colorScheme="red">FAILED</Badge>;
  }
};

const UploadsTable = ({ uploads }: { uploads: IMovementUploadsEdge[] }) => {
  // keep track of which item is expanded so we can collapse if the item gets deleted
  const [expandedAccordianIndex, setExpandedAccordianIndex] =
    useState<number>(-1);
  const [expandedMovementUploadId, setExpandedMovementUploadId] =
    useState<string>();
  const setExpandedIndex = (index: ExpandedIndex) => {
    if (uploads && uploads.length > 0) {
      if (typeof index === "number" && index !== -1) {
        // expand the accordian and track the expanded node
        setExpandedAccordianIndex(index);
        setExpandedMovementUploadId(uploads[index].node.id);
      } else {
        // unexpand
        setExpandedAccordianIndex(-1);
        setExpandedMovementUploadId(undefined);
      }
    }
  };
  // check that the expanded node still exists in the list - it may have been deleted
  const safeExpandedIndex = uploads.find(
    ({ node }) => node.id === expandedMovementUploadId
  )
    ? expandedAccordianIndex
    : -1;

  return (
    <>
      <Flex px={4} mb={4}>
        <Flex w="30%">
          <Text>
            <TrText message="table_headings.filename" />
          </Text>
        </Flex>
        <Flex w="20%">
          <Text>
            <TrText message="table_headings.account" />
          </Text>
        </Flex>
        <Flex w="20%">
          <Text>
            <TrText message="roles.uploader" />
          </Text>
        </Flex>
        <Flex w="15%">
          <Text>
            <TrText message="table_headings.date" />
          </Text>
        </Flex>
        <Flex w="15%" justifyContent="center">
          <Text>
            <TrText message="table_headings.status" />
          </Text>
        </Flex>
        <Box w="25px" textAlign="right"></Box>
      </Flex>

      <Card flexGrow={0}>
        <Accordion
          allowToggle
          onChange={(expandedIndex) => setExpandedIndex(expandedIndex)}
          index={safeExpandedIndex}
        >
          {uploads.map((node) => {
            const upload = node.node;
            const date = new Date(upload.createdAt);
            const options: {
              year: "numeric";
              month: "numeric";
              day: "numeric";
              hour: "numeric";
              minute: "numeric";
              second: "numeric";
              hour12: boolean;
            } = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
            };

            return (
              <AccordionItem key={upload.id}>
                <AccordionButton
                  _hover={{ bg: colors.new.background_light[500] }}
                  _expanded={{ bg: colors.new.background_light[500] }}
                >
                  <Flex justifyContent="space-between" w="100%">
                    <Flex w="30%" textAlign="left" alignItems="center">
                      <Text>{upload.originalFilename}</Text>
                    </Flex>

                    <Flex w="20%" textAlign="left" alignItems="center">
                      <Text>{upload.account.name}</Text>
                    </Flex>

                    <Box w="20%" textAlign="left">
                      <Text>{upload.user.name}</Text>
                    </Box>

                    <Box w="15%" textAlign="left" alignItems="center">
                      <Text>
                        {new Intl.DateTimeFormat("en-UK", options).format(date)}
                      </Text>
                    </Box>

                    <Flex w="15%" alignItems="center" justifyContent="center">
                      {getUploadStatusBadge(upload.status, upload.deleted)}
                    </Flex>

                    <Box w="25px" textAlign="right">
                      <AccordionIcon />
                    </Box>
                  </Flex>
                </AccordionButton>

                <AccordionPanel py={4} bg={colors.new.background_light[500]}>
                  <Flex w="100%" direction="row">
                    <UploadDetails upload={upload} />
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Card>
    </>
  );
};

export default UploadsTable;
