import { useMutation } from "@apollo/client";
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import AvatarEditor, { ImageState } from "react-avatar-editor";
import Dropzone from "react-dropzone";
import profilePlaceholder from "../../../../../assets/images/avatars/profile-placeholder.png";
import {
  IUpdateUserAvatarParams,
  IUpdateUserAvatarResponse,
  UPDATE_USER_AVATAR,
} from "../../../../../graphql/mutations/updateUserAvatar";
import { GET_CURRENT_USER } from "../../../../../graphql/queries/getCurrentUser";
import translate from "../../../../../i18n/translate";
import TrText from "../../../../../i18n/TrText";
import ButtonPrimary from "../../../../../ui/ButtonPrimary";
import DangerButton from "../../../../../ui/DangerButton";
import { colors } from "../../../../../ui/theme";
interface AvatarProps {
  userId: string;
  avatarUrl: string | undefined | null;
}

const Avatar = (props: AvatarProps) => {
  const { avatarUrl } = props;

  const [avatar, setAvatar] = useState<File>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [scale, setScale] = useState(1);
  const [minScale, setMinScale] = useState(1);
  const [maxScale, setMaxScale] = useState(100);

  const [updateAvatar] = useMutation<
    IUpdateUserAvatarResponse,
    IUpdateUserAvatarParams
  >(UPDATE_USER_AVATAR, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const toast = useToast();

  const onDrop = (dropped: File[]) => {
    if (dropped.length > 0) {
      setAvatar(dropped[0]);
    }
  };

  const onLoadSuccess = (imgInfo: ImageState) => {
    // work out the default scale to fit the image into the canvas
    const scale = Math.min(imgInfo.height / 300, imgInfo.width / 300);
    setScale(scale * 100);
    setMinScale(scale * 100);
    setMaxScale(5 * scale * 100);
  };

  const editorRef = useRef<AvatarEditor>(null);

  const uploadAvatar = () => {
    const editor = editorRef.current;
    const image = editor?.getImageScaledToCanvas();
    if (image) {
      setUploading(true);
      image.toBlob(async (blob) => {
        if (blob) {
          const formData = new FormData();
          formData.append("avatar", blob);
          formData.append("userId", props.userId);
          try {
            const { data } = await updateAvatar({
              variables: {
                userId: props.userId,
                avatar: blob,
              },
            });
            if ((data?.updateUserAvatar.errors || []).length === 0)
              toast({
                title: translate("app.success"),
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            setUploading(false);
            setAvatar(undefined);
            return;
          } catch (error) {
            console.error(error);
          }
          toast({
            title: translate("app.error"),
            description: translate("app.try_again"),
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
        setUploading(false);
        setAvatar(undefined);
      });
    }
  };

  return (
    <Flex direction="column">
      <Label>
        <TrText message="profile_settings.profile_image" />
      </Label>

      <Dropzone
        disabled={uploading}
        accept={{
          "image/*": [".jpeg", ".png"],
        }}
        noClick={!!avatar}
        maxFiles={1}
        maxSize={1000000}
        onDrop={(files) => onDrop(files)}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const borderColor = isDragAccept
            ? colors.new.primary["500"]
            : isDragReject
            ? "red"
            : "transparent";
          const cursor = isDragActive
            ? isDragReject
              ? "not-allowed"
              : "pointer"
            : "pointer";
          return (
            <Container
              {...getRootProps()}
              style={{
                cursor: cursor,
                border: `2px solid ${borderColor}`,
              }}
            >
              {avatar ? (
                <>
                  <AvatarEditor
                    ref={editorRef}
                    onLoadSuccess={onLoadSuccess}
                    width={300}
                    height={300}
                    scale={scale / 100}
                    image={avatar}
                    borderRadius={150}
                    color={[255, 255, 255, 0.9]}
                  />
                  <Slider
                    mt={4}
                    aria-label="zoom"
                    value={scale}
                    min={minScale}
                    max={maxScale}
                    onChange={(value) => {
                      setScale(value);
                    }}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <Flex mt={4} direction="row" alignContent="flex-end">
                    <DangerButton
                      isDisabled={uploading}
                      onClick={() => {
                        setAvatar(undefined);
                      }}
                    >
                      <TrText message="app.cancel" />
                    </DangerButton>
                    <Box ml={4}>
                      <ButtonPrimary
                        isLoading={uploading}
                        onClick={() => {
                          uploadAvatar();
                        }}
                      >
                        <TrText message="app.apply" />
                      </ButtonPrimary>
                    </Box>
                  </Flex>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: 350,
                      height: 350,
                      backgroundImage: `url(${
                        avatarUrl || profilePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: 180,
                      border: "2px dashed #ccc",
                    }}
                  />
                  <div style={{ textAlign: "center", marginTop: 20 }}>
                    <TrText message="profile_settings.profile_image_instructions" />
                  </div>
                </>
              )}
              <input {...getInputProps()} />
            </Container>
          );
        }}
      </Dropzone>
    </Flex>
  );
};

const Label = styled.span`
  margin-bottom: 4px;
`;

const Container = styled.div`
  background: ${colors.new.text.light[500]};
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  padding: 20px;
  cursor: not-allowed;
`;

export default Avatar;
