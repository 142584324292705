import { useRef, useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";

import {
  isLongitudeValid,
  isLatitudeValid,
} from "../../aux/validateCoordinates";
import { mapboxgl, isMapboxSupported } from "../../services/mapbox";
import Text from "../../ui/Text";

const LocationChart = (props) => {
  const [canDrawMap, setCanDrawMap] = useState(true);

  let wasteMapRef = useRef(null);
  const { longitude, latitude, name, address } = props;
  let map;

  useEffect(() => {
    if (isMapboxSupported) {
      if (isLongitudeValid(longitude) && isLatitudeValid(latitude)) {
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [longitude, latitude],
          zoom: 9,
        });

        new mapboxgl.Marker()
          .setLngLat([longitude, latitude])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `<b>${name}</b> <br />
              ${address} <br />
              Lon: ${longitude} <br />
              Lat: ${latitude}`
            )
          )
          .addTo(map);
      } else {
        // No useful location data
        setCanDrawMap(false);
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [longitude, latitude, map]);

  if (canDrawMap && isMapboxSupported) {
    return (
      <div
        ref={(elem) => (wasteMapRef = elem)}
        style={{
          height: "100%",
          borderRadius: "10px",
        }}
      />
    );
  } else if (!canDrawMap) {
    // There is no location data for both producer and receiver,
    // can't draw anything
    // TODO A bit more useful UI should go here.
    return (
      <Flex
        justifyContent="center"
        alignItems={"center"}
        h="100%"
        flexDirection={"column"}
        flex={1}
      >
        <Text>No location data available.</Text>
      </Flex>
    );
  } else {
    return <Text>Map is not supported in this browser.</Text>;
  }
};

export default LocationChart;
