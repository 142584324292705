import { Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { Flex, Link } from "@chakra-ui/react";

import Text from "../../../../../ui/Text";
import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import { ISuppliersSummary } from "../../../../../graphql/queries/getSuppliersSummary";
import { colors } from "../../../../../ui/theme";

type Props = {
  supplier: ISuppliersSummary;
  setSupplierSelected: Dispatch<SetStateAction<ISuppliersSummary | null>>;
  distanceUnits: "miles" | "kilometers" | undefined;
};

export const SupplierRow = (props: Props) => {
  const { supplier, distanceUnits, setSupplierSelected } = props;

  const totalMaterialWeight = supplier?.totalWeight || 0;

  const validationPctFormatted = `${formatToDecimalPlaces(
    supplier?.validationPercentage,
    1
  )}%`;

  const landfillPctFormatted = `${formatToDecimalPlaces(
    supplier?.landfillFatePercentage,
    1
  )}%`;

  const divertedPctFormatted = `${formatToDecimalPlaces(
    supplier?.landfillDiversionFatePercentage,
    1
  )}%`;

  const unknownPctFormatted = `${formatToDecimalPlaces(
    supplier?.unknownFatePercentage,
    1
  )}%`;

  const distancePerMovementKmsFormatted = `${formatToDecimalPlaces(
    supplier?.distancePerMovementKms,
    2
  )} kms`;
  const distancePerMovementMilesFormatted = `${formatToDecimalPlaces(
    supplier?.distancePerMovementMiles,
    2
  )} miles`;

  const co2PerMovement = () => {
    if (supplier.movementCount === 0) {
      return "0";
    } else {
      return formatToDecimalPlaces(
        supplier.primaryCo2Kgs / supplier.movementCount,
        4
      );
    }
  };

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      w="100%"
      _notLast={{
        borderBottom: "1px solid #e2e8f0",
      }}
    >
      <Flex w="28%" textAlign="left" alignItems="center" pr={2}>
        <Link
          style={{ color: colors.new.text.blue[500] }}
          fontWeight="bold"
          onClick={() => setSupplierSelected(supplier)}
        >
          <Text>{supplier?.supplierName}</Text>
        </Link>
      </Flex>

      <Cell w="9%">
        <Text>{supplier.movementCount}</Text>
      </Cell>

      <Cell w="9%">
        <Text>
          {totalMaterialWeight === 0
            ? `0kg`
            : `${formatToDecimalPlaces(totalMaterialWeight / 1000, 3)}t`}
        </Text>
      </Cell>

      {/* Validation */}
      <Cell w="9%">
        <Text>{validationPctFormatted}</Text>
      </Cell>

      {/* Landfill */}
      <Cell w="9%">
        <Text>{landfillPctFormatted}</Text>
      </Cell>

      {/* Diverted */}
      <Cell w="9%">
        <Text>{divertedPctFormatted}</Text>
      </Cell>

      {/* Unknown */}
      <Cell w="9%">
        <Text>{unknownPctFormatted}</Text>
      </Cell>

      {/* DistancePerMovement */}
      <Cell w="9%">
        <Text>
          {distanceUnits === "miles"
            ? distancePerMovementMilesFormatted
            : distancePerMovementKmsFormatted}
        </Text>
      </Cell>

      {/* CO2 per movement */}
      <Cell w="9%">
        <Text>{co2PerMovement()}kg</Text>
      </Cell>
    </Flex>
  );
};

const Cell = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 32px;
`;
