import { Flex } from "@chakra-ui/react";
import TrText from "../../../../../i18n/TrText";

import Text from "./../../../../../ui/Text";

export const TotalWeightPerMonthTooltip = () => {
  return (
    <Flex direction="column">
      <Text fontSize="13px">
        <b>
          <TrText message="table_headings.actual_weight" />:
        </b>{" "}
        <TrText message="table_headings.actual_weight_details" />
      </Text>
      <Text fontSize="13px">
        <b>
          <TrText message="table_headings.assumed_weight" />:
        </b>{" "}
        <TrText message="table_headings.assumed_weight_details" />
      </Text>
      <Text fontSize="13px">
        <b>
          <TrText message="table_headings.estimated_weight" />:
        </b>{" "}
        <TrText message="table_headings.estimated_weight_details" />
      </Text>
    </Flex>
  );
};
