import { useEffect } from "react";
import { Flex, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { Icon } from "@chakra-ui/react";
import { GoSettings } from "react-icons/go";

import { colors } from "../../../../ui/theme";
import SimpleFilterSummary from "./../../../../components/SimpleFilter/components/SimpleFilterSummary";
import { useFiltersFromQueryParams } from "./../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import TrText from "../../../../i18n/TrText";
import { SaveFilter } from "../../../../components/SimpleFilter/components/SaveFilter";
import TabSelector from "../../../../ui/TabSelector";
import Text from "../../../../ui/Text";

type TabsType =
  | "overview"
  | "sites"
  | "groups"
  | "suppliers"
  | "carriers"
  | "receivers"
  | "activities"
  | "mapview";
const tabsAllowedValues: TabsType[] = [
  "overview",
  "sites",
  "groups",
  "suppliers",
  "carriers",
  "receivers",
  "activities",
  "mapview",
];

type Props = {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
};

const AnalyticsHeader = (props: Props) => {
  const { toggleSimpleFilter, isOpen } = props;

  const [tab, setTab] = useQueryParam("tab", StringParam);
  useEffect(() => {
    if (!tab) {
      setTab("overview");
      // @ts-ignore
    } else if (!tabsAllowedValues.includes(tab)) {
      setTab("overview");
    }
  }, [tab, setTab]);

  const filters = useFiltersFromQueryParams();

  const isSelected = (value: TabsType) => {
    if (value === tab) {
      return true;
    } else return null;
  };
  return (
    <header style={{ width: "100%", height: 72 }}>
      <Flex
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        mb={0}
        px={0}
        pl={4}
        bg={"#fff"}
        h="72px"
        borderBottom={`1px solid ${colors.new.grey[500]}`}
      >
        <NavContainer aria-label="analytics-tabs">
          <TabSelector
            onClick={() => setTab("overview")}
            isSelected={isSelected("overview")}
          >
            <Text fontSize="md">
              <TrText message="nav.overview" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("sites")}
            isSelected={isSelected("sites")}
          >
            <Text fontSize="md">
              <TrText message="nav.sites" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("groups")}
            isSelected={isSelected("groups")}
          >
            <Text fontSize="md">
              <TrText message="nav.groups" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("suppliers")}
            isSelected={isSelected("suppliers")}
          >
            <Text fontSize="md">
              <TrText message="nav.suppliers" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("carriers")}
            isSelected={isSelected("carriers")}
          >
            <Text fontSize="md">
              <TrText message="nav.carriers" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("receivers")}
            isSelected={isSelected("receivers")}
          >
            <Text fontSize="md">
              <TrText message="nav.receivers" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("activities")}
            isSelected={isSelected("activities")}
          >
            <Text fontSize="md">
              <TrText message="nav.activities" />
            </Text>
          </TabSelector>
          <TabSelector
            onClick={() => setTab("mapview")}
            isSelected={isSelected("mapview")}
          >
            <Text fontSize="md">
              <TrText message="nav.map" />
            </Text>
          </TabSelector>
        </NavContainer>

        <Flex align="center" justify="space-between">
          <Flex align="center" justify="space-between" h="100%"></Flex>

          <Flex
            pr={8}
            h="100%"
            align="center"
            justify="center"
            style={{ visibility: isOpen ? "hidden" : "visible" }}
            // Accessibility: we hide it when the filter is open, so
            // it can't be accessed by keyboard
          >
            <SaveFilter />
            <Button
              ml={2}
              leftIcon={
                <Icon
                  w={6}
                  h={6}
                  mr={2}
                  as={GoSettings}
                  color={colors.new.text.light[500]}
                />
              }
              color={colors.new.text.light[500]}
              bg={colors.new.blue[500]}
              onClick={toggleSimpleFilter}
              justifyContent="flex-start"
              borderTopRightRadius={0}
              borderTopLeftRadius={6}
              borderBottomLeftRadius={6}
              borderBottomRightRadius={0}
              _hover={{
                background: "#1c3270",
              }}
              fontSize="sm"
            >
              Filters
            </Button>

            <SimpleFilterSummary filters={filters} />
          </Flex>
        </Flex>
      </Flex>
    </header>
  );
};

const NavContainer = styled("nav")`
  height: 100%;
`;

export default AnalyticsHeader;
