import { memo } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import Loading from "../../../../components/Loading";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import H1 from "../../../../ui/H1";
import Top10Sites from "../../components/Top10Sites";
import AlertsTable from "../../components/AlertsTable";
import CardHeader from "./../../../../components/CardHeader";
import CardContent from "./../../../../components/CardContent";
import CardH2 from "./../../../../components/CardH2";
import WasteStreamBarChart from "../../../../charts/WasteStreamBarChart";
import PrimaryMovementValidationChart from "../../../../charts/PrimaryMovementValidationChart";
import DestinationChart from "../../../../charts/DestinationChart";
import EndFatesBarChart from "../../../../charts/EndFatesBarChart";
import { TotalWeightPerMonthTooltip } from "./components/Tooltips";
import {
  IMovementsSummaryParams,
  IMovementsSummaryResult,
  MOVEMENTS_SUMMARY_QUERY,
} from "../../../../graphql/queries/getMovementsSummary";
import TotalMovements from "./components/TotalMovements";
import ValidatedPrimaryMovements from "./components/ValidatedPrimaryMovements";
import TotalMaterialWeight from "./components/TotalMaterialWeight";
import TotalPrimaryDistance from "./components/TotalPrimaryDistance";
import TotalPrimaryTransportCO2 from "./components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "./../../../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "./../../../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "./../../../../components/TotalUnknownMaterial";
import {
  ITop10ProducerSitesParams,
  ITop10ProducerSitesResults,
  TOP10_PRODUCER_SITES_QUERY,
} from "../../../../graphql/queries/getTop10ProducerSites";
import {
  IWasteStreamsParams,
  IWasteStreamsResult,
  WASTE_STREAMS_QUERY,
} from "../../../../graphql/queries/getWasteStreams";
import {
  ITotalWeightPerMonthParams,
  ITotalWeightPerMonthResult,
  TOTAL_WEIGHT_PER_MONTH_QUERY,
} from "../../../../graphql/queries/getTotalWeightPerMonth";
import TotalWeightPerMonthChart from "../../../../charts/TotalWeightPerMonthChart";
import {
  IWasteEndFatesParams,
  IWasteEndFatesResult,
  WASTE_END_FATES_QUERY,
} from "../../../../graphql/queries/getWasteEndFates";
import {
  IDestinationFatesParams,
  IPrimaryDestinationFatesResult,
  ISecondaryDestinationFatesResult,
  PRIMARY_DESTINATION_FATES_QUERY,
  SECONDARY_DESTINATION_FATES_QUERY,
} from "../../../../graphql/queries/getDestinationFates";
import {
  IMovementsWithIssuesResult,
  MOVEMENTS_WITH_ISSUES,
} from "../../../../graphql/queries/getMovementsWithIssues";
import InfoBox from "../../../../components/InfoBox";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

const Overview = () => {
  const filters = useFiltersFromQueryParams();

  const { data: currentUserResults } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const {
    data: movementsSummaryData,
    loading: isLoadingMovementsSummaryData,
    error: isErrorMovementsSummaryData,
  } = useQuery<IMovementsSummaryResult, IMovementsSummaryParams>(
    MOVEMENTS_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const {
    data: top10ProducerSitesData,
    loading: loadingTop10ProducerSitesData,
  } = useQuery<ITop10ProducerSitesResults, ITop10ProducerSitesParams>(
    TOP10_PRODUCER_SITES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const { data: wasteStreamsData, loading: loadingWasteStreamsData } = useQuery<
    IWasteStreamsResult,
    IWasteStreamsParams
  >(WASTE_STREAMS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: filters,
  });

  const { data: wasteEndFatesData, loading: loadingWasteEndFatesData } =
    useQuery<IWasteEndFatesResult, IWasteEndFatesParams>(
      WASTE_END_FATES_QUERY,
      {
        fetchPolicy: "cache-and-network",
        variables: filters,
      }
    );

  const {
    data: totalWeightPerMonthData,
    loading: loadingTotalWeightPerMonthData,
  } = useQuery<ITotalWeightPerMonthResult, ITotalWeightPerMonthParams>(
    TOTAL_WEIGHT_PER_MONTH_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const {
    data: primaryDestinationData,
    loading: loadingPrimaryDestinationData,
  } = useQuery<IPrimaryDestinationFatesResult, IDestinationFatesParams>(
    PRIMARY_DESTINATION_FATES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const {
    data: secondaryDestinationData,
    loading: loadingSecondaryDestinationData,
  } = useQuery<ISecondaryDestinationFatesResult, IDestinationFatesParams>(
    SECONDARY_DESTINATION_FATES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const { data: movementErrorsData, loading: loadingMovementErrorsData } =
    useQuery<IMovementsWithIssuesResult>(MOVEMENTS_WITH_ISSUES, {
      fetchPolicy: "cache-and-network",
      variables: {
        first: 10,
        startDate: filters.startDate,
        endDate: filters.endDate,
        wasteCodes: filters.wasteCodes ? filters.wasteCodes : null,
        poiIds: filters.poiIds ? filters.poiIds : null,
        carrierName: filters.carrierName ? filters.carrierName : null,
        supplierIds: filters.supplierIds ? filters.supplierIds : null,
      },
    });

  const currentUser = currentUserResults?.currentUser;
  const top10Producers = top10ProducerSitesData?.topProducers;
  const movementsSummary = movementsSummaryData?.movementsSummary;
  const wasteStreams = wasteStreamsData?.wasteStreams;
  const wasteEndFates = wasteEndFatesData?.wasteEndFates;
  const totalWeightPerMonth = totalWeightPerMonthData?.totalWeightPerMonth;
  const primaryDestinations = primaryDestinationData?.primaryDestinationFates;
  const secondaryDestinations =
    secondaryDestinationData?.secondaryDestinationFates;
  const movementErrors = movementErrorsData?.movementsWithIssues;
  const totalErrorsCount = movementErrorsData?.movementsWithIssues?.totalCount;

  const totalMaterialWeight = movementsSummary?.totalWeight || 0;
  const totalPrimaryMiles = movementsSummary?.totalPrimaryDistanceMiles || 0;
  const totalPrimaryKms = movementsSummary?.totalPrimaryDistanceKms || 0;
  const totalPrimaryCo2Kgs = movementsSummary?.totalPrimaryCo2Kgs || 0;

  const divertedFromLandfillPercentage =
    movementsSummary?.landfillDiversionFatePercentage || 0;

  const landfillPercentage = movementsSummary?.landfillFatePercentage || 0;

  const unknownPercentage = movementsSummary?.unknownFatePercentage || 0;

  const marginValue = 4;

  return (
    <Flex direction="column" w="100%" px={8} pt={8}>
      <Flex w="100%" h={74} alignItems="flex-start">
        <H1>
          <TrText message="overview.title" />
        </H1>
      </Flex>

      <Flex justify="stretch" align="center" w="100%">
        <Box w="25%" mr={marginValue}>
          <ValidatedPrimaryMovements
            isLoading={isLoadingMovementsSummaryData}
            movementsSummary={movementsSummary}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalMaterialWeight
            isLoading={isLoadingMovementsSummaryData}
            weight={totalMaterialWeight}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalPrimaryDistance
            isLoading={isLoadingMovementsSummaryData}
            miles={totalPrimaryMiles}
            kms={totalPrimaryKms}
            currentUser={currentUser}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalPrimaryTransportCO2
            isLoading={isLoadingMovementsSummaryData}
            kgs={totalPrimaryCo2Kgs}
          />
        </Box>
      </Flex>

      <Flex justify="stretch" align="center" w="100%" direction="row" mt="20px">
        <Box w="25%" mr={marginValue}>
          <TotalMovements
            isLoading={isLoadingMovementsSummaryData}
            movementsSummary={movementsSummary}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalDivertedFromLandfill
            isLoading={isLoadingMovementsSummaryData}
            totalWeight={totalMaterialWeight}
            divertedFromLandfillPercentage={divertedFromLandfillPercentage}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalMaterialLandfill
            isLoading={isLoadingMovementsSummaryData}
            totalWeight={totalMaterialWeight}
            landfillPercentage={landfillPercentage}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalUnknownMaterial
            isLoading={isLoadingMovementsSummaryData}
            totalWeight={totalMaterialWeight}
            unknownPercentage={unknownPercentage}
          />
        </Box>
      </Flex>

      <Flex w="100%" direction="row" mt="40px">
        <Box w={"40%"} mr={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.primary_activities_validation_title" />
              </CardH2>
            </CardHeader>
            <CardContent>
              <PrimaryMovementValidationChart
                movementsSummary={movementsSummary}
                isLoading={isLoadingMovementsSummaryData}
                isError={!!isErrorMovementsSummaryData}
              />
            </CardContent>
          </Card>
        </Box>
        <Box w={"60%"} ml={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader
              style={{
                justifyContent: "space-between",
              }}
            >
              <CardH2>
                <TrText message="overview.activities_issues_title" />
              </CardH2>
              <Text>
                <TrText message="app.total" />:{" "}
                {totalErrorsCount ? totalErrorsCount : "--"}
              </Text>
            </CardHeader>
            <CardContent isLoading={loadingMovementErrorsData}>
              {loadingMovementErrorsData ? (
                <Loading />
              ) : (
                <AlertsTable data={movementErrors} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Flex>

      <Flex w={"100%"} mt="40px" minH={500}>
        <Box w="33.3%" mr={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.total_weight_per_month_title" />
              </CardH2>
              <Flex>
                <InfoBox component={<TotalWeightPerMonthTooltip />} />
              </Flex>
            </CardHeader>
            <CardContent isLoading={loadingTotalWeightPerMonthData}>
              {loadingTotalWeightPerMonthData ? (
                <Loading />
              ) : (
                <TotalWeightPerMonthChart
                  chartData={totalWeightPerMonth}
                  includeActualWeight={filters.includeActualWeight}
                  includeAssumedWeight={filters.includeAssumedWeight}
                  includeEstimatedWeight={filters.includeEstimatedWeight}
                />
              )}
            </CardContent>
          </Card>
        </Box>

        <Box
          w="33.3%"
          ml={marginValue}
          mr={marginValue}
          display="flex"
          flexDirection="column"
        >
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.topo_10_site_by_weight_title" />
              </CardH2>
            </CardHeader>
            <CardContent isLoading={loadingTop10ProducerSitesData}>
              {loadingTop10ProducerSitesData ? (
                <Loading />
              ) : (
                <Top10Sites top10Producers={top10Producers} />
              )}
            </CardContent>
          </Card>
        </Box>

        <Box w="33.3%" ml={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.waste_streams_title" />
              </CardH2>
              <Flex>
                <InfoBox text="infobox.waste_streams" />
              </Flex>
            </CardHeader>
            <CardContent isLoading={loadingWasteStreamsData}>
              {loadingWasteStreamsData ? (
                <Loading />
              ) : (
                <WasteStreamBarChart wasteStreams={wasteStreams} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Flex>

      <Flex w={"100%"} mt="20px" mb={12} minH={500}>
        <Box w="33.3%" mr={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.primary_destination_title" />
              </CardH2>
              <Flex>
                <InfoBox text="infobox.primary_destination" />
              </Flex>
            </CardHeader>
            <CardContent isLoading={loadingPrimaryDestinationData}>
              {loadingPrimaryDestinationData ? (
                <Loading />
              ) : (
                <DestinationChart data={primaryDestinations} />
              )}
            </CardContent>
          </Card>
        </Box>

        <Box
          w="33.3%"
          ml={marginValue}
          mr={marginValue}
          display="flex"
          flexDirection="column"
        >
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.secondary_destination_title" />
              </CardH2>
              <Flex>
                <InfoBox text="infobox.secondary_destination" />
              </Flex>
            </CardHeader>
            <CardContent isLoading={loadingSecondaryDestinationData}>
              {loadingSecondaryDestinationData ? (
                <Loading />
              ) : (
                <DestinationChart data={secondaryDestinations} />
              )}
            </CardContent>
          </Card>
        </Box>

        <Box w="33.3%" ml={marginValue} display="flex" flexDirection="column">
          <Card>
            <CardHeader>
              <CardH2>
                <TrText message="overview.waste_end_fates_title" />
              </CardH2>
              <Flex>
                <InfoBox text="infobox.end_fates" />
              </Flex>
            </CardHeader>
            <CardContent isLoading={loadingWasteEndFatesData}>
              {loadingWasteEndFatesData ? (
                <Loading />
              ) : (
                <EndFatesBarChart data={wasteEndFates} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(Overview);
