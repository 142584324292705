import { Dispatch, SetStateAction } from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { MdArrowBack } from "react-icons/md";

import { IGroupsSummary } from "../../../../graphql/queries/getGroupsSummary";
import GhostButton from "../../../../ui/GhostButton";
import Text from "../../../../ui/Text";
import Tile from "../../../../ui/Tile";
import { formatToDecimalPlaces } from "../../../../aux/numbers";
import TotalMaterialWeight from "./components/TotalMaterialWeight";
import TotalPrimaryDistance from "./components/TotalPrimaryDistance";
import TotalPrimaryTransportCO2 from "./components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "./../../../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "./../../../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "./../../../../components/TotalUnknownMaterial";
import {
  GET_GROUP_POIS,
  IGroupPoisResult,
} from "../../../../graphql/queries/getGroupPois";
import H1 from "../../../../ui/H1";
import { colors } from "../../../../ui/theme";
import Card from "../../../../ui/Card";
import H2 from "../../../../ui/H2";
import SitesLocationsMap from "./components/SitesLocationsMap";
import Link from "../../../../ui/Link";
import TrText from "../../../../i18n/TrText";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";

type Props = {
  groupId: string;
  groupSelected: IGroupsSummary;
  setGroupSelected: Dispatch<SetStateAction<IGroupsSummary | null>>;
};

const marginValue = 4;

export const SingleGroupView = ({
  groupId,
  setGroupSelected,
  groupSelected,
}: Props) => {
  const { data, loading: loadingGroupsData } = useQuery<IGroupPoisResult>(
    GET_GROUP_POIS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: groupId,
      },
    }
  );

  const { data: currentUserResult } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const currentUser = currentUserResult?.currentUser;

  const group = data?.poiGroup;
  const pois = data?.poiGroup.pois;

  const totalMaterialWeight = groupSelected?.totalWeight || 0;
  const divertedFromLandfillPercentage =
    groupSelected?.landfillDiversionFatePercentage || 0;
  const landfillPercentage = groupSelected?.landfillFatePercentage || 0;

  const unknownPercentage = groupSelected?.unknownFatePercentage || 0;

  return (
    <Flex mt={4} direction="column">
      <Box mb={4} ml={-5}>
        <GhostButton onClick={() => setGroupSelected(null)}>
          <Flex direction="row" align="center" justify="flex-start">
            <Icon
              w={6}
              h={6}
              mr={4}
              as={MdArrowBack}
              color={colors.new.text.blue[500]}
            />
            <Text style={{ textDecoration: "underline" }}>
              <TrText message="app.go_back" />
            </Text>
          </Flex>
        </GhostButton>
      </Box>

      <Flex my={4}>
        <H1>{group?.name}</H1>
      </Flex>

      <Flex justify="stretch" align="center" w="100%">
        <Box w="25%" mr={marginValue}>
          {groupSelected && (
            <Tile
              title="overview.validated_card_title"
              iconName="truck_with_tick"
              bottomText={
                <TrText
                  message="overview.in_total_activities"
                  options={{
                    amount: groupSelected?.movementCount
                      ? groupSelected.movementCount
                      : "...",
                  }}
                />
              }
              value={
                groupSelected.movementCount
                  ? `${formatToDecimalPlaces(
                      ((groupSelected.movementCount -
                        groupSelected?.receiverValidationErrors) /
                        groupSelected.movementCount) *
                        100,
                      1
                    )}%`
                  : "No data"
              }
            />
          )}
        </Box>

        <Box w="25%" ml={marginValue} mr={marginValue}>
          {groupSelected && (
            <TotalMaterialWeight weight={groupSelected?.totalWeight} />
          )}
        </Box>

        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalPrimaryDistance
            miles={groupSelected.primaryDistanceMiles}
            kms={groupSelected.primaryDistanceKms}
            currentUser={currentUser}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalPrimaryTransportCO2 kgs={groupSelected.primaryCo2Kgs} />
        </Box>
      </Flex>

      <Flex justify="stretch" align="center" w="100%" direction="row" mt="20px">
        <Box w="25%" mr={marginValue}>
          <Tile
            title="overview.movements_card_title"
            bottomText="overview.in_total"
            iconName="just_track"
            value={String(groupSelected?.movementCount)}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalDivertedFromLandfill
            totalWeight={totalMaterialWeight}
            divertedFromLandfillPercentage={divertedFromLandfillPercentage}
            isLoading={loadingGroupsData}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalMaterialLandfill
            totalWeight={groupSelected.totalWeight}
            landfillPercentage={landfillPercentage}
            isLoading={loadingGroupsData}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalUnknownMaterial
            totalWeight={groupSelected.totalWeight}
            unknownPercentage={unknownPercentage}
            isLoading={loadingGroupsData}
          />
        </Box>
      </Flex>

      <Flex
        my={4}
        w={["100%"]}
        direction={["column", "column", "column", "row"]}
      >
        <Flex direction="column" w={["100%", "100%", "100%", "50%"]}>
          <Card>
            <Flex p={4} direction="column">
              <H2>
                <TrText message="groups.details" />
              </H2>
              <Box mt={2} />

              <Text>
                <TrText message="groups.group_name" />: {group?.name}
              </Text>
              <Text>
                <TrText message="groups.description" />:{" "}
                {group?.description ? group?.description : "--"}
              </Text>
              <Text>
                <TrText message="groups.custom_code" />:{" "}
                {group?.customCode ? group?.customCode : "--"}
              </Text>
              <Text>
                <TrText message="groups.number_of_sites" />:{" "}
                {group?.poisInGroupCount}
              </Text>
              <Text>
                <TrText message="groups.start_date" />:{" "}
                {group?.startDate ? group?.startDate : "--"}
              </Text>
              <Text>
                {" "}
                <TrText message="groups.end_date" />:{" "}
                {group?.endDate ? group?.endDate : "--"}
              </Text>
            </Flex>
          </Card>

          <Box mt={4} />

          <Card>
            <Flex p={4} direction="column" w="100%">
              <H2>
                <TrText message="groups.sites_in_group" />
              </H2>
              <Text>First 30 sites in the group.</Text>
              <Box mt={2} />

              <Flex direction="column" w="100%">
                {pois &&
                  pois.length > 0 &&
                  pois
                    .filter((_, i) => i < 30) // TODO pagination - show only the first 30 pois for now
                    .map((p, i) => {
                      return (
                        <Box key={p.id} py={2} w="100%">
                          <Flex
                            ml={0}
                            mb={0}
                            style={{ color: colors.new.text.blue[500] }}
                          >
                            <Link to={`/h/pois/${p?.topoSiteId}`}>
                              <Text>{p?.poiName}</Text>
                            </Link>
                          </Flex>
                          <Text>{p?.poiAddress}</Text>
                        </Box>
                      );
                    })}
              </Flex>
            </Flex>
          </Card>
        </Flex>

        <Box
          w={["100%", "100%", "100%", "50%"]}
          h={500}
          ml={[0, 0, 0, 8]}
          mt={[4, 4, 4, 0]}
        >
          <SitesLocationsMap pois={pois} />
        </Box>
      </Flex>
    </Flex>
  );
};
