import Chartjs from "chart.js/auto";

import { colors } from "../../ui/theme";
import { IWasteEndFates } from "../../graphql/queries/getWasteEndFates";
import { useCallback, useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import dayjs from "dayjs";
import TrText from "../../i18n/TrText";

const EndFatesBarChart = ({ data }: { data: IWasteEndFates[] | undefined }) => {
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  // create the chart
  const chartContainerRef = useCallback((canvasNode: any) => {
    if (chartInstance) {
      chartInstance.destroy();
    }
    if (canvasNode !== null) {
      const newChartInstance = new Chartjs(canvasNode, {
        type: "bar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              title: {
                display: true,
                text: "tonnes",
              },
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            title: {
              display: false,
              text: "Waste End Fates",
            },
          },
        },
      });
      setChartInstance(newChartInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate the chart
  useEffect(() => {
    if (data && chartInstance) {
      const fateColors = colors.charts.fates;
      // sort the data by month ascending
      const sortedData = sortBy(data, "month");
      // get the labels
      const labels = sortedData.map((row) =>
        dayjs(row.month).format("MMMM YYYY")
      );
      // we need a dataset for each of the end fates
      const endFates = [
        {
          key: "recoveryWeight",
          label: "Recovery",
          color: fateColors.recovery[40],
        },
        {
          key: "incinerationWeight",
          label: "Incineration",
          color: fateColors.incineration[40],
        },
        {
          key: "landfillWeight",
          label: "Landfill",
          color: fateColors.landfill[40],
        },
        {
          key: "transferWeight",
          label: "Transfer",
          color: fateColors.transfer[40],
        },
        {
          key: "unknownWeight",
          label: "Unknown",
          color: fateColors.unknown[40],
        },
        {
          key: "treatmentWeight",
          label: "Treatment",
          color: fateColors.treatment[40],
        },
        {
          key: "longTermStorageWeight",
          label: "Storage",
          color: fateColors.long_term_storage[40],
        },
      ];
      // convert the data into something the chart understands
      // the chart is weird it wants a dataset for each end fate group with data for each month inside
      const datasets = endFates.map((endFate) => ({
        label: endFate.label,
        data: sortedData.map((row) => (row as any)[endFate.key] / 1000),
        backgroundColor: endFate.color,
      }));
      chartInstance.data = { labels, datasets };
      try {
        chartInstance.update("none");
      } catch {
        // ignored - caused by hot reloading
      }
    }
  }, [data, chartInstance]);

  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        position: "relative",
        height: "350px",
        paddingBottom: "20px",
        width: "100%",
      }}
    >
      {data ? (
        <canvas id="wasteEndFatesChart" ref={chartContainerRef} />
      ) : (
        <div>
          <TrText message="app.loading" />
        </div>
      )}
    </div>
  );
};

export default EndFatesBarChart;
