import { IMovementsSummary } from "./../graphql/queries/getMovementsSummary";

/**
 * Format movements summary data to validation percentage.
 * @param {IMovementsSummary} data - Movements summary data
 * @returns {string} For example 23.5%
 * */
export const validationPercentage = (
  movementsSummary: IMovementsSummary | undefined
): string => {
  // Check for data shape
  if (
    movementsSummary &&
    typeof movementsSummary === "object" &&
    "activityCount" in movementsSummary &&
    "receiverValidationErrors" in movementsSummary
  ) {
    // It's not smart to divide by zero...
    if (movementsSummary.activityCount === 0) {
      return `0`;
    } else if (
      movementsSummary.receiverValidationErrors > movementsSummary.activityCount
    ) {
      // We don't want negative percentage
      return `0%`;
    } else {
      return `${calculatePercentage(
        movementsSummary.activityCount -
          movementsSummary.receiverValidationErrors,
        movementsSummary.activityCount
      )}%`;
    }
  } else {
    // The data input is malformed, return empty string
    return "";
  }
};

export const calculatePercentage = (numerator: number, denominator: number) => {
  const percent = (100 * numerator) / denominator;
  return (Math.round(percent * 10) / 10).toFixed(1);
};
