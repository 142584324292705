import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { ICellErrorDetail } from "../../../graphql/queries/getMovementsUploads";
import TrText from "../../../i18n/TrText";

export interface IUICellErrorDetail extends ICellErrorDetail {
  column: string;
}
const CellErrors = ({
  errors,
  maxRows,
}: {
  errors: IUICellErrorDetail[];
  maxRows: number;
}) => {
  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>
              <TrText message="table_headings.row" />
            </Th>
            <Th>
              <TrText message="table_headings.column" />
            </Th>
            <Th>
              <TrText message="table_headings.message" />
            </Th>
            <Th>
              <TrText message="table_headings.value" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {errors.slice(0, maxRows).map((w) => (
            <Tr key={`${w.row},${w.column}`}>
              <Td>{w.row}</Td>
              <Td>{w.column}</Td>
              <Td>{w.message}</Td>
              <Td>{w.value}</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          {errors.length > maxRows && (
            <Tr>
              <Td colSpan={4}>
                <TrText
                  message="upload.errors_count"
                  options={{ count: errors.length - maxRows }}
                />
              </Td>
            </Tr>
          )}
        </Tfoot>
      </Table>
    </>
  );
};

export default CellErrors;
