import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { Icon } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";

import Text from "../../ui/Text";
import LocationChart from "./LocationChart";
import GhostButton from "../../ui/GhostButton";
import { colors } from "../../ui/theme";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../graphql/queries/getCurrentUser";
import TrText from "../../i18n/TrText";
import {
  IProducerSitesSummary,
  IProducerSitesSummaryResult,
  PRODUCER_SITES_SUMMARY_QUERY,
} from "../../graphql/queries/getProducerSitesSummary";
import H1 from "../../ui/H1";
import Tile from "../../ui/Tile";
import { formatToDecimalPlaces } from "../../aux/numbers";
import TotalMaterialWeight from "../Analytics/containers/Sites/components/TotalMaterialWeight";
import TotalPrimaryDistance from "../Analytics/containers/Sites/components/TotalPrimaryDistance";
import TotalPrimaryTransportCO2 from "../Analytics/containers/Sites/components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "../../components/TotalUnknownMaterial";
import Card from "../../ui/Card";
import {
  GET_PRODUCER_SITES_DETAILS_QUERY,
  IProducerSitesDetailsResult,
} from "../../graphql/queries/getProducerSitesDetails";

const Poi = () => {
  let { poiId } = useParams<{ poiId: string }>();
  const history = useHistory();

  const { data: poiSummaryData, loading: loadingSummaryData } = useQuery<
    IProducerSitesSummaryResult,
    { poiIds: string[] }
  >(PRODUCER_SITES_SUMMARY_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      poiIds: [poiId],
    },
  });

  const { data: producerSiteDetailsData } = useQuery<
    IProducerSitesDetailsResult,
    { poiIds: string[] }
  >(GET_PRODUCER_SITES_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      poiIds: [poiId],
    },
  });

  const poiSummary: IProducerSitesSummary | undefined =
    poiSummaryData?.producerSitesSummary?.edges[0]?.node;
  const poiDetails = producerSiteDetailsData?.producerSites[0];

  const { data: currentUserResult } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const currentUser = currentUserResult?.currentUser;

  const totalMaterialWeight = poiSummary?.totalWeight || 0;
  const totalPrimaryMiles = poiSummary?.primaryDistanceMiles || 0;
  const totalPrimaryKms = poiSummary?.primaryDistanceKms || 0;
  const totalPrimaryCo2Kgs = poiSummary?.primaryCo2Kgs || 0;
  const divertedFromLandfillPercentage =
    poiSummary?.landfillDiversionFatePercentage || 0;
  const landfillPercentage = poiSummary?.landfillFatePercentage || 0;
  const unknownPercentage = poiSummary?.unknownFatePercentage || 0;

  return (
    <>
      <Flex
        align="flex-start"
        justify="flex-start"
        direction={["column"]}
        p={8}
      >
        <Box mb={4} ml={-5}>
          <GhostButton onClick={history.goBack}>
            <Flex direction="row" align="center" justify="flex-start">
              <Icon
                w={6}
                h={6}
                mr={4}
                as={MdArrowBack}
                color={colors.new.text.blue[500]}
              />
              <Text style={{ textDecoration: "underline" }}>
                <TrText message="app.go_back" />
              </Text>
            </Flex>
          </GhostButton>
        </Box>

        <Flex mb={4}>
          {loadingSummaryData ? (
            <Text>loading data...</Text>
          ) : (
            <H1>{poiDetails?.poiName ?? "--"}</H1>
          )}
        </Flex>

        <Flex justify="stretch" align="center" w="100%">
          <Box w="25%" mr={4}>
            <Tile
              title="overview.validated_card_title"
              iconName="truck_with_tick"
              bottomText={
                <TrText
                  message="overview.in_total_activities"
                  options={{
                    amount: poiSummary?.activityCount
                      ? poiSummary.activityCount
                      : "",
                  }}
                />
              }
              value={
                poiSummary?.activityCount
                  ? `${formatToDecimalPlaces(
                      ((poiSummary?.activityCount -
                        poiSummary?.receiverValidationErrors) /
                        poiSummary?.activityCount) *
                        100,
                      1
                    )}%`
                  : "--"
              }
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalMaterialWeight weight={totalMaterialWeight} />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalPrimaryDistance
              miles={totalPrimaryMiles}
              kms={totalPrimaryKms}
              currentUser={currentUser}
            />
          </Box>
          <Box w="25%" ml={4}>
            <TotalPrimaryTransportCO2 kgs={totalPrimaryCo2Kgs} />
          </Box>
        </Flex>

        <Flex
          justify="stretch"
          align="center"
          w="100%"
          direction="row"
          mt="20px"
        >
          <Box w="25%" mr={4}>
            <Tile
              title="overview.movements_card_title"
              bottomText="overview.in_total"
              iconName="just_track"
              value={String(poiSummary?.movementCount ?? "0")}
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalDivertedFromLandfill
              totalWeight={totalMaterialWeight}
              divertedFromLandfillPercentage={divertedFromLandfillPercentage}
              isLoading={loadingSummaryData}
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalMaterialLandfill
              totalWeight={totalMaterialWeight}
              landfillPercentage={landfillPercentage}
              isLoading={loadingSummaryData}
            />
          </Box>
          <Box w="25%" ml={4}>
            <TotalUnknownMaterial
              totalWeight={totalMaterialWeight}
              unknownPercentage={unknownPercentage}
              isLoading={loadingSummaryData}
            />
          </Box>
        </Flex>

        {poiDetails && (
          <Flex
            align="flex-start"
            justify="flex-start"
            direction={["column", "column", "column", "row"]}
            w="100%"
            mt="20px"
          >
            <Box
              w={["100%", "100%", "100%", "50%"]}
              mr={[0, 0, 0, 4]}
              h={[null, null, null, 500]}
            >
              <Card>
                <table style={{ marginBottom: 12 }}>
                  <tbody>
                    <tr>
                      <TH>Name</TH>
                      <TD>{poiDetails?.poiName}</TD>
                    </tr>
                    <tr>
                      <TH>Site reference</TH>
                      <TD>{poiDetails?.poiRefId}</TD>
                    </tr>
                    <tr>
                      <TH>Address</TH>
                      <TD>
                        {poiDetails?.poiAddress
                          ?.split(",")
                          .map((p: string, i: number) => {
                            return <p key={i}>{p}</p>;
                          })}
                      </TD>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Box>

            <Box
              w={["100%", "100%", "100%", "50%"]}
              h={500}
              ml={[0, 0, 0, 4]}
              mt={[4, 4, 4, 0]}
            >
              <LocationChart
                produceLon={poiDetails.poiLng}
                produceLat={poiDetails.poiLat}
                name={poiDetails.poiName}
                address={poiDetails.poiAddress}
              />
            </Box>
          </Flex>
        )}
      </Flex>
    </>
  );
};

const TH = styled.th`
  text-align: left;
  width: 30%;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 24px;
  vertical-align: baseline;
`;
const TD = styled.td`
  text-align: left;
  vertical-align: baseline;
`;

export default Poi;
