import { useState } from "react";
import { Stack, Input, Box } from "@chakra-ui/react";

import { ValueChangeType } from "./../SimpleFilter";
import Text from "./../../../ui/Text";
import Checkbox from "./Checkbox";
import { IPoiGroup } from "./../../../graphql/queries/getPoiGroups";

interface IGroupListProps {
  poiGroups: IPoiGroup[];
  onGroupIdValueChange: (props: ValueChangeType) => void;
  poiGroupIdValues: string[];
}

const GroupSelectionList = ({
  poiGroups,
  onGroupIdValueChange,
  poiGroupIdValues,
}: IGroupListProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Stack direction="column" px={0} mt={0}>
      <div
        style={{
          position: "absolute",
          background: "#fff",
          width: "88%",
          zIndex: 2,
        }}
      >
        <Box mt={1} py={2}>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search group by name"
          />
        </Box>
      </div>

      <div style={{ marginTop: 80 }}>
        {poiGroups
          .filter((group) =>
            group.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((group) => {
            return (
              <Checkbox
                key={group.id}
                id={group.id}
                isChecked={poiGroupIdValues.includes(group.id)}
                name={group.name}
                label={group.name}
                onChange={(prop) => {
                  onGroupIdValueChange({
                    id: prop.id,
                    isChecked: prop.isChecked,
                  });
                }}
              />
            );
          })}

        {!poiGroups ||
          (poiGroups.length === 0 && <Text>No groups available.</Text>)}
      </div>
    </Stack>
  );
};

export default GroupSelectionList;
