import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { Flex, Badge } from "@chakra-ui/react";

import SortingIcon from "../../../../components/SortingIcon";
import { RespondType } from "../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import Link from "../../../../ui/Link";
import { colors } from "../../../../ui/theme";
import {
  MOVEMENTS_QUERY,
  IMovementsResult,
  ISingleMovement,
} from "../../../../graphql/queries/getMovements";
import { formatToDecimalPlaces } from "../../../../aux/numbers";
import {
  IFilters,
  useFiltersFromQueryParams,
} from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";
import LoadingData from "../../../../components/LoadingData";
import TrText from "../../../../i18n/TrText";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "weight_in_kgs",
    "producer_timestamp",
    "waste_code",
    "carrier_name",
    "receiver_name",
    "producer_name",
    "source_movement_id",
    "supplier_name",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const WeightTypeIndicator = (props: {
  weightType: "measured" | "estimated" | "assumed";
}) => {
  switch (props.weightType) {
    case "measured":
      return <></>;
    case "estimated":
      return <sup>*</sup>;
    case "assumed":
      return <sup>&dagger;</sup>;
    default:
      return <></>;
  }
};

const Activities = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const filters = useFiltersFromQueryParams();
  const [orderBy, setOrderBy] = useQueryParam("orderBy", StringParam);
  const [orderDirection, setOrderDirection] = useQueryParam(
    "orderDirection",
    StringParam
  );

  const movementsFilter: IFilters & {
    orderBy: string;
    orderDirection: string;
  } = {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy) ? orderBy : "weight_in_kgs",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  };

  const exportUrl = getExportURL("movements", movementsFilter);

  const { data, loading, fetchMore } = useQuery<IMovementsResult>(
    MOVEMENTS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...movementsFilter,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const movements: ISingleMovement[] =
    data === undefined ? [] : data.movements.edges.map((edge) => edge.node);

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };
  const totalCount = data?.movements?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !data?.movements?.pageInfo.hasPreviousPage || loading;

  const isNextPageDisabled = !data?.movements.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: data?.movements?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: data?.movements?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (data?.movements?.pageInfo.hasPreviousPage === false) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [data?.movements?.pageInfo.hasPreviousPage]);

  return (
    <Flex direction="column" w="100%" px={8} pt={8}>
      <Flex direction="column" w="100%">
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          px={4}
          mb={8}
        >
          <Flex w="40%" h={74} alignItems="center">
            <H1>
              <TrText message="activities_table.title" />
            </H1>
          </Flex>
          <Flex
            flex="1"
            mr={10}
            h={74}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ButtonPrimary as="a" href={exportUrl}>
              Export
            </ButtonPrimary>
          </Flex>
          {data && totalPages > 0 && (
            <PaginationNavigation
              id="analytics-movements"
              isLoading={loading}
              previousButtonName="previous page"
              nextButtonName="next page"
              onChange={onRowSelectChange}
              currentPageCounter={currentPageCounter}
              totalPages={totalPages}
              isPreviousPageDisabled={isPreviousPageDisabled}
              isNextPageDisabled={isNextPageDisabled}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
            />
          )}
        </Flex>

        <Flex px={4} mb={4}>
          <Flex width={100} alignItems="center">
            <Text>
              <TrText message="table_headings.id" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="source_movement_id"
                ariaLabel="sort by movement id ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="source_movement_id"
                ariaLabel="sort by movement id descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="17%" alignItems="center">
            <Text>
              <TrText message="table_headings.producer_details" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="producer_name"
                ariaLabel="sort by producer name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="producer_name"
                ariaLabel="sort by producer name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="8%" alignItems="center">
            <Text>
              <TrText message="table_headings.lift" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="producer_timestamp"
                ariaLabel="sort by time ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="producer_timestamp"
                ariaLabel="sort by time descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="10%" alignItems="center">
            <Text>
              <TrText message="table_headings.waste_code" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="waste_code"
                ariaLabel="sort by waste code ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="waste_code"
                ariaLabel="sort by waste code descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="8%" alignItems="center">
            <Text>
              <TrText message="table_headings.weight_kgs" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="weight_in_kgs"
                ariaLabel="sort by weight ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="weight_in_kgs"
                ariaLabel="sort by weight descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="13%" alignItems="center">
            <Text>
              <TrText message="table_headings.supplier" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="supplier_name"
                ariaLabel="sort by supplier name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="supplier_name"
                ariaLabel="sort by supplier name descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="15%" alignItems="center">
            <Text>
              <TrText message="table_headings.carrier_details" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="carrier_name"
                ariaLabel="sort by carrier name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="carrier_name"
                ariaLabel="sort by carrier name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="15%" alignItems="center">
            <Text>
              <TrText message="table_headings.receiver_details" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="receiver_name"
                ariaLabel="sort by receiver name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="receiver_name"
                ariaLabel="sort by receiver name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="6%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.status" />
            </Text>
          </Flex>
        </Flex>

        <Card>
          {loading ? (
            <LoadingData />
          ) : (
            <Flex direction="column">
              {movements &&
                movements.map((m: ISingleMovement) => {
                  return (
                    <Flex
                      p={4}
                      w="100%"
                      _notLast={{
                        borderBottom: "1px solid #e2e8f0",
                      }}
                      key={m.id}
                    >
                      <Flex width={100} textAlign="left" alignItems="center">
                        <Link to={`/h/activities/${m.movementId}`}>
                          <Text
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontWeight: "bold",
                              color: colors.new.text.blue[500],
                              width: 100,
                            }}
                          >
                            {m.sourceMovementId}
                          </Text>
                        </Link>
                      </Flex>

                      <Flex
                        w="17%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        <Text>{m.producerName}</Text>
                      </Flex>

                      <Flex
                        w="8%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        <Text>
                          {new Date(m.producerTimestamp).toLocaleDateString()}
                        </Text>
                      </Flex>

                      <Flex
                        w="10%"
                        pr={2}
                        textAlign="left"
                        alignItems="flex-start"
                        justifyContent={"center"}
                        flexDirection={"column"}
                        overflowWrap="anywhere"
                      >
                        <Text>{m.wasteCode}</Text>
                        <Text>{m.wasteDescription}</Text>
                      </Flex>

                      <Flex
                        w="8%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        {m.hasWeightData ? (
                          <Text>
                            {formatToDecimalPlaces(m.topoWeightInKgs, 0)}kg
                            <WeightTypeIndicator weightType={m.weightType} />
                          </Text>
                        ) : (
                          <Text>
                            <TrText message="app.no_weight" />
                          </Text>
                        )}
                      </Flex>

                      <Flex
                        w="13%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        <Text>{m.supplierAccountName}</Text>
                      </Flex>

                      <Flex
                        w="15%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        <Text>
                          {m.carrierName
                            ? m.carrierName
                            : m.topoCarrierName
                            ? m.topoCarrierName
                            : "--"}
                        </Text>
                      </Flex>

                      <Flex
                        w="15%"
                        pr={2}
                        textAlign="left"
                        alignItems="center"
                        overflowWrap="anywhere"
                      >
                        <Text>{m.receiverName}</Text>
                      </Flex>

                      <Flex w="6%" alignItems="center" justifyContent="center">
                        {m.hasIssues ? (
                          <Badge colorScheme="red">
                            <TrText message="cards.issue_badge" />
                          </Badge>
                        ) : (
                          <Badge colorScheme="green">
                            <TrText message="cards.valid_badge" />
                          </Badge>
                        )}
                      </Flex>
                    </Flex>
                  );
                })}
            </Flex>
          )}
        </Card>

        <Flex px={4} mb={4} mt={4} direction="row" justifyContent="flex-end">
          <Flex direction={"column"}>
            {movements.some((m) => m.weightType === "estimated") && (
              <p>
                <sup>*</sup>
                <TrText message="table_headings.estimated_weight_details" />
              </p>
            )}
            {movements.some((m) => m.weightType === "assumed") && (
              <p>
                <sup>&dagger;</sup>
                <TrText message="table_headings.assumed_weight_details" />
              </p>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Activities);
