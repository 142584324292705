import { Flex } from "@chakra-ui/react";

import { colors } from "../../../../../ui/theme";
import { formatToDecimalPlaces } from "./../../../../../aux/numbers";
import Text from "../../../../../ui/Text";
import { IProducerSitesSummary } from "../../../../../graphql/queries/getProducerSitesSummary";
import Link from "../../../../../ui/Link";

type Props = {
  site: IProducerSitesSummary;
  distanceUnits: "miles" | "kilometers" | undefined;
};

export const SiteRow = (props: Props) => {
  const { site, distanceUnits } = props;

  const totalMaterialWeight = site?.totalWeight || 0;

  const validationPctFormatted = `${formatToDecimalPlaces(
    site?.validationPercentage,
    1
  )}%`;

  const landfillPctFormatted = `${formatToDecimalPlaces(
    site?.landfillFatePercentage,
    1
  )}%`;
  const divertedPctFormatted = `${formatToDecimalPlaces(
    site?.landfillDiversionFatePercentage,
    1
  )}%`;
  const unknownPctFormatted = `${formatToDecimalPlaces(
    site?.unknownFatePercentage,
    1
  )}%`;

  const distancePerMovementKmsFormatted = `${formatToDecimalPlaces(
    site?.distancePerMovementKms,
    2
  )} kms`;
  const distancePerMovementMilesFormatted = `${formatToDecimalPlaces(
    site?.distancePerMovementMiles,
    2
  )} miles`;

  const co2PerMovementFormatted = `${formatToDecimalPlaces(
    site?.primaryCo2PerMovementKgs,
    3
  )}kg`;

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      w="100%"
      _notLast={{
        borderBottom: "1px solid #e2e8f0",
      }}
    >
      <Flex
        w="28%"
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
      >
        <Flex
          textAlign="left"
          ml={0}
          mb={1}
          style={{ color: colors.new.text.blue[500] }}
          fontWeight="bold"
        >
          <Link to={`/h/pois/${site.topoSiteId}`}>
            <h2>{site.name}</h2>
          </Link>
        </Flex>
        <Flex textAlign="left">
          <Text>{site.address}</Text>
        </Flex>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{site?.movementCount}</Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>
          {totalMaterialWeight === 0
            ? `0t`
            : `${formatToDecimalPlaces(totalMaterialWeight / 1000, 3)}t`}
        </Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{validationPctFormatted}</Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{landfillPctFormatted}</Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{divertedPctFormatted}</Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{unknownPctFormatted}</Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>
          {distanceUnits === "miles"
            ? distancePerMovementMilesFormatted
            : distancePerMovementKmsFormatted}
        </Text>
      </Flex>

      <Flex w="9%" justifyContent="center" alignItems="center">
        <Text>{co2PerMovementFormatted}</Text>
      </Flex>
    </Flex>
  );
};
