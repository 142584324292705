import { I18n } from "i18n-js/dist/require/I18n";
import { TranslateOptions } from "i18n-js/typings/typing";

import translations from "./translations.json";
import { SupportedLocales } from "./aux";

// if we don't have an explicit translation for the language and region then fall back to these default
// regions for the language - e.g. en-US will fall back to en-GB if we don't have translations for en-US
const fallbackRegions: { [key: string]: string } = {
  en: "GB",
  de: "DE",
  es: "ES",
  fr: "FR",
  nl: "NL",
  pt: "PT",
  sv: "SE",
  zh: "CN",
  da: "DK",
  nb: "NO",
};

// get the locale from the browser and fall back to one we support
export function getLocale(requestedLocale = window.navigator.language) {
  // return "ja-JP";
  // if we don't have a locale then give up and fallback to british english
  if (!requestedLocale) {
    return "en-GB";
  }
  // do we support this locale?
  if (translations[requestedLocale as SupportedLocales]) {
    return requestedLocale;
  }
  // we don't have an exact match for the locale to try and find a match
  // to the language and fallback region
  const [language] = requestedLocale.split("-");
  const fallbackRegion = fallbackRegions[language];
  if (fallbackRegion) {
    const fallbackLocale = `${language}-${fallbackRegion}`;
    if (translations[fallbackLocale as SupportedLocales]) {
      return fallbackLocale;
    }
  }
  // we don't have the language at all so just return english
  return "en-GB";
}

export const i18n = new I18n(translations);

// only fallback to english in production
i18n.defaultLocale = "en-GB";
i18n.locale = getLocale(window.localStorage.getItem("language") as string);
i18n.translations = translations;

export default function translate(
  key: string,
  options: TranslateOptions = {},
  defaultValue = undefined
): string | undefined {
  let translatedText = i18n.t(key, { ...options });
  if (translatedText === null) {
    if (process.env.NODE_ENV === "production") {
      // In production, use the default value or english translation or finally the key
      translatedText = defaultValue || i18n.t(key, { locale: "en-GB" }) || key;
    }
    const requestedLocale =
      options && options.locale ? options.locale : i18n.locale;

    // eslint-disable-next-line
    console.warn(
      `No translation exists for key '${key}' in the requested locale (${requestedLocale}). Current locale is: ${getLocale()}`
    );
  }
  if (translatedText === null) {
    translatedText = defaultValue;
  }
  return translatedText;
}
