import { DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IUser } from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import DangerButton from "../../../../ui/DangerButton";

const RemoveInvitationButton = (props: {
  invite: IUser;
  onRemoveInvitation: (userId: string) => Promise<void>;
}) => {
  const { invite, onRemoveInvitation } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = useRef(null);

  const okRemoveInvitation = async () => {
    setIsLoading(true);
    await onRemoveInvitation(invite.id);
    onClose();
    setIsLoading(false);
  };

  return (
    <>
      <DangerButton ml={4} leftIcon={<DeleteIcon />} onClick={onOpen}>
        <TrText message="app.remove" />
      </DangerButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <TrText
                message="add_user.remove_invitation"
                options={{ name: invite.name }}
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              <TrText message="add_user.remove_invitation_confirm" />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button isDisabled={isLoading} ref={cancelRef} onClick={onClose}>
                <TrText message="app.cancel" />
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme="red"
                onClick={okRemoveInvitation}
                ml={3}
              >
                <TrText message="add_user.remove" />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RemoveInvitationButton;
