import { IMovementsSummary } from "../../../../../graphql/queries/getMovementsSummary";
import Tile from "../../../../../ui/Tile";

const TotalMovements = ({
  movementsSummary,
  isLoading,
}: {
  movementsSummary?: IMovementsSummary;
  isLoading: boolean;
}) => (
  <Tile
    title="overview.movements_card_title"
    bottomText="overview.in_total"
    iconName="just_track"
    popoverText="overview.movements_popover"
    value={
      isLoading
        ? undefined
        : Number(movementsSummary?.movementCount) >= 0
        ? String(movementsSummary?.movementCount)
        : undefined
    }
  />
);

export default TotalMovements;
