import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Flex } from "@chakra-ui/react";

import H2 from "../../../../ui/H2";
import Text from "../../../../ui/Text";
import ButtonSecondary from "../../../../ui/ButtonSecondary";
import PaginationNavigation from "./../../../../components/PaginationNavigation";
import {
  MOVEMENTS_UPLOADS_QUERY,
  IMovementUploadsResult,
} from "../../../../graphql/queries/getMovementsUploads";
import UploadsTable from "../../components/UploadsTable";
import NoUserUploads from "./NoUserUploads";
import RefreshIcon from "../../../../ui/RefreshIcon";
import TrText from "../../../../i18n/TrText";

const RecentUploads = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const {
    data: movementsUploadsData,
    loading,
    fetchMore,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery<IMovementUploadsResult>(MOVEMENTS_UPLOADS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      first: rowsPerPage,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    startPolling(30000);
    // then switch off on navigating away, so we don't leak
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const uploadsData = movementsUploadsData?.movementsUploads?.edges;

  const totalCount = movementsUploadsData?.movementsUploads?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !movementsUploadsData?.movementsUploads?.pageInfo.hasPreviousPage ||
    loading;

  const isNextPageDisabled =
    !movementsUploadsData?.movementsUploads.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: movementsUploadsData?.movementsUploads?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: movementsUploadsData?.movementsUploads?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  if (movementsUploadsData && totalPages > 0) {
    return (
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="flex-start" alignItems={"center"} flex={1}>
            <H2>
              <TrText message="upload.history" />
            </H2>
            <Flex ml={4}>
              <ButtonSecondary onClick={() => refetch()}>
                <TrText message="app.refresh" />
                <Flex ml={2}>
                  <RefreshIcon />
                </Flex>
              </ButtonSecondary>
            </Flex>
          </Flex>

          <PaginationNavigation
            id="recent-uploads"
            isLoading={loading}
            previousButtonName="previous page"
            nextButtonName="next page"
            onChange={onRowSelectChange}
            currentPageCounter={currentPageCounter}
            totalPages={totalPages}
            isPreviousPageDisabled={isPreviousPageDisabled}
            isNextPageDisabled={isNextPageDisabled}
            onPreviousPageClick={onPreviousPageClick}
            onNextPageClick={onNextPageClick}
          />
        </Flex>
        {uploadsData && <UploadsTable uploads={uploadsData} />}
      </Flex>
    );
  } else if (uploadsData?.length === 0) {
    // This is the case when there are no uploads yet
    // for the given organisation (empty state),
    // i.e. uploadsData.length === 0
    return <NoUserUploads />;
  } else
    return (
      <Text>
        <TrText message="app.loading" />
      </Text>
    );
};

export default RecentUploads;
