import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

interface IPageWrapper {
  children: ReactNode;
  justify: "center" | "flex-start";
  align: "center" | "flex-start";
}

const PageWrapper = ({ children, align, justify }: IPageWrapper) => {
  return (
    <Flex
      as={"main"}
      align={align}
      justify={justify}
      direction={["column"]}
      p={[0]}
      style={{
        flex: "1 1 0%",
      }}
    >
      {children}
    </Flex>
  );
};
export default PageWrapper;
