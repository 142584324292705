import { gql } from "@apollo/client";

export const GET_SINGLE_PERMITTED_SITE_QUERY = gql`
  query GetSinglePermittedSite($topoSiteId: String!) {
    permittedSite(topoSiteId: $topoSiteId) {
      id
      topoSiteId
      poiName
      poiAddress
      poiType
      poiCategory
      poiLng
      poiLat
      permits
      countryName
      regulatorName
      organisation {
        name
      }
    }
  }
`;

export interface ISinglePermittedSiteData {
  id: string;
  topoSiteId: string;
  poiName: string;
  poiAddress: string;
  poiType: string;
  poiCategory: string;
  poiLng: number;
  poiLat: number;
  permits: string[];
  countryName: string;
  regulatorName: string;
  organisation: {
    name: string;
  };
}

export interface ISinglePermittedSiteResult {
  permittedSite: ISinglePermittedSiteData;
}
