import { Heading } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  size?: string;
};

const H3 = (props: Props) => {
  const { size } = props;
  return (
    <Heading
      as="h3"
      size={size ? size : "xl"}
      fontFamily="Open Sans;sans-serif"
      {...props}
    />
  );
};

export default H3;
