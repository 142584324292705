import { Box, Flex } from "@chakra-ui/react";

import Text from "../../../ui/Text";

type Props = {
  title?: string;
  label?: string;
};

const RadioLabel = ({ title, label }: Props) => {
  return (
    <Flex direction="column" ml={2}>
      {title && (
        <Box>
          <Text style={{ fontSize: "0.8rem" }}>
            <b>{title}</b>
          </Text>
        </Box>
      )}
      {label && (
        <Box mb={1}>
          <Text style={{ fontSize: "0.8rem" }}>{label}</Text>
        </Box>
      )}
    </Flex>
  );
};

export default RadioLabel;
