import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

interface IPageWrapper {
  children: ReactNode;
  justify: "center" | "flex-start";
  align: "center" | "flex-start";
  direction?: "row";
}

/**
 * This component is mainly to be used with the side navigation
 * present on the left
 * */

const ViewWrapper = ({ children, align, justify, direction }: IPageWrapper) => {
  return (
    <Flex
      as={"main"}
      align={align}
      justify={justify}
      direction={direction === "row" ? ["row"] : ["column"]}
      px={[0]}
      style={{
        flex: "1 1 0%",
      }}
    >
      {children}
    </Flex>
  );
};
export default ViewWrapper;
