import {
  Box,
  Badge,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Icon,
} from "@chakra-ui/react";

import Text from "./../../../../ui/Text";
import { formatDateDMMMYYYY } from "./../../../../aux/formatDateDMMMYYYY";
import TrText from "../../../../i18n/TrText";
import { FiChevronDown } from "react-icons/fi";
import { colors } from "../../../../ui/theme";
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParam,
} from "use-query-params";

interface ISimpleFilterSummaryProps {
  filters: IFilters;
}

export interface IFilters {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  poiIds: (string | null)[] | null | undefined;
  receiverPoiIds: (string | null)[] | null | undefined;
  supplierIds: (string | null)[] | null | undefined;
  poiGroupIds: (string | null)[] | null | undefined;
  carrierName: string | null | undefined;
  hasIssues: boolean | null | undefined;
  wasteCodes: (string | null)[] | null | undefined;
  includeActualWeight: boolean | null;
  includeAssumedWeight: boolean | null;
  includeEstimatedWeight: boolean | null;
}

export const useFiltersFromQueryParams = () => {
  const [startDate] = useQueryParam("startDate", StringParam);
  const [endDate] = useQueryParam("endDate", StringParam);
  const [wasteCodes] = useQueryParam("wc", ArrayParam);
  const [hasIssues] = useQueryParam("hasIssues", BooleanParam);
  const [poiIds] = useQueryParam("pois", ArrayParam);
  const [receiverPoiIds] = useQueryParam("rPois", ArrayParam);
  const [supplierIds] = useQueryParam("supplierIds", ArrayParam);
  const [poiGroupIds] = useQueryParam("poiGroupIds", ArrayParam);
  const [carrierName] = useQueryParam("carrierName", StringParam);
  const [includeAssumedWeight = true] = useQueryParam(
    "includeAssumedWeight",
    BooleanParam
  );
  const [includeEstimatedWeight = true] = useQueryParam(
    "includeEstimatedWeight",
    BooleanParam
  );
  const [includeActualWeight = true] = useQueryParam(
    "includeActualWeight",
    BooleanParam
  );

  const filters = {
    poiIds: poiIds ? poiIds : null,
    receiverPoiIds: receiverPoiIds ? receiverPoiIds : null,
    supplierIds: supplierIds ? supplierIds : null,
    poiGroupIds: poiGroupIds ? poiGroupIds : null,
    startDate,
    endDate,
    wasteCodes: wasteCodes ? wasteCodes : null,
    carrierName: carrierName ? carrierName : null,
    hasIssues,
    includeAssumedWeight,
    includeActualWeight,
    includeEstimatedWeight,
  };
  return filters;
};

const SimpleFilterSummary = ({ filters }: ISimpleFilterSummaryProps) => {
  const {
    poiIds,
    receiverPoiIds,
    supplierIds,
    poiGroupIds,
    carrierName,
    wasteCodes,
    startDate,
    endDate,
    hasIssues,
  } = filters;

  const formatDateString = () => {
    // TODO Guard for case when not a date object
    if (startDate && endDate) {
      return `${formatDateDMMMYYYY(startDate)} - ${formatDateDMMMYYYY(
        endDate
      )}`;
    } else return <TrText message="app.all" />;
  };

  const ShowDate = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.date_range" />:{" "}
        </Text>
        <Badge bg="#E4E9E9">{formatDateString()}</Badge>
      </Flex>
    );
  };

  const ShowSites = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.sites" />:{" "}
        </Text>
        {poiIds?.length ? (
          <Badge data-testid="sites-badge" bg="#E4E9E9">
            {poiIds?.length}
          </Badge>
        ) : (
          <Badge data-testid="sites-badge" bg="#E4E9E9">
            <TrText message="app.all" />
          </Badge>
        )}
      </Flex>
    );
  };

  const ShowReceiverSites = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.receivers" />:{" "}
        </Text>
        {receiverPoiIds?.length ? (
          <Badge data-testid="receivers-badge" bg="#E4E9E9">
            {receiverPoiIds?.length}
          </Badge>
        ) : (
          <Badge data-testid="receivers-badge" bg="#E4E9E9">
            <TrText message="app.all" />
          </Badge>
        )}
      </Flex>
    );
  };

  const ShowSuppliers = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.suppliers" />:{" "}
        </Text>
        {supplierIds?.length ? (
          <Badge data-testid="suppliers-badge" bg="#E4E9E9">
            {supplierIds?.length}
          </Badge>
        ) : (
          <Badge data-testid="suppliers-badge" bg="#E4E9E9">
            <TrText message="app.all" />
          </Badge>
        )}
      </Flex>
    );
  };

  const ShowGroups = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.groups" />:{" "}
        </Text>
        {poiGroupIds?.length ? (
          <Badge data-testid="groups-badge" bg="#E4E9E9">
            {poiGroupIds?.length}
          </Badge>
        ) : (
          <Badge data-testid="groups-badge" bg="#E4E9E9">
            <TrText message="app.all" />
          </Badge>
        )}
      </Flex>
    );
  };

  const ShowCarriers = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.carriers" />:{" "}
        </Text>
        <Text>
          {carrierName ? (
            <Badge data-testid="carriers-badge" bg="#E4E9E9">
              {1}
            </Badge>
          ) : (
            <Badge data-testid="carriers-badge" bg="#E4E9E9">
              <TrText message="app.all" />
            </Badge>
          )}
        </Text>
      </Flex>
    );
  };

  const ShowMaterials = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.materials" />:{" "}
        </Text>
        <Text>
          {wasteCodes ? (
            <Badge data-testid="materials-badge" bg="#E4E9E9">
              {wasteCodes?.length}
            </Badge>
          ) : (
            <Badge data-testid="materials-badge" bg="#E4E9E9">
              <TrText message="app.all" />
            </Badge>
          )}
        </Text>
      </Flex>
    );
  };

  const ShowIssues = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
          <TrText message="filter.has_issues" />:{" "}
        </Text>
        <Text>
          {hasIssues === undefined || hasIssues === null ? (
            <Badge data-testid="issues-badge" bg="#E4E9E9">
              <TrText message="app.all" />
            </Badge>
          ) : (
            <Badge data-testid="issues-badge" bg="#E4E9E9">
              {hasIssues ? "Yes" : "No"}
            </Badge>
          )}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            height="42px"
            width="24px"
            marginLeft="-1"
            variant="outline"
            background="#1D3887"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            _hover={{
              background: "#1c3270",
            }}
          >
            <Icon
              w={6}
              h={6}
              as={FiChevronDown}
              color={colors.new.text.light[500]}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow bg="#E4E9E9" />
          <PopoverCloseButton />
          <PopoverHeader background="#E4E9E9">
            <b>
              <TrText message="filter_summary.title" />
            </b>
          </PopoverHeader>
          <PopoverBody>
            <Box mb={2}>
              <ShowDate />
            </Box>
            <Box mb={2}>
              <ShowSites />
            </Box>
            <Box mb={2}>
              <ShowSuppliers />
            </Box>
            <Box mb={2}>
              <ShowGroups />
            </Box>
            <Box mb={2}>
              <ShowCarriers />
            </Box>
            <Box mb={2}>
              <ShowReceiverSites />
            </Box>
            <Box mb={2}>
              <ShowMaterials />
            </Box>
            <Box mb={2}>
              <ShowIssues />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default SimpleFilterSummary;
