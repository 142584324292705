import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ICellWarningsSummary } from "../../../graphql/queries/getMovementsUploads";
import TrText from "../../../i18n/TrText";

export interface IUICellWarningSummary extends ICellWarningsSummary {
  column: string;
}
const CellWarningsSummaries = ({
  summaries,
  maxRows,
}: {
  summaries: IUICellWarningSummary[];
  maxRows: number;
}) => {
  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>
              <TrText message="table_headings.count" />
            </Th>
            <Th>
              <TrText message="table_headings.column" />
            </Th>
            <Th>
              <TrText message="table_headings.message" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {summaries.slice(0, maxRows).map((s) => (
            <Tr key={`${s.count}-${s.column}-${s.message}`}>
              <Td>{s.count}</Td>
              <Td>{s.column}</Td>
              <Td>{s.message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {summaries.length > maxRows && (
        <p>
          <TrText
            message="upload.warnings_count"
            options={{ count: summaries.length - maxRows }}
          />
          .
        </p>
      )}
    </>
  );
};

export default CellWarningsSummaries;
