import styled from "@emotion/styled";
import Text from "./../Text";

type Props = {
  children: React.ReactNode;
  forHtml: string;
};

const Label = (props: Props) => {
  const { children, forHtml } = props;

  return (
    <L htmlFor={forHtml}>
      <Text>{children}</Text>
    </L>
  );
};

const L = styled.label`
  margin-bottom: 0px;
`;

export default Label;
