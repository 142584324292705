import { useRef, useState, useEffect } from "react";
import Chartjs from "chart.js/auto";
import { Flex } from "@chakra-ui/react";

import Loading from "../../components/Loading";
import Text from "./../../ui/Text";
import TrText from "../../i18n/TrText";
import { IMovementsSummary } from "../../graphql/queries/getMovementsSummary";

const PrimaryMovementValidationChart = ({
  movementsSummary,
  isLoading,
  isError,
}: {
  movementsSummary?: IMovementsSummary;
  isLoading: boolean;
  isError: boolean;
}) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const isDataWithZeroMovements =
    movementsSummary?.activityCount === 0 &&
    movementsSummary?.totalIssues === 0;

  const constructData = (movementsSummary: IMovementsSummary) => ({
    labels: ["Verified", " Issues"],
    datasets: [
      {
        data: [
          movementsSummary.activityCount - movementsSummary.totalIssues,
          movementsSummary.totalIssues,
        ],
        backgroundColor: ["#009400", "red"],
        borderWidth: 0,
        labels: ["Verified", "Issues"],
        label: "",
      },
    ],
  });

  useEffect(() => {
    // Construct the chart, since data has arrived
    if (movementsSummary && chartContainer && !chartInstance) {
      // @ts-ignore
      const newChartInstance = new Chartjs(chartContainer.current, {
        type: "doughnut",
        data: constructData(movementsSummary),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              text: "",
            },
          },
          animations: false,
        },
      });
      // @ts-ignore
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, chartInstance, movementsSummary]);

  useEffect(() => {
    // Update the chart data when we have new date range
    if (chartInstance && movementsSummary) {
      // @ts-ignore
      chartInstance.data = constructData(movementsSummary);
      // @ts-ignore
      chartInstance.update();
    }
  }, [movementsSummary, chartInstance]);

  if (chartContainer && !isError) {
    return (
      <div
        style={{
          position: "relative",
          height: "300px",
          paddingBottom: "20px",
          width: "100%",
        }}
      >
        {isDataWithZeroMovements && (
          <div>
            <TrText message="app.no_data" />
          </div>
        )}
        {isLoading && (
          <Flex w="100%" h="250" justifyContent="center" alignItems="center">
            <Loading />
          </Flex>
        )}
        <canvas
          style={{
            display: isLoading ? "none" : "block",
          }}
          id="primaryMovementValidation"
          ref={chartContainer}
        />
      </div>
    );
  } else if (isError) {
    return (
      <Flex w="100%" h="250" justifyContent="center" alignItems="center">
        <Text>
          <TrText message="app.error_loading_data" />
        </Text>
      </Flex>
    );
  } else {
    return null;
  }
};

export default PrimaryMovementValidationChart;
