import { gql } from "@apollo/client";

export const MOVEMENTS_UPLOADS_QUERY = gql`
  query FetchMovementsUploads(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    movementsUploads(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          originalFilename
          createdAt
          status
          updatedAt
          deleted
          processingErrors
          processingWarnings
          numberMovements
          account {
            id
            name
          }
          uploadedByAccount {
            id
            name
          }
          user {
            id
            name
          }
          permissions {
            canDelete
          }
        }
      }
    }
  }
`;

export const SINGLE_MOVEMENTS_UPLOAD_QUERY = gql`
  query MovementsUpload($id: ID!) {
    movementsUpload(id: $id) {
      id
      originalFilename
      createdAt
      status
      deleted
      updatedAt
      processingErrors
      processingWarnings
      numberMovements
      user {
        id
        name
      }
      account {
        id
        name
      }
      uploadedByAccount {
        id
        name
      }
      permissions {
        canDelete
      }
    }
  }
`;

export interface ICellErrorDetail {
  row: number;
  value: string;
  message: string;
}

export interface ICellErrorsDetails {
  [key: string]: ICellErrorDetail[];
}

export interface ICellErrorsSummary {
  count: number;
  column: string;
  message: string;
}

export interface ICellErrors {
  summary: ICellErrorsSummary[];
  details: ICellErrorsDetails;
}

export interface ICellWarningDetail {
  row: number;
  value: string;
  message: string;
}

export interface ICellWarningsDetails {
  [key: string]: ICellWarningDetail[];
}

export interface ICellWarningsSummary {
  count: number;
  column: string;
  message: string;
}

export interface ICellWarnings {
  summary: ICellWarningsSummary[];
  details: ICellWarningsDetails;
}

export type UploadStatusType =
  | "waiting_for_upload"
  | "queued_for_processing"
  | "processing"
  | "failed"
  | "completed"
  | "completed_with_warnings";

export interface IMovementUpload {
  id: string;
  originalFilename: string;
  createdAt: string;
  status: UploadStatusType;
  updatedAt: string;
  numberMovements?: number;
  deleted: boolean;
  account: {
    id: string;
    name: string;
  };
  uploadedByAccount: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    name: string;
  };
  permissions: {
    canDelete: boolean;
  };
  processingErrors?: {
    error?: string;
    errors?: {
      cells?: {
        message: string;
        cellErrors: ICellErrors;
      };
      sheet?: {
        message: string;
        missingColumns: string[];
      };
    };
  };
  processingWarnings?: {
    warning?: string;
    warnings?: {
      cells?: {
        message: string;
        cellWarnings: ICellWarnings;
      };
      sheet?: {
        message: string;
        missingColumns: string[];
      };
    };
  };
}

export interface IMovementUploadsEdge {
  node: IMovementUpload;
}

export interface IMovementUploadsResult {
  movementsUploads: {
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: IMovementUploadsEdge[];
  };
}

export interface ISingleMovementUploadResult {
  movementsUpload: IMovementUpload;
}

export interface ISingleMovementUploadParams {
  id: string;
}
