import { Box, Flex } from "@chakra-ui/layout";

import H2 from "../../../ui/H2";
import H3 from "./../../../ui/H3";
import Card from "./../../../ui/Card";
import Text from "./../../../ui/Text";
import TrText from "../../../i18n/TrText";

type Props = {
  sourceMovementId: string;
  distance: string;
  filename: string;
  sheetRowNumber: number;
  auhorityMovementId: string;
  auhorityMovementDescription: string;
  supplier: string;
};

export const ActivityDetailsCard = ({
  sourceMovementId,
  distance,
  filename,
  sheetRowNumber,
  auhorityMovementId,
  auhorityMovementDescription,
  supplier,
}: Props) => {
  return (
    <Card height="100%">
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <H2 size="md">
            <TrText message="activities_card.title" />
          </H2>
        </Flex>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.supplier" />:
          </H3>
          <Text>{supplier}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.data_source" />:
          </H3>
          <Text>{sourceMovementId}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.distance" />:
          </H3>
          <Text>{distance}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.filename" />:
          </H3>
          <Text>{filename}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.spreadsheet_row" />:
          </H3>
          <Text>{sheetRowNumber}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.authority_id" />:
          </H3>
          <Text>{auhorityMovementId}</Text>
        </Box>

        <Box py={2}>
          <H3 size="sm">
            <TrText message="table_headings.authority_id_description" />:
          </H3>
          <Text>{auhorityMovementDescription}</Text>
        </Box>
      </Box>
    </Card>
  );
};
