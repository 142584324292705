import { Avatar as ChakraAvatar } from "@chakra-ui/react";

type Props = {
  fullName?: string;
  url?: string | null;
};

const Avatar = (props: Props) => {
  const { fullName, url } = props;

  return (
    <ChakraAvatar
      as="button"
      name={fullName ? fullName : "You"}
      src={url ? url : ""}
    />
  );
};

export default Avatar;
