import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const CARRIERS_SUMMARY_QUERY = gql`
  query GetCarriersSummary(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $producerAddress: String
    $wasteCodes: [String!]
    $hasIssues: Boolean
    $orderBy: String
    $orderDirection: String
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    carriersSummary(
      first: $first
      last: $last
      after: $after
      before: $before
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      producerAddress: $producerAddress
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      orderBy: $orderBy
      orderDirection: $orderDirection
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          name
          license
          totalWeight
          incinerationWeight
          recoveryWeight
          landfillWeight
          transferWeight
          treatmentWeight
          unknownWeight
          divertedFromLandfillWeight
          primaryDistanceKms
          primaryDistanceMiles
          primaryCo2Kgs
          receiverValidationErrors
          carrierValidationErrors
          movementCount
          landfillFatePercentage
          unknownFatePercentage
          landfillDiversionFatePercentage
          validationPercentage
          primaryCo2PerMovementKgs
          distancePerMovementKms
          distancePerMovementMiles
        }
      }
    }
  }
`;

export interface ICarriersSummary {
  name: string;
  license: string;
  totalWeight: number;
  incinerationWeight: number;
  recoveryWeight: number;
  landfillWeight: number;
  transferWeight: number;
  treatmentWeight: number;
  unknownWeight: number;
  divertedFromLandfillWeight: number;
  primaryDistanceKms: number;
  primaryDistanceMiles: number;
  primaryCo2Kgs: number;
  receiverValidationErrors: number;
  carrierValidationErrors: number;
  movementCount: number;
  landfillFatePercentage: number;
  unknownFatePercentage: number;
  landfillDiversionFatePercentage: number;
  validationPercentage: number;
  distancePerMovementKms: number;
  distancePerMovementMiles: number;
  primaryCo2PerMovementKgs: number;
}

export interface ICarriersSummaryResult {
  carriersSummary: {
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      node: ICarriersSummary;
    }>;
  };
}

export type ICarriersSummaryParams = IFilters & {
  first: number;
  after: string | null;
  orderBy: string;
  orderDirection: string;
};
