import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Flex, useToast, Spinner } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

import Text from "../../../../ui/Text";
import Select from "../../../../ui/Select";
import H1 from "../../../../ui/H1";
import H2 from "../../../../ui/H2";
import Field from "../../../../ui/Field";
import PasswordInput from "../../../../ui/PasswordInput";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import {
  IUpdateUserParams,
  IUpdateUserResponse,
  UPDATE_USER,
} from "../../../../graphql/mutations/updateUser";
import GhostButton from "../../../../ui/GhostButton";
import { colors } from "../../../../ui/theme";
import TrText from "../../../../i18n/TrText";
import { i18n, getLocale } from "../../../../i18n/translate";
import { allowedLanguageLocales } from "../../../../i18n/aux";
import { allowedCountriesForCentering } from "../../../../services/maps/initialLocations";
import Avatar from "./components/Avatar";

const User = () => {
  const history = useHistory();

  const { data } = useQuery<ICurrentUserResult>(GET_CURRENT_USER);
  const user = data?.currentUser;

  const [updateUser, { loading: mutationLoading }] = useMutation<
    IUpdateUserResponse,
    IUpdateUserParams
  >(UPDATE_USER);

  const toast = useToast();

  const [languageSelected, setLanguageSelected] = useState(() =>
    window.localStorage.getItem("language")
  );
  const [mapLocationSelected, setMapLocationSelected] = useState(() =>
    window.localStorage.getItem("mapLocation")
  );

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordBoxVisible, setPasswordBoxVisible] = useState(false);

  const isSaveNewPasswordButtonEnabled = () => {
    if (
      oldPassword.length > 5 &&
      newPassword.length > 5 &&
      confirmPassword.length > 5 &&
      newPassword === confirmPassword
    ) {
      return true;
    } else return false;
  };

  const clearPasswordFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordBoxVisible(false);
  };

  const newPasswordsMatch = () => {
    if (
      newPassword.length > 0 &&
      confirmPassword.length > 0 &&
      newPassword === confirmPassword
    ) {
      return true;
    } else return false;
  };

  // Cleaning up when navigating away
  useEffect(() => {
    return () => {
      clearPasswordFields();
    };
  }, []);

  const updatePassword = async () => {
    try {
      const mutationResult = await updateUser({
        variables: {
          id: user?.id as string,
          password: newPassword,
          oldPassword: oldPassword,
        },
      });

      if (mutationResult.data?.updateUser.user) {
        // User attached to the respond, so password change
        // was successful
        toast({
          title: "Password changed",
          description: "Your password has been changed",
          status: "success",
        });
      } else if (
        mutationResult.data?.updateUser?.errors &&
        mutationResult.data?.updateUser?.errors?.length > 0
      ) {
        mutationResult.data.updateUser?.errors.forEach((error) => {
          toast({
            title: "Failed to update password",
            description: error,
            status: "error",
          });
        });
      }

      clearPasswordFields();
    } catch (error) {
      toast({
        title: "Failed to update profile",
        description:
          "Please try again later - contact support if the problem persists",
        status: "error",
      });
      clearPasswordFields();
    }
  };

  return (
    <Flex direction="column" w="100%" p={8}>
      <H1>
        <TrText message="profile_settings.title" />
      </H1>

      <Box mt={4} w={400}>
        {user && (
          <>
            <Box>
              <Avatar avatarUrl={user.avatarUrl} userId={user.id} />
            </Box>
            <Box mt={8}>
              <Field label={<TrText message="profile_settings.name" />}>
                {user.name}
              </Field>
            </Box>
            <Box mt={8}>
              <Field
                label={<TrText message="profile_settings.email_address" />}
              >
                {user.email}
              </Field>
            </Box>

            <Box mt={8}>
              <H2>
                <TrText message="profile_settings.change_language" />
              </H2>

              <Box mt={4}>
                <Select
                  id="language-select"
                  onChange={(ev) => {
                    localStorage.setItem("language", ev.target.value);
                    setLanguageSelected(ev.target.value);
                    i18n.locale = getLocale(ev.target.value);
                    // make sure we apply the new language without reload
                    history.push("/h/settings/profile");
                  }}
                  defaultValue={languageSelected as string}
                >
                  {allowedLanguageLocales.map((lang) => {
                    return (
                      <option key={lang.code} value={lang.code}>
                        {lang.displayName}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            <Box mt={8}>
              <H2>
                <TrText message="profile_settings.change_map_centering" />
              </H2>

              <Box mt={4}>
                <Select
                  id="map-centering-select"
                  onChange={(ev) => {
                    localStorage.setItem("mapLocation", ev.target.value);
                    setMapLocationSelected(ev.target.value);
                  }}
                  defaultValue={mapLocationSelected as string}
                >
                  {allowedCountriesForCentering.map((country) => {
                    return (
                      <option key={country.code} value={country.code}>
                        {country.displayName}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            <Box mt={12}>
              {!passwordBoxVisible ? (
                <ButtonPrimary
                  onClick={() => setPasswordBoxVisible(true)}
                  isDisabled={false}
                >
                  Change password
                </ButtonPrimary>
              ) : (
                <Box
                  w="100%"
                  h="5px"
                  borderTop={`2px dashed ${colors.new.grey[500]}`}
                ></Box>
              )}
            </Box>

            {passwordBoxVisible && (
              <Box mt={8}>
                <Box mb={4}>
                  <PasswordInput
                    ariaLabel="old password"
                    name="old-password"
                    labelText="Old password"
                    placeholder="Old password"
                    value={oldPassword}
                    onChange={(v: string) => setOldPassword(v)}
                  />
                </Box>
                <Box mb={4}>
                  <PasswordInput
                    ariaLabel="new password"
                    name="new-password"
                    labelText="New password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={(v: string) => setNewPassword(v)}
                  />

                  <Flex mt={4}>
                    <PasswordStrengthBox
                      color="#B92347"
                      show={newPassword.length > 0}
                    />
                    <PasswordStrengthBox
                      color="#FF9635"
                      show={newPassword.length > 4}
                    />
                    <PasswordStrengthBox
                      color="#FFDE2F"
                      show={newPassword.length > 8}
                    />
                    <PasswordStrengthBox
                      color="#A9E844"
                      show={newPassword.length > 12}
                    />
                    <PasswordStrengthBox
                      color="#19C682"
                      show={newPassword.length > 15}
                    />
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text style={{ fontSize: "0.8rem" }}>
                      <TrText message="profile_settings.weak_password" />
                    </Text>
                    <Text style={{ fontSize: "0.8rem" }}>
                      <TrText message="profile_settings.strong_password" />
                    </Text>
                  </Flex>
                </Box>
                <Box mb={4}>
                  <PasswordInput
                    ariaLabel="confirm password"
                    name="confirm-password"
                    labelText="Confirm new password"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(v: string) => setConfirmPassword(v)}
                  />

                  <Flex direction="column" mt={4}>
                    <Text style={{ fontSize: "0.8rem" }}>
                      Password match:{" "}
                      {newPasswordsMatch() ? (
                        <CheckIcon ml={2} color="green" />
                      ) : (
                        <CloseIcon ml={2} color="red" />
                      )}
                    </Text>
                    <Text style={{ fontSize: "0.8rem" }}>
                      Min 6 characters:{" "}
                      {newPassword.length > 5 ? (
                        <CheckIcon ml={2} color="green" />
                      ) : (
                        <CloseIcon ml={2} color="red" />
                      )}
                    </Text>
                  </Flex>
                </Box>

                <Flex mt={8} justifyContent="space-between">
                  <GhostButton
                    onClick={() => {
                      setPasswordBoxVisible(false);
                      clearPasswordFields();
                    }}
                    isDisabled={false}
                  >
                    Cancel
                  </GhostButton>
                  <ButtonPrimary
                    onClick={() => updatePassword()}
                    rightIcon={mutationLoading ? <Spinner /> : <p> </p>}
                    isDisabled={!isSaveNewPasswordButtonEnabled()}
                  >
                    Save new password
                  </ButtonPrimary>
                </Flex>
              </Box>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

const PasswordStrengthBox = styled(Flex)`
  width: 20%;
  height: 5px;
  margin: 2px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.show ? props.color : colors.new.grey[500]};
`;
export default User;
