import { gql } from "@apollo/client";

export const DELETE_FILTER = gql`
  mutation deleteFilter($id: ID!) {
    deleteFilter(input: { id: $id }) {
      errors
      success
    }
  }
`;

export interface IDeleteFilterParams {
  id: string;
}

export interface IDeleteFilterResponse {
  deleteFilter: {
    errors?: string[];
    success?: string;
  };
}
