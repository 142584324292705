import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex, Accordion } from "@chakra-ui/react";

import PaginationNavigation from "../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import {
  GROUPS_SUMMARY_QUERY,
  IGroupsSummaryResult,
  IGroupsSummaryParams,
  IGroupsSummary,
} from "../../../../graphql/queries/getGroupsSummary";
import { GroupRow } from "./components/GroupRow";
import TrText from "../../../../i18n/TrText";
import { SingleGroupView } from "./SingleGroupView";
import LoadingData from "../../../../components/LoadingData";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import SortingIcon from "../../../../components/SortingIcon";
import {
  OrderByType,
  RespondType,
} from "../../../../components/SortingIcon/SortingIcon";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "movement_count",
    "total_weight",
    "activity_count",
    "group_name",
    "landfill_diversion_fate_percentage",
    "unknown_fate_percentage",
    "landfill_fate_percentage",
    "validation_percentage",
    "primary_co2_per_movement_kgs",
    "distance_per_movement_kms",
    "distance_per_movement_miles",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Groups = () => {
  const filters = useFiltersFromQueryParams();

  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const distanceUnits = window.localStorage.getItem("distanceUnits") as
    | "miles"
    | "kilometers"
    | undefined;

  const displayDistanceColumnm = () => {
    if (distanceUnits === "kilometers") {
      return "distance_per_movement_kms";
    } else if (distanceUnits === "miles") {
      return "distance_per_movement_miles";
    } else return undefined;
  };

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("activity_count");

  const [groupSelected, setGroupSelected] = useState<IGroupsSummary | null>(
    null
  );

  const exportUrl = getExportURL("groups", {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy)
        ? orderBy
        : "activity_count",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  });

  const {
    data: groupsSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<IGroupsSummaryResult, IGroupsSummaryParams>(
    GROUPS_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "activity_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const totalCount = groupsSummaryData?.producerGroupsSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !groupsSummaryData?.producerGroupsSummary?.pageInfo.hasPreviousPage ||
    loadingData;

  const isNextPageDisabled =
    !groupsSummaryData?.producerGroupsSummary?.pageInfo.hasNextPage ||
    loadingData;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: groupsSummaryData?.producerGroupsSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: groupsSummaryData?.producerGroupsSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  useEffect(() => {
    if (
      groupsSummaryData?.producerGroupsSummary?.pageInfo.hasPreviousPage ===
      false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [groupsSummaryData?.producerGroupsSummary?.pageInfo.hasPreviousPage]);

  // account has no groups to display
  if (totalCount === 0 && !loadingData) {
    return (
      <Flex
        direction="column"
        minH="50vh"
        w="100%"
        h="200px"
        p={8}
        pt={16}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text>
          <TrText message="analytics_groups.no_groups_text_1" />
        </Text>
        <Text>
          <TrText message="analytics_groups.no_groups_text_2" />
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex direction="column" w="100%" p={8} mb={8}>
        {!groupSelected && (
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            px={4}
            mb={8}
          >
            <Flex w="40%" h={74} alignItems="center">
              <H1>
                <TrText message="analytics_groups.title" />
              </H1>
            </Flex>

            <Flex
              flex="1"
              mr={10}
              h={74}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <ButtonPrimary as="a" href={exportUrl}>
                Export
              </ButtonPrimary>
            </Flex>

            {groupsSummaryData && totalPages > 0 && (
              <PaginationNavigation
                id="compare-carriers"
                previousButtonName="previous page"
                nextButtonName="next page"
                onChange={onRowSelectChange}
                currentPageCounter={currentPageCounter}
                totalPages={totalPages}
                isPreviousPageDisabled={isPreviousPageDisabled}
                isNextPageDisabled={isNextPageDisabled}
                onPreviousPageClick={onPreviousPageClick}
                onNextPageClick={onNextPageClick}
                isLoading={loadingData}
              />
            )}
          </Flex>
        )}

        {!groupSelected && (
          <Flex px={4} mb={4}>
            <Flex w="28%" justifyContent="flex-start" alignItems="center">
              <Text>
                <TrText message="table_headings.groups" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="group_name"
                  ariaLabel="sort by group name ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="group_name"
                  ariaLabel="sort by group name descending"
                />
              </SortingBox>
            </Flex>
            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.activities" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="activity_count"
                  ariaLabel="sort by activity count ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="activity_count"
                  ariaLabel="sort by activity count descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.weight" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight descending"
                />
              </SortingBox>
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                <TrText message="table_headings.validation" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                <TrText message="table_headings.landfill" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                <TrText message="table_headings.landfill_diversion" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                <TrText message="table_headings.unknown" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                <TrText message="table_headings.distance_per_movement" />
              </Text>
              {distanceUnits && (
                <SortingBox>
                  <SortingIcon
                    direction="asc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} ascending`}
                  />
                  <SortingIcon
                    direction="desc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} descending`}
                  />
                </SortingBox>
              )}
            </Flex>

            <Flex w="9%" justifyContent="center" alignItems="center">
              <Text>
                CO<sub>2</sub>/<TrText message="table_headings.movement" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement kg ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement in kg descending"
                />
              </SortingBox>
            </Flex>
          </Flex>
        )}

        {groupSelected ? (
          <SingleGroupView
            groupId={groupSelected.groupId}
            setGroupSelected={setGroupSelected}
            groupSelected={groupSelected}
          />
        ) : (
          <Card>
            {loadingData ? (
              <LoadingData />
            ) : (
              <Accordion allowToggle>
                {groupsSummaryData?.producerGroupsSummary?.edges?.map(
                  (edge) => {
                    return (
                      <GroupRow
                        group={edge.node}
                        key={edge.node.groupId}
                        setGroupSelected={setGroupSelected}
                        distanceUnits={distanceUnits}
                      />
                    );
                  }
                )}
              </Accordion>
            )}
          </Card>
        )}
      </Flex>
    );
  }
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Groups);
