import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../graphql/queries/getCurrentUser";
import { isUserAdmin } from "../../../aux/userUtils";
import AccountAdmin from "./AccountAdmin";
import H2 from "../../../ui/H2";
import TrText from "../../../i18n/TrText";

const Account = () => {
  const { data: user, loading: loadingUser } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  return (
    <Flex direction="column" w="100%" p={8}>
      <H2>
        <TrText message="account_admin.title" />
      </H2>
      <Skeleton isLoaded={!loadingUser}>
        <Box w="400px" mt={4} mb={4}>
          <Card>
            {user?.currentUser?.account && (
              <Box p={4}>
                <Text>
                  <b>{`${user.currentUser.account.name}`}</b>
                </Text>
                <Text>{`Role: ${user.currentUser.roles.join(", ")}`}</Text>
              </Box>
            )}
          </Card>
        </Box>
      </Skeleton>
      {user && isUserAdmin(user.currentUser) && (
        <AccountAdmin currentUser={user?.currentUser} />
      )}
    </Flex>
  );
};

export default Account;
