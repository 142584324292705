import { Flex, Box } from "@chakra-ui/react";

import Text from "./../Text";
import Loading from "./../../components/Loading";
import TileIcons from "./../../components/TileIcons";
import { TileIconName } from "./../../components/TileIcons/TileIcons";
import { colors } from "./../theme";
import InfoBox from "../../components/InfoBox";
import TrText from "../../i18n/TrText";

interface ITileProps {
  value: string | undefined;
  title: string;
  bottomText: React.ReactNode;
  iconName: TileIconName;
  popoverText?: string;
}

const Tile = ({
  title,
  value,
  bottomText,
  iconName,
  popoverText,
}: ITileProps) => {
  return (
    <Flex
      as="section"
      bg={colors.new.text.light[500]}
      style={{
        borderRadius: "10px",
        boxShadow: `0px 2px 20px rgba(0, 0, 0, 0.08)`,
      }}
      overflow="hidden"
      px={8}
      align="center"
      direction="row"
      position="relative"
    >
      <Box
        w="100%"
        py={4}
        alignContent="flex-start"
        justifyContent="flex-start"
      >
        <Text>
          <TrText message={title} />
        </Text>
        <Box minH="45px" mt={value ? 1 : 0}>
          {value ? (
            <Text fontSize={["l", "xl", "2xl"]} style={{ fontWeight: "bold" }}>
              {value}
            </Text>
          ) : (
            <Flex>
              <Loading />
            </Flex>
          )}
        </Box>
        <Text>
          {typeof bottomText === "string" ? (
            <TrText message={bottomText} />
          ) : (
            bottomText
          )}
        </Text>
      </Box>

      <Box>
        <TileIcons name={iconName} />
      </Box>

      {popoverText && (
        <Box position="absolute" top={3} right={3}>
          <InfoBox text={popoverText} />
        </Box>
      )}
    </Flex>
  );
};
export default Tile;
