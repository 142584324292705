import { Flex, Box } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import _ from "lodash";

import Label from "./../../ui/Label";
import Select from "./../../ui/Select";
import Text from "./../../ui/Text";
import GhostButton from "./../../ui/GhostButton";
import PageBox from "./../PageBox";
import TrText from "../../i18n/TrText";

type Props = {
  id: string;
  previousButtonName: string;
  nextButtonName: string;
  onChange: (value: string) => void;
  currentPageCounter: number;
  totalPages: number;
  isPreviousPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onPreviousPageClick: () => void;
  onNextPageClick: () => void;
  isLoading: boolean;
};

const PaginationNavigation = (props: Props) => {
  const {
    id,
    previousButtonName,
    nextButtonName,
    onChange,
    currentPageCounter,
    totalPages,
    isPreviousPageDisabled,
    isNextPageDisabled,
    onPreviousPageClick,
    onNextPageClick,
    isLoading,
  } = props;

  const debouncedPreviousPageClick = _.debounce(onPreviousPageClick, 300);
  const debouncedNextPageClick = _.debounce(onNextPageClick, 300);

  return (
    <Flex direction="row" justify="flex-end" alignItems="center" py={4}>
      <Label forHtml={`${id}-select-rows-per-page`}>
        <TrText message="app.rows_per_page" />
      </Label>

      <Box ml={4} mr={4} w="100px">
        <Select
          id={`${id}-select-rows-per-page`}
          placeholder={""}
          defaultValue={"25"}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            onChange(event.target.value)
          }
        >
          <option key="25" value="25">
            25
          </option>
          <option key="50" value="50">
            50
          </option>
          <option key="100" value="100">
            100
          </option>
        </Select>
      </Box>

      <GhostButton
        name={previousButtonName}
        isDisabled={isPreviousPageDisabled || isLoading}
        onClick={debouncedPreviousPageClick}
        hiddenText="Previous page"
      >
        <ChevronLeftIcon w={12} h={12} />
      </GhostButton>

      <Flex justifyContent="center" alignItems="center">
        <Text>
          <TrText message="app.page" />{" "}
        </Text>
        <PageBox value={currentPageCounter} />
        <Text> of {totalPages}</Text>
      </Flex>

      <GhostButton
        name={nextButtonName}
        isDisabled={isNextPageDisabled || isLoading}
        onClick={debouncedNextPageClick}
        hiddenText="Next page"
      >
        <ChevronRightIcon w={12} h={12} />
      </GhostButton>
    </Flex>
  );
};

export default PaginationNavigation;
