export type SupportedLocales = "en-GB" | "de-DE" | "ja-JP" | "pl-PL";

type LocalesType = {
  code: SupportedLocales;
  displayName: string;
};

type AllowedLanguageLocalesType = LocalesType[];

export const allowedLanguageLocales: AllowedLanguageLocalesType = [
  { code: "en-GB", displayName: "English" },
  {
    code: "de-DE",
    displayName: "German",
  },
  {
    code: "ja-JP",
    displayName: "Japanese",
  },
  {
    code: "pl-PL",
    displayName: "Polski",
  },
];
