import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { formatToDecimalPlaces } from "../../../aux/numbers";
import { ITop10ProducerSites } from "../../../graphql/queries/getTop10ProducerSites";
import TrText from "../../../i18n/TrText";

const Top10Sites = ({
  top10Producers,
}: {
  top10Producers: ITop10ProducerSites | undefined;
}) => {
  return (
    <Table style={{ width: "100%" }}>
      <Thead>
        <Tr>
          <Th>
            <TrText message="table_headings.site_name" />
          </Th>
          <Th textAlign="right">
            <TrText message="table_headings.tonnes" />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {(top10Producers || []).map((producer) => (
          <Tr key={producer.topoPoiId}>
            <Td pt={1} pb={1}>
              <Flex direction="column">
                <Text fontSize="sm" lineHeight={1}>
                  {producer.name}
                </Text>
                <Text fontSize="xs" lineHeight={1}>
                  {producer.address}
                </Text>
              </Flex>
            </Td>
            <Td pt={1} pb={1} style={{ textAlign: "right" }}>
              {formatToDecimalPlaces(producer.kgs / 1000.0, 3)}t
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Top10Sites;
