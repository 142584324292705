import { Flex } from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";

import { colors } from "../../../../ui/theme";
import TabSelector from "../../../../ui/TabSelector";
import Text from "../../../../ui/Text";

const ProfileHeader = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Flex
      as="header"
      w="100%"
      mb={0}
      px={0}
      pl={4}
      bg="#fff"
      h="72px"
      borderBottom={`1px solid ${colors.new.grey[500]}`}
    >
      <Flex as="nav" align="center" justify="space-between" h="100%">
        <TabSelector
          onClick={() => history.push("/h/settings/accounts")}
          isSelected={location.pathname.includes("accounts")}
        >
          <Text fontSize="md">Accounts</Text>
        </TabSelector>
        <TabSelector
          onClick={() => history.push("/h/settings/profile")}
          isSelected={location.pathname.includes("profile")}
        >
          Profile
        </TabSelector>
      </Flex>
    </Flex>
  );
};

export default ProfileHeader;
