import { Icon } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";

import { colors } from "../theme";

const RefreshIcon = () => {
  return (
    <Icon
      role="img"
      aria-label="Refresh icon"
      w={6}
      h={6}
      as={MdRefresh}
      color={colors.new.background_light[500]}
    />
  );
};

export default RefreshIcon;
