import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import styled from "@emotion/styled";

import { colors } from "../../ui/theme";

export type OrderDirectionType = "asc" | "desc";
export type OrderByType =
  | "weight_in_kgs"
  | "total_weight"
  | "movement_count"
  | "activity_count"
  | "producer_timestamp"
  | "waste_code"
  | "carrier_name"
  | "receiver_operator_name"
  | "receiver_name"
  | "producer_name"
  | "source_movement_id"
  | "supplier_name"
  | "poi_name"
  | "poi_address"
  | "customer_site_id"
  | "group_name"
  | "landfill_diversion_fate_percentage"
  | "unknown_fate_percentage"
  | "landfill_fate_percentage"
  | "validation_percentage"
  | "primary_co2_per_movement_kgs"
  | "distance_per_movement_miles"
  | "distance_per_movement_kms"
  | "receiver_poi_name";

type Props = {
  onClick: (respond: RespondType) => void;
  direction: OrderDirectionType;
  column: OrderByType;
  ariaLabel: string;
};

export type RespondType = {
  orderDirection: OrderDirectionType;
  orderBy: OrderByType;
};

const SortingIcon = ({ onClick, direction, column, ariaLabel }: Props) => {
  const respondObject: RespondType = {
    orderDirection: direction,
    orderBy: column,
  };

  return (
    <Button onClick={() => onClick(respondObject)} aria-label={ariaLabel}>
      {direction === "asc" ? (
        <ChevronUpIcon w={6} h={4} />
      ) : (
        <ChevronDownIcon w={6} h={4} />
      )}
    </Button>
  );
};

const Button = styled.button`
  :hover {
    background: ${colors.new.grey[500]};
  }
`;

export default SortingIcon;
