import styled from "@emotion/styled";
import { colors } from "../../ui/theme";

import H2 from "./../../ui/H2";

type Props = {
  children: React.ReactNode;
};

const CardH2 = (props: Props) => {
  const { children } = props;

  return (
    <CustomH2 style={{ fontSize: "1rem", fontWeight: "bold" }}>
      {children}
    </CustomH2>
  );
};

const CustomH2 = styled(H2)`
  font-weight: 400;
  color: ${colors.new.text.grey[500]};
`;

export default CardH2;
