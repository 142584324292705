import dashboard from "./../../assets/images/icons/dashboard.svg";
import movements from "./../../assets/images/icons/movements.svg";
import directory from "./../../assets/images/icons/directory.svg";
import upload from "./../../assets/images/icons/upload.svg";
import analytics from "./../../assets/images/icons/analytics.svg";
import comparison from "./../../assets/images/icons/comparison.svg";

export type IconName =
  | "dashboard"
  | "movements"
  | "upload"
  | "analytics"
  | "directory"
  | "comparison";

type Props = {
  name: IconName;
  width?: number;
};

const Icons = (props: Props) => {
  switch (props.name) {
    case "dashboard":
      return <img src={dashboard} alt="dashboard icon" />;
    case "analytics":
      return <img src={analytics} alt="analytics icon" />;
    case "comparison":
      return <img src={comparison} alt="comparison icon" />;
    case "movements":
      return <img src={movements} alt="movements icon" />;
    case "directory":
      return <img src={directory} alt="directory icon" />;
    case "upload":
      return <img src={upload} alt="upload icon" />;
    default:
      return null;
  }
};

export default Icons;
