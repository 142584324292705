import { gql } from "@apollo/client";

export const MOVEMENTS_QUERY = gql`
  query Movements(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $poiGroupIds: [String!]
    $wasteCodes: [String!]
    $supplierIds: [String!]
    $hasIssues: Boolean
    $orderBy: String
    $orderDirection: String
  ) {
    movements(
      first: $first
      last: $last
      after: $after
      before: $before
      startDate: $startDate
      endDate: $endDate
      carrierName: $carrierName
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      wasteCodes: $wasteCodes
      hasIssues: $hasIssues
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          movementId
          supplierAccountId
          supplierAccountName
          sourceMovementId
          wasteCode
          wasteDescription
          weightInKgs
          containerType
          containerCount
          topoWeightInKgs
          weightType
          producerName
          producerPoiName
          producerPoiRefId
          producerAddress
          producerTopoPoiId
          carrierName
          carrierLicense
          topoCarrierName
          topoCarrierLicense
          receiverOperatorName
          receiverName
          receiverAddress
          receiverPoiName
          receiverPoiAddress
          receiverPermitId
          producerTimestamp
          vehicleRegistration
          receiverValidationError
          carrierValidationError
          hasIssues
          validationScore
          complianceScore
          warnings
          movementsUpload {
            id
            originalFilename
          }
          hasWeightData
          sheetRowNumber
          isHazardous
        }
      }
    }
  }
`;

export const SINGLE_MOVEMENT_QUERY = gql`
  query Movement($movementId: String!) {
    movement(movementId: $movementId) {
      id
      movementId
      sourceMovementId
      sourceMovementDescription
      supplierAccountId
      supplierAccountName
      producerTopoPoiId
      producerTimestamp
      producerName
      producerPoiName
      producerAddress
      producerSiteId
      producerLat
      producerLng
      producerPoiLat
      producerPoiLng
      producerPoiRefId
      wasteCode
      wasteDescription
      weightInKgs
      topoWeightInKgs
      weightType
      weightUnit
      containerType
      containerCount
      carrierName
      carrierLicense
      topoCarrierName
      topoCarrierLicense
      vehicleRegistration
      receiverTimestamp
      receiverName
      receiverAddress
      receiverPermitId
      receiverLat
      receiverLng
      receiverPoiLat
      receiverPoiLng
      receiverPoiName
      receiverPoiAddress
      receiverOperatorName
      routeGeojson
      routeDistance
      primaryMovementDistanceKms
      primaryMovementDistanceMiles
      receiverValidationError
      carrierValidationError
      hasIssues
      validationScore
      complianceScore
      warnings
      movementsUpload {
        id
        originalFilename
      }
      authorityMovementId
      authorityMovementDescription
      authorityMovementEntity
      hasWeightData
      sheetRowNumber
      isHazardous
    }
  }
`;

export interface ISingleMovement {
  id: string;
  movementId: string;
  sourceMovementId: string;
  sourceMovementDescription: string;
  supplierAccountId: string;
  supplierAccountName: string;
  producerTopoPoiId: string;
  producerTimestamp: string;
  producerName: string;
  producerPoiName: string;
  producerAddress: string;
  producerSiteId: string;
  producerLat: number;
  producerPoiLat: number;
  producerLng: number;
  producerPoiLng: number;
  producerPoiRefId: string;
  wasteCode: string;
  wasteDescription: string;
  weightInKgs: number;
  topoWeightInKgs: number;
  weightType: "measured" | "assumed" | "estimated";
  weightUnit: string;
  containerType: string;
  containerCount: number;
  carrierName: string;
  carrierLicense: string;
  topoCarrierName: string;
  topoCarrierLicense: string;
  vehicleRegistration: string;
  receiverTimestamp: string;
  receiverName: string;
  receiverAddress: string;
  receiverPermitId: string;
  receiverLat: number;
  receiverPoiLat: number;
  receiverLng: number;
  receiverPoiLng: number;
  receiverPoiName: string;
  receiverPoiAddress: string;
  receiverOperatorName: string;
  routeGeojson: string;
  routeDistance: number;
  primaryMovementDistanceKms: number | null;
  primaryMovementDistanceMiles: number | null;
  receiverValidationError: string | null;
  carrierValidationError: string | null;
  hasIssues: boolean;
  validationScore: number;
  complianceScore: number;
  warnings?: IRowWarnings;
  movementsUpload: {
    id: number;
    originalFilename: string;
  };
  authorityMovementId: string | null;
  authorityMovementDescription: string | null;
  authorityMovementEntity: string | null;
  hasWeightData: boolean;
  sheetRowNumber: number | null;
  isHazardous: boolean;
}

export interface ISingleMovementResult {
  movement: ISingleMovement;
}

export interface IMovementsResult {
  movements: {
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      node: ISingleMovement;
    }>;
  };
}

export interface IMovementParams {
  movementId: string;
}

export interface IRowWarning {
  row: number;
  column: string;
  message: string;
  value: string;
  section: string;
  code: string;
  validationScore: number;
  complianceScore: number;
}

export interface IRowWarnings {
  warnings: IRowWarning[];
}
