import { Button as ChakraButton } from "@chakra-ui/react";

import { colors } from "./../theme";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  leftIcon?: React.ReactElement;
  width?: string;
};

const ButtonSecondary = (props: Props) => {
  const { children, onClick, isDisabled, leftIcon } = props;
  return (
    <ChakraButton
      leftIcon={leftIcon}
      color={colors.new.text.light[500]}
      bg={colors.new.blue[500]}
      isDisabled={isDisabled}
      onClick={onClick}
      justifyContent="flex-start"
      borderRadius={6}
      _hover={{
        background: "#1c3270",
      }}
      fontSize="sm"
    >
      {children}
    </ChakraButton>
  );
};

export default ButtonSecondary;
