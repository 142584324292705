import { Flex } from "@chakra-ui/react";
import { useQueryParam, StringParam } from "use-query-params";
import { motion } from "framer-motion";

import ViewWrapper from "../../../ui/ViewWrapper";
import ProducerSites from "./ProducerSites";
import Carriers from "./Carriers";
import ReceiverSites from "./ReceiverSites";
import ComparisonHeader from "../components/ComparisonHeader";
import { useEffect } from "react";
import { SIMPLE_FILTER_SECTION_ANIMATION_DURATION } from "../../../aux/constants";

type Props = {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
};

const Dashboard = (props: Props) => {
  const { toggleSimpleFilter, isOpen } = props;

  const variants = {
    open: { opacity: 0.8 },
    closed: { opacity: 0 },
  };

  const [tab, setTab] = useQueryParam("tab", StringParam);

  useEffect(() => {
    if (!tab) {
      setTab("producer_sites");
    }
  }, [tab, setTab]);

  const renderContent = () => {
    if (tab === "producer_sites") {
      return <ProducerSites />;
    } else if (tab === "receiver_sites") {
      return <ReceiverSites />;
    } else if (tab === "carriers") {
      return <Carriers />;
    } else return <ProducerSites />;
  };

  return (
    <Flex
      justify="space-between"
      align="flex-start"
      w="100%"
      direction="row"
      height="100vh"
      overflowY="scroll"
    >
      <ViewWrapper justify="flex-start" align="flex-start">
        <motion.div
          id="mask"
          onClick={toggleSimpleFilter}
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(249, 247, 243, 1)",
            zIndex: -1,
          }}
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          transition={{
            // @ts-ignore
            type: "tween",
            // @ts-ignore
            duration: SIMPLE_FILTER_SECTION_ANIMATION_DURATION,
          }}
        />
        <ComparisonHeader
          toggleSimpleFilter={toggleSimpleFilter}
          isOpen={isOpen}
        />

        <Flex
          justify="center"
          align="center"
          direction="column"
          w="100%"
          px={8}
        >
          {renderContent()}
        </Flex>
      </ViewWrapper>
    </Flex>
  );
};
export default Dashboard;
