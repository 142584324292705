import { gql } from "@apollo/client";

export const GET_PERMITTED_SITES_QUERY = gql`
  query GetPermittedSites(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $siteTypes: [String!]
    $wasteCodes: [String!]
    $topoOrgId: [String!]
  ) {
    permittedSites(
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      siteTypes: $siteTypes
      wasteCodes: $wasteCodes
      topoOrgId: $topoOrgId
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          topoSiteId
          poiName
          poiAddress
          poiType
          poiCategory
          poiLng
          poiLat
          permits
          countryName
          regulatorName
          organisation {
            name
          }
        }
      }
    }
  }
`;

export interface IPermittedSitesData {
  id: string;
  topoSiteId: string;
  poiName: string;
  poiAddress: string;
  poiType: string;
  poiCategory: string;
  poiLng: number;
  poiLat: number;
  permits: string[];
  countryName: string;
  regulatorName: string;
  organisation: {
    name: string;
  };
}

export interface IPermittedSitesResult {
  permittedSites: {
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{ node: IPermittedSitesData }>;
  };
}
