import { Flex } from "@chakra-ui/layout";
import styled from "@emotion/styled";

import Text from "../Text";
import { colors } from "./../theme";

type Props = {
  children: string;
  label: React.ReactNode;
};

const Field = (props: Props) => {
  const { children, label } = props;

  return (
    <Flex direction="column">
      <Label>{label}</Label>
      <Box>
        <Text style={{ fontSize: "1rem" }}>{children}</Text>
      </Box>
    </Flex>
  );
};

const Box = styled.span`
  background: ${colors.new.text.light[500]};
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  padding: 10px;
  cursor: not-allowed;
`;

const Label = styled.span`
  margin-bottom: 4px;
`;

export default Field;
