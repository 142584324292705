import { Flex } from "@chakra-ui/react";
import TrText from "../../../../i18n/TrText";

import GhostButton from "../../../../ui/GhostButton";
import { colors } from "../../../../ui/theme";

type HeaderProps = {
  onFilterClick: () => void;
};

const Header = ({ onFilterClick }: HeaderProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      h="71px"
      px={0}
      pt={0}
      pb={0}
      backgroundColor={colors.new.blue[500]}
      borderTopLeftRadius="10px"
    >
      <GhostButton
        onClick={onFilterClick}
        colour={colors.new.text.light[500]}
        noUnderline={true}
      >
        <TrText message="filter.header_button" />
      </GhostButton>
    </Flex>
  );
};

export default Header;
