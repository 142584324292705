import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex, Box, Accordion } from "@chakra-ui/react";

import SortingIcon from "./../../../../components/SortingIcon";
import { RespondType } from "./../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "./../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import InputBox from "./components/InputBox";
import {
  RECEIVING_SITES_SUMMARY_QUERY,
  IReceivingSitesSummaryResult,
  IReceivingSitesSummary,
  IReceivingSitesSummaryParams,
} from "../../../../graphql/queries/getReceivingSitesSummary";
import { SiteRow } from "./components/SiteRow";
import { colors } from "../../../../ui/theme";
import LoadingData from "../../../../components/LoadingData";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "movement_count",
    "total_weight",
    "receiver_poi_name",
    "receiver_operator_name",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const ReceiverSites = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const filters = useFiltersFromQueryParams();

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("movement_count");

  const { data: currentUserResult, loading: loadingUser } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const {
    data: receivingSitesSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<IReceivingSitesSummaryResult, IReceivingSitesSummaryParams>(
    RECEIVING_SITES_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "movement_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const totalCount =
    receivingSitesSummaryData?.receiverSitesSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const loading = loadingData || loadingUser;

  // Validation
  const [validationValue, setValidationValue] = useState<number | undefined>(
    undefined
  );
  const [
    validationValueThresholdSelected,
    setValidationValueThresholdSelected,
  ] = useState<number | undefined>(undefined);

  // Landfill
  const [landfillValue, setLandfillValue] = useState<number | undefined>(
    undefined
  );
  const [landfillValueThresholdSelected, setLandfillValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // Recovery/Diverted
  const [divertedValue, setDivertedValue] = useState<number | undefined>(
    undefined
  );
  const [divertedValueThresholdSelected, setDivertedValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // Unknown
  const [unknownValue, setUnknownValue] = useState<number | undefined>(
    undefined
  );
  const [unknownValueThresholdSelected, setUnknownValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // Distance per movement
  const [distancePerMovementValue, setDistancePerMovementValue] = useState<
    number | undefined
  >(undefined);
  const [
    distancePerMovementThresholdSelected,
    setDistancePerMovementThresholdSelected,
  ] = useState<number | undefined>(undefined);

  // CO2 per movement
  const [co2PerMovementValue, setCo2PerMovementValue] = useState<
    number | undefined
  >(undefined);
  const [co2PerMovementThresholdSelected, setCo2PerMovementThresholdSelected] =
    useState<number | undefined>(undefined);

  const sites: IReceivingSitesSummary[] =
    receivingSitesSummaryData === undefined
      ? []
      : receivingSitesSummaryData.receiverSitesSummary.edges.map(
          (edge) => edge.node
        );

  const validationValueChange = (value: number) => setValidationValue(value);

  const landfillValueChange = (value: number) => setLandfillValue(value);

  const divertedValueChange = (value: number) => setDivertedValue(value);

  const unknownValueChange = (value: number) => setUnknownValue(value);

  const distancePerMovementonChange = (value: number) =>
    setDistancePerMovementValue(value);

  const co2PerMovementonChange = (value: number) =>
    setCo2PerMovementValue(value);

  const updateWithNewSelections = () => {
    setValidationValueThresholdSelected(validationValue);
    setLandfillValueThresholdSelected(landfillValue);
    setDivertedValueThresholdSelected(divertedValue);
    setUnknownValueThresholdSelected(unknownValue);
    setDistancePerMovementThresholdSelected(distancePerMovementValue);
    setCo2PerMovementThresholdSelected(co2PerMovementValue);
  };

  const isApplyButtonDisabled = () => {
    if (
      validationValueThresholdSelected === validationValue &&
      distancePerMovementThresholdSelected === distancePerMovementValue &&
      co2PerMovementThresholdSelected === co2PerMovementValue &&
      landfillValueThresholdSelected === landfillValue &&
      divertedValueThresholdSelected === divertedValue &&
      unknownValueThresholdSelected === unknownValue
    ) {
      return true;
    } else return false;
  };

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !receivingSitesSummaryData?.receiverSitesSummary?.pageInfo
      .hasPreviousPage || loading;

  const isNextPageDisabled =
    !receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.hasNextPage ||
    loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before:
          receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after:
          receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (
      receivingSitesSummaryData?.receiverSitesSummary?.pageInfo
        .hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [
    receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.hasPreviousPage,
  ]);

  return (
    <>
      <Flex direction="column" w="100%" mb={8}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          px={4}
          mb={8}
        >
          <Flex w="40%" h={74} alignItems="center">
            <H1>
              <TrText message="receiver_comparison.title" />
            </H1>
          </Flex>

          {receivingSitesSummaryData && totalPages > 0 && (
            <PaginationNavigation
              id="compare-receivers"
              isLoading={loading}
              previousButtonName="previous page"
              nextButtonName="next page"
              onChange={onRowSelectChange}
              currentPageCounter={currentPageCounter}
              totalPages={totalPages}
              isPreviousPageDisabled={isPreviousPageDisabled}
              isNextPageDisabled={isNextPageDisabled}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
            />
          )}
        </Flex>

        <Flex
          px={4}
          py={4}
          mb={4}
          bg={colors.new.background_dark[500]}
          color={colors.new.text.light[500]}
          borderRadius={10}
        >
          <Flex w="28%">
            <ButtonPrimary
              onClick={updateWithNewSelections}
              isDisabled={isApplyButtonDisabled()}
            >
              <TrText message="app.apply_changes" />
            </ButtonPrimary>
          </Flex>

          <Box textAlign="center" w="9%" />

          <Box textAlign="center" w="9%" />

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={validationValue}
              onChange={validationValueChange}
              prefix=">"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={landfillValue}
              onChange={landfillValueChange}
              prefix="<"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={divertedValue}
              onChange={divertedValueChange}
              prefix=">"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={unknownValue}
              onChange={unknownValueChange}
              prefix="<"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={distancePerMovementValue}
              onChange={distancePerMovementonChange}
              prefix="<"
              min={0}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={co2PerMovementValue}
              onChange={co2PerMovementonChange}
              prefix="<"
              min={0}
              step={0.01}
            />
          </Flex>
        </Flex>

        <Flex px={4} mb={4}>
          <Flex w="14%" alignItems="center" justifyContent="flex-start">
            <Text>
              <TrText message="table_headings.operator" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="receiver_operator_name"
                ariaLabel="sort by receiver operator name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="receiver_operator_name"
                ariaLabel="sort by receiver operator name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="14%" alignItems="center" justifyContent="flex-start">
            <Text>
              <TrText message="table_headings.receiver_site_name" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="receiver_poi_name"
                ariaLabel="sort by receiver name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="receiver_poi_name"
                ariaLabel="sort by receiver name descending"
              />
            </SortingBox>
          </Flex>
          <Flex
            textAlign="center"
            w="9%"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              <TrText message="table_headings.movements" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count descending"
              />
            </SortingBox>
          </Flex>
          <Flex
            textAlign="center"
            w="9%"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              <TrText message="table_headings.weight" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight descending"
              />
            </SortingBox>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.validation" />
            </Text>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.landfill" />
            </Text>
          </Flex>
          <Flex
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.landfill_diversion" />
            </Text>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.unknown" />
            </Text>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.distance_per_movement" />
            </Text>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              CO<sub>2</sub>/<TrText message="table_headings.movement" />
            </Text>
          </Flex>
        </Flex>

        <Card>
          {loading ? (
            <LoadingData />
          ) : (
            <Accordion allowToggle>
              {sites.map((site) => {
                return (
                  <SiteRow
                    site={site}
                    currentUser={currentUserResult!.currentUser}
                    key={site.id}
                    validationValueThresholdSelected={
                      validationValueThresholdSelected === validationValue
                        ? validationValueThresholdSelected
                        : undefined
                    }
                    landfillValueThresholdSelected={
                      landfillValueThresholdSelected === landfillValue
                        ? landfillValueThresholdSelected
                        : undefined
                    }
                    divertedValueThresholdSelected={
                      divertedValueThresholdSelected === divertedValue
                        ? divertedValueThresholdSelected
                        : undefined
                    }
                    unknownValueThresholdSelected={
                      unknownValueThresholdSelected === unknownValue
                        ? unknownValueThresholdSelected
                        : undefined
                    }
                    distancePerMovementThresholdSelected={
                      distancePerMovementThresholdSelected ===
                      distancePerMovementValue
                        ? distancePerMovementThresholdSelected
                        : undefined
                    }
                    co2PerMovementThresholdSelected={
                      co2PerMovementThresholdSelected === co2PerMovementValue
                        ? co2PerMovementThresholdSelected
                        : undefined
                    }
                  />
                );
              })}
            </Accordion>
          )}
        </Card>
      </Flex>
    </>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(ReceiverSites);
