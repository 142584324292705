import { gql } from "@apollo/client";

export const UPDATE_UPLOAD_STATUS = gql`
  mutation updateMovementsUploadStatus($filename: String!, $status: String!) {
    updateMovementsUploadStatus(
      input: { filename: $filename, status: $status }
    ) {
      upload {
        status
      }
      errors
    }
  }
`;

export const UPDATE_UPLOAD_DELETED = gql`
  mutation updateMovementsUploadDeleted($id: ID!, $deleted: Boolean!) {
    updateMovementsUploadDeleted(input: { id: $id, deleted: $deleted }) {
      errors
    }
  }
`;

export interface IUpdateUploadDeletedParams {
  id: string;
  deleted: boolean;
}

export interface IUpdateUploadStatusParams {
  filename: string;
  status: string;
}

export interface IUpdateUploadResponse {
  createMovementsUpload: {
    errors?: string[];
  };
}
