import { Switch, Route, Redirect } from "react-router-dom";

import Account from "./containers/Account";
import User from "./containers/User";
import ViewWrapper from "../../ui/ViewWrapper";
import ProfileHeader from "./components/ProfileHeader";

const Profile = () => {
  return (
    <ViewWrapper justify="flex-start" align="flex-start">
      <ProfileHeader />

      <Switch>
        <Route
          exact={true}
          path="/h/settings/accounts"
          key="/h/settings/accounts"
        >
          <Account />
        </Route>
        <Route
          exact={false}
          path="/h/settings/profile"
          key="/h/settings/profile"
        >
          <User />
        </Route>
        <Route>
          <Redirect to="/h/settings" />
        </Route>
      </Switch>
    </ViewWrapper>
  );
};

export default Profile;
