import { useState } from "react";
import { Stack, Input, Box } from "@chakra-ui/react";

import { ValueChangeType } from "./../SimpleFilter";
import WasteCheckbox from "./WasteCheckbox";

interface IWasteCodesValues {
  code: string;
  description: string;
  isHazardous: boolean;
}

interface ISelectionListProps {
  uniqueWasteCodes: IWasteCodesValues[];
  wasteCodeValues: string[];
  onWasteCodeValueChange: (props: ValueChangeType) => void;
}

const WasteCodeSelectionList = ({
  uniqueWasteCodes,
  wasteCodeValues,
  onWasteCodeValueChange,
}: ISelectionListProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const allValuesChecked = wasteCodeValues.length === uniqueWasteCodes.length;

  const isIndeterminate = wasteCodeValues.length > 0 && !allValuesChecked;

  return (
    <Stack direction="column" px={1} mt={0}>
      <div
        style={{
          position: "absolute",
          background: "#fff",
          width: "88%",
          zIndex: 2,
          marginTop: "-8px",
        }}
      >
        <Box py={2} borderBottom={"1px dashed gray"}>
          <WasteCheckbox
            key="wc-all"
            isChecked={allValuesChecked}
            isIndeterminate={isIndeterminate}
            name={"Select all materials"}
            onChange={() => {
              onWasteCodeValueChange({
                id: "all",
                isChecked: allValuesChecked,
              });
            }}
          />
        </Box>

        <Box py={2}>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search materials by code"
          />
        </Box>
      </div>

      <div style={{ marginTop: 100 }}>
        {uniqueWasteCodes &&
          uniqueWasteCodes
            .filter(
              (wc) =>
                wc.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (wc.description &&
                  wc.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()))
            )
            .map((wc) => {
              return (
                <WasteCheckbox
                  key={`${wc.code}-${wc.description}`}
                  code={wc.code}
                  isChecked={wasteCodeValues.includes(wc.code)}
                  name={wc.description}
                  label={`${wc.code}${wc.isHazardous ? "*" : ""}`}
                  onChange={({ wasteCode, isChecked }) => {
                    onWasteCodeValueChange({
                      id: wasteCode,
                      isChecked,
                    });
                  }}
                />
              );
            })}
      </div>
    </Stack>
  );
};

export default WasteCodeSelectionList;
