import { Flex } from "@chakra-ui/react";
import TrText from "../../i18n/TrText";

import Text from "../../ui/Text";

const LoadingData = () => {
  return (
    <Flex p={4} justifyContent="flex-start" alignItems="center" h="65px">
      <Text>
        <TrText message="app.loading" />
      </Text>
    </Flex>
  );
};

export default LoadingData;
