import { Box, Flex, Badge } from "@chakra-ui/layout";

import H2 from "../../../ui/H2";
import H3 from "./../../../ui/H3";
import Card from "./../../../ui/Card";
import Text from "./../../../ui/Text";
import { ISingleMovement } from "./../../../graphql/queries/getMovements";
import TrText from "../../../i18n/TrText";
import Link from "../../../ui/Link";
import { colors } from "../../../ui/theme";

type Props = {
  movement: ISingleMovement;
};

export const ProducerCard = (props: Props) => {
  const m = props.movement;
  const w = m?.warnings?.warnings;

  const hasWarning = w?.some((warning) => warning.section === "PRODUCER");

  if (m) {
    return (
      <Card height="100%">
        <Box p={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <H2 size="md">
              <TrText message="producer_card.title" />
            </H2>
            {hasWarning ? (
              <Badge colorScheme="red">
                <TrText message="cards.issue_badge" />
              </Badge>
            ) : (
              <Badge colorScheme="green">
                <TrText message="cards.valid_badge" />
              </Badge>
            )}
          </Flex>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.name" />:
            </H3>
            <Flex ml={0} mb={0} style={{ color: colors.new.text.blue[500] }}>
              <Link to={`/h/pois/${m?.producerTopoPoiId}`}>
                <Text>{m.producerName}</Text>
              </Link>
            </Flex>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.address" />:
            </H3>
            <Text>{m.producerAddress}</Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.site_reference" />:
            </H3>
            <Text>{m.producerPoiRefId}</Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.timestamp" />:
            </H3>
            <Text>{new Date(m.producerTimestamp).toLocaleDateString()}</Text>
          </Box>
        </Box>
      </Card>
    );
  } else return null;
};
