import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

import { colors } from "../theme";

const TabSelector = styled(Button, { shouldForwardProp: isPropValid })`
  background: #fff;
  border-radius: 0px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  color: ${colors.new.text.dark[500]};

  &:focus {
    box-shadow: 0 0 0 0px transparent;
  }

  &:hover {
    background: #fff;
  }
`;

export default TabSelector;
