import { Button as ChakraButton } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { colors } from "./../theme";

type Props = {
  name?: string;
  hiddenText?: string;
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  noUnderline?: boolean;
  colour?: string;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
};

const GhostButton = (props: Props) => {
  const {
    children,
    onClick,
    isDisabled,
    colour,
    noUnderline,
    name,
    hiddenText,
    rightIcon,
    leftIcon,
  } = props;
  return (
    <ChakraButton
      name={name}
      color={colour ? colour : colors.new.text.blue[500]}
      onClick={onClick}
      variant="outline"
      isDisabled={isDisabled}
      rightIcon={rightIcon ? rightIcon : undefined}
      leftIcon={leftIcon ? leftIcon : undefined}
      borderColor="transparent"
      _disabled={{
        borderColor: "transparent",
        color: colors.new.grey[500],
        cursor: "not-allowed",
      }}
      _hover={{
        bg: "transparent",
      }}
      _focus={{
        bg: "transparent",
      }}
      _active={{
        bg: "transparent",
      }}
      style={{
        fontWeight: 400,
        textDecoration: noUnderline ? "none" : "underline",
      }}
      fontSize="sm"
    >
      {children}
      {hiddenText && <HiddenText>{hiddenText}</HiddenText>}
    </ChakraButton>
  );
};

// This to make the button accessible when there is only icon displayed and no text
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#icon_buttons
// https://gomakethings.com/hidden-content-for-better-a11y/#skip-to-navigation
const HiddenText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export default GhostButton;
