import { useRef, useEffect } from "react";

import {
  isLongitudeValid,
  isLatitudeValid,
} from "../../../../../aux/validateCoordinates";
import { mapboxgl, isMapboxSupported } from "./../../../../../services/mapbox";

const SitesLocationsMap = (props) => {
  let wasteMapRef = useRef(null);
  const { pois } = props;
  let map;

  useEffect(() => {
    if (isMapboxSupported) {
      if (pois && pois.length > 0) {
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/streets-v11",
          zoom: 8,
          center: [pois[0]?.poiLng, pois[0]?.poiLat],
        });

        const longs = [];
        const lats = [];

        // render producer sites markers
        pois.forEach((poi) => {
          if (isLongitudeValid(poi?.poiLng) && isLatitudeValid(poi?.poiLat)) {
            longs.push(poi?.poiLng);
            lats.push(poi?.poiLat);

            new mapboxgl.Marker()
              .setLngLat([poi.poiLng, poi.poiLat])
              .setPopup(
                new mapboxgl.Popup().setHTML(
                  `<h2>${poi?.poiName}</h2>
                 <p>Lon: ${poi?.poiLng} Lat: ${poi?.poiLat}</p>`
                )
              )
              .addTo(map);
          }
        });

        // Calculate bounding box
        const minLng = Math.min(...longs);
        const minLat = Math.min(...lats);
        const maxLng = Math.max(...longs);
        const maxLat = Math.max(...lats);

        // Make sure to show all the points,
        // but only when 2 or more markers
        if (pois.length > 1) {
          map.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 100 }
          );
        }
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [pois, map]);

  return (
    <div
      ref={(elem) => (wasteMapRef = elem)}
      style={{
        height: "100%",
        borderRadius: "10px",
        boxShadow: "rgb(0 0 0 / 8%) 0px 2px 20px",
      }}
    />
  );
};

export default SitesLocationsMap;
