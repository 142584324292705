import { Heading } from "@chakra-ui/react";
import React from "react";

type Props = {
  children: React.ReactNode;
  size?: string;
  style?: any;
  my?: string;
};

const H2 = (props: Props) => {
  const { children } = props;

  return (
    <Heading as="h2" fontSize="lg" fontFamily="Open Sans;sans-serif" {...props}>
      {children}
    </Heading>
  );
};

export default H2;
