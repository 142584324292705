import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import Tile from "../../../../../ui/Tile";

const TotalMaterialWeight = ({ weight }: { weight: number }) => (
  <Tile
    title="overview.material_weight_card_title"
    bottomText="overview.in_total"
    iconName="weight"
    value={weight === 0 ? `0t` : `${formatToDecimalPlaces(weight / 1000, 1)}t`}
  />
);

export default TotalMaterialWeight;
