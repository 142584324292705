export const colors = {
  new: {
    primary: {
      500: "#19C682",
    },
    secondary: {
      500: "#3E66FB",
    },
    green: {
      500: "#19C682",
    },
    blue: {
      500: "#284599",
    },
    background_light: {
      500: "#F9F7F3",
    },
    background_dark: {
      500: "#0F123F",
    },
    grey: {
      500: "#E4E4E4",
      700: "#637D7D",
    },
    feedback: {
      red: "#B92347",
    },
    outline: {
      500: "#19C682",
    },
    text: {
      dark: {
        500: "#11263C",
      },
      light: {
        500: "#fff",
      },
      grey: {
        500: "#3c3c43",
      },
      blue: {
        500: "#3E66FB",
      },
    },
  },
  primary: {
    700: "#74A838",
    500: "#84BF41",
  },
  secondary: {
    700: "#0B82F2",
    500: "#1395D9",
  },
  grey: {
    700: "#2D3748",
    500: "#718096",
    300: "#CBD5E0",
    100: "#EDF2F7",
  },
  red: {
    500: "C2941E",
  },
  green: {
    500: "#94C21E",
  },
  purple: {
    500: "#8B51EC",
  },
  blue: {
    500: "#00A3B2",
  },
  darkBlue: {
    500: "#0A1722",
  },
  midBlue: {
    500: "#627AEE",
  },
  lightBlue: {
    500: "#EEF4F9",
  },
  darkGray: {
    500: "#525F6A",
  },
  midGray: {
    500: "#B3B9BF",
  },
  lightGray: {
    500: "#E3E5E7",
  },
  background: {
    500: "#FCFDFD",
  },
  white: {
    500: "#FFFFFF",
  },
  charts: {
    primary: {
      main: "#FF6B6B",
      opacity20: "rgba(255, 107, 107, 0.2)",
      opacity40: "rgba(255, 107, 107, 0.4)",
    },
    secondary: {
      main: "#7EBDC3",
      opacity20: "rgba(126, 189, 195, 0.2)",
      opacity40: "rgba(126, 189, 195, 0.4)",
    },
    tertiary: {
      main: "#5DA7B7",
      opacity20: "rgba(41, 112, 69, 0.2)",
      opacity40: "#665DA7B7",
    },
    quaternary: {
      main: "#CF995F",
      opacity20: "rgba(207, 153, 95, 0.2)",
      opacity40: "rgba(207, 153, 95, 0.4)",
    },
    materials: {
      wood: {
        main: "#AD7234",
      },
      plastic: {
        main: "#297045",
      },
      glass: {
        main: "#7852F4",
      },
      other: {
        main: "#7981A4",
      },
    },
    fates: {
      landfill: {
        main: "rgba(58, 135, 207)",
        20: "rgba(58, 135, 207, 0.2)",
        40: "rgba(58, 135, 207, 0.4)",
      },
      recycling: {
        main: "rgb(91, 243, 14)",
        20: "rgba(91, 243, 14, 0.2)",
        40: "rgba(91, 243, 14, 0.6)",
      },
      incineration: {
        main: "rgba(193, 226, 119)",
        20: "rgba(193, 226, 119, 0.2)",
        40: "rgba(193, 226, 119, 0.6)",
      },
      transfer: {
        main: "rgb(114, 90, 193)",
        20: "rgba(114, 90, 193, 0.2)",
        40: "rgba(114, 90, 193, 0.4)",
      },
      unknown: {
        main: "rgb(229, 116, 188)",
        20: "rgba(229, 116, 188, 0.2)",
        40: "rgba(229, 116, 188, 0.4)",
      },
      lost: {
        main: "rgb(245, 183, 0)",
        20: "rgba(245, 183, 0, 0.2)",
        40: "rgba(245, 183, 0, 0.4)",
      },
      other: {
        main: "rgb(166, 250, 239",
        20: "rgba(166, 250, 239, 0.2)",
        40: "rgba(166, 250, 239, 0.4)",
      },
      long_term_storage: {
        main: "rgb(166, 130, 255)",
        20: "rgba(166, 130, 255, 0.2)",
        40: "rgba(166, 130, 255, 0.4)",
      },
      treatment: {
        main: "rgb(88, 153, 94)",
        20: "rgba(88, 153, 94, 0.2)",
        40: "rgba(88, 153, 94, 0.6)",
      },
      recovery: {
        main: "rgb(88, 53, 94)",
        20: "rgba(88, 53, 94, 0.2)",
        40: "rgba(88, 53, 94, 0.4)",
      },
    },
  },
};

// These are used by react-select selectors
export const selectors = {
  primary25: colors.new.grey[500],
  primary: colors.new.primary[500],
  neutral0: "#fff",
  neutral10: colors.new.grey[500],
  neutral20: colors.new.primary[500],
  neutral50: "#999",
  neutral60: colors.new.primary[500],
  neutral80: "#716352",
  borderRadius: 6,
  control: {
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 20px",
    border: "2px solid #cccbc8",
  },
};
