import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const MOVEMENTS_SUMMARY_QUERY = gql`
  query GetMovementsSummary(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $poiGroupIds: [String!]
    $supplierIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    movementsSummary(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      totalWeight
      totalPrimaryDistanceKms
      totalPrimaryDistanceMiles
      totalPrimaryCo2Kgs
      totalLandfillFateWeight
      totalDivertedFromLandfillFateWeight
      totalUnknownFateWeight
      landfillFatePercentage
      landfillDiversionFatePercentage
      unknownFatePercentage
      activityCount
      movementCount
      receiverValidationErrors
      carrierValidationErrors
      validationIssues
      complianceIssues
      totalIssues
    }
  }
`;

export interface IMovementsSummary {
  totalWeight: number;
  totalPrimaryDistanceKms: number;
  totalPrimaryDistanceMiles: number;
  totalPrimaryCo2Kgs: number;
  totalLandfillFateWeight: number;
  totalDivertedFromLandfillFateWeight: number;
  totalUnknownFateWeight: number;
  landfillFatePercentage: number;
  landfillDiversionFatePercentage: number;
  unknownFatePercentage: number;
  activityCount: number;
  movementCount: number;
  receiverValidationErrors: number;
  carrierValidationErrors: number;
  validationIssues: number;
  complianceIssues: number;
  totalIssues: number;
}

export interface IMovementsSummaryResult {
  movementsSummary: IMovementsSummary;
}

export type IMovementsSummaryParams = IFilters;

export const UNIQUE_VALUES_QUERY = gql`
  query GetUniqueValues {
    movementsSummary {
      uniqueCarriers
      uniqueWasteCodes {
        code
        description
        isHazardous
      }
      uniqueProducerPois {
        poiId
        name
        address
      }
      uniqueReceiverPois {
        receiverId
        name
        address
      }
      uniqueSuppliers {
        supplierId
        name
      }
    }
  }
`;

export interface WasteCode {
  code: string;
  description: string;
  isHazardous: boolean;
}

export interface ProducerPoi {
  poiId: string;
  name: string;
  address: string;
}
export interface ReceiverPoi {
  receiverId: string;
  name: string;
  address: string;
}

export interface Supplier {
  supplierId: string;
  name: string;
}

export interface IUniqueValues {
  movementsSummary: {
    uniqueCarriers: [string];
    uniqueWasteCodes: WasteCode[];
    uniqueProducerPois: ProducerPoi[];
    uniqueReceiverPois: ReceiverPoi[];
    uniqueSuppliers: Supplier[];
  };
}

export const READ_ACTIVITIES_COUNT = gql`
  query ReadActivitiesCount(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $poiGroupIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    movementsSummary(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      poiGroupIds: $poiGroupIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      activityCount
    }
  }
`;

export interface IActivitiesCountResult {
  movementsSummary: {
    activityCount: number;
  };
}
