import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import Tile from "../../../../../ui/Tile";

const TotalMaterialWeight = ({
  weight,
  isLoading,
}: {
  weight: number;
  isLoading: boolean;
}) => (
  <Tile
    title="overview.material_weight_card_title"
    bottomText="overview.in_total"
    iconName="weight"
    popoverText="overview.material_weight_popover"
    value={
      isLoading
        ? undefined
        : weight === 0
        ? `0t`
        : `${formatToDecimalPlaces(weight / 1000, 1)}t`
    }
  />
);

export default TotalMaterialWeight;
