import { useState, memo, useEffect } from "react";
import { Stack, Radio, RadioGroup, Box, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";

import DatePicker from "./../DatePicker";
import { formatDate } from "../../../../aux/formatDate";

type DateRangeType =
  | "this_month"
  | "last_month"
  | "last_3_months"
  | "last_6_months"
  | "last_year"
  | "all"
  | "custom";

type Props = {
  setStartDateStringValue: React.Dispatch<React.SetStateAction<string | null>>;
  setEndDateStringValue: React.Dispatch<React.SetStateAction<string | null>>;
  areDatesInUrlSet?: boolean;
};

const DateRange = ({
  setStartDateStringValue,
  setEndDateStringValue,
  areDatesInUrlSet,
}: Props) => {
  const today = dayjs();

  const [startDate, setStartDate] = useState(
    formatDate(today.subtract(1, "month"))
  );
  const [endDate, setEndDate] = useState(formatDate(today));

  const [dateRangeValue, setDateRangeValue] = useState<DateRangeType>(() => {
    return "all";
  });

  const isDatePickerDisabled = () => {
    if (
      [
        "this_month",
        "last_month",
        "last_3_months",
        "last_6_months",
        "last_year",
        "all",
      ].includes(dateRangeValue)
    ) {
      return true;
    } else return false;
  };

  // Clear dates and checkboxes on ClearAll action
  useEffect(() => {
    const clearDates = () => {
      const today = dayjs();

      setDateRangeValue("all");
      setStartDate(formatDate(today.subtract(1, "month")));
      setEndDate(formatDate(today));
    };

    if (!areDatesInUrlSet) {
      clearDates();
    }
  }, [areDatesInUrlSet]);

  return (
    <Flex direction="column">
      <Flex direction="row">
        <Box w="50%" mr={2}>
          <DatePicker
            name="start-date"
            labelText="Start date"
            value={startDate}
            max={endDate}
            isDisabled={isDatePickerDisabled()}
            onChange={(v) => {
              if (dayjs(v).isValid()) {
                if (dayjs(v) >= dayjs(endDate)) {
                  const d = dayjs(endDate).subtract(1, "day");
                  setStartDate(formatDate(d));
                  setStartDateStringValue(formatDate(d));
                } else {
                  setStartDate(v);
                  setStartDateStringValue(v);
                }
              }
            }}
          />
        </Box>

        <Box w="50%" ml={2}>
          <DatePicker
            name="end-date"
            labelText="End date"
            value={endDate}
            max={formatDate(today)}
            min={startDate}
            isDisabled={isDatePickerDisabled()}
            onChange={(v) => {
              if (dayjs(v).isValid()) {
                if (dayjs(v) >= today) {
                  setEndDate(formatDate(today));
                  setEndDateStringValue(formatDate(today));
                } else {
                  setEndDate(v);
                  setEndDateStringValue(v);
                }
              }
            }}
          />
        </Box>
      </Flex>

      <Box mt={4}>
        <RadioGroup
          value={dateRangeValue}
          onChange={(v: DateRangeType) => {
            const today = dayjs();

            if (v === "this_month") {
              setStartDate(formatDate(today.startOf("month")));
              setStartDateStringValue(formatDate(today.startOf("month")));
              setEndDate(formatDate(today));
              setEndDateStringValue(formatDate(today));
            } else if (v === "last_month") {
              const sDate = formatDate(
                today.subtract(1, "month").startOf("month")
              );
              const eDate = formatDate(
                today.subtract(1, "month").endOf("month")
              );

              setStartDate(sDate);
              setStartDateStringValue(sDate);
              setEndDate(eDate);
              setEndDateStringValue(eDate);
            } else if (v === "last_3_months") {
              const sDate = formatDate(
                today.subtract(3, "month").startOf("month")
              );
              const eDate = formatDate(
                today.subtract(1, "month").endOf("month")
              );

              setStartDate(sDate);
              setStartDateStringValue(sDate);
              setEndDate(eDate);
              setEndDateStringValue(eDate);
            } else if (v === "last_6_months") {
              const sDate = formatDate(
                today.subtract(6, "month").startOf("month")
              );
              const eDate = formatDate(
                today.subtract(1, "month").endOf("month")
              );

              setStartDate(sDate);
              setStartDateStringValue(sDate);
              setEndDate(eDate);
              setEndDateStringValue(eDate);
            } else if (v === "last_year") {
              const sDate = formatDate(
                today.subtract(1, "year").startOf("year")
              );
              const eDate = formatDate(today.subtract(1, "year").endOf("year"));

              setStartDate(sDate);
              setStartDateStringValue(sDate);
              setEndDate(eDate);
              setEndDateStringValue(eDate);
            } else if (v === "all") {
              setStartDateStringValue(null);
              setEndDateStringValue(null);
            }

            setDateRangeValue(v);
          }}
        >
          <Stack direction="column" px={1}>
            <Radio colorScheme="green" id="all" name="all" value="all">
              All
            </Radio>
            <Radio
              colorScheme="green"
              id="this_month"
              name="this_month"
              value="this_month"
            >
              This month
            </Radio>
            <Radio
              colorScheme="green"
              id="last_month"
              name="last_month"
              value="last_month"
            >
              Last month
            </Radio>
            <Radio
              colorScheme="green"
              id="last_3_months"
              name="last_3_months"
              value="last_3_months"
            >
              Last 3 months
            </Radio>
            <Radio
              colorScheme="green"
              id="last_6_months"
              name="last_6_months"
              value="last_6_months"
            >
              Last 6 months
            </Radio>
            <Radio
              colorScheme="green"
              id="last_year"
              name="last_year"
              value="last_year"
            >
              Last year
            </Radio>
            <Radio colorScheme="green" id="custom" name="custom" value="custom">
              Custom
            </Radio>
          </Stack>
        </RadioGroup>
      </Box>
    </Flex>
  );
};

export default memo(DateRange);
