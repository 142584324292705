import { Switch, Route, Redirect } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import ViewWrapper from "./../../ui/ViewWrapper";
import NewUpload from "./NewUpload";
import UploadHeader from "./components/UploadHeader";

const Upload = () => {
  return (
    <ViewWrapper justify="flex-start" align="flex-start">
      <UploadHeader />
      <Flex direction="column" py={8} px={8} w="100%" overflowY="scroll">
        <Switch>
          <Route exact path="/h/uploads">
            <NewUpload />
          </Route>
          <Route exact path="/h/uploads/all">
            <Redirect to="/h/uploads" />
          </Route>
        </Switch>
      </Flex>
    </ViewWrapper>
  );
};

export default Upload;
