import { Box, Flex } from "@chakra-ui/react";

import Text from "../Text";

type Props = {
  title: string | undefined;
  label: string | undefined;
};

const RadioLabel = ({ title, label }: Props) => {
  return (
    <Flex direction="column" ml={2}>
      {title && (
        <Box>
          <Text>
            <b>{title}</b>
          </Text>
        </Box>
      )}
      {label && (
        <Box mb={1}>
          <Text style={{ fontSize: "0.9rem" }}>{label}</Text>
        </Box>
      )}
    </Flex>
  );
};

export default RadioLabel;
