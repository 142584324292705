import { formatToDecimalPlaces } from "../../aux/numbers";
import Tile from "../../ui/Tile";

const TotalUnknownMaterial = ({
  totalWeight,
  isLoading,
  unknownPercentage,
}: {
  totalWeight: number;
  isLoading: boolean;
  unknownPercentage: number;
}) => {
  // Divide by 100, since we receive a number like 65.8% and we want 0.658
  // Divide by 1000 to get tonnes
  const tonnes = formatToDecimalPlaces(
    (totalWeight * Number(unknownPercentage.toFixed(2))) / 100 / 1000,
    1
  );

  const calculateValue = ((totalWeight: number, tonnes: string): string => {
    if (totalWeight <= 0) {
      return `0t`;
    }
    if (unknownPercentage < 0 || unknownPercentage > 100) {
      return `0t`;
    } else {
      return `${tonnes}t (${unknownPercentage.toFixed(2)}%)`;
    }
  })(totalWeight, tonnes);

  return (
    <Tile
      title="overview.unknown_fate_title"
      bottomText="overview.in_total"
      popoverText="overview.unknown_popover"
      iconName="weight"
      value={isLoading ? undefined : calculateValue}
    />
  );
};

export default TotalUnknownMaterial;
