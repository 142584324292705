import { useCallback, useEffect, useState } from "react";
import Chartjs from "chart.js/auto";
import dayjs from "dayjs";
import _ from "lodash";
import { Flex } from "@chakra-ui/react";

import Loading from "../../components/Loading";
import {
  materialGroupsColours,
  MaterialGroupsColoursType,
} from "./../../ui/materialGroupsColours";
import { IWasteStreams } from "../../graphql/queries/getWasteStreams";

const WasteStreamBarChart = ({
  wasteStreams,
}: {
  wasteStreams: IWasteStreams[] | undefined;
}) => {
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  // create the chart
  const chartContainerRef = useCallback((canvasNode: any) => {
    if (chartInstance) {
      chartInstance.destroy();
    }
    if (canvasNode !== null) {
      const newChartInstance = new Chartjs(canvasNode, {
        type: "bar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              title: {
                display: true,
                text: "tonnes",
              },
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            title: {
              display: false,
              text: "Waste Streams",
            },
          },
        },
      });
      setChartInstance(newChartInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate the chart
  useEffect(() => {
    if (wasteStreams && chartInstance) {
      // group everything by the month
      const monthly = _.groupBy(wasteStreams, "month");
      // these are the months - get them nicely sorted
      const months = Object.keys(monthly).sort();
      // turn them into nice strings for the labels
      const labels = months.map((month) => dayjs(month).format("MMMM YYYY"));
      // group the monthly data by soc group to make lookup faster when we generate the datasets for the chart
      // this gives us an object keyed by month and then topoSoc2Group
      const monthsSocGrouped = _.mapValues(monthly, (socGroups) =>
        _.keyBy(socGroups, "topoSoc2Group")
      );
      // collate all the soc groups mentioned in the data
      const all_groups = Array.from(
        new Set(
          Object.values(monthsSocGrouped).reduce(
            (collection, socGroups) => [
              ...collection,
              ...Object.keys(socGroups),
            ],
            [] as string[]
          )
        )
      ).sort();
      // convert the data into something the chart understands
      // the chart is weird it wants a dataset for each soc2 group with data for each month inside
      const datasets = all_groups.map((group) => ({
        label: group,
        // for each month find the data for this group or 0 if the group is not mentioned in the month
        // data: months.map(month => ((monthly[month].find(socGroup => socGroup.topoSoc2Group === group) || { kgs: 0 }).kgs)),
        data: months.map(
          (month) => _.get(monthsSocGrouped, [month, group, "kgs"], 0) / 1000
        ),
        backgroundColor:
          materialGroupsColours[group as keyof MaterialGroupsColoursType],
      }));
      chartInstance.data = { labels, datasets };
      try {
        chartInstance.update("none");
      } catch {
        // ignored - caused by hot reloading
      }
    }
  }, [wasteStreams, chartInstance]);

  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        position: "relative",
        height: "350px",
        paddingBottom: "20px",
        width: "100%",
      }}
    >
      {wasteStreams ? (
        <canvas id="wasteStreambarChart" ref={chartContainerRef} />
      ) : (
        <Flex w="100%" h="250" justifyContent="center" alignItems="center">
          <Loading />
        </Flex>
      )}
    </div>
  );
};

export default WasteStreamBarChart;
