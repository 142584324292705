import { useMutation } from "@apollo/client";
import { Box, Skeleton, useToast } from "@chakra-ui/react";
import {
  ICreateInvitationResponse,
  ICreateInvitationParams,
  CREATE_INVITATION,
  ICancelInvitationResponse,
  ICancelInvitationParams,
  CANCEL_INVITATION,
  IRemoveUserResponse,
  IRemoveUserParams,
  REMOVE_USER,
  IUpdateUserRolesResponse,
  IUpdateUserRolesParams,
  UPDATE_USER_ROLE,
} from "../../../graphql/mutations/userManagement";
import {
  GET_CURRENT_USER,
  ICurrentUser,
  Role,
} from "../../../graphql/queries/getCurrentUser";
import TrText from "../../../i18n/TrText";
import Card from "../../../ui/Card";
import H2 from "../../../ui/H2";
import AddUser from "../components/AddUser";
import ManageUsers from "../components/ManageUsers";
import PendingInvites from "../components/PendingInvites";

const AccountAdmin = ({
  currentUser,
}: {
  currentUser: ICurrentUser | undefined;
}) => {
  const toast = useToast();

  const [createInvitation] = useMutation<
    ICreateInvitationResponse,
    ICreateInvitationParams
  >(CREATE_INVITATION, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const [cancelInvitation] = useMutation<
    ICancelInvitationResponse,
    ICancelInvitationParams
  >(CANCEL_INVITATION, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const [removeUser] = useMutation<IRemoveUserResponse, IRemoveUserParams>(
    REMOVE_USER,
    {
      refetchQueries: [{ query: GET_CURRENT_USER }],
    }
  );

  const [updateUserRoles] = useMutation<
    IUpdateUserRolesResponse,
    IUpdateUserRolesParams
  >(UPDATE_USER_ROLE, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const runMutation = async (
    mutation: any,
    variables: any,
    successTitle: string,
    successMessage: string,
    errorTitle: string
  ) => {
    try {
      const response = await mutation({ variables });
      if (!response.data?.success) {
        toast({
          title: successTitle,
          description: successMessage,
          status: "success",
        });
      } else {
        toast({
          title: errorTitle,
          description: response.data.errors.join(", "),
        });
      }
    } catch (e) {
      toast({
        title: errorTitle,
        description: "Please try again later",
        status: "error",
      });
    }
  };

  const onCreateInvitation = async (
    email: string,
    name: string,
    roles: Role[]
  ) => {
    if (!currentUser?.account?.id) {
      return;
    }
    await runMutation(
      createInvitation,
      {
        accountId: currentUser.account.id,
        email,
        name,
        roles,
      },
      "An invitation has been sent",
      `An invitation has been sent to ${name} at ${email}`,
      "Failed to create invitation"
    );
  };

  const onRemoveInvitation = async (userId: string) => {
    await runMutation(
      cancelInvitation,
      { userId },
      "Success",
      "Invitation cancelled",
      "Failed to cancel invitation"
    );
  };

  const onRemoveUser = async (userId: string) => {
    await runMutation(
      removeUser,
      { userId },
      "Success",
      "User removed",
      "Failed to remove user"
    );
  };

  const onUpdateUserRoles = async (userId: string, roles: Role[]) => {
    await runMutation(
      updateUserRoles,
      { userId, roles },
      "Success",
      "User roles updated",
      "Failed to update user roles"
    );
  };

  const pendingInvites = currentUser?.account?.users?.filter(
    (user) => user.isInvited && user.id !== currentUser.id
  );
  const existingUsers = currentUser?.account?.users?.filter(
    (user) => !user.isInvited && user.id !== currentUser.id
  );

  return (
    <>
      <H2>
        <TrText message="account_admin.invite_title" />
      </H2>
      <Skeleton isLoaded={!!currentUser}>
        <Box w="400px" mt={4} mb={4}>
          <Card>
            <AddUser
              currentUser={currentUser}
              onCreateInvitation={onCreateInvitation}
            />
          </Card>
        </Box>
      </Skeleton>

      <H2>
        <TrText message="account_admin.pending_invites_title" />
      </H2>
      <Skeleton isLoaded={!!currentUser}>
        <Box mt={4} mb={4}>
          <Card>
            <PendingInvites
              pendingInvites={pendingInvites || []}
              onRemoveInvitation={onRemoveInvitation}
            />
          </Card>
        </Box>
      </Skeleton>

      <H2>
        <TrText message="account_admin.users_title" />
      </H2>
      <Skeleton isLoaded={!!currentUser}>
        <Box mt={4} mb={4}>
          <Card>
            <ManageUsers
              users={existingUsers || []}
              onRemoveUser={onRemoveUser}
              onUpdateUserRoles={onUpdateUserRoles}
            />
          </Card>
        </Box>
      </Skeleton>
    </>
  );
};

export default AccountAdmin;
