const getHostname = () => {
  return window.location.hostname;
};

export const apiUri = (hostname = getHostname()) => {
  // are we running in local development mode?
  if (hostname.includes("localhost")) {
    if (window.location.port) {
      return `http://localhost:${parseInt(window.location.port, 10) + 1}`;
    } else {
      return "http://localhost";
    }
  }
  // are we running in docker?
  if (hostname.includes("frontend.local.tp0.uk")) {
    return "http://backend.local.tp0.uk";
  }
  // are we running in dev or stage? We'll start with tm.dev or tm.stage
  if (hostname.startsWith("tm.dev.") || hostname.startsWith("tm.stage.")) {
    return `https://${hostname.replace("tm.", "service.")}`;
  }
  // must be running in production...
  return "https://service.wastemap.io";
};

export const docsUrl = (hostname = getHostname()) => {
  // are we running in local development mode?
  if (hostname.includes("localhost")) {
    return "http://docs.dev.tp0.uk/index.html";
  }
  // are we running in docker?
  if (hostname.includes("frontend.local.tp0.uk")) {
    return "http://docs.dev.tp0.uk/index.html";
  }
  // are we running in dev or stage? We'll start with tm.dev or tm.stage
  if (hostname.startsWith("tm.dev.")) {
    return "http://docs.dev.tp0.uk/index.html";
  }

  if (hostname.startsWith("tm.stage.")) {
    return "http://docs.stage.tp0.uk/index.html";
  }
  // must be running in production...
  return "https://docs.wastemap.io/index.html";
};

export const graphQLEndpoint = () => {
  const hostname = getHostname();
  return `${apiUri(hostname)}/app/graphql`;
};

export const logoutEndpoint = () => {
  const hostname = getHostname();
  return `${apiUri(hostname)}/app/users/sign_out?redirect_uri=${
    window.location.href
  }`;
};

export const loginEndpoint = () => {
  const hostname = getHostname();
  return `${apiUri(hostname)}/app/users/sign_in?redirect_uri=${
    window.location.href
  }`;
};

export const exportEndpoint = () => {
  const hostname = getHostname();
  return `${apiUri(hostname)}/app/exports`;
};

/**
 * Upload a file with progress
 * @param url URL to post file to
 * @param file The file to upload
 * @param progress Callback for progress events
 * @returns A promise
 */
export const fileUpload = (
  url: string,
  file: File,
  progress: (progress: number) => void
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const request = new XMLHttpRequest();
      request.open("PUT", url);

      // upload progress event
      request.upload.addEventListener("progress", function (e) {
        // upload progress as percentage
        progress((100 * e.loaded) / e.total);
      });

      // request finished event
      request.addEventListener("load", function (e) {
        if (request.status === 200 || request.status === 201) {
          resolve(true);
        }
        reject(new Error(request.response));
      });

      request.addEventListener("error", function (e) {
        reject(new Error("Upload Failed"));
      });

      // send POST request to server
      request.send(file);
    } catch (error) {
      reject(error);
    }
  });
};
