import { gql } from "@apollo/client";

export const GET_GROUP_POIS = gql`
  query GetGroupPois($id: ID!) {
    poiGroup(id: $id) {
      id
      name
      customCode
      description
      startDate
      endDate
      poisInGroupCount
      pois {
        id
        topoSiteId
        poiName
        poiAddress
        poiLng
        poiLat
      }
    }
  }
`;

export interface IGroupPois {
  id: string;
  name: string;
  customCode: string;
  description: string;
  startDate: string;
  endDate: string;
  poisInGroupCount: number;
  pois: Array<IPoi>;
}

export interface IPoi {
  id: string;
  topoSiteId: string;
  poiName: string;
  poiAddress: string;
  poiLng: number;
  poiLat: number;
}

export interface IGroupPoisResult {
  poiGroup: IGroupPois;
}
