import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import { MaterialGroupsColoursType } from "../../ui/materialGroupsColours";

export const WASTE_STREAMS_QUERY = gql`
  query GetWasteStreams(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    wasteStreams(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      month
      topoSoc2Group
      kgs
    }
  }
`;

export interface IWasteStreams {
  month: Date;
  topoSoc2Group: keyof MaterialGroupsColoursType;
  kgs: number;
}

export interface IWasteStreamsResult {
  wasteStreams: IWasteStreams[];
}

export type IWasteStreamsParams = IFilters;
