import { Flex } from "@chakra-ui/react";
import { useQueryParam, StringParam } from "use-query-params";
import { motion } from "framer-motion";

import ViewWrapper from "../../../ui/ViewWrapper";
import Overview from "./Overview";
import Sites from "./Sites";
import Receivers from "./Receivers";
import Groups from "./Groups";
import Suppliers from "./Suppliers";
import Activities from "./Activities";
import Carriers from "./Carriers";
import MapView from "./MapView";
import AnalyticsHeader from "../components/AnalyticsHeader";
import { useEffect } from "react";
import { SIMPLE_FILTER_SECTION_ANIMATION_DURATION } from "./../../../aux/constants";

type Props = {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
};

const Dashboard = (props: Props) => {
  const { toggleSimpleFilter, isOpen } = props;

  const variants = {
    open: { opacity: 0.8 },
    closed: { opacity: 0 },
  };

  const [tab, setTab] = useQueryParam("tab", StringParam);

  useEffect(() => {
    if (!tab) {
      setTab("overview");
    }
  }, [tab, setTab]);

  const renderContent = () => {
    if (tab === "overview") {
      return <Overview />;
    } else if (tab === "sites") {
      return <Sites />;
    } else if (tab === "groups") {
      return <Groups />;
    } else if (tab === "suppliers") {
      return <Suppliers />;
    } else if (tab === "activities") {
      return <Activities />;
    } else if (tab === "carriers") {
      return <Carriers />;
    } else if (tab === "receivers") {
      return <Receivers />;
    } else if (tab === "mapview") {
      return <MapView />;
    } else return <Overview />;
  };

  return (
    <Flex
      justify="space-between"
      align="flex-start"
      w="100%"
      direction="row"
      height="100vh"
      overflowY="scroll"
    >
      <ViewWrapper justify="flex-start" align="flex-start">
        <motion.div
          id="mask"
          onClick={toggleSimpleFilter}
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(249, 247, 243, 1)",
            zIndex: -1,
          }}
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          transition={{
            // @ts-ignore
            type: "tween",
            // @ts-ignore
            duration: SIMPLE_FILTER_SECTION_ANIMATION_DURATION,
          }}
        />
        <AnalyticsHeader
          toggleSimpleFilter={toggleSimpleFilter}
          isOpen={isOpen}
        />

        <Flex justify="center" align="center" direction="column" w="100%">
          {renderContent()}
        </Flex>
      </ViewWrapper>
    </Flex>
  );
};
export default Dashboard;
