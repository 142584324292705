import { useQuery, useMutation } from "@apollo/client";
import { Flex, IconButton, Button } from "@chakra-ui/react";
import { RiDeleteBin6Fill as Delete } from "react-icons/ri";
import {
  GET_FILTERS,
  IFiltersResult,
} from "../../../../graphql/queries/getFilters";
import {
  DELETE_FILTER,
  IDeleteFilterResponse,
  IDeleteFilterParams,
} from "../../../../graphql/mutations/deleteFilter";

import { colors } from "../../../../ui/theme";
import Text from "./../../../../ui/Text";
import { LineItem } from "./LineItem";

export type FilterType = {
  id: string;
  name: string;
  params: {
    startDate?: string;
    endDate?: string;
    wasteCodes?: string[];
    carrierName?: string;
    poiIds?: string[];
    receiverPoiIds?: string[];
    poiGroupIds?: string[];
    supplierIds?: string[];
    hasIssues?: boolean;
  };
};

const LoadFilters = ({
  applyFilter,
}: {
  applyFilter: (f: FilterType) => void;
}) => {
  const { data } = useQuery<IFiltersResult>(GET_FILTERS, {});

  const [deleteFilter] = useMutation<
    IDeleteFilterResponse,
    IDeleteFilterParams
  >(DELETE_FILTER, {
    refetchQueries: [{ query: GET_FILTERS }],
  });

  return (
    <Flex as="article" py={4} direction={"column"}>
      {data?.filters.length === 0 && (
        <Flex>
          <Text>No saved filters.</Text>
        </Flex>
      )}

      {data && data?.filters?.length > 0 && (
        <Flex direction="column" px={0}>
          {data?.filters.map((f) => {
            return (
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={1}
                px={0}
                key={f.id}
                borderBottom="1px dashed gray"
              >
                <Flex
                  w="100%"
                  justifyContent="flex-start"
                  direction="column"
                  py={1}
                >
                  <LineItem filter={f} />
                </Flex>

                <Button
                  size="xs"
                  fontWeight="normal"
                  px={4}
                  ml={2}
                  onClick={() => applyFilter(f)}
                >
                  <Text>Load</Text>
                </Button>

                <IconButton
                  color="#888"
                  _hover={{
                    color: colors.new.feedback.red,
                  }}
                  aria-label="Delete filter"
                  icon={<Delete />}
                  size="xs"
                  ml={2}
                  onClick={() => {
                    deleteFilter({
                      variables: {
                        id: f.id,
                      },
                    });
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default LoadFilters;
