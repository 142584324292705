import { gql } from "@apollo/client";

export const CREATE_FILTER = gql`
  mutation createFilter($name: String!, $params: JSON!) {
    createFilter(input: { name: $name, isShared: false, params: $params }) {
      errors
      filter {
        id
        name
        params
      }
    }
  }
`;

export interface ICreateFilterParams {
  name: string;
  params: object;
}

export interface ICreateFilterResponse {
  createFilter: {
    errors?: string[];
    filter?: {
      id: string;
      name: string;
      params: any;
    };
  };
}
