import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";

import marketing_image_1 from "./../../assets/images/marketing/marketing_image_1.jpg";
import topo_logo from "./../../assets/images/logos/topolytics-logo.svg";
import PageWrapper from "../../ui/PageWrapper";
import H1 from "../../ui/H1";
import { loginEndpoint } from "../../api";
import { colors } from "../../ui/theme";
import ButtonPrimary from "./../../ui/ButtonPrimary";

const LandingPage = () => {
  return (
    <PageWrapper align="center" justify="center">
      <Background imgPath={marketing_image_1}>
        <TextBackground>
          <Heading>
            <Green>
              Making the world's waste
              <br />
            </Green>
            <White>
              Visible, Verifiable
              <br />
            </White>
            <White>and Valuable</White>
          </Heading>

          <ButtonContainer>
            <ButtonPrimary
              data-testid="login-button"
              onClick={() => {
                window.location.href = loginEndpoint();
              }}
            >
              Sign in to WasteMap &reg;
            </ButtonPrimary>
          </ButtonContainer>
        </TextBackground>

        <a href={"https://topolytics.com"} target="blank">
          <PoweredBy imgPath={topo_logo}>
            <TopoText>POWERED BY</TopoText>
          </PoweredBy>
        </a>
      </Background>
    </PageWrapper>
  );
};
export default LandingPage;

const Background = styled("div", {
  shouldForwardProp: isPropValid,
})<{ imgPath: string }>`
  background-image: url(${(props) => props.imgPath});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem;

  @media (min-width: 500px) {
    padding: 5rem;
  }

  @media (min-width: 1000px) {
    padding: 11rem;
    justify-content: flex-start;
    align-items: center;
  }
`;
const TextBackground = styled(Box)`
  @media (min-width: 1000px) {
    padding: 3rem;
    background: rgba(12, 12, 12, 0.4);
    border-radius: 10px;
  }
`;
const Heading = styled(H1)`
  color: white;
  line-height: 1.2;

  @media (min-width: 1000px) {
    text-align: center;
  }
`;
const Green = styled.span`
  color: ${colors.new.primary[500]};
  font-size: 1.2rem;
  font-weight: 400;

  @media (min-width: 500px) {
    font-size: 2.1rem;
  }
`;
const White = styled.span`
  color: ${colors.new.background_light[500]};
  font-size: 3rem;
  font-weight: 400;

  @media (min-width: 500px) {
    font-size: 3.3rem;
  }
`;
const ButtonContainer = styled(Box)`
  margin-top: 1.5rem;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;

const PoweredBy = styled(Box)`
  width: 200px;
  height: 55px;
  margin: 0 0 20px 20px;
  background-image: url(${(props) => props.imgPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  position: fixed;
  bottom: 0;
  left: 0;
`;
const TopoText = styled.p`
  color: #fff;
  font-size: 0.5rem;
  margin-left: 45px;
`;
