import { useState } from "react";
import styled from "@emotion/styled";
import { Box, Progress, Text, Flex, useToast } from "@chakra-ui/react";
import Dropzone, { FileRejection } from "react-dropzone";
import { useMutation } from "@apollo/client";
import isPropValid from "@emotion/is-prop-valid";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";

import { fileUpload } from "../../../../api";
import { colors } from "../../../../ui/theme";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import {
  CREATE_UPLOAD,
  ICreateUploadParams,
  ICreateUploadResponse,
} from "../../../../graphql/mutations/createUpload";
import {
  IUpdateUploadStatusParams,
  IUpdateUploadResponse,
  UPDATE_UPLOAD_STATUS,
} from "../../../../graphql/mutations/updateUpload";
import { MOVEMENTS_UPLOADS_QUERY } from "../../../../graphql/queries/getMovementsUploads";
import translate from "../../../../i18n/translate";
import TrText from "../../../../i18n/TrText";

type ScreenState =
  | "initial"
  | "create_upload"
  | "uploading"
  | "success"
  | "failed";

type Props = {
  selectedUploadAccountId: string;
  uploadingAccountId: string;
  isDisabled: boolean;
};

const UploadBox = (props: Props) => {
  const {
    isDisabled,
    selectedUploadAccountId,
    uploadingAccountId: uploadedByAccountId,
  } = props;

  const [screenState, setScreenState] = useState<ScreenState>("initial");
  const [currentFile, setCurrentFile] = useState<string>();
  const [progressValue, setProgressValue] = useState<number>(0);

  const toast = useToast();

  const [createUpload] = useMutation<
    ICreateUploadResponse,
    ICreateUploadParams
  >(CREATE_UPLOAD, {
    refetchQueries: [{ query: MOVEMENTS_UPLOADS_QUERY }],
  });
  const [updateUpload] = useMutation<
    IUpdateUploadResponse,
    IUpdateUploadStatusParams
  >(UPDATE_UPLOAD_STATUS, {
    refetchQueries: [{ query: MOVEMENTS_UPLOADS_QUERY }],
  });

  const getBackToInitialScreen = () => {
    // After 3 seconds we want to get back to initial upload screen
    setTimeout(function () {
      // Clear file
      setCurrentFile(undefined);
      setScreenState("initial");
    }, 3000);
  };

  const onDropRejected = (rejections: FileRejection[]) => {
    const messages: { [key: string]: string | undefined } = {
      "file-too-large": translate("upload.file_too_large"),
      "file-invalid-type": translate("upload.file_invalid_type"),
      "too-many-files": translate("upload.only_one_file"),
    };
    const message =
      messages[rejections[0].errors[0].code] ||
      translate("upload.invalid_file");
    toast({
      title: translate("upload.file_rejected", {
        filename: rejections[0].file.name,
      }),
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      // only one file will be dropped
      const file = files[0];
      setCurrentFile(file.name);
      setScreenState("create_upload");

      const upload = await createUpload({
        variables: {
          originalFilename: file.name,
          accountId: selectedUploadAccountId,
          uploadedByAccountId: uploadedByAccountId,
        },
      });

      if (upload.data?.createMovementsUpload.upload?.uploadUrl) {
        try {
          setProgressValue(0);
          const uploadUrl = upload.data.createMovementsUpload.upload.uploadUrl;
          await fileUpload(uploadUrl, file, (progress) => {
            setProgressValue(progress);
          });
          await updateUpload({
            variables: {
              filename: upload.data.createMovementsUpload.upload.filename,
              status: "queued_for_processing",
            },
          });
          setProgressValue(100);
          setScreenState("success");

          getBackToInitialScreen();

          return;
        } catch (error) {
          setScreenState("failed");

          toast({
            title: translate("upload.upload_failed"),
            description: translate("upload.upload_failed_description"),
            status: "error",
          });

          await updateUpload({
            variables: {
              filename: upload.data.createMovementsUpload.upload.filename,
              status: "upload_aborted",
            },
          });

          getBackToInitialScreen();
        }
      } else {
        alert("An error occurred: " + upload.errors?.join(","));
        setScreenState("failed");
        getBackToInitialScreen();
      }
      setScreenState("failed");
      getBackToInitialScreen();
    }
  };

  switch (screenState) {
    case "initial":
      return (
        <Dropzone
          maxFiles={1}
          maxSize={10000000}
          onDrop={(files) => onDrop(files)}
          onDropRejected={(files) => onDropRejected(files)}
          disabled={isDisabled}
          accept={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
          }}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            const borderColor = isDragAccept
              ? colors.new.primary["500"]
              : isDragReject
              ? "red"
              : "transparent";
            const backgroundColor = isDragAccept
              ? "#f0f5ef"
              : isDragReject
              ? "#f5f0ef"
              : "transparent";
            const cursor = isDragActive
              ? isDragAccept
                ? "pointer"
                : "not-allowed"
              : "pointer";
            return (
              <Flex
                direction="column"
                border={`2px solid ${borderColor}`}
                cursor={cursor}
                background={backgroundColor}
                borderRadius="10px"
                py={12}
                px={12}
                alignItems="center"
                {...getRootProps()}
              >
                <Text align="center">
                  <TrText message="upload.upload_waste_data" />
                </Text>
                <input {...getInputProps()} />

                <FakeButton isDisabled={isDisabled} isDragActive={isDragActive}>
                  <TrText message="upload.drag_and_drop" />
                </FakeButton>

                <Text>
                  <TrText message="upload.or" />
                </Text>

                <Flex my={4} justifyContent="center">
                  <ButtonPrimary
                    isDisabled={isDisabled || isDragActive}
                    onClick={() => {}}
                  >
                    <TrText message="upload.select_new_file" />
                  </ButtonPrimary>
                </Flex>

                {isDragActive ? (
                  isDragReject ? (
                    <>
                      <WarningIcon color="red.500" />
                      <Text align="center" style={{ fontStyle: "italic" }}>
                        <TrText message="upload.max_size" />
                      </Text>
                      <Text align="center" style={{ fontStyle: "italic" }}>
                        <TrText message="upload.format" />
                      </Text>
                      <Text align="center" style={{ fontStyle: "italic" }}>
                        <TrText message="upload.number_files" />
                      </Text>
                    </>
                  ) : (
                    <>
                      <CheckIcon color="green.500" />
                      <Text align="center" style={{ fontStyle: "italic" }}>
                        <TrText message="upload.looks_good" />
                      </Text>
                    </>
                  )
                ) : (
                  <>
                    <Text align="center" style={{ fontStyle: "italic" }}>
                      <TrText message="upload.max_size" />
                    </Text>
                    <Text align="center" style={{ fontStyle: "italic" }}>
                      <TrText message="upload.format" />
                    </Text>
                  </>
                )}
              </Flex>
            );
          }}
        </Dropzone>
      );
    case "create_upload":
    case "uploading":
      return (
        <Box _hover={{ cursor: "busy" }} py={16} px={12} alignItems="center">
          <Text align="center">
            <TrText message="upload.uploading" /> <br />
            <b>{currentFile}</b>
          </Text>
          <Box m={4} p={1}>
            <Progress
              colorScheme="green"
              bgColor="#F0F5EF"
              hasStripe={false}
              borderColor="transparent"
              borderRadius="20px"
              size="lg"
              isIndeterminate={screenState === "create_upload"}
              value={progressValue}
            />
          </Box>
        </Box>
      );
    case "success":
      return (
        <Box py={16} px={12} alignItems="center">
          <Text align="center">
            <TrText message="upload.finished_upload" /> <br />
            <b>{currentFile}</b>
          </Text>
        </Box>
      );
    case "failed":
      return (
        <Box _hover={{ cursor: "pointer" }} py={16} px={12} alignItems="center">
          <Text align="center">
            <TrText message="upload.upload_failed" />
          </Text>
        </Box>
      );
  }
  return null;
};

const FakeButton = styled(Box, { shouldForwardProp: isPropValid })`
  border: ${(props) =>
    props.isDisabled
      ? `2px dashed ${colors.new.grey[500]}`
      : `2px solid ${colors.new.primary[500]}`};
  padding: 8px 12px;
  border-radius: 5px;
  margin: 12px 0;
  color: ${(props) =>
    props.isDisabled ? "grey" : `${colors.new.primary[500]}`};
`;

export default UploadBox;
