import { ICurrentUser } from "../graphql/queries/getCurrentUser";
import { formatToDecimalPlaces } from "./numbers";

/**
 * Is the user an uploader?
 * @param user The currently logged in user
 * @returns True if the user is has the upload role
 */
export const isUserUploader = (user: ICurrentUser) => {
  return (user.roles || []).includes("uploader");
};

/**
 * Is the user able to do more than uploading? ie does he have any other role apart from upload?
 * @param user The currently logged in user
 * @returns True if the user can do more than uploading
 */
export const isUserMoreThanUploader = (user: ICurrentUser) => {
  return (
    (user.roles || []).filter((role) => role !== "uploader" && role.length > 0)
      .length > 0
  );
};

/**
 * Is the user an admin?
 * @param user The currently logged in user
 * @returns True if the user is an admin
 */
export const isUserAdmin = (user: ICurrentUser) => {
  return user && (user.roles || []).includes("admin");
};

export const screensVisibleToUser = (user?: ICurrentUser) => {
  const uploader = user && (isUserUploader(user) || isUserAdmin(user));
  const general = user && (isUserMoreThanUploader(user) || isUserAdmin(user));
  return {
    isDashboardVisible: general,
    isAnalyticsVisible: general,
    isMovementsVisible: general,
    isComparisonVisible: general,
    isDirectoryVisible: general,
    user: general,
    isUploadVisible: uploader,
  };
};

export const getDistanceUnits = (user?: ICurrentUser) => {
  if (user?.account?.distanceUnits) {
    return user.account.distanceUnits;
  }
  // default to miles
  return "miles";
};

export const getFormattedDistanceForUser = (
  miles: number | null | undefined,
  kms: number | null | undefined,
  user?: ICurrentUser,
  decimalPlaces = 3
) => {
  const distanceUnits = getDistanceUnits(user);
  if (distanceUnits === "miles") {
    if (miles !== undefined && miles !== null) {
      return `${formatToDecimalPlaces(miles, decimalPlaces)} miles`;
    } else {
      return "--";
    }
  }
  if (kms !== undefined && kms !== null) {
    return `${formatToDecimalPlaces(kms, decimalPlaces)} km`;
  }
  return "--";
};

export const getDistanceForUser = (
  miles: number | null | undefined,
  kms: number | null | undefined,
  user?: ICurrentUser
) => {
  const distanceUnits = getDistanceUnits(user);
  if (distanceUnits === "miles") {
    return miles || 0;
  }
  return kms || 0;
};
