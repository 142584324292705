import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import Tile from "../../../../../ui/Tile";

const TotalPrimaryTransportCO2 = ({ kgs }: { kgs: number }) => (
  <Tile
    title="overview.primary_co2_card_title"
    bottomText="overview.in_total"
    iconName="co2"
    value={kgs === 0 ? `0t` : `${formatToDecimalPlaces(kgs / 1000, 1)}t`}
  />
);

export default TotalPrimaryTransportCO2;
