import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { IUser, Role } from "../../../../graphql/queries/getCurrentUser";
import { FieldValues, useForm } from "react-hook-form";
import ButtonSecondary from "../../../../ui/ButtonSecondary";
import { EditIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import TrText from "../../../../i18n/TrText";

interface IUpdateUserRolesButtonProps {
  user: IUser;
  onUpdateUserRoles: (userId: string, roles: Role[]) => Promise<void>;
}

const UpdateUserRolesButton = (props: IUpdateUserRolesButtonProps) => {
  const { user, onUpdateUserRoles } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    clearErrors,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const cancelRef = useRef(null);

  const onSubmit = async (values: FieldValues) => {
    await onUpdateUserRoles(user.id, values.roles);
    onClose();
  };
  return (
    <>
      <ButtonSecondary
        leftIcon={<EditIcon />}
        onClick={() => {
          clearErrors();
          onOpen();
        }}
      >
        <TrText message="add_user.change_roles" />
      </ButtonSecondary>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <TrText
                  message="add_user.change_roles_for_user"
                  options={{ name: user.name }}
                />
              </AlertDialogHeader>

              <AlertDialogBody>
                <FormControl mt={4} isInvalid={errors.roles ? true : false}>
                  <FormLabel htmlFor="inviteeRoles">
                    <TrText message="table_headings.roles" />:
                  </FormLabel>
                  <Box display="flex" justifyContent="center">
                    <Checkbox
                      id="inviteeRoles"
                      {...register("roles", {
                        validate: (value) => value.length > 0,
                      })}
                      value={"admin"}
                      defaultChecked={user.roles.includes("admin")}
                    >
                      <TrText message="roles.admin" />
                    </Checkbox>
                    <Checkbox
                      ml={4}
                      id="inviteeRoles"
                      {...register("roles", {
                        validate: (value) => value.length > 0,
                      })}
                      value={"member"}
                      defaultChecked={user.roles.includes("member")}
                    >
                      <TrText message="roles.member" />
                    </Checkbox>
                    <Checkbox
                      ml={4}
                      id="inviteeRoles"
                      {...register("roles", {
                        validate: (value) => value.length > 0,
                      })}
                      value={"uploader"}
                      defaultChecked={user.roles.includes("uploader")}
                    >
                      <TrText message="roles.uploader" />
                    </Checkbox>
                  </Box>
                  <FormErrorMessage>
                    {errors.roles && (
                      <TrText message="add_user.roles_required" />
                    )}
                  </FormErrorMessage>
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  mr={4}
                  isDisabled={isSubmitting}
                  ref={cancelRef}
                  onClick={onClose}
                >
                  <TrText message="app.cancel" />
                </Button>
                <ButtonPrimary isLoading={isSubmitting} type="submit">
                  <TrText message="app.update" />
                </ButtonPrimary>
              </AlertDialogFooter>
            </AlertDialogContent>
          </form>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default UpdateUserRolesButton;
