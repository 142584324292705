import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";

import Label from "./Label";

const WasteCheckbox = ({
  code,
  isChecked,
  isIndeterminate,
  onChange,
  name,
  label,
}: {
  code?: string;
  isChecked: boolean;
  isIndeterminate?: boolean;
  name: string;
  label?: string;
  onChange: ({
    wasteCode,
    isChecked,
  }: {
    wasteCode: string;
    isChecked: boolean;
  }) => void;
}) => {
  return (
    <ChakraCheckbox
      w="100%"
      size="lg"
      colorScheme="green"
      value={code}
      isChecked={isChecked}
      isIndeterminate={isIndeterminate ? isIndeterminate : undefined}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          wasteCode: event.target.value,
          isChecked: event.target.checked,
        });
      }}
    >
      <Label title={label} label={name} />
    </ChakraCheckbox>
  );
};

export default WasteCheckbox;
