import { Box, Flex, Badge } from "@chakra-ui/layout";

import H2 from "../../../ui/H2";
import H3 from "../../../ui/H3";
import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import { ISingleMovement } from "../../../graphql/queries/getMovements";
import TrText from "../../../i18n/TrText";

type Props = {
  movement: ISingleMovement;
};

export const ReceiverCard = (props: Props) => {
  const m = props.movement;
  const w = m?.warnings?.warnings;

  const hasWarning = w?.some((warning) => warning.section === "RECEIVER");

  if (m) {
    return (
      <Card height="100%">
        <Box p={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <H2 size="md">
              <TrText message="receiver_card.title" />
            </H2>
            {hasWarning ? (
              <Badge colorScheme="red">
                <TrText message="cards.issue_badge" />
              </Badge>
            ) : (
              <Badge colorScheme="green">
                <TrText message="cards.valid_badge" />
              </Badge>
            )}
          </Flex>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.operator" />:
            </H3>
            <Text>
              {m.receiverOperatorName ? m.receiverOperatorName : "--"}
            </Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.name" />:
            </H3>
            <Text>
              {m.receiverName
                ? m.receiverName
                : m.receiverPoiName
                ? m.receiverPoiName
                : "--"}
            </Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.address" />:
            </H3>
            <Text>
              {m.receiverAddress
                ? m.receiverAddress
                : m.receiverPoiAddress
                ? m.receiverPoiAddress
                : "--"}
            </Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.permit_id" />:
            </H3>
            <Text>{m.receiverPermitId ? m.receiverPermitId : "--"}</Text>
          </Box>
        </Box>
      </Card>
    );
  } else return null;
};
