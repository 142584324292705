import { Flex } from "@chakra-ui/react";

import { colors } from "../../ui/theme";
import no_image from "./../../assets/images/icons/no_image.svg";

const NoLogo = () => {
  return (
    <Flex
      borderRadius={10}
      bg={colors.new.grey[500]}
      w={200}
      h={200}
      p={4}
      mt={8}
      justifyContent="center"
      alignItems="center"
    >
      <img src={no_image} alt="No logo present" />
    </Flex>
  );
};

export default NoLogo;
