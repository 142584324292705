import { gql } from "@apollo/client";
import AsyncSelect from "react-select/async";

import { client } from "../../../graphql/client";
import translate from "../../../i18n/translate";
import { selectors } from "../../../ui/theme";
import { IOrganisationResult } from "./../../../graphql/queries/getOrganisations";

type Props = {
  placeholder: string;
  onOrganisationChange: (orgs: any) => void;
};

const fetchOrgs = async (inputValue: string) => {
  if (inputValue && inputValue.trim().length < 4) {
    return [];
  }

  const res = await client.query<IOrganisationResult>({
    query: gql`
      query { organisations(search: "${inputValue}") {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              topoOrgId
              name
            }
          }
        }
    }
    `,
  });

  if (res.data && res.data.organisations) {
    return res.data.organisations.edges.map((item) => {
      const org = item.node;
      return { label: org.name, value: org.topoOrgId };
    });
  }

  return [];
};

const SelectOperator = ({ placeholder, onOrganisationChange }: Props) => {
  return (
    <AsyncSelect
      isMulti={true}
      cacheOptions
      defaultOptions
      loadOptions={fetchOrgs}
      placeholder={translate(placeholder)}
      isDisabled={false}
      onChange={(orgs) => {
        onOrganisationChange(
          orgs.map((i: { label: string; value: string }) => i.value)
        );
      }}
      styles={{
        option: (provided, state) => ({
          ...provided,
          padding: 12,
        }),
        control: (provided) => ({
          ...provided,
          ...selectors.control,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = "opacity 300ms";

          return { ...provided, opacity, transition };
        },
        container: (provided) => ({
          ...provided,
          width: "100%",
          zIndex: 12,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: selectors.borderRadius,
        colors: {
          ...theme.colors,
          ...selectors,
        },
      })}
    />
  );
};

export default SelectOperator;
