import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const TOP10_PRODUCER_SITES_QUERY = gql`
  query GetTop10ProducerSites(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $poiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $receiverPoiIds: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    topProducers(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      carrierName: $carrierName
      hasIssues: $hasIssues
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      topoPoiId
      name
      address
      kgs
    }
  }
`;

export type ITop10ProducerSites = Array<{
  topoPoiId: string;
  name: string;
  address: string;
  kgs: number;
}>;

export interface ITop10ProducerSitesResults {
  topProducers: ITop10ProducerSites;
}

export type ITop10ProducerSitesParams = IFilters;
