import {
  Flex,
  Box,
  PopoverContent,
  Popover,
  Portal,
  PopoverTrigger,
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
} from "@chakra-ui/react";

import TrText from "../../i18n/TrText";
import Text from "../../ui/Text";
import { colors } from "../../ui/theme";
import helpIcon from "./../../assets/images/icons/help.svg";

type Props = {
  text?: string;
  component?: React.ReactNode;
};

const InfoBox = ({ text, component }: Props) => {
  return (
    <Popover closeOnBlur={true} placement="top-start">
      <PopoverTrigger>
        <Box as="button" _hover={{ cursor: "pointer" }} aria-label="Info box">
          <img width={24} src={helpIcon} alt="Help icon" />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="#fff"
          color={colors.new.text.dark[500]}
          borderBottomRightRadius="10px"
          borderTopRadius="10px"
          _focus={{
            boxShadow: `0px 0px 20px 4px rgba(0, 0, 0, 0.08)`,
          }}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader borderTopRadius="10px" bg="#0f123f30">
            <b>
              <TrText message="infobox.title" />
            </b>
          </PopoverHeader>
          <PopoverBody py={2}>
            <Flex px={0} alignItems="flex-start">
              {text && (
                <Text fontSize="13px">
                  <TrText message={text} />
                </Text>
              )}
              {component && component}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default InfoBox;
