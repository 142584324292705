import { Dispatch, SetStateAction } from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";

import { formatToDecimalPlaces } from "../../../../aux/numbers";
import { ICurrentUser } from "../../../../graphql/queries/getCurrentUser";
import Text from "../../../../ui/Text";
import H1 from "../../../../ui/H1";
import H2 from "../../../../ui/H2";
import Tile from "../../../../ui/Tile";
import GhostButton from "../../../../ui/GhostButton";
import { colors } from "../../../../ui/theme";
import Card from "../../../../ui/Card";
import { ICarriersSummary } from "../../../../graphql/queries/getCarriersSummary";
import TrText from "../../../../i18n/TrText";
import TotalMaterialWeight from "./components/TotalMaterialWeight";
import TotalPrimaryTransportCO2 from "./components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "./../../../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "./../../../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "./../../../../components/TotalUnknownMaterial";
import TotalPrimaryDistance from "./components/TotalPrimaryDistance";

type Props = {
  currentUser: ICurrentUser | undefined;
  carrier: ICarriersSummary;
  setCarrierSelected: Dispatch<SetStateAction<ICarriersSummary | null>>;
};

export const SingleCarrierView = ({
  carrier,
  currentUser,
  setCarrierSelected,
}: Props) => {
  const totalMaterialWeight = carrier?.totalWeight || 0;
  const landfillPercentage = carrier?.landfillFatePercentage || 0;
  const divertedFromLandfillPercentage =
    carrier?.landfillDiversionFatePercentage || 0;
  const unknownPercentage = carrier?.unknownFatePercentage || 0;

  return (
    <Flex mt={4} direction="column">
      <Box mb={4} ml={-5}>
        <GhostButton onClick={() => setCarrierSelected(null)}>
          <Flex direction="row" align="center" justify="flex-start">
            <Icon
              w={6}
              h={6}
              mr={4}
              as={MdArrowBack}
              color={colors.new.text.blue[500]}
            />
            <Text style={{ textDecoration: "underline" }}>
              <TrText message="app.go_back" />
            </Text>
          </Flex>
        </GhostButton>
      </Box>

      <Flex direction="row" w={["100%"]} mt={4}>
        <Flex direction="row" w={["50%"]} ml={0} mr={2}>
          <H1>{carrier?.name}</H1>
        </Flex>

        <Flex direction="row" w={["50%"]} ml={2} mr={0}>
          <Card>
            <Flex p={4} direction="column">
              <H2>DETAILS</H2>
              <Box mt={2} />

              <Text>Carrier name: {carrier?.name}</Text>
              <Text>Licence: {carrier?.license ? carrier?.license : "--"}</Text>
            </Flex>
          </Card>
        </Flex>
      </Flex>

      <Flex w={["100%"]} direction={["column"]}>
        <Flex direction="row" w={["100%"]} mt={4}>
          <Box w="25%" ml={0} mr={2}>
            <Tile
              title="overview.validated_card_title"
              iconName="truck_with_tick"
              bottomText={
                <TrText
                  message="overview.in_total_activities"
                  options={{
                    amount: carrier.movementCount
                      ? carrier.movementCount
                      : "...",
                  }}
                />
              }
              value={
                carrier.movementCount
                  ? `${formatToDecimalPlaces(
                      ((carrier.movementCount -
                        carrier?.receiverValidationErrors) /
                        carrier.movementCount) *
                        100,
                      1
                    )}%`
                  : "No data"
              }
            />
          </Box>
          <Box w="25%" ml={2} mr={2}>
            <Tile
              title="overview.movements_card_title"
              bottomText="overview.in_total"
              iconName="just_track"
              value={String(carrier?.movementCount)}
            />
          </Box>

          <Box w="25%" ml={2} mr={2}>
            <TotalPrimaryDistance
              miles={carrier.primaryDistanceMiles}
              kms={carrier.primaryDistanceKms}
              currentUser={currentUser}
            />
          </Box>
          <Box w="25%" ml={2} mr={0}>
            <TotalPrimaryTransportCO2 kgs={carrier.primaryCo2Kgs} />
          </Box>
        </Flex>

        <Flex direction="row" w={["100%"]} mt={4}>
          <Box w="25%" ml={0} mr={2}>
            <TotalMaterialWeight weight={carrier.totalWeight} />
          </Box>

          <Box w="25%" ml={2} mr={2}>
            <TotalDivertedFromLandfill
              totalWeight={totalMaterialWeight}
              divertedFromLandfillPercentage={divertedFromLandfillPercentage}
              isLoading={false}
            />
          </Box>

          <Box w="25%" ml={2} mr={2}>
            <TotalMaterialLandfill
              totalWeight={totalMaterialWeight}
              landfillPercentage={landfillPercentage}
              isLoading={false}
            />
          </Box>

          <Box w="25%" ml={2} mr={0}>
            <TotalUnknownMaterial
              totalWeight={totalMaterialWeight}
              unknownPercentage={unknownPercentage}
              isLoading={false}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
