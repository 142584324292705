import { gql } from "@apollo/client";

export const GET_PRODUCER_SITES_DETAILS_QUERY = gql`
  query GetProducerSitesDetails($poiIds: [String!], $poiGroupIds: [String!]) {
    producerSites(poiIds: $poiIds, poiGroupIds: $poiGroupIds) {
      id
      topoSiteId
      poiName
      poiAddress
      poiLng
      poiLat
      poiRefId
    }
  }
`;

export interface IProducerSitesDetails {
  id: string;
  topoSiteId: string;
  poiName: string;
  poiAddress: string;
  poiLng: number;
  poiLat: number;
  poiRefId: string;
}

export interface IProducerSitesDetailsResult {
  producerSites: Array<IProducerSitesDetails>;
}
