import { Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { formatToDecimalPlaces } from "../../aux/numbers";
import { IDestinationFates } from "../../graphql/queries/getDestinationFates";

const DestinationChart = ({
  data,
}: {
  data: IDestinationFates[] | undefined;
}) => {
  const [hovering, setHovering] = useState<string>();

  if (data) {
    // fix up the missing values and replace them with unknown
    const fixedData = data.map((d) => ({
      ...d,
      category: d.category || "Unknown",
      type: d.type || "Unknown",
    }));

    const colors: { [key: string]: string } = {
      "In Process": "blue",
      Landfill: "red",
      Other: "grey",
      "Recovery - Energy": "green",
      "Recovery - Incineration": "orange",
      "Recovery - Other": "green",
      Recycling: "green",
      Reuse: "green",
      Unknown: "grey",
      Logistics: "blue",
    };
    const defaultColor = "darkred";
    // calculate the total weight
    const totalWeight = fixedData.reduce((acc, d) => acc + d.totalWeight, 0);
    // group the data by category calculating the total weight for the category and collecting the type and weights
    const categories = fixedData.reduce((acc, d) => {
      const category = d.category;
      const type = d.type;
      const weight = d.totalWeight;
      if (!acc[category]) {
        acc[category] = {
          totalWeight: weight,
          types: { [type]: { weight, runningTotal: 0 } },
        };
      } else {
        acc[category].totalWeight += weight;
        acc[category].types[type] = { weight, runningTotal: 0 };
      }
      return acc;
    }, {} as { [key: string]: { totalWeight: number; types: { [key: string]: { weight: number; runningTotal: number } } } });
    // flatten the data into a single array of objects with the titla and the weight
    const flattened = Object.keys(categories)
      .sort()
      .map((category) => {
        const categoryData = categories[category];
        const types = Object.keys(categoryData.types).sort();
        // add a running total of weight to the types
        let runningTotal = 0;
        types.forEach((type) => {
          categoryData.types[type].runningTotal = runningTotal;
          runningTotal += categoryData.types[type].weight;
        });
        return [
          {
            title: category,
            weight: categoryData.totalWeight,
            runningTotal: 0,
            isCategory: true,
            key: category,
            color: colors[category] || defaultColor,
            tooltip: `${category} (${formatToDecimalPlaces(
              categoryData.totalWeight / 1000,
              2
            )}t, ${formatToDecimalPlaces(
              totalWeight > 0
                ? (100 * categoryData.totalWeight) / totalWeight
                : 0,
              0
            )}%)`,
          },
          ...types.map((type) => {
            const weight = categoryData.types[type].weight;
            return {
              title: type,
              weight: weight,
              runningTotal: categoryData.types[type].runningTotal,
              isCategory: false,
              key: `${category}-${type}`,
              color: colors[category] || defaultColor,
              tooltip: `${category} (${formatToDecimalPlaces(
                weight / 1000,
                2
              )}t, ${formatToDecimalPlaces(
                totalWeight > 0 ? (100 * weight) / totalWeight : 0,
                0
              )}%)`,
            };
          }),
        ];
      });
    // flatten the array of arrays into a single array
    const flattenedData = flattened.reduce((acc, d) => acc.concat(d), []);
    // get the maximum width - we'll use this to scale the chart so it takes up the full width
    const maxWeight = flattenedData.reduce((acc, d) => {
      if (d.weight > acc) {
        return d.weight;
      }
      return acc;
    }, 0);

    const onEnter = (key: string) => {
      setHovering(key);
    };
    const onLeave = () => {
      setHovering(undefined);
    };

    // build the chart
    return (
      <div
        style={{
          position: "relative",
          marginBottom: "auto",
          width: "100%",
          minHeight: "300px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* the title and color */}
        <div style={{ display: "flex", width: 200, flexDirection: "column" }}>
          {flattenedData.map((d, i) => (
            <Tooltip
              hasArrow
              label={d.tooltip}
              placement="bottom"
              key={`${d.title}-${i}`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: d.isCategory ? 20 : 0,
                }}
              >
                <div
                  style={{
                    width: 200,
                    fontWeight: d.isCategory ? "bold" : "normal",
                    textAlign: "right",
                    paddingRight: 5,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textDecoration: d.key === hovering ? "underline" : "none",
                  }}
                >
                  {d.title}
                </div>
                <div
                  style={{
                    backgroundColor: d.color,
                    opacity: d.isCategory ? 1 : 0.5,
                    width: 5,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                ></div>
              </div>
            </Tooltip>
          ))}
        </div>
        {/* the percentage and bar */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {flattenedData.map((d, i) => (
            <Tooltip
              hasArrow
              label={d.tooltip}
              placement="bottom"
              key={`${d.title}-${i}`}
            >
              <div
                style={{
                  flex: 1,
                  fontWeight: d.isCategory ? "bold" : "normal",
                  textAlign: "right",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: d.isCategory ? 20 : 0,
                  textDecoration: d.key === hovering ? "underline" : "none",
                }}
              >
                {formatToDecimalPlaces(
                  totalWeight > 0 ? (100 * d.weight) / totalWeight : 0,
                  0
                )}
                %
              </div>
            </Tooltip>
          ))}
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {flattenedData.map((d, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: d.isCategory ? 20 : 0,
              }}
              onMouseEnter={() => onEnter(d.key)}
              onMouseLeave={() => onLeave()}
              key={`${d.title}-${i}`}
            >
              <div>&nbsp;</div>
              <Tooltip hasArrow label={d.tooltip} placement="bottom">
                <div
                  style={{
                    backgroundColor: d.color,
                    opacity: d.isCategory ? 1 : 0.5,
                    marginLeft: `${(100 * d.runningTotal) / maxWeight}%`,
                    width: `${(100 * d.weight) / maxWeight}%`,
                    marginTop: 5,
                    marginBottom: 5,
                    border: d.key === hovering ? "2px solid black" : "none",
                  }}
                ></div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default DestinationChart;
