import { Button as ChakraButton } from "@chakra-ui/react";

import { colors } from "../theme";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: React.ReactElement;
  ml?: number;
};

const DangerButton = (props: Props) => {
  const { children, onClick, isDisabled, isLoading, leftIcon, ml } = props;
  return (
    <ChakraButton
      ml={ml}
      color={colors.new.text.light[500]}
      bg={"red.300"}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      leftIcon={leftIcon}
    >
      {children}
    </ChakraButton>
  );
};

export default DangerButton;
