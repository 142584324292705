import { useLocation, useHistory } from "react-router-dom";
import { Flex, Box, Icon } from "@chakra-ui/react";
import styled from "@emotion/styled";

import GhostButton from "../../../ui/GhostButton";
import H1 from "../../../ui/H1";
import Text from "../../../ui/Text";
import Card from "../../../ui/Card";
import SingleSiteMap from "./../components/SingleSiteMap";
import { IPermittedSitesData } from "./../../../graphql/queries/getPermittedSites";
import { colors } from "../../../ui/theme";
import { MdArrowBack } from "react-icons/md";
import TrText from "../../../i18n/TrText";

const SingleSiteView = () => {
  let location = useLocation();
  const history = useHistory();

  const site: IPermittedSitesData = location.state as IPermittedSitesData;

  return (
    <Box style={{ overflowY: "scroll" }} paddingBottom="8">
      <Flex direction="row" align="flex-start" justify="flex-start" p={4}>
        <GhostButton onClick={history.goBack}>
          <Flex direction="row" align="center" justify="flex-start">
            <Icon
              w={6}
              h={6}
              mr={4}
              as={MdArrowBack}
              color={colors.new.text.blue[500]}
            />
            <Text style={{ textDecoration: "underline" }}>
              <TrText message="app.go_back" />
            </Text>
          </Flex>
        </GhostButton>
      </Flex>

      <Flex
        align="flex-start"
        justify="flex-start"
        direction={["column"]}
        p={8}
      >
        <H1>{site?.poiName}</H1>
      </Flex>

      <Flex direction={["column", "column", "row", "row"]} px={8} w={"100%"}>
        <Flex direction="column" w="40%" mr={4}>
          <Card>
            <table style={{ marginBottom: 12 }}>
              <tbody>
                <tr>
                  <TH>Address</TH>
                  <TD>
                    {site?.poiAddress
                      ?.split(",")
                      .map((p: string, i: number) => {
                        return <p key={`${p}-${i}`}>{p}</p>;
                      })}
                  </TD>
                </tr>
              </tbody>
            </table>
          </Card>

          <Box h="24px" />

          <Card>
            <table>
              <tbody>
                <TR>
                  <TH>Country</TH>
                  <TD>{site?.countryName ?? "--"}</TD>
                </TR>
                <TR>
                  <TH>Regulator</TH>
                  <TD>{site?.regulatorName ?? "--"}</TD>
                </TR>
                <TR>
                  <TH>Operator</TH>
                  <TD>{site?.organisation?.name ?? "--"}</TD>
                </TR>
                <TR>
                  <TH>Permits</TH>
                  <TD>
                    {(site?.permits || []).map((permit: string) => {
                      return <p key={permit}>{permit}</p>;
                    })}
                  </TD>
                </TR>
                <TR>
                  <TH>Site type</TH>
                  <TD>{site.poiType ? site.poiType : "--"}</TD>
                </TR>
                <TR>
                  <TH>Site category</TH>
                  <TD>{site.poiCategory ? site.poiCategory : "--"}</TD>
                </TR>
                <TR>
                  <TH>Lat/long</TH>
                  <TD>
                    {Number(site?.poiLat).toFixed(5)},{" "}
                    {Number(site?.poiLng).toFixed(5)}
                  </TD>
                </TR>
              </tbody>
            </table>
          </Card>
        </Flex>

        <Flex w="60%" ml={4}>
          <SingleSiteMap lat={site.poiLat} lon={site.poiLng} />
        </Flex>
      </Flex>
    </Box>
  );
};

const TH = styled.th`
  text-align: left;
  width: 30%;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 24px;
  vertical-align: baseline;
`;
const TD = styled.td`
  text-align: left;
  vertical-align: baseline;
`;

const TR = styled.tr`
  border-bottom: 1px solid ${colors.new.grey[500]};
`;

export default SingleSiteView;
