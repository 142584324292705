import { useState, useEffect } from "react";
import ReactMapboxGl, { Popup, Marker } from "react-mapbox-gl";
import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CloseIcon } from "@chakra-ui/icons";

import { IPermittedSitesData } from "./../../../graphql/queries/getPermittedSites";
import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import H2 from "../../../ui/H2";
import Link from "../../../ui/Link";
import { colors } from "../../../ui/theme";
import {
  getInitialCountryLocation,
  locations,
} from "../../../services/maps/initialLocations";
import TrText from "../../../i18n/TrText";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY29kYm9kIiwiYSI6ImNrNTZtYzBiNzBoZGczZW4xcXQ5emhmNTEifQ.Ij2lPbn4xF1eeAxXhON0Hg",
});

type Props = {
  sites:
    | {
        node: IPermittedSitesData;
      }[]
    | undefined;
  selectedSiteId: string | null;
  setSelectedSiteId: (id: string) => void;
};

const SitesMap = ({ sites, selectedSiteId, setSelectedSiteId }: Props) => {
  // Location used for initial centering of the map
  const [mapInitialLocation] = useState(locations[getInitialCountryLocation()]);

  const [center, setCenter] = useState<[number, number]>([
    mapInitialLocation.longitude,
    mapInitialLocation.latitude,
  ]);
  const [zoom, setZoom] = useState<[number]>([4.4]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [siteSelected, setSiteSelected] = useState<IPermittedSitesData | null>(
    null
  );

  useEffect(() => {
    let elem = document.querySelector(".mapboxgl-popup-content");
    if (elem) {
      // @ts-ignore
      elem.style.padding = 0;
      // @ts-ignore
      elem.style["border-radius"] = "10px";
      // @ts-ignore
      elem.style["box-shadow"] = "rgba(0, 0, 0, 0.08) 0px 2px 20px";
    }
  }, [siteSelected]);

  useEffect(() => {
    setShowPopup(false);
    setSiteSelected(null);

    const site = sites?.filter((i) => i.node.id === selectedSiteId);

    if (site && site[0]) {
      setSiteSelected(site[0]?.node);
      setShowPopup(true);
    }
  }, [selectedSiteId, sites]);

  useEffect(() => {
    return () => {
      setSiteSelected(null);
    };
  }, []);

  return (
    <Card>
      {/* @ts-ignore */}
      <Map
        center={center}
        zoom={zoom}
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        flyToOptions={{
          speed: 0.8,
        }}
        onDragEnd={(map, evt) => {
          setCenter([map.getCenter().lng, map.getCenter().lat]);
          setZoom([Number(map.getZoom().toFixed(2))]);
        }}
      >
        {sites &&
          sites.map((item: any) => {
            const site: IPermittedSitesData = item.node;

            return (
              <Marker
                coordinates={[site.poiLng, site.poiLat]}
                anchor="bottom"
                onClick={() => {
                  setShowPopup(true);
                  setSiteSelected(site);
                  setSelectedSiteId(site.id);
                }}
                key={site.id}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 1.625C9.15601 1.625 4.40161 6.3794 4.40161 12.2234C4.40161 17.9528 14.2524 27.6452 14.6718 28.0544L15 28.3743L15.3281 28.0544C15.7476 27.6442 25.5983 17.948 25.5983 12.2234C25.5983 6.3794 20.8439 1.625 14.9999 1.625H15ZM15 16.1399C12.8403 16.1399 11.0823 14.3821 11.0823 12.2222C11.0823 10.0624 12.839 8.3057 15 8.3057C17.1598 8.3057 18.9165 10.0624 18.9165 12.2222C18.9165 14.3831 17.1599 16.1399 15 16.1399Z"
                    fill={`${
                      selectedSiteId === site.id ? "#a726d6" : "#1339F2"
                    }`}
                  />
                </svg>
              </Marker>
            );
          })}
        {siteSelected && showPopup && (
          <Popup
            coordinates={[siteSelected?.poiLng, siteSelected?.poiLat]}
            offset={{
              // @ts-ignore
              "bottom-left": [12, -38],
              bottom: [0, -38],
              "bottom-right": [-12, -38],
            }}
            style={{ padding: 0 }}
          >
            <StyledPopup>
              <Flex p={2} justifyContent="space-between" alignItems="center">
                <Heading>
                  <b>{siteSelected.poiName}</b>
                </Heading>
                <button onClick={() => setShowPopup(false)}>
                  <CloseIcon w={4} />
                </button>
              </Flex>

              <Flex
                p={2}
                borderTop={`1px solid ${colors.new.grey[500]}`}
                borderBottom={`1px solid ${colors.new.grey[500]}`}
                bg="#F8F7F6"
                direction="column"
              >
                <table>
                  <tbody>
                    <tr>
                      <TH>A:</TH>
                      <TD>
                        {siteSelected.poiAddress
                          .split(",")
                          .map((line: string) => {
                            return <Text>{line}</Text>;
                          })}
                      </TD>
                    </tr>
                    <tr>
                      <TH>T:</TH>
                      <TD>--</TD>
                    </tr>
                    <tr>
                      <TH>E:</TH>
                      <TD>--</TD>
                    </tr>
                  </tbody>
                </table>
              </Flex>

              <Flex
                justifyContent="flex-start"
                alignItems="center"
                p={2}
                pl={4}
              >
                <Link
                  to={`/h/directory/site/${siteSelected.topoSiteId}`}
                  state={siteSelected}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      color: colors.new.blue[500],
                    }}
                  >
                    <TrText message="app.details" />
                  </span>
                </Link>
              </Flex>
            </StyledPopup>
          </Popup>
        )}
      </Map>
    </Card>
  );
};

const StyledPopup = styled.div`
  background: white;
  color: ${colors.new.text.dark[500]};
  border-radius: 10px;
  padding: 0;
  width: 300px;
`;

const Heading = styled(H2)`
  font-size: 0.8rem;
  padding-left: 8px;
`;

const TH = styled.th`
  text-align: left;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 8px;
  vertical-align: baseline;
`;
const TD = styled.td`
  text-align: left;
  vertical-align: baseline;
`;

export default SitesMap;
