import { useCallback, useEffect, useState } from "react";
import Chartjs from "chart.js/auto";
import dayjs from "dayjs";
import _ from "lodash";
import { Flex } from "@chakra-ui/react";

import { ITotalWeightPerMonth } from "../../graphql/queries/getTotalWeightPerMonth";
import { colors } from "../../ui/theme";
import Loading from "../../components/Loading";

const TotalWeightPerMonthChart = ({
  chartData,
  includeAssumedWeight,
  includeEstimatedWeight,
  includeActualWeight,
}: {
  chartData: ITotalWeightPerMonth[] | undefined;
  includeAssumedWeight: boolean | null;
  includeEstimatedWeight: boolean | null;
  includeActualWeight: boolean | null;
}) => {
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  // create the chart
  const chartContainerRef = useCallback(
    (canvasNode: any) => {
      if (chartInstance) {
        chartInstance.destroy();
      }
      if (canvasNode !== null) {
        const newChartInstance = new Chartjs(canvasNode, {
          type: "bar",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                title: {
                  display: true,
                  text: "tonnes",
                },
                stacked: true,
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
              title: {
                display: false,
                text: "Waste Streams",
              },
            },
          },
        });
        setChartInstance(newChartInstance);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // update the chart
  useEffect(() => {
    if (chartData && chartInstance) {
      // make sure the data is in the correct order
      const sortedChartData = _.sortBy(chartData, "month");
      // these are the months - get them nicely sorted
      const months = sortedChartData.map((row) => row.month);
      // turn them into nice strings for the labels
      const labels = months.map((month) => dayjs(month).format("MMMM YYYY"));
      // convert the data into something the chart understands
      const datasets = [];
      if (includeActualWeight || includeActualWeight === null) {
        datasets.push({
          label: "Actual Weight (t)",
          data: sortedChartData.map((row) => row.actualWeightInKgs / 1000),
          backgroundColor: colors.charts.primary.main,
        });
      }
      if (includeAssumedWeight || includeAssumedWeight === null) {
        datasets.push({
          label: "Assumed Weight (t)",
          data: sortedChartData.map((row) => row.assumedWeightInKgs / 1000),
          backgroundColor: colors.charts.secondary.main,
        });
      }
      if (includeEstimatedWeight || includeEstimatedWeight === null) {
        datasets.push({
          label: "Estimated Weight (t)",
          data: sortedChartData.map((row) => row.estimatedWeightInKgs / 1000),
          backgroundColor: colors.charts.tertiary.main,
        });
      }
      chartInstance.data.labels = labels;
      chartInstance.data.datasets = datasets;
      try {
        chartInstance.update("none");
      } catch {
        // ignored - caused by hot reloading
      }
    }
  }, [
    chartInstance,
    chartData,
    includeActualWeight,
    includeAssumedWeight,
    includeEstimatedWeight,
  ]);

  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        position: "relative",
        height: "350px",
        width: "100%",
      }}
    >
      {chartData ? (
        <canvas id="totalWeightPerMonthChart" ref={chartContainerRef} />
      ) : (
        <Flex w="100%" h="250" justifyContent="center" alignItems="center">
          <Loading />
        </Flex>
      )}
    </div>
  );
};

export default TotalWeightPerMonthChart;
