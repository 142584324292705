export const locations = {
  GB: {
    longitude: -1.4939563,
    latitude: 53.6493248,
    zoom: 4,
    pitch: 0,
    bearing: 0,
  },
  JP: {
    longitude: 136.5717621,
    latitude: 39.175601,
    zoom: 3,
    pitch: 0,
    bearing: 0,
  },
  PL: {
    longitude: 19.233109129291442,
    latitude: 52.21607505434187,
    zoom: 3,
    pitch: 0,
    bearing: 0,
  },
  GER: {
    longitude: 10.278157982062197,
    latitude: 51.057811989794466,
    zoom: 5,
    pitch: 0,
    bearing: 0,
  },
  ZA: {
    longitude: 29.4608014,
    latitude: -24.0064467,
    zoom: 5,
    pitch: 0,
    bearing: 0,
  },
};
export type AllowedCountriesType = "GB" | "JP" | "PL" | "GER" | "ZA";

type CountryType = {
  code: AllowedCountriesType;
  displayName: string;
};

type AllowedCountriesForCenteringType = CountryType[];

export const allowedCountriesForCentering: AllowedCountriesForCenteringType = [
  { code: "GB", displayName: "United Kingdom" },
  {
    code: "JP",
    displayName: "Japan",
  },
  {
    code: "PL",
    displayName: "Poland",
  },
  {
    code: "GER",
    displayName: "Germany",
  },
  {
    code: "ZA",
    displayName: "South Africa",
  },
];
export const allowedCountryCodesForCentering = allowedCountriesForCentering.map(
  (el) => el.code
);

export const getInitialCountryLocation = () => {
  const location = window.localStorage.getItem("mapLocation");
  if (location) {
    if (
      allowedCountryCodesForCentering.includes(location as AllowedCountriesType)
    ) {
      return location as AllowedCountriesType;
    } else return "GB";
  } else return "GB";
};
