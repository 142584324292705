import { Heading } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const H1 = (props: Props) => {
  return (
    <Heading
      as="h1"
      fontFamily="Open Sans;sans-serif"
      fontSize="lg"
      {...props}
    />
  );
};

export default H1;
