import { useState } from "react";
import {
  Flex,
  InputGroup,
  IconButton,
  Input as ChakraInput,
  InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import styled from "@emotion/styled";

import Label from "../Label";
import { colors } from "../theme";

type Props = {
  ariaLabel: string;
  name: string;
  placeholder: string;
  labelText: string;
  value: string;
  onChange: (value: string) => void;
};

const PasswordInput = (props: Props) => {
  const [show, setShow] = useState(false);

  const { name, labelText, placeholder, value, onChange, ariaLabel } = props;

  return (
    <Flex direction="column">
      <Label forHtml={name}>{labelText}</Label>
      <InputGroup alignItems="center">
        <StyledInput
          id={name}
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          {show ? (
            <IconButton
              aria-label={`Hide ${ariaLabel}`}
              icon={<ViewOffIcon />}
              size="sm"
              onClick={() => setShow(false)}
              w="48px"
              h="26px"
              borderRadius="5px"
              mt="8px"
            />
          ) : (
            <IconButton
              aria-label={`Show ${ariaLabel}`}
              icon={<ViewIcon />}
              h="1.75rem"
              size="sm"
              w="48px"
              borderRadius="5px"
              mt="8px"
              onClick={() => setShow(true)}
            />
          )}
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

const StyledInput = styled(ChakraInput)`
  margin-top: 4px;
  background: ${colors.new.text.light[500]};
  border-radius: 10px;
  border-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  padding: 10px;
`;

export default PasswordInput;
