import { Box, Flex, Badge } from "@chakra-ui/layout";

import H3 from "../../../ui/H3";
import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import { ISingleMovement } from "../../../graphql/queries/getMovements";
import { formatToDecimalPlaces } from "../../../aux/numbers";
import TrText from "../../../i18n/TrText";
import H2 from "../../../ui/H2";

type Props = {
  movement: ISingleMovement;
};

export const MaterialCard = (props: Props) => {
  const m = props.movement;
  const w = m?.warnings?.warnings;

  const hasWarning = w?.some((warning) => warning.section === "MATERIAL");

  if (m) {
    const formattedWeight = formatToDecimalPlaces(m.topoWeightInKgs, 0);
    return (
      <Card height="100%">
        <Box p={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <H2 size="md">
              <TrText message="material_card.title" />
            </H2>
            {hasWarning ? (
              <Badge colorScheme="red">
                <TrText message="cards.issue_badge" />
              </Badge>
            ) : (
              <Badge colorScheme="green">
                <TrText message="cards.valid_badge" />
              </Badge>
            )}
          </Flex>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.code" />:
            </H3>
            <Text>
              {m.wasteCode}
              {m.isHazardous ? "*" : null}
            </Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.description" />:
            </H3>
            <Text>{m.wasteDescription}</Text>
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.weight" />:
            </H3>
            {m.hasWeightData ? (
              <Text>
                {formattedWeight}kg
                {m.weightType === "estimated" ? " (estimated)" : null}
                {m.weightType === "assumed" ? " (assumed)" : null}
              </Text>
            ) : (
              <Text>
                <TrText message="app.no_weight" />
              </Text>
            )}
          </Box>

          <Box py={2}>
            <H3 size="sm">
              <TrText message="table_headings.container_type" />:
            </H3>
            <Text>{m.containerType ? m.containerType : "--"}</Text>

            <H3 size="sm">
              <TrText message="table_headings.container_count" />:
            </H3>
            <Text>
              {" "}
              {m.containerCount
                ? formatToDecimalPlaces(m.containerCount, 2, {
                    trailingZeros: false,
                  })
                : "--"}
            </Text>
          </Box>
        </Box>
      </Card>
    );
  } else return null;
};
