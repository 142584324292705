import { gql } from "@apollo/client";

export const MOVEMENTS_WITH_ISSUES = gql`
  query MovementsWithIssues(
    $first: Int
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $wasteCodes: [String!]
  ) {
    movementsWithIssues(
      first: $first
      startDate: $startDate
      endDate: $endDate
      carrierName: $carrierName
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      wasteCodes: $wasteCodes
    ) {
      totalCount
      edges {
        node {
          id
          movementId
          producerName
          producerTimestamp
          hasIssues
          validationScore
          complianceScore
          receiverValidationError
          carrierValidationError
        }
      }
    }
  }
`;
export interface ISingleMovementWithIssues {
  id: string;
  movementId: string;
  producerName: string;
  producerTimestamp: string;
  receiverValidationError: string;
  carrierValidationError: string;
}

export interface IMovementsWithIssues {
  totalCount: number;
  edges: Array<{
    node: ISingleMovementWithIssues;
  }>;
}

export interface IMovementsWithIssuesResult {
  movementsWithIssues: IMovementsWithIssues;
}
