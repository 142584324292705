import { exportEndpoint } from "../api";
import { IFilters } from "../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const getExportURL = (
  type:
    | "movements"
    | "sites"
    | "groups"
    | "suppliers"
    | "carriers"
    | "receivers",
  filter: IFilters & { orderBy: string | null; orderDirection: string | null }
) => {
  // work out the export search parameters
  const exportSearchParams = new URLSearchParams();
  filter.startDate && exportSearchParams.append("start_date", filter.startDate);
  filter.endDate && exportSearchParams.append("end_date", filter.endDate);
  filter.wasteCodes &&
    filter.wasteCodes.filter(notEmpty).forEach((w) => {
      exportSearchParams.append("waste_codes[]", w);
    });
  filter.poiIds &&
    filter.poiIds.filter(notEmpty).forEach((p: string) => {
      exportSearchParams.append("poi_ids[]", p);
    });
  filter.supplierIds &&
    filter.supplierIds.filter(notEmpty).forEach((s: string) => {
      exportSearchParams.append("supplier_ids[]", s);
    });
  filter.poiGroupIds &&
    filter.poiGroupIds.filter(notEmpty).forEach((g: string) => {
      exportSearchParams.append("poi_group_ids[]", g);
    });
  filter.carrierName &&
    exportSearchParams.append("carrier_name", filter.carrierName);
  filter.orderBy && exportSearchParams.append("order_by", filter.orderBy);
  filter.orderDirection &&
    exportSearchParams.append("order_direction", filter.orderDirection);
  exportSearchParams.append(
    "include_assumed_weight",
    filter.includeAssumedWeight === false ? "false" : "true"
  );
  exportSearchParams.append(
    "include_actual_weight",
    filter.includeActualWeight === false ? "false" : "true"
  );
  exportSearchParams.append(
    "include_estimated_weight",
    filter.includeEstimatedWeight === false ? "false" : "true"
  );
  if (filter.hasIssues !== undefined && filter.hasIssues !== null) {
    exportSearchParams.append(
      "has_issues",
      filter.hasIssues === false ? "false" : "true"
    );
  }
  return `${exportEndpoint()}/${type}?${exportSearchParams.toString()}`;
};
