import { Badge, Box, Table, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IUser } from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import Text from "../../../../ui/Text";
import CancelInviteButton from "./CancelInviteButton";

interface PendingInvitesProps {
  pendingInvites: IUser[];
  onRemoveInvitation: (userId: string) => Promise<void>;
}

const PendingInvites = (props: PendingInvitesProps) => {
  const { pendingInvites, onRemoveInvitation } = props;
  return (
    <Box p={4}>
      {pendingInvites && pendingInvites.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
              <Th>
                <TrText message="table_headings.name" />
              </Th>
              <Th>
                <TrText message="table_headings.email" />
              </Th>
              <Th>
                <TrText message="table_headings.roles" />
              </Th>
              <Th>
                <TrText message="table_headings.actions" />
              </Th>
            </Tr>
          </Thead>
          <tbody>
            {pendingInvites.map((invite) => (
              <Tr key={invite.id}>
                <Td>{invite.name}</Td>
                <Td>{invite.email}</Td>
                <Td>
                  {invite.roles.map((role, index) => (
                    <Badge key={`${role}-${index}`} ml={4} colorScheme="green">
                      {role}
                    </Badge>
                  ))}
                </Td>
                <Td>
                  <CancelInviteButton
                    invite={invite}
                    onRemoveInvitation={onRemoveInvitation}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Text>
          <TrText message="add_user.no_invites" />
        </Text>
      )}
    </Box>
  );
};

export default PendingInvites;
