import { gql } from "@apollo/client";

import { ICurrentUser } from "./../queries/getCurrentUser";

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $password: String
    $oldPassword: String
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        password: $password
        oldPassword: $oldPassword
      }
    ) {
      errors
      user {
        id
        name
      }
    }
  }
`;

export interface IUpdateUserParams {
  id: string;
  name?: string;
  password?: string;
  oldPassword?: string;
}

export interface IUpdateUserResponse {
  updateUser: {
    errors?: string[];
    user: ICurrentUser | null;
  };
}
