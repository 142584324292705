import React from "react";
import { Switch, Route } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import Dashboard from "./containers/Dashboard";

const Analytics = ({
  toggleSimpleFilter,
  isOpen,
}: {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
}) => {
  return (
    <Flex>
      <Switch>
        <Route path="/h/analytics">
          <Dashboard toggleSimpleFilter={toggleSimpleFilter} isOpen={isOpen} />
        </Route>
      </Switch>
    </Flex>
  );
};
export default Analytics;
