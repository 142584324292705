import { Switch, Route } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import Dashboard from "./containers/Dashboard";

const Comparison = ({
  toggleSimpleFilter,
  isOpen,
}: {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
}) => {
  return (
    <Flex>
      <Switch>
        <Route path="/h/comparison">
          <Dashboard toggleSimpleFilter={toggleSimpleFilter} isOpen={isOpen} />
        </Route>
      </Switch>
    </Flex>
  );
};
export default Comparison;
