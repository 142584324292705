import { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import {} from "../../aux/freshdesk";

import WasteMovementDetails from "./../WasteMovementDetails";
import Poi from "./../Poi";
import ReceiverPoi from "./../ReceiverPoi";
import Analytics from "./../Analytics";
import SitesDirectory from "./../SitesDirectory";
import Comparison from "./../Comparison";
import Upload from "./../Upload";
import Profile from "./../Profile";
import MainNavigation from "./../../components/MainNavigation";
import SimpleFilter from "./../../components/SimpleFilter";
import { colors } from "../../ui/theme";
import {
  SIMPLE_FILTER_SECTION_ANIMATION_DURATION,
  SIMPLE_FILTER_SECTION_WIDTH,
} from "../../aux/constants";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../graphql/queries/getCurrentUser";
import { screensVisibleToUser } from "../../aux/userUtils";

const Home = () => {
  // Filter section
  const [isOpen, setIsOpen] = useState(false);

  const variants = {
    open: { right: 0 },
    closed: {
      right: -SIMPLE_FILTER_SECTION_WIDTH,
    },
  };

  const closingSimpleFilter = () => {
    if (isOpen) {
      setIsOpen(false);
      // We are closing, so allow the animation to run
      // and then update z-index
      setTimeout(() => {
        const mask = document.getElementById("mask");
        if (mask) {
          mask.style.zIndex = "-1";
        }

        // We are closing then we also want to hide the filter,
        // so it is not accessible by keyboard
        const filter = document.getElementById("analytics_filter");
        if (filter) {
          filter.style.visibility = "hidden";
        }
        window.FreshworksWidget("show");
      }, SIMPLE_FILTER_SECTION_ANIMATION_DURATION * 1000);
    }
  };

  const openingSimpleFilter = () => {
    setIsOpen(true);
    window.FreshworksWidget("hide");
    // We are opening, so can update z-index immediately
    // then animation runs
    const mask = document.getElementById("mask");
    if (mask) {
      mask.style.zIndex = "1";
    }

    // We are opening then we also want to show the filter,
    // so it is accessible by keyboard
    const filter = document.getElementById("analytics_filter");
    if (filter) {
      filter.style.visibility = "visible";
    }
  };

  const toggleSimpleFilter = () => {
    // This controls the mask z-index,
    // so it updates gracefully and the animation is smoother
    // We also manage the keyboard accessibility
    if (isOpen) {
      closingSimpleFilter();
    } else {
      openingSimpleFilter();
    }
  };

  // End of filter

  const { data } = useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  useEffect(() => {
    if (data) {
      const { currentUser } = data;
      if (currentUser) {
        window.FreshworksWidget("identify", "ticketForm", {
          name: currentUser.name,
          email: currentUser.email,
        });
      }
    }
  }, [data]);

  const visibility = screensVisibleToUser(data?.currentUser);

  const routes = [
    // {
    //   path: '/h',
    //   exact: true,
    //   component: () => <Dashboard />,
    //   visible: visibility.isDashboardVisible,
    // },
    {
      path: "/h/analytics",
      exact: false,
      component: () => (
        <Analytics toggleSimpleFilter={toggleSimpleFilter} isOpen={isOpen} />
      ),
      visible: visibility.isAnalyticsVisible,
    },
    {
      path: "/h/uploads",
      exact: false,
      component: () => <Upload />,
      visible: visibility.isUploadVisible,
    },
    {
      path: "/h/comparison",
      exact: false,
      component: () => (
        <Comparison toggleSimpleFilter={toggleSimpleFilter} isOpen={isOpen} />
      ),
      visible: visibility.isComparisonVisible,
    },
    {
      path: "/h/directory",
      exact: false,
      component: () => <SitesDirectory />,
      visible: visibility.isDirectoryVisible,
    },
    {
      path: "/h/activities/:movementId",
      exact: false,
      component: () => <WasteMovementDetails />,
      visible: visibility.isMovementsVisible,
    },
    {
      path: "/h/pois/:poiId",
      exact: false,
      component: () => <Poi />,
      visible: visibility.isMovementsVisible,
    },
    {
      path: "/h/receivers/:poiId",
      exact: false,
      component: () => <ReceiverPoi />,
      visible: visibility.isMovementsVisible,
    },
  ];

  const visibleRoutes = routes.filter((route) => route.visible);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <MainNavigation closingSimpleFilter={closingSimpleFilter} />
      <Flex direction="column" w="100%" overflowY="scroll">
        <Switch>
          {visibleRoutes.map((route) => (
            <Route exact={route.exact} path={route.path} key={route.path}>
              {route.component()}
            </Route>
          ))}
          <Route exact={false} path="/h/settings" key="/h/settings">
            <Profile />
          </Route>
          <Route>
            <Redirect to={visibleRoutes[0].path} />
          </Route>
        </Switch>

        <motion.div
          id="analytics_filter"
          style={{
            borderRadius: "10px 0 0 10px",
            boxShadow: `0px 2px 20px rgba(0, 0, 0, 0.08)`,
            position: "fixed",
            top: 0,
            height: "100%",
            width: SIMPLE_FILTER_SECTION_WIDTH,
            background: `${colors.new.text.light[500]}`,
            zIndex: 5,
            visibility: "hidden", // we start with hidden so it's not accessible by keyboard
          }}
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          initial={{
            right: -SIMPLE_FILTER_SECTION_WIDTH,
          }}
          transition={{
            // @ts-ignore
            type: "tween",
            // @ts-ignore
            duration: SIMPLE_FILTER_SECTION_ANIMATION_DURATION,
          }}
        >
          <SimpleFilter toggleSimpleFilter={toggleSimpleFilter} />
        </motion.div>
      </Flex>
    </div>
  );
};

export default Home;
