import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import Text from "../../ui/Text";
import { colors } from "../../ui/theme";

type MenuItemProps = {
  to?: string;
  search: string;
  children: ReactNode;
  isSelected: boolean;
  displayText: React.ReactNode;
  closingSimpleFilter: () => void;
};

export const MenuItem = (props: MenuItemProps) => {
  const {
    children,
    isSelected,
    to = "/",
    displayText,
    search,
    closingSimpleFilter,
  } = props;

  return (
    <Link
      to={{
        pathname: to,
        search: search,
      }}
      style={{ width: "100%" }}
      onClick={closingSimpleFilter}
    >
      <Flex direction="column" alignItems="center" w="100%">
        <IconContainer>
          <Box
            w="64px"
            h="64px"
            bg={isSelected ? colors.new.green[500] : "transparent"}
            borderRadius="8px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {children}
          </Box>
        </IconContainer>
        <Text
          style={{
            color: "white",
            fontWeight: isSelected ? "bold" : "400",
            marginTop: -8,
            marginBottom: 8,
          }}
        >
          {displayText}
        </Text>
      </Flex>
    </Link>
  );
};

const IconContainer = styled.div`
  padding: 0;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
