import { ReactNode } from "react";
import { Text as ChakraText } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
  fontSize?: string | string[];
  style?: object;
};

const Text = (props: Props) => {
  return (
    <ChakraText
      fontSize={props.fontSize || ["sm", "sm", "sm", "sm", "sm", "sm"]}
      {...props}
      style={props.style}
    >
      {props.children}
    </ChakraText>
  );
};

export default Text;
