import styled from "@emotion/styled";

import Text from "../../ui/Text";

type Props = {
  value: number;
};

const PageBox = ({ value }: Props) => {
  return (
    <PB>
      <Text>{String(value)}</Text>
    </PB>
  );
};

const PB = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  padding: 4px;
  width: 35px;
  height: 35px;
  margin: 0 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export default PageBox;
