import { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
// import Select from "react-select";
import { useQueryParam, ArrayParam, StringParam } from "use-query-params";

import H1 from "../../../ui/H1";
import Text from "../../../ui/Text";
import SitesMap from "../components/SitesMap";
import SelectSiteType from "../components/SelectSiteType";
import SelectOperator from "../components/SelectOperator";
// import SelectWasteCode from "../components/SelectWasteCode";
import {
  GET_PERMITTED_SITES_QUERY,
  IPermittedSitesResult,
} from "../../../graphql/queries/getPermittedSites";
import Input from "../../../ui/Input";
import { colors } from "../../../ui/theme";
import Label from "../../../ui/Label";
import ButtonPrimary from "../../../ui/ButtonPrimary";
import SitesList from "../components/SitesList";
import GhostButton from "../../../ui/GhostButton";
// import materialGroups from "./../data/soc2_groups";
import TrText from "../../../i18n/TrText";
import { isWebGLContextSupported } from "../../../aux/webgl";
// import translate from "../../../i18n/translate";

export type SiteType =
  | "AMENITY SITE"
  | "ANAEROBIC DIGESTION"
  | "BIOLOGICAL COMBUSTION"
  | "BIOLOGICAL TREATMENT"
  | "COMPOSTING"
  | "ENERGY RECOVERY"
  | "GASIFICATION"
  | "INCINERATION AND OTHER HEAT TREATMENT"
  | "LAND DISPOSAL"
  | "LAND RECOVERY"
  | "MINING WASTE"
  | "MRF"
  | "OTHER"
  | "PHYSICAL AND CHEMICAL TREATMENT"
  | "PRODUCTION AND MANUFACTURE"
  | "RECOVERY - OTHER"
  | "RECYCLING"
  | "STORAGE"
  | "UNKNOWN"
  | "VEHICLE DEPOLLUTION FACILITY"
  | "WASTE TRANSFER STN";

const SitesSearch = () => {
  const rowsPerPage = 25;

  const [queryInUrl, setQueryInUrl] = useQueryParam("q", StringParam);
  const [siteTypesInUrl, setSiteTypesInUrl] = useQueryParam("st", ArrayParam);
  const [wasteCodesInUrl, setWasteCodesInUrl] = useQueryParam("wc", ArrayParam);
  // const [material, setMaterial] = useQueryParam("m", ArrayParam);
  // const [operatorInUrl, setOperatorInUrl] = useQueryParam("op", ArrayParam);

  // Name or permit search
  const [queryForSearch, setQueryForSearch] = useState<string>(() => {
    if (queryInUrl) {
      return queryInUrl;
    } else return "";
  });
  const [queryValue, setQueryValue] = useState<string>(
    queryInUrl ? queryInUrl : ""
  );

  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);

  // Site type selection
  const [selectedSiteTypesForQuery, setSelectedSiteTypesForQuery] = useState<
    SiteType[]
  >(() => {
    if (siteTypesInUrl) {
      return [...(siteTypesInUrl as SiteType[])];
    } else return [];
  });
  const [selectedSiteTypes, setSelectedSiteTypes] = useState<SiteType[]>(() => {
    if (siteTypesInUrl) {
      return [...(siteTypesInUrl as SiteType[])];
    } else return [];
  });
  const onSiteTypeChange = (selectedSites: SiteType[]) =>
    setSelectedSiteTypes(selectedSites);

  // Waste code selection
  const [selectedWasteCodeForQuery, setSelectedWasteCodeForQuery] = useState<
    string[]
  >(() => {
    if (wasteCodesInUrl) {
      return [...(wasteCodesInUrl as string[])];
    } else return [];
  });
  const [selectedWasteCodes, setSelectedWasteCodes] = useState<string[]>([]);
  // const onWasteCodeChange = (codes: string[]) => setSelectedWasteCodes(codes);

  // Operator selection
  const [selectedOperatorForQuery, setSelectedOperatorForQuery] = useState<
    string[]
  >([]);
  const [selectedOperator, setSelectedOperator] = useState<string[]>([]);
  const onOrganisationChange = (operators: string[]) =>
    setSelectedOperator(operators);

  // const optionsMaterial = () => {
  //   return materialGroups.map((group: string) => {
  //     return {
  //       label: group,
  //       value: group,
  //     };
  //   });
  // };

  const optionsSitesType: { label: string; value: SiteType }[] = [
    { label: "Amenity Site", value: "AMENITY SITE" },
    { label: "Anaerobic Digestion", value: "ANAEROBIC DIGESTION" },
    { label: "Biological Combustion", value: "BIOLOGICAL COMBUSTION" },
    { label: "Biological Treatment", value: "BIOLOGICAL TREATMENT" },
    { label: "Composting", value: "COMPOSTING" },
    { label: "Energy Recovery", value: "ENERGY RECOVERY" },
    { label: "Gasification", value: "GASIFICATION" },
    {
      label: "Incineration and Other Heat Treatment",
      value: "INCINERATION AND OTHER HEAT TREATMENT",
    },
    { label: "Land Disposal", value: "LAND DISPOSAL" },
    { label: "Land Recovery", value: "LAND RECOVERY" },
    { label: "Mining Waste", value: "MINING WASTE" },
    { label: "MRF", value: "MRF" },
    { label: "Other", value: "OTHER" },
    {
      label: "Physical and chemical treatment",
      value: "PHYSICAL AND CHEMICAL TREATMENT",
    },
    {
      label: "Production and Manufacture",
      value: "PRODUCTION AND MANUFACTURE",
    },
    { label: "Recovery - Other", value: "RECOVERY - OTHER" },
    { label: "Recycling", value: "RECYCLING" },
    { label: "Storage", value: "STORAGE" },
    { label: "Unknown", value: "UNKNOWN" },
    {
      label: "Vehicle Depollution Facility",
      value: "VEHICLE DEPOLLUTION FACILITY",
    },
    { label: "Waste transfer stn", value: "WASTE TRANSFER STN" },
  ];

  const {
    data: permittedSitesData,
    loading: permittedSitesLoading,
    fetchMore,
  } = useQuery<IPermittedSitesResult>(GET_PERMITTED_SITES_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      first: rowsPerPage,
      after: null,
      search: queryForSearch ? queryForSearch : null,
      siteTypes: selectedSiteTypesForQuery?.length
        ? selectedSiteTypesForQuery
        : null,
      wasteCodes: selectedWasteCodeForQuery?.length
        ? selectedWasteCodeForQuery
        : null,
      topoOrgId: selectedOperatorForQuery?.length
        ? selectedOperatorForQuery
        : null,
    },
  });

  const handleQueryValueChange = (value: string) => setQueryValue(value);

  const submitQueryValueChange = () => {
    if (queryValue !== "") {
      setQueryForSearch(queryValue);
      setQueryValue(queryValue);
      setQueryInUrl(queryValue);
    }
  };

  const onSearch = () => {
    submitQueryValueChange();
  };

  const isPreviousPageDisabled =
    !permittedSitesData?.permittedSites?.pageInfo.hasPreviousPage ||
    permittedSitesLoading;

  const isNextPageDisabled =
    !permittedSitesData?.permittedSites?.pageInfo.hasNextPage ||
    permittedSitesLoading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: permittedSitesData?.permittedSites?.pageInfo.startCursor,
      },
    });
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: permittedSitesData?.permittedSites?.pageInfo.endCursor,
      },
    });
  };

  const onApplyChangesAll = () => {
    setSelectedSiteTypesForQuery(selectedSiteTypes);
    setSiteTypesInUrl(selectedSiteTypes);

    setSelectedWasteCodeForQuery(selectedWasteCodes);
    setWasteCodesInUrl(selectedWasteCodes);

    setSelectedOperatorForQuery(selectedOperator);
  };

  const isSimpleSearchButtonDisabled = () => {
    if (queryValue.length === 0 || queryValue === queryForSearch) {
      return true;
    } else return false;
  };

  const isApplyAllDisabled = () => {
    if (
      selectedSiteTypes.length === 0 &&
      selectedSiteTypesForQuery.length === 0 &&
      selectedWasteCodes.length === 0 &&
      selectedWasteCodeForQuery.length === 0 &&
      selectedOperator.length === 0 &&
      selectedOperatorForQuery?.length === 0
    ) {
      return true;
    } else if (
      selectedSiteTypes === selectedSiteTypesForQuery &&
      selectedWasteCodes === selectedWasteCodeForQuery &&
      selectedOperator === selectedOperatorForQuery
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearNameOrPermitSearch = () => {
    // Clear name and permit selections, including for GraphQL queries
    setQueryValue("");
    setQueryInUrl(undefined);
  };

  const clearAll = () => {
    // Clear all selections, including for GraphQL queries
    setQueryForSearch("");
    setQueryInUrl(undefined);

    // clear site types selections
    setSelectedSiteTypesForQuery([]);
    setSelectedSiteTypes([]);
    setSiteTypesInUrl(undefined);

    // clear waste codes selections
    setSelectedWasteCodeForQuery([]);
    setSelectedWasteCodes([]);
    setWasteCodesInUrl(undefined);
  };

  const count =
    (permittedSitesData?.permittedSites?.totalCount || 0) < rowsPerPage
      ? permittedSitesData?.permittedSites?.totalCount
      : rowsPerPage;

  const totalCount = permittedSitesData?.permittedSites?.totalCount || 0;

  return (
    <Box style={{ overflowY: "scroll" }}>
      <Flex
        align="flex-start"
        justify="flex-start"
        direction={["column"]}
        p={8}
      >
        <H1>
          <TrText message="site_directory.title" />
        </H1>
      </Flex>

      <Flex w="100%" direction="column" px={8}>
        <SimpleSearch>
          <Flex w="100%">
            <Flex direction="column" alignItems="flex-start" w="100%">
              <Flex w="100%" alignItems="center">
                <Flex direction="column" w="100%">
                  <Box mb={1}>
                    <Label forHtml="search-name-permit-box">
                      <TrText message="site_directory.search" />
                    </Label>
                  </Box>
                  <Input
                    id="search-name-permit-box"
                    placeholder="site_directory.search_placeholder"
                    value={queryValue}
                    valueSelectedForQuery={undefined}
                    onChange={handleQueryValueChange}
                    onSubmit={submitQueryValueChange}
                    onClear={clearNameOrPermitSearch}
                  />
                </Flex>
                <Flex ml={4} alignSelf="flex-end">
                  <GhostButton
                    isDisabled={false}
                    onClick={() => {
                      setQueryForSearch("");
                      setQueryValue("");
                      setQueryInUrl(undefined);
                    }}
                  >
                    <TrText message="app.clear" />
                  </GhostButton>
                </Flex>
                <Flex ml={4} alignSelf="flex-end">
                  <ButtonPrimary
                    isDisabled={isSimpleSearchButtonDisabled()}
                    onClick={onSearch}
                  >
                    <TrText message="app.search" />
                  </ButtonPrimary>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </SimpleSearch>

        <AdvancedSearch direction="column">
          <Text>
            <TrText message="site_directory.advanced_search" />
          </Text>
          <Flex mt={2}>
            <Box w="50%" mr={2}>
              <SelectSiteType
                placeholder="site_directory.site_type_placeholder"
                selectedSitesValues={selectedSiteTypes}
                selectValues={optionsSitesType}
                onSiteTypeChange={onSiteTypeChange}
              />
            </Box>

            {/*  <Box w="25%" mx={2}>
              <SelectWasteCode
                placeholder="site_directory.waste_code_placeholder"
                onWasteCodeChange={onWasteCodeChange}
              />
            </Box> */}

            {/*   <Box w="25%" mx={2}>
            <Select
                placeholder={translate("site_directory.material_placeholder")}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    padding: 12,
                  }),
                  control: (provided) => ({
                    ...provided,
                    ...selectors.control,
                  }),
                  singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";

                    return { ...provided, opacity, transition };
                  },
                  container: (provided) => ({
                    ...provided,
                    width: "100%",
                    zIndex: 12,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: selectors.borderRadius,
                  colors: {
                    ...theme.colors,
                    ...selectors,
                  },
                })}
                options={optionsMaterial()}
                isMulti={true}
              />
            </Box>  */}

            <Box w="50%" ml={2}>
              <SelectOperator
                placeholder="site_directory.operator_placeholder"
                onOrganisationChange={onOrganisationChange}
              />
            </Box>

            <Box ml={4}>
              <GhostButton isDisabled={false} onClick={clearAll}>
                <TrText message="app.clear_all" />
              </GhostButton>
            </Box>
            <Box ml={4}>
              <ButtonPrimary
                isDisabled={isApplyAllDisabled()}
                onClick={onApplyChangesAll}
              >
                <TrText message="app.apply" />
              </ButtonPrimary>
            </Box>
          </Flex>
        </AdvancedSearch>
      </Flex>

      <Flex px={8} mt={4} mb={2}>
        <Box h="20px" pl={4}>
          {permittedSitesLoading ? (
            <Text> </Text>
          ) : permittedSitesData?.permittedSites?.totalCount ? (
            <Text>
              <TrText
                message="site_directory.results"
                options={{ count, totalCount }}
              />
            </Text>
          ) : (
            <Text>
              <TrText message="site_directory.no_sites" />
            </Text>
          )}
        </Box>
      </Flex>

      <Flex
        direction={["column", "column", "column", "row", "row"]}
        px={8}
        w={"100%"}
        h={["100vh", "100vh", "100vh", "60vh", "60vh"]}
        pb={12}
      >
        <Flex
          direction="column"
          w={["100%", "100%", "100%", "40%", "40%"]}
          pb="70px"
          position="relative"
          overflowY="scroll"
        >
          <SitesList
            selectedSiteId={selectedSiteId}
            setSelectedSiteId={setSelectedSiteId}
            permittedSitesData={permittedSitesData}
            onPreviousPageClick={onPreviousPageClick}
            onNextPageClick={onNextPageClick}
            isPreviousPageDisabled={isPreviousPageDisabled}
            isNextPageDisabled={isNextPageDisabled}
            isDataLoading={permittedSitesLoading}
          />
        </Flex>

        <Flex
          w={["100%", "100%", "100%", "60%", "60%"]}
          mt={[4, 4, 4, 0, 0]}
          ml={[0, 0, 0, 4, 4]}
          h={["400px", "400px", "400px", "100%", "100%"]}
        >
          {isWebGLContextSupported() ? (
            <SitesMap
              sites={permittedSitesData?.permittedSites.edges}
              selectedSiteId={selectedSiteId}
              setSelectedSiteId={setSelectedSiteId}
            />
          ) : (
            <Box p={4}>
              <TrText message="overview.webgl_not_supported" />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const SimpleSearch = styled(Flex)`
  padding: 12px;
  border: 1px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
`;
const AdvancedSearch = styled(Flex)`
  padding: 12px;
  border-top: 1px solid #eae6df;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${colors.new.background_light[500]};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
`;
export default SitesSearch;
