import { gql } from "@apollo/client";

export const GET_POI_GROUPS = gql`
  query GetPoiGroups {
    poiGroups {
      id
      name
    }
  }
`;

export interface IPoiGroup {
  id: string;
  name: string;
}

export interface IPoiGroupResult {
  poiGroups: IPoiGroup[];
}
