/**
 * Format number to specified decimal places.
 * @param {number} value - Number to be converted
 * @param {number} decimal - Number of decimal places
 * @returns {string}
 * */
export const formatToDecimalPlaces = (
  value: number,
  decimal: number,
  options: { trailingZeros: boolean } = { trailingZeros: true }
): string => {
  if (typeof value === "number" && typeof decimal === "number") {
    const multiplier = Math.pow(10, decimal);
    if (options.trailingZeros) {
      return (Math.round(value * multiplier) / multiplier).toFixed(decimal);
    } else {
      return (Math.floor(value * multiplier) / multiplier).toString();
    }
  } else return "";
};
