import { Flex } from "@chakra-ui/react";

import { SIMPLE_FILTER_SECTION_WIDTH } from "../../../../aux/constants";
import TrText from "../../../../i18n/TrText";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import GhostButton from "../../../../ui/GhostButton";
import { colors } from "../../../../ui/theme";

type SimpleFilterControlsProps = {
  isSubmitDisabled: boolean;
  onClearAllSubmit: () => void;
  onNewFilteringSubmit: () => void;
};

const SimpleFilterControls = ({
  isSubmitDisabled,
  onClearAllSubmit,
  onNewFilteringSubmit,
}: SimpleFilterControlsProps) => {
  return (
    <Flex
      justifyContent="space-between"
      p="10px"
      alignItems="center"
      height="60px"
      width={SIMPLE_FILTER_SECTION_WIDTH}
      borderTop={`1px solid ${colors.new.grey[500]}`}
    >
      <GhostButton onClick={onClearAllSubmit}>
        <TrText message="filter.clear_all_button" />
      </GhostButton>
      <ButtonPrimary
        onClick={onNewFilteringSubmit}
        isDisabled={isSubmitDisabled}
      >
        <TrText message="app.apply_changes" />
      </ButtonPrimary>
    </Flex>
  );
};

export default SimpleFilterControls;
