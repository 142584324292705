import { Flex } from "@chakra-ui/react";

import {
  IMovementUpload,
  ICellErrorsDetails,
  ICellWarningsDetails,
} from "../../../graphql/queries/getMovementsUploads";
import ErrorSummary from "./ErrorSummary";
import CellErrors, { IUICellErrorDetail } from "./CellErrors";
import MissingColumns from "./MissingColumns";
import Text from "../../../ui/Text";
import Card from "../../../ui/Card";
import CellWarnings, { IUICellWarningDetail } from "./CellWarnings";
import CellWarningsSummaries from "./CellWarningsSummaries";
import CellErrorsSummaries from "./CellErrorsSummaries";
import TrText from "../../../i18n/TrText";

const getHasErrors = (upload: IMovementUpload) => {
  return upload.status === "failed";
};

const getHasWarnings = (upload: IMovementUpload) => {
  return upload.status === "completed_with_warnings";
};

const getMessages = (upload: IMovementUpload) => {
  switch (upload.status) {
    case "completed":
      return ["Your file has finished processing"];
    case "completed_with_warnings":
      return [
        "Your file has finished processing, but with " +
          upload.processingWarnings?.warnings?.cells?.message,
      ];
    case "failed":
      return [
        upload.processingErrors?.error,
        upload.processingErrors?.errors?.sheet?.message,
        upload.processingErrors?.errors?.cells?.message,
      ]
        .filter((message): message is string => !!message)
        .map((message) => message);
    case "processing":
      return ["Your file is being processed"];
    case "queued_for_processing":
      return ["Your file will be processed shortly"];
    case "waiting_for_upload":
      return ["Waiting for file upload"];
    default:
      return ["Please check back later"];
  }
};

const getHeading = (upload: IMovementUpload) => {
  switch (upload.status) {
    case "completed":
      return `${
        upload.numberMovements || 0
      } activities in file successfully uploaded`;
    case "completed_with_warnings":
      return `${
        upload.numberMovements || 0
      } activities in file successfully uploaded. Review the warnings below, fix and upload your file again if required.`;
    case "failed":
      return "Your file failed to upload due to errors in your file listed below. Fix the errors and upload file again.";
    case "processing":
    case "queued_for_processing":
    case "waiting_for_upload":
      return "Please check back later";
    default:
      return "Please check back later";
  }
};

const getCellErrors = (errors: ICellErrorsDetails): IUICellErrorDetail[] => {
  const errorColumns = Object.keys(errors);
  // flatten all the errors so they will go into one table
  const rows = errorColumns.reduce((rs, column) => {
    // errors for the current column
    const columnErrors = errors[column];
    // augment the errors with the column name
    const columnRows = columnErrors.map((w) => ({
      column,
      ...w,
    }));
    // add them into the running list of errors
    return [...rs, ...columnRows];
  }, new Array<IUICellErrorDetail>());
  return rows;
};

const getCellWarnings = (
  warnings: ICellWarningsDetails
): IUICellWarningDetail[] => {
  const warningColumns = Object.keys(warnings);
  // flatten all the warnings so they will go into one table
  const rows = warningColumns.reduce((rs, column) => {
    // errors for the current column
    const columnWarnings = warnings[column];
    // augment the warnings with the column name
    const columnRows = columnWarnings.map((w) => ({
      column,
      ...w,
    }));
    // add them into the running list of errors
    return [...rs, ...columnRows];
  }, new Array<IUICellWarningDetail>());
  return rows;
};

type Props = {
  upload: IMovementUpload;
};

const UploadDetails = (props: Props) => {
  const { upload } = props;

  if (upload) {
    const hasErrors = getHasErrors(upload);
    const hasWarnings = getHasWarnings(upload);
    const messages = getMessages(upload);
    const heading = getHeading(upload);

    const missingColumns =
      upload?.processingErrors?.errors?.sheet?.missingColumns || [];
    const cellErrors = getCellErrors(
      upload?.processingErrors?.errors?.cells?.cellErrors?.details || {}
    );
    const cellWarnings = getCellWarnings(
      upload?.processingWarnings?.warnings?.cells?.cellWarnings?.details || {}
    );
    const cellErrorsSummaries =
      upload?.processingErrors?.errors?.cells?.cellErrors?.summary || [];
    const cellWarningsSummaries =
      upload?.processingWarnings?.warnings?.cells?.cellWarnings?.summary || [];

    return (
      <Flex w="100%" direction="column">
        <ErrorSummary upload={upload} heading={heading} messages={messages} />

        {missingColumns.length > 0 && (
          <>
            <Card>
              <Flex direction="column" mt={8}>
                <MissingColumns columns={missingColumns} />
              </Flex>
            </Card>
          </>
        )}

        {hasErrors && cellErrors.length > 0 && (
          <>
            <Card>
              <Flex direction="row" mt={8} px={4}>
                <Text>
                  <TrText message="upload.errors_summary_title" />
                </Text>
              </Flex>

              <Flex direction="column" mt={8}>
                <CellErrorsSummaries
                  summaries={cellErrorsSummaries}
                  maxRows={10}
                />
              </Flex>

              <Flex direction="row" mt={8} px={4}>
                <Text>
                  <TrText message="upload.errors_title" />
                </Text>
              </Flex>

              {cellErrors.length > 0 && (
                <Flex direction="column" mt={8}>
                  <CellErrors errors={cellErrors} maxRows={10} />
                </Flex>
              )}
            </Card>

            <Flex
              direction="row"
              mt={8}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <ButtonPrimary onClick={() => alert("TODO")}>
                <DownloadIcon />
                Download warnings report
              </ButtonPrimary> */}
            </Flex>
          </>
        )}

        {hasWarnings && cellWarnings.length > 0 && (
          <>
            <Card>
              <Flex direction="row" mt={8} px={4}>
                <Text>
                  <TrText message="upload.warnings_summary_title" />
                </Text>
              </Flex>

              <Flex direction="column" mt={8}>
                <CellWarningsSummaries
                  summaries={cellWarningsSummaries}
                  maxRows={10}
                />
              </Flex>

              <Flex direction="row" mt={8} px={4}>
                <Text>
                  <TrText message="upload.warnings_title" />
                </Text>
              </Flex>

              {cellWarnings.length > 0 && (
                <Flex direction="column" mt={8}>
                  <CellWarnings warnings={cellWarnings} maxRows={10} />
                </Flex>
              )}
            </Card>

            <Flex
              direction="row"
              mt={8}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <ButtonPrimary onClick={() => alert("TODO")}>
                <DownloadIcon />
                Download warnings report
              </ButtonPrimary> */}
            </Flex>
          </>
        )}
      </Flex>
    );
  } else {
    return null;
  }
};

export default UploadDetails;
