import { Flex } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  style?: React.CSSProperties;
};

const CardContent = (props: Props) => {
  const { children, isLoading, style } = props;

  return (
    <Flex
      w="100%"
      direction="column"
      justifyContent={isLoading ? "center" : "flex-start"}
      alignItems="center"
      flexGrow={1}
      p={4}
      style={{ ...style }}
    >
      {children}
    </Flex>
  );
};

export default CardContent;
