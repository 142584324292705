import { formatToDecimalPlaces } from "../../aux/numbers";
import Tile from "../../ui/Tile";

const TotalMaterialLandfill = ({
  totalWeight,
  isLoading,
  landfillPercentage,
}: {
  totalWeight: number;
  isLoading: boolean;
  landfillPercentage: number;
}) => {
  // Divide by 100, since we receive a number like 65.8% and we want 0.658
  // Divide by 1000 to get tonnes
  const tonnes = formatToDecimalPlaces(
    (totalWeight * Number(landfillPercentage.toFixed(2))) / 100 / 1000,
    1
  );

  const calculateValue = ((totalWeight: number, tonnes: string): string => {
    if (totalWeight <= 0) {
      return `0t`;
    }
    if (landfillPercentage < 0 || landfillPercentage > 100) {
      return `0t`;
    } else {
      return `${tonnes}t (${landfillPercentage.toFixed(2)}%)`;
    }
  })(totalWeight, tonnes);

  return (
    <Tile
      title="overview.landfill_title"
      bottomText="overview.in_total"
      popoverText="overview.landfill_popover"
      iconName="landfill"
      value={isLoading ? undefined : calculateValue}
    />
  );
};

export default TotalMaterialLandfill;
