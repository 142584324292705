import { Stack, Box } from "@chakra-ui/react";

import { ValueChangeType } from "../SimpleFilter";
import Checkbox from "./Checkbox";

interface IValues {
  id: string;
  name: string;
}

interface ISelectionListProps {
  values: IValues[];
  selectedIds: string[];
  onValueChange: ({ id, isChecked }: ValueChangeType) => void;
}

const MultiSelectionList = ({
  values,
  selectedIds,
  onValueChange,
}: ISelectionListProps) => {
  const allValuesChecked = values.length === selectedIds.length;

  const isIndeterminate = selectedIds.length > 0 && !allValuesChecked;

  return (
    <Stack direction="column" px={1} mt={0}>
      <div
        style={{
          position: "absolute",
          background: "#fff",
          width: "88%",
          zIndex: 2,
          marginTop: "-8px",
        }}
      >
        <Box py={2} borderBottom={"1px dashed gray"}>
          <Checkbox
            key="all"
            isChecked={allValuesChecked}
            isIndeterminate={isIndeterminate}
            name={"Select all"}
            onChange={() => {
              onValueChange({
                id: "all",
                isChecked: allValuesChecked,
              });
            }}
          />
        </Box>
      </div>

      <div style={{ marginTop: 50 }}>
        {values &&
          values.map((value) => {
            return (
              <div key={value.id} style={{ marginTop: 5 }}>
                <Checkbox
                  key={value.id}
                  id={value.id}
                  isChecked={selectedIds.includes(value.id)}
                  name={value.name}
                  onChange={({ id, isChecked }) => {
                    onValueChange({
                      id,
                      isChecked,
                    });
                  }}
                />
              </div>
            );
          })}
      </div>
    </Stack>
  );
};

export default MultiSelectionList;
