import styled from "@emotion/styled";
import { Flex, AccordionItem } from "@chakra-ui/react";

import Text from "../../../../../ui/Text";
import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import { calculatePercentage } from "../../../../../aux/validationPercentage";
import { ICarriersSummary } from "../../../../../graphql/queries/getCarriersSummary";
import {
  getDistanceForUser,
  getFormattedDistanceForUser,
} from "../../../../../aux/userUtils";
import { ICurrentUser } from "../../../../../graphql/queries/getCurrentUser";

type Props = {
  currentUser: ICurrentUser | undefined;
  carrier: ICarriersSummary;
  validationValueThresholdSelected: number | undefined;
  landfillValueThresholdSelected: number | undefined;
  divertedValueThresholdSelected: number | undefined;
  unknownValueThresholdSelected: number | undefined;
  distancePerMovementThresholdSelected: number | undefined;
  co2PerMovementThresholdSelected: number | undefined;
};

export const SiteRow = (props: Props) => {
  const {
    currentUser,
    carrier,
    validationValueThresholdSelected,
    landfillValueThresholdSelected,
    divertedValueThresholdSelected,
    unknownValueThresholdSelected,
    distancePerMovementThresholdSelected,
    co2PerMovementThresholdSelected,
  } = props;

  const totalMaterialWeight = (carrier?.totalWeight || 0) / 1000;

  const validationPct = () => {
    if (carrier.movementCount === 0) {
      return "0%";
    } else {
      return `${calculatePercentage(
        carrier.movementCount - carrier.receiverValidationErrors,
        carrier.movementCount
      )}%`;
    }
  };

  const landfillPct =
    carrier.totalWeight === 0
      ? 0
      : (100 * carrier.landfillWeight) / carrier.totalWeight;
  const landfillPctFormatted = `${formatToDecimalPlaces(landfillPct, 1)}%`;

  const divertedPct =
    carrier.totalWeight === 0
      ? 0
      : (100 * carrier.recoveryWeight) / carrier.totalWeight;
  const divertedPctFormatted = `${formatToDecimalPlaces(divertedPct, 1)}%`;

  const unknownPct =
    carrier.totalWeight === 0
      ? 0
      : (100 *
          (carrier.totalWeight -
            (carrier.landfillWeight + carrier.recoveryWeight))) /
        carrier.totalWeight;
  const unknownPctFormatted = `${formatToDecimalPlaces(unknownPct, 1)}%`;

  const distancePerMovementFormatted = () => {
    if (carrier.movementCount === 0) {
      return "0";
    } else {
      return getFormattedDistanceForUser(
        carrier.primaryDistanceMiles / carrier.movementCount,
        carrier.primaryDistanceKms / carrier.movementCount,
        currentUser,
        1
      );
    }
  };

  const distancePerMovement = () => {
    if (carrier.movementCount === 0) {
      return getFormattedDistanceForUser(0, 0, currentUser, 1);
    } else {
      return getDistanceForUser(
        carrier.primaryDistanceMiles / carrier.movementCount,
        carrier.primaryDistanceKms / carrier.movementCount,
        currentUser
      );
    }
  };

  const co2PerMovement = () => {
    if (carrier.movementCount === 0) {
      return 0;
    } else {
      return formatToDecimalPlaces(
        carrier.primaryCo2Kgs / carrier.movementCount,
        4
      );
    }
  };

  return (
    <AccordionItem>
      <Flex w="100%" px={4} py={4}>
        <Flex w="28%" textAlign="left" alignItems="center">
          <Text>{carrier.name}</Text>
        </Flex>

        <Cell w="9%">
          <Text>{carrier.movementCount}</Text>
        </Cell>

        <Cell w="9%">
          <Text>
            {totalMaterialWeight === 0
              ? `0kg`
              : `${formatToDecimalPlaces(totalMaterialWeight, 3)}t`}
          </Text>
        </Cell>

        {/* Validation */}
        <Cell w="9%">
          {validationValueThresholdSelected ? (
            <Badge
              isPositive={
                Number(validationPct().replace("%", "")) >
                validationValueThresholdSelected
              }
            >
              <Text>{validationPct()}</Text>
            </Badge>
          ) : (
            <Text>{validationPct()}</Text>
          )}
        </Cell>

        {/* Landfill */}
        <Cell w="9%">
          {landfillValueThresholdSelected ? (
            <Badge
              isPositive={
                landfillValueThresholdSelected &&
                landfillPct < landfillValueThresholdSelected
              }
            >
              <Text>{landfillPctFormatted}</Text>
            </Badge>
          ) : (
            <Text>{landfillPctFormatted}</Text>
          )}
        </Cell>

        {/* Diverted */}
        <Cell w="9%">
          {divertedValueThresholdSelected ? (
            <Badge
              isPositive={
                divertedValueThresholdSelected &&
                divertedPct > divertedValueThresholdSelected
              }
            >
              <Text>{divertedPctFormatted}</Text>
            </Badge>
          ) : (
            <Text>{divertedPctFormatted}</Text>
          )}
        </Cell>

        {/* Unknown */}
        <Cell w="9%">
          {unknownValueThresholdSelected ? (
            <Badge
              isPositive={
                unknownValueThresholdSelected &&
                unknownPct < unknownValueThresholdSelected
              }
            >
              <Text>{unknownPctFormatted}</Text>
            </Badge>
          ) : (
            <Text>{unknownPctFormatted}</Text>
          )}
        </Cell>

        {/* DistancePerMovement */}
        <Cell w="9%">
          {distancePerMovementThresholdSelected ? (
            <Badge
              isPositive={
                distancePerMovementThresholdSelected &&
                distancePerMovement() < distancePerMovementThresholdSelected
              }
            >
              <Text>{distancePerMovementFormatted()}</Text>
            </Badge>
          ) : (
            <Text>{distancePerMovementFormatted()}</Text>
          )}
        </Cell>

        {/* CO2 per movement */}
        <Cell w="9%">
          {co2PerMovementThresholdSelected ? (
            <Badge
              isPositive={
                co2PerMovementThresholdSelected &&
                Number(co2PerMovement()) < co2PerMovementThresholdSelected
              }
            >
              <Text>{co2PerMovement()}kg</Text>
            </Badge>
          ) : (
            <Text>{co2PerMovement()}kg</Text>
          )}
        </Cell>
      </Flex>
    </AccordionItem>
  );
};

const Badge = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.isPositive ? "#f0f5ef" : "#f7eff1")};
  border-radius: 4px;
  color: ${(props) => (props.isPositive ? "#12ab6f" : "#b92347")};
  font-weight: bold;
  height: 28px;
  min-width: 64px;
  padding: 0 8px;
`;
const Cell = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 32px;
`;
