import { Flex } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import { IPermittedSitesResult } from "./../../../graphql/queries/getPermittedSites";
import Card from "./../../../ui/Card";
import Text from "./../../../ui/Text";
import Link from "./../../../ui/Link";
import GhostButton from "../../../ui/GhostButton";
import { colors } from "./../../../ui/theme";
import TrText from "../../../i18n/TrText";
import LoadingData from "../../../components/LoadingData";

type Props = {
  permittedSitesData: IPermittedSitesResult | undefined;
  onPreviousPageClick: () => void;
  onNextPageClick: () => void;
  isPreviousPageDisabled: boolean;
  isNextPageDisabled: boolean;
  selectedSiteId: string | null;
  setSelectedSiteId: (id: string) => void;
  isDataLoading: boolean;
};

const SitesList = ({
  permittedSitesData,
  onPreviousPageClick,
  onNextPageClick,
  isPreviousPageDisabled,
  isNextPageDisabled,
  selectedSiteId,
  setSelectedSiteId,
  isDataLoading,
}: Props) => {
  return (
    <Card overflowY="scroll">
      {isDataLoading && <LoadingData />}
      {permittedSitesData &&
        !isDataLoading &&
        permittedSitesData.permittedSites.edges.map((i) => {
          const site = i.node;
          return (
            <Flex
              w="100%"
              justifyContent="space-between"
              borderBottom={`1px solid ${colors.new.grey[500]}`}
              borderLeft={`${
                selectedSiteId === site.id
                  ? `5px solid ${colors.new.primary[500]}`
                  : `5px solid rgba(19, 57, 242, 0.05)`
              }`}
              bg={`${selectedSiteId === site.id && "rgba(19, 57, 242, 0.05)"}`}
              pl={4}
              py={4}
              key={site.id}
              onClick={() => setSelectedSiteId(site.id)}
              _hover={{
                cursor: "pointer",
              }}
            >
              <Flex direction="column">
                <Text>
                  <b>{site.poiName}</b>
                </Text>
                <Text>{site.poiAddress}</Text>
              </Flex>

              <Flex alignItems="flex-start" px={4}>
                <Link to={`/h/directory/site/${site.topoSiteId}`} state={site}>
                  <Text
                    style={{
                      color: colors.new.blue[500],
                      textDecoration: "underline",
                    }}
                  >
                    <TrText message="app.details" />
                  </Text>
                </Link>
              </Flex>
            </Flex>
          );
        })}

      <Flex
        position="absolute"
        bottom={0}
        style={{ height: 72, width: "100%" }}
        bg="#fff"
        borderBottomRadius="10px"
        justifyContent="space-between"
        alignItems="center"
        borderTop={`1px solid ${colors.new.grey[500]}`}
        px={4}
      >
        {permittedSitesData && (
          <>
            <GhostButton
              isDisabled={isPreviousPageDisabled}
              onClick={onPreviousPageClick}
              leftIcon={<ChevronLeftIcon />}
            >
              Previous
            </GhostButton>
            <GhostButton
              isDisabled={isNextPageDisabled}
              onClick={onNextPageClick}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </GhostButton>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default SitesList;
