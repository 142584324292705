import { getFormattedDistanceForUser } from "../../../../../aux/userUtils";
import { ICurrentUser } from "../../../../../graphql/queries/getCurrentUser";
import Tile from "../../../../../ui/Tile";

const TotalPrimaryDistance = ({
  miles,
  kms,
  currentUser,
}: {
  miles: number;
  kms: number;
  currentUser: ICurrentUser | undefined;
}) => (
  <Tile
    title="overview.primary_distance_card_title"
    bottomText="overview.in_total"
    iconName="miles"
    value={getFormattedDistanceForUser(miles, kms, currentUser, 0)}
  />
);

export default TotalPrimaryDistance;
