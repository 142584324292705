import { Dispatch, SetStateAction } from "react";
import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { SIMPLE_FILTER_SECTION_WIDTH } from "../../../../aux/constants";
import { IPoiGroup } from "../../../../graphql/queries/getPoiGroups";
import TrText from "../../../../i18n/TrText";
import H2 from "../../../../ui/H2";
import DateRange from "./../../components/DateRange";
import GroupSelectionList from "./../../components/GroupSelectionList";
import Header from "./../../components/Header";
import LoadFilters from "./../../components/LoadFilters";
import { FilterType } from "./../../components/LoadFilters/LoadFilters";
import MultiSelectionList from "./../../components/MultiSelectionList";
import SelectionList from "./../../components/SelectionList";
import SimpleFilterControls from "./../../components/SimpleFilterControls";
import SitesSelectionList from "./../../components/SitesSelectionList";
import WasteCodeSelectionList from "./../../components/WasteCodeSelectionList";
import { ValueChangeType } from "./../../SimpleFilter";
import {
  ProducerPoi,
  ReceiverPoi,
  Supplier,
  WasteCode,
} from "../../../../graphql/queries/getMovementsSummary";

type Props = {
  poiGroups: IPoiGroup[] | undefined;
  toggleSimpleFilter: () => void;
  applyFilter: (filter: FilterType) => void;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  setStartDateStringValue: Dispatch<SetStateAction<string | null>>;
  setEndDateStringValue: Dispatch<SetStateAction<string | null>>;
  poiGroupIdValues: string[];
  onGroupIdValueChange: ({ id, isChecked }: ValueChangeType) => void;
  uniqueProducerPois: ProducerPoi[];
  poisValues: string[];
  onProducerPoiIdValueChange: ({ id, isChecked }: ValueChangeType) => void;
  uniqueSuppliers: Supplier[];
  supplierIdValues: string[];
  onSupplierIdValueChange: ({ id, isChecked }: ValueChangeType) => void;
  uniqueCarriers: string[];
  carrierNameValue: string;
  setCarrierNameValue: Dispatch<SetStateAction<string>>;
  uniqueReceiverPois: ReceiverPoi[];
  receiversValues: string[];
  onReceiverIdValueChange: ({ id, isChecked }: ValueChangeType) => void;
  uniqueWasteCodes: WasteCode[];
  wasteCodeValues: string[];
  onWasteCodeValueChange: ({ id, isChecked }: ValueChangeType) => void;
  hasIssuesValue: string;
  setHasIssuesValue: Dispatch<React.SetStateAction<string>>;
  isApplyDisabled: () => boolean;
  onClearAllSubmit: () => void;
  onNewFilteringSubmit: () => void;
};

const SimpleFilterContainer = (props: Props) => {
  const {
    poiGroups,
    toggleSimpleFilter,
    applyFilter,
    startDate,
    endDate,
    setStartDateStringValue,
    setEndDateStringValue,
    poiGroupIdValues,
    onGroupIdValueChange,
    uniqueProducerPois,
    poisValues,
    onProducerPoiIdValueChange,
    uniqueSuppliers,
    supplierIdValues,
    onSupplierIdValueChange,
    uniqueCarriers,
    carrierNameValue,
    setCarrierNameValue,
    uniqueReceiverPois,
    receiversValues,
    onReceiverIdValueChange,
    uniqueWasteCodes,
    wasteCodeValues,
    onWasteCodeValueChange,
    hasIssuesValue,
    setHasIssuesValue,
    isApplyDisabled,
    onClearAllSubmit,
    onNewFilteringSubmit,
  } = props;

  return (
    <Flex
      as="nav"
      align="flex-start"
      justify="flex-start"
      wrap="nowrap"
      direction="column"
      width={SIMPLE_FILTER_SECTION_WIDTH}
      borderTopLeftRadius="10px"
    >
      <Header onFilterClick={toggleSimpleFilter} />

      <Accordion defaultIndex={[0]} w="100%">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.saved_filters" />
                </H2>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {isExpanded && <LoadFilters applyFilter={applyFilter} />}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.date_range" />
                </H2>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {isExpanded && (
                  <DateRange
                    setStartDateStringValue={setStartDateStringValue}
                    setEndDateStringValue={setEndDateStringValue}
                    areDatesInUrlSet={!!startDate || !!endDate}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {(poiGroups || []).length > 0 && (
          <AccordionItem>
            {({ isExpanded }: { isExpanded: boolean }) => (
              <>
                <AccordionButton
                  w="100%"
                  display="flex"
                  justifyContent="space-between"
                  py={4}
                >
                  <H2 style={{ fontSize: "1rem" }}>
                    <TrText message="filter.groups" />
                  </H2>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} maxHeight="400" overflowY="scroll">
                  {isExpanded && (
                    <GroupSelectionList
                      poiGroups={poiGroups || []}
                      poiGroupIdValues={poiGroupIdValues}
                      onGroupIdValueChange={onGroupIdValueChange}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.sites" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="400" overflowY="scroll">
                {isExpanded && (
                  <SitesSelectionList
                    uniquePois={
                      uniqueProducerPois.map((poi) => ({
                        id: poi.poiId,
                        address: poi.address,
                        name: poi.name,
                      })) || []
                    }
                    poisValues={poisValues}
                    onValueChange={onProducerPoiIdValueChange}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.suppliers" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="400" overflowY="scroll">
                {isExpanded && (
                  <MultiSelectionList
                    values={
                      uniqueSuppliers.map((us) => ({
                        id: us.supplierId,
                        name: us.name,
                      })) || []
                    }
                    selectedIds={supplierIdValues}
                    onValueChange={onSupplierIdValueChange}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.carriers" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="300" overflowY="scroll">
                {isExpanded && (
                  <SelectionList
                    values={
                      uniqueCarriers.map((uc) => ({
                        value: uc,
                        label: uc,
                      })) || []
                    }
                    selectedValue={carrierNameValue}
                    onChange={setCarrierNameValue}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.receivers" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="400" overflowY="scroll">
                {isExpanded && (
                  <MultiSelectionList
                    values={
                      uniqueReceiverPois.map((ur) => ({
                        id: ur.receiverId,
                        name: ur.name,
                      })) || []
                    }
                    selectedIds={receiversValues}
                    onValueChange={onReceiverIdValueChange}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.materials" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="400" overflowY="scroll">
                {isExpanded && (
                  <WasteCodeSelectionList
                    uniqueWasteCodes={
                      uniqueWasteCodes.map((wc) => ({
                        code: wc.code,
                        description: wc.description,
                        isHazardous: wc.isHazardous,
                      })) || []
                    }
                    wasteCodeValues={wasteCodeValues}
                    onWasteCodeValueChange={onWasteCodeValueChange}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <AccordionButton
                w="100%"
                display="flex"
                justifyContent="space-between"
                py={4}
              >
                <H2 style={{ fontSize: "1rem" }}>
                  <TrText message="filter.validation" />
                </H2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} maxHeight="300" overflowY="scroll">
                {isExpanded && (
                  <SelectionList
                    values={[
                      {
                        value: "true",
                        label: "Select activities with issues",
                      },
                      {
                        value: "false",
                        label: "Select activities without issues",
                      },
                    ]}
                    selectedValue={hasIssuesValue}
                    onChange={setHasIssuesValue}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <Box
        style={{
          position: "fixed",
          bottom: 0,
          background: "white",
        }}
      >
        <SimpleFilterControls
          isSubmitDisabled={isApplyDisabled()}
          onClearAllSubmit={onClearAllSubmit}
          onNewFilteringSubmit={onNewFilteringSubmit}
        />
      </Box>
    </Flex>
  );
};

export default SimpleFilterContainer;
