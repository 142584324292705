import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const TOTAL_WEIGHT_PER_MONTH_QUERY = gql`
  query GetTotalWeightPerMonth(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $wasteCodes: [String!]
    $carrierName: String
    $hasIssues: Boolean
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    totalWeightPerMonth(
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      carrierName: $carrierName
      hasIssues: $hasIssues
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      month
      actualWeightInKgs
      assumedWeightInKgs
      estimatedWeightInKgs
    }
  }
`;

export interface ITotalWeightPerMonth {
  month: Date;
  actualWeightInKgs: number;
  assumedWeightInKgs: number;
  estimatedWeightInKgs: number;
}

export interface ITotalWeightPerMonthResult {
  totalWeightPerMonth: ITotalWeightPerMonth[];
}

export type ITotalWeightPerMonthParams = IFilters;
