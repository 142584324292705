import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";

import Label from "./Label";

const Checkbox = ({
  id,
  isChecked,
  isIndeterminate,
  onChange,
  name,
  label,
}: {
  id?: string;
  isChecked: boolean;
  isIndeterminate?: boolean;
  name: string;
  label?: string;
  onChange: ({ id, isChecked }: { id: string; isChecked: boolean }) => void;
}) => {
  return (
    <ChakraCheckbox
      w="100%"
      size="lg"
      colorScheme="green"
      value={id}
      isChecked={isChecked}
      isIndeterminate={isIndeterminate ? isIndeterminate : undefined}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          id: event.target.value,
          isChecked: event.target.checked,
        });
      }}
    >
      <Label title={name} label={label} />
    </ChakraCheckbox>
  );
};

export default Checkbox;
