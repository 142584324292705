import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

import { graphQLEndpoint } from "./../api";

const uploadLink = createUploadLink({
  uri: graphQLEndpoint(),
  credentials: "include",
}) as unknown as ApolloLink;

// retry requests if we get a 429 error
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      if (error?.response?.status === 429) {
        console.log("429 error, retrying");
        return true;
      }
      return false;
    },
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([retryLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          movements: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          movementsUploads: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          producerSitesSummary: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          receiverSitesSummary: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          permittedSites: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          producerGroupsSummary: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
          carriersSummary: {
            read: relayStylePagination().read,
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
