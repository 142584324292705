import truck_with_tick from "./../../assets/images/icons/truck_with_tick.svg";
import just_track from "./../../assets/images/icons/just_track.svg";
import weight from "./../../assets/images/icons/weight.svg";
import recycling_bin from "./../../assets/images/icons/recycling_bin.svg";
import miles from "./../../assets/images/icons/miles.svg";
import landfill from "./../../assets/images/icons/landfill.svg";
import co2 from "./../../assets/images/icons/co2.svg";

export type TileIconName =
  | "truck_with_tick"
  | "just_track"
  | "weight"
  | "recycling_bin"
  | "miles"
  | "landfill"
  | "co2";

type Props = {
  name: TileIconName;
  width?: number;
};

const TileIcons = (props: Props) => {
  const { name, width } = props;
  switch (name) {
    case "truck_with_tick":
      return (
        <img
          width={width ?? 90}
          src={truck_with_tick}
          alt="Validated primary movements icon"
        />
      );
    case "just_track":
      return <img width={width ?? 70} src={just_track} alt="Movements icon" />;
    case "weight":
      return (
        <img width={width ?? 55} src={weight} alt="Material weight icon" />
      );
    case "recycling_bin":
      return (
        <img
          width={width ?? 60}
          src={recycling_bin}
          alt="Material recovered icon"
        />
      );
    case "miles":
      return <img width={width ?? 80} src={miles} alt="Primary miles icon" />;
    case "landfill":
      return (
        <img
          width={width ?? 60}
          src={landfill}
          alt="Diverted from landfill icon"
        />
      );
    case "co2":
      return <img width={width ?? 80} src={co2} alt="Primary co2 icon" />;
    default:
      return null;
  }
};

export default TileIcons;
