import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

import Text from "./../../../../ui/Text";
import paper from "./../../../../assets/images/icons/paper.svg";
import step_1_select from "./../../../../assets/images/icons/step_1_select.svg";
import step_2_upload from "./../../../../assets/images/icons/step_2_upload.svg";
import { colors } from "../../../../ui/theme";

const NoUserUploads = () => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems={["center", "center", "center", "flex-start"]}
      p={8}
      mt={12}
    >
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Flex w={100} h={100} mb={4}>
          <PaperIcon />
        </Flex>

        <Flex
          my={8}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text style={{ fontWeight: "bold", marginBottom: 20 }}>
            You haven't uploaded any data yet
          </Text>
          <Text>Follow these 2 steps to populate some data</Text>
        </Flex>

        <Flex w="100%" justifyContent="space-around">
          <Flex direction="column" mr={12}>
            <Flex mb={6}>
              <NumberBox>1</NumberBox>
              <Step1Icon />
            </Flex>
            <Text>Choose an organisation</Text>
          </Flex>

          <Flex direction="column">
            <Flex mb={6}>
              <NumberBox>2</NumberBox>
              <Step2Icon />
            </Flex>
            <Text>Upload waste data file</Text>
          </Flex>
        </Flex>

        <Flex
          my={16}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text>
            If you still have any problems,{" "}
            <span style={{ textDecoration: "underline" }}>
              contact our support
            </span>
          </Text>
          <Text>
            or go to{" "}
            <span style={{ textDecoration: "underline" }}>knowledge base</span>{" "}
            to get more info
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const PaperIcon = () => {
  return <img src={paper} alt="paper" />;
};
const Step1Icon = () => {
  return <img src={step_1_select} alt="choose organisation icon" />;
};
const Step2Icon = () => {
  return <img src={step_2_upload} alt="upload waste data icon" />;
};
const NumberBox = styled(Flex)`
  height: 24px;
  width: 24px;
  border: 2px solid ${colors.new.grey[500]};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export default NoUserUploads;
