export const materialGroupsColours: MaterialGroupsColoursType = {
  "Metal waste, ferrous": "#FD4B3A",
  "Wood wastes": "#1F03C6",
  "Waste of naturally occurring minerals": "#2378A7",
  "Discarded electrical and electronic equipment": "#DC38FE",
  "Construction and demolition wastes": "#BB940F",
  "Solidified or stabilised waste": "#751850",
  "Various mineral wastes": "#E3F23E",
  "Plastic wastes": "#1BB194",
  "Discarded machines and equipment components": "#065696",
  "Discarded vehicles": "#3348C9",
  "Textile wastes": "#1BF0D5",
  "Unused explosives": "#4CA609",
  "Combustion wastes": "#1F76C7",
  "Non-infectious health care wastes": "#4AF9CD",
  "Waste containing PCB": "#D8D4C6",
  "Asbestos wastes": "#607C22",
  "Glass wastes": "#159C85",
  "Slurry and manure": "#9D3443",
  "Cesspit contents": "#E598E2",
  "Mixed chemical wastes": "#EDAEF6",
  "Infectious health care wastes": "#F2F3DE",
  "Mixed and undifferentiated materials": "#AF2438",
  "Acid, alkaline or salt wastes": "#1ED934",
  "Metal waste, non-ferrous": "#D19F63",
  "Waste from waste treatment": "#9031FC",
  "Paper and cardboard wastes": "#8BDE0C",
  "Sludges from purification of drinking and process water": "#BB064E",
  "Metal wastes, mixed ferrous and non-ferrous": "#87CB36",
  "Vegetal wastes": "#E711EA",
  "Household and similar wastes": "#769B95",
  Soils: "#BC1E76",
  "Chemical wastes": "#AAADB8",
  "Sludges and liquid wastes from waste treatment": "#47AD88",
  "Rubber wastes": "#455DB6",
  "Industrial effluent sludges": "#187F3F",
  "Off-specification chemical wastes": "#22C766",
  "Sorting residues": "#D1C5EF",
  "Used oils": "#D048CD",
  "Vitrified wastesa": "#66F384",
  "Waste water treatment sludges": "#109C2A",
  "Chemical deposits and residues": "#E6ACB7",
  "Spent Solvents": "#28F9DA",
  "Animal and mixed food waste": "#0AF12F",
  "Dredging spoil": "#B2250F",
};

export type MaterialGroupsColoursType = {
  "Metal waste, ferrous": string;
  "Wood wastes": string;
  "Waste of naturally occurring minerals": string;
  "Discarded electrical and electronic equipment": string;
  "Construction and demolition wastes": string;
  "Solidified or stabilised waste": string;
  "Various mineral wastes": string;
  "Plastic wastes": string;
  "Discarded machines and equipment components": string;
  "Discarded vehicles": string;
  "Textile wastes": string;
  "Unused explosives": string;
  "Combustion wastes": string;
  "Non-infectious health care wastes": string;
  "Waste containing PCB": string;
  "Asbestos wastes": string;
  "Glass wastes": string;
  "Slurry and manure": string;
  "Cesspit contents": string;
  "Mixed chemical wastes": string;
  "Infectious health care wastes": string;
  "Mixed and undifferentiated materials": string;
  "Acid, alkaline or salt wastes": string;
  "Metal waste, non-ferrous": string;
  "Waste from waste treatment": string;
  "Paper and cardboard wastes": string;
  "Sludges from purification of drinking and process water": string;
  "Metal wastes, mixed ferrous and non-ferrous": string;
  "Vegetal wastes": string;
  "Household and similar wastes": string;
  Soils: string;
  "Chemical wastes": string;
  "Sludges and liquid wastes from waste treatment": string;
  "Rubber wastes": string;
  "Industrial effluent sludges": string;
  "Off-specification chemical wastes": string;
  "Sorting residues": string;
  "Used oils": string;
  "Vitrified wastesa": string;
  "Waste water treatment sludges": string;
  "Chemical deposits and residues": string;
  "Spent Solvents": string;
  "Animal and mixed food waste": string;
  "Dredging spoil": string;
};
