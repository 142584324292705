import { gql } from "@apollo/client";
import { Role } from "../queries/getCurrentUser";

export const CREATE_INVITATION = gql`
  mutation createInvitation(
    $email: String!
    $name: String!
    $accountId: ID!
    $roles: [Role!]!
  ) {
    createInvitation(
      input: {
        email: $email
        name: $name
        accountId: $accountId
        roles: $roles
      }
    ) {
      errors
      success
    }
  }
`;

export type ICreateInvitationParams = {
  email: string;
  name: string;
  accountId: string;
  roles: Role[];
};

export type ICreateInvitationResponse = {
  errors?: string[];
  success: boolean;
};

export const CANCEL_INVITATION = gql`
  mutation cancelInvitation($userId: ID!) {
    cancelInvitation(input: { userId: $userId }) {
      errors
      success
    }
  }
`;

export type ICancelInvitationParams = {
  userId: string;
};

export type ICancelInvitationResponse = {
  errors?: string[];
  success: boolean;
};

export const REMOVE_USER = gql`
  mutation removeUser($userId: ID!) {
    removeUser(input: { userId: $userId }) {
      errors
      success
    }
  }
`;

export type IRemoveUserParams = {
  userId: string;
};

export type IRemoveUserResponse = {
  errors?: string[];
  success: boolean;
};

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRoles($userId: ID!, $roles: [Role!]!) {
    updateUserRoles(input: { userId: $userId, roles: $roles }) {
      errors
      success
    }
  }
`;

export type IUpdateUserRolesParams = {
  userId: string;
  roles: Role[];
};

export type IUpdateUserRolesResponse = {
  errors?: string[];
  success: boolean;
};
