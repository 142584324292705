import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const PRIMARY_DESTINATION_FATES_QUERY = gql`
  query PrimaryDestinationFates(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $poiGroupIds: [String!]
    $wasteCodes: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
    $hasIssues: Boolean
  ) {
    primaryDestinationFates(
      startDate: $startDate
      endDate: $endDate
      carrierName: $carrierName
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      poiGroupIds: $poiGroupIds
      wasteCodes: $wasteCodes
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
      hasIssues: $hasIssues
    ) {
      category
      type
      totalWeight
    }
  }
`;

export const SECONDARY_DESTINATION_FATES_QUERY = gql`
  query SecondaryDestinationFates(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $supplierIds: [String!]
    $poiIds: [String!]
    $poiGroupIds: [String!]
    $wasteCodes: [String!]
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
    $hasIssues: Boolean
  ) {
    secondaryDestinationFates(
      startDate: $startDate
      endDate: $endDate
      carrierName: $carrierName
      supplierIds: $supplierIds
      poiIds: $poiIds
      poiGroupIds: $poiGroupIds
      wasteCodes: $wasteCodes
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
      hasIssues: $hasIssues
    ) {
      category
      type
      totalWeight
    }
  }
`;

export interface IDestinationFates {
  category: string;
  type: string;
  totalWeight: number;
}

export interface IPrimaryDestinationFatesResult {
  primaryDestinationFates: IDestinationFates[];
}

export interface ISecondaryDestinationFatesResult {
  secondaryDestinationFates: IDestinationFates[];
}

export type IDestinationFatesParams = IFilters;
