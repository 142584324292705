import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

import { colors } from "../theme";

interface Props {
  children: ReactNode;
  flexGrow?: 0 | 1;
  overflowY?: "scroll" | "hidden";
  height?: string;
}

const Card = ({
  children,
  flexGrow = 1,
  overflowY = "hidden",
  height,
}: Props) => {
  return (
    <Flex
      as="article"
      bg={colors.new.text.light[500]}
      style={{
        height: height,
        flexGrow: flexGrow,
        borderRadius: "10px",
        boxShadow: `0px 2px 20px rgba(0, 0, 0, 0.08)`,
      }}
      overflow={overflowY}
      w="100%"
      direction="column"
    >
      {children}
    </Flex>
  );
};

export default Card;
