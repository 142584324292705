// eslint-disable-next-line import/no-anonymous-default-export
export default `\
#define SHADER_NAME scatterplot-layer-fragment-shader
precision highp float;
uniform bool filled;
uniform float stroked;
varying vec4 vFillColor;
varying vec4 vLineColor;
varying vec2 unitPosition;
varying float innerUnitRadius;
varying float outerRadiusPixels;

void main(void) {
  geometry.uv = unitPosition;
  float distanceX = abs(unitPosition.x) - 0.5;
  float distanceY = abs(unitPosition.y) - 0.5;
  if (distanceX > 0.1 || distanceY > 0.1) {
    gl_FragColor = vLineColor;
  } else {
    gl_FragColor = vFillColor;
  }
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
