import Select, { MultiValue } from "react-select";
import translate from "../../../i18n/translate";

import { selectors } from "../../../ui/theme";
import { SiteType } from "./../containers/SitesSearch";

type Props = {
  placeholder: string;
  selectValues: { label: string; value: SiteType }[];
  onSiteTypeChange: (selectedSites: SiteType[]) => void;
  selectedSitesValues: SiteType[];
};

const SelectSiteType = ({
  placeholder,
  selectValues,
  onSiteTypeChange,
  selectedSitesValues,
}: Props) => {
  return (
    <Select
      placeholder={translate(placeholder)}
      isDisabled={false}
      onChange={(sites: MultiValue<{ label: string; value: SiteType }>) => {
        onSiteTypeChange(sites.map((i) => i.value));
      }}
      styles={{
        option: (provided) => ({
          ...provided,
          padding: 12,
        }),
        control: (provided) => ({
          ...provided,
          ...selectors.control,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = "opacity 300ms";

          return { ...provided, opacity, transition };
        },
        container: (provided) => ({
          ...provided,
          width: "100%",
          zIndex: 12,
        }),
      }}
      value={
        selectedSitesValues
          ? selectedSitesValues.map((i) => ({ value: i, label: i }))
          : []
      }
      theme={(theme) => ({
        ...theme,
        borderRadius: selectors.borderRadius,
        colors: {
          ...theme.colors,
          ...selectors,
        },
      })}
      options={
        selectValues as {
          label: SiteType;
          value: SiteType;
        }[]
      }
      isMulti={true}
    />
  );
};

export default SelectSiteType;
