import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { QueryParamProvider } from "use-query-params";
import { parse, stringify } from "query-string";

import PageWrapper from "./ui/PageWrapper";
import Home from "./containers/Home";
import LandingPage from "./containers/LandingPage";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "./graphql/queries/getCurrentUser";
import TrText from "./i18n/TrText";
import { allowedCountryCodesForCentering } from "./services/maps/initialLocations";

function App() {
  const { data, loading, error } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  useEffect(() => {
    // This is to accomodate language selection
    // If "language" key does not exist in localStorage, create one
    const language = window.localStorage.getItem("language");
    if (!language) {
      localStorage.setItem("language", "en-GB");
    }
  }, []);

  useEffect(() => {
    // If there is no mapLocation key in localStorage
    // set it up with country code from account data
    const location = window.localStorage.getItem("mapLocation");

    if (!location) {
      if (data && data?.currentUser?.account?.countryCodes?.length > 0) {
        // only use when the key exists in locations data object
        if (allowedCountryCodesForCentering.includes(location as any)) {
          localStorage.setItem(
            "mapLocation",
            data?.currentUser?.account?.countryCodes[0]
          );
        }
      } else {
        // defaults to GB centering
        localStorage.setItem("mapLocation", "GB");
      }
    }
  }, [data]);

  useEffect(() => {
    // If there is no distanceUnits key in localStorage
    // set it up with miles as default
    const distanceUnits = window.localStorage.getItem("distanceUnits");

    if (!distanceUnits) {
      if (data && data?.currentUser?.account?.distanceUnits) {
        if (data?.currentUser?.account?.distanceUnits === "miles") {
          localStorage.setItem("distanceUnits", "miles");
        } else if (data?.currentUser?.account?.distanceUnits === "kilometers") {
          localStorage.setItem("distanceUnits", "kilometers");
        } else {
          localStorage.setItem("distanceUnits", "miles");
        }
      } else {
        // defaults to miles
        localStorage.setItem("distanceUnits", "miles");
      }
    }
  }, [data]);

  return (
    <Router>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        {loading && (
          <PageWrapper justify="center" align="center">
            <h1>
              <TrText message="app.loading" />
            </h1>
          </PageWrapper>
        )}

        {data?.currentUser && (
          <Switch>
            <Route path="/h">
              <Home />
            </Route>
            <Route path="/">
              <Redirect to="/h" />
            </Route>
          </Switch>
        )}

        {error && (
          <Switch>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        )}
      </QueryParamProvider>
    </Router>
  );
}

export default App;
