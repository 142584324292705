import { useEffect } from "react";
import { Flex, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { Icon } from "@chakra-ui/react";
import { GoSettings } from "react-icons/go";

import { colors } from "../../../../ui/theme";
import SimpleFilterSummary from "./../../../../components/SimpleFilter/components/SimpleFilterSummary";
import TrText from "../../../../i18n/TrText";
import { SaveFilter } from "../../../../components/SimpleFilter/components/SaveFilter";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import TabSelector from "../../../../ui/TabSelector";

type TabsType = "producer_sites" | "receiver_sites" | "carriers";
const tabsAllowedValues: TabsType[] = [
  "producer_sites",
  "receiver_sites",
  "carriers",
];

type Props = {
  toggleSimpleFilter: () => void;
  isOpen: boolean;
};

const ComparisonHeader = (props: Props) => {
  const { toggleSimpleFilter, isOpen } = props;

  const [tab, setTab] = useQueryParam("tab", StringParam);

  useEffect(() => {
    if (!tab) {
      setTab("producer_sites");
      // @ts-ignore
    } else if (!tabsAllowedValues.includes(tab)) {
      setTab("producer_sites");
    }
  }, [tab, setTab]);

  const filters = useFiltersFromQueryParams();

  const isSelected = (value: TabsType) => {
    if (value === tab) {
      return true;
    } else return null;
  };

  return (
    <header style={{ width: "100%", height: 72 }}>
      <Flex
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        mb={0}
        px={0}
        pl={4}
        bg="#fff"
        h="72px"
        borderBottom={`1px solid ${colors.new.grey[500]}`}
      >
        <NavContainer aria-label="comparison-tabs">
          <TabSelector
            onClick={() => setTab("producer_sites")}
            isSelected={isSelected("producer_sites")}
          >
            <TrText message="nav.producer_sites" />
          </TabSelector>
          <TabSelector
            onClick={() => setTab("receiver_sites")}
            isSelected={isSelected("receiver_sites")}
          >
            <TrText message="nav.receiver_sites" />
          </TabSelector>
          <TabSelector
            onClick={() => setTab("carriers")}
            isSelected={isSelected("carriers")}
          >
            <TrText message="nav.carriers" />
          </TabSelector>
        </NavContainer>

        <Flex align="center" justify="space-between">
          <Flex
            pr={8}
            h="100%"
            align="center"
            justify="center"
            style={{ visibility: isOpen ? "hidden" : "visible" }}
            // Accessibility: we hide it when the filter is open, so
            // it can't be accessed by keyboard
          >
            <SaveFilter />
            <Button
              ml={2}
              leftIcon={
                <Icon
                  w={6}
                  h={6}
                  mr={2}
                  as={GoSettings}
                  color={colors.new.text.light[500]}
                />
              }
              color={colors.new.text.light[500]}
              bg={colors.new.blue[500]}
              onClick={toggleSimpleFilter}
              justifyContent="flex-start"
              borderTopRightRadius={0}
              borderTopLeftRadius={6}
              borderBottomLeftRadius={6}
              borderBottomRightRadius={0}
              _hover={{
                background: "#1c3270",
              }}
              fontSize="sm"
            >
              Filters
            </Button>
            <SimpleFilterSummary filters={filters} />
          </Flex>
        </Flex>
      </Flex>
    </header>
  );
};

const NavContainer = styled("nav")`
  height: 100%;
`;

export default ComparisonHeader;
