import { useState, memo, useEffect } from "react";
// @ts-ignore
import DeckGL from "@deck.gl/react";
// @ts-ignore
import { FlyToInterpolator } from "deck.gl";
// @ts-ignore
import { ScatterplotLayer, PathLayer, ArcLayer } from "@deck.gl/layers";
import { Flex } from "@chakra-ui/react";
import ReactMapGL from "react-map-gl";
import styled from "@emotion/styled";

import {
  locations,
  getInitialCountryLocation,
} from "../../../../../services/maps/initialLocations";
import { colors } from "../../../../../ui/theme";
import Text from "../../../../../ui/Text";
import RectangleLayer from "./RectangleLayer";
import { IMovementsGeoSummaryResult } from "../../../../../graphql/queries/getMovementsGeoData";
import { ProducerSiteMapDataType } from "./../MapView";
import Link from "../../../../../ui/Link";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiY29kYm9kIiwiYSI6ImNrNTZtYzBiNzBoZGczZW4xcXQ5emhmNTEifQ.Ij2lPbn4xF1eeAxXhON0Hg";

type GeoPathType = {
  activitiesCount: Number;
  wasteCodes: String;
  weightInKgs: Number;
  producerName: String;
  receiverName: String;
  path: [[Number, Number], [Number, Number]];
};

type Props = {
  activitiesData: IMovementsGeoSummaryResult | undefined;
  is3DSelected: Boolean;
  onProducerSiteClick: (obj: ProducerSiteMapDataType) => void;
};

const MapStyle = {
  streets: "mapbox://styles/mapbox/streets-v11",
  outdoors: "mapbox://styles/mapbox/outdoors-v11",
  light: "mapbox://styles/mapbox/light-v10",
  dark: "mapbox://styles/mapbox/dark-v10",
  satellite: "mapbox://styles/mapbox/satellite-v9",
  sat_streets: "mapbox://styles/mapbox/satellite-streets-v11",
  nav_day: "mapbox://styles/mapbox/navigation-day-v1",
  nav_night: "mapbox://styles/mapbox/navigation-night-v1",
};

const SitesMap = ({
  activitiesData,
  is3DSelected,
  onProducerSiteClick,
}: Props) => {
  // Location used for initial centering of the map
  const [mapInitialLocation] = useState(locations[getInitialCountryLocation()]);

  const [producerHoverInfo, setProducerHoverInfo] = useState<any>(null);
  const [receiverHoverInfo, setReceiverHoverInfo] = useState<any>(null);
  const [receiverHoverObject, setReceiverHoverObject] = useState<any>(null);
  const [hoverActivitiesInfo, setHoverActivitiesInfo] = useState<any>(null);

  useEffect(() => {
    function handleClickOutsideReceiverBox(event: Event) {
      const receiverBox = document.getElementById("receiver-hover-info-box");

      if (!receiverBox?.contains(event.target as Node)) {
        setReceiverHoverInfo(null);
        setReceiverHoverObject(null);
      }
    }

    function handleClickOutsideProducerBox(event: Event) {
      const producerBox = document.getElementById("producer-hover-info-box");

      if (!producerBox?.contains(event.target as Node)) {
        setProducerHoverInfo(null);
      }
    }

    document.addEventListener("click", handleClickOutsideReceiverBox);
    document.addEventListener("click", handleClickOutsideProducerBox);

    return () => {
      document.removeEventListener("click", handleClickOutsideReceiverBox);
      document.removeEventListener("click", handleClickOutsideProducerBox);
    };
  }, []);

  const layers = [
    activitiesData &&
      new ScatterplotLayer({
        id: "receiver-layer",
        data: activitiesData?.movementsGeoSummary?.map((p) => {
          return {
            coordinates: [p.receiverPoiLng, p.receiverPoiLat],
            poiName: p.receiverName,
            topoSiteId: p.receiverId,
          };
        }),
        pickable: true,
        opacity: 0.9,
        stroked: true,
        filled: true,
        radiusScale: 6,
        radiusMinPixels: 3,
        radiusMaxPixels: 10,
        lineWidthMinPixels: 1,
        getPosition: (d: any) => d.coordinates,
        getRadius: 20,
        getFillColor: [62, 102, 251],
        getLineColor: [62, 102, 251],
        onClick: (info: any) => {
          setReceiverHoverInfo(info);
          setReceiverHoverObject(info?.object);
        },
      }),

    activitiesData &&
      // @ts-ignore
      new RectangleLayer({
        id: "producers-scatterplot-layer",
        data: activitiesData?.movementsGeoSummary?.map((p) => {
          return {
            topoSiteId: p.producerId,
            coordinates: [p.producerPoiLng, p.producerPoiLat],
            poiName: p.producerName,
            poiAddress: p.producerAddress,
          };
        }),
        pickable: true,
        opacity: 0.9,
        stroked: true,
        filled: true,
        radiusScale: 3,
        radiusMinPixels: 3,
        radiusMaxPixels: 10,
        lineWidthMinPixels: 1,
        getPosition: (d: any) => d.coordinates,
        getRadius: 20,
        getFillColor: [25, 198, 130],
        getLineColor: [25, 198, 130],
        onHover: (info: any) => setProducerHoverInfo(info),
        onClick: (info: any) => {
          onProducerSiteClick(info.object as ProducerSiteMapDataType);
          zoomInToProducerSite(info.object.coordinates);
        },
      }),

    !is3DSelected &&
      activitiesData &&
      new PathLayer({
        id: "primary-activities-layer",
        data: activitiesData?.movementsGeoSummary?.map<GeoPathType>((p) => {
          return {
            activitiesCount: p.activitiesCount,
            wasteCodes: p.wasteCodes,
            weightInKgs: p.weightInKgs,
            producerName: p.producerName,
            receiverName: p.receiverName,
            path: [
              [p.producerPoiLng, p.producerPoiLat],
              [p.receiverPoiLng, p.receiverPoiLat],
            ],
          };
        }),
        pickable: true,
        widthMinPixels: 2,
        widthMaxPixels: 10,
        getPath: (d: GeoPathType) => d.path,
        getColor: [95, 103, 132, 155],
        // @ts-ignore
        getWidth: (d: GeoPathType) => d.weightInKgs / 10,
        onHover: (info: any) => {
          setHoverActivitiesInfo(info);
        },
      }),

    is3DSelected &&
      activitiesData &&
      new ArcLayer({
        id: "arc-primary-movement-layer",
        data: activitiesData?.movementsGeoSummary?.map<GeoPathType>((p) => {
          return {
            activitiesCount: p.activitiesCount,
            wasteCodes: p.wasteCodes,
            weightInKgs: p.weightInKgs,
            producerName: p.producerName,
            receiverName: p.receiverName,
            path: [
              [p.producerPoiLng, p.producerPoiLat],
              [p.receiverPoiLng, p.receiverPoiLat],
            ],
          };
        }),
        pickable: true,
        getSourcePosition: (d: GeoPathType) => d.path[0],
        getTargetPosition: (d: GeoPathType) => d.path[1],
        getSourceColor: () => [25, 198, 130],
        getTargetColor: () => [62, 102, 251],
        getWidth: 3,
        onHover: (info: any) => setHoverActivitiesInfo(info),
      }),
  ];

  // We navigate/zoom in to the single site view
  // It is an approximate solution for now -
  // TODO - calculate bounding box and take sliding box into account
  const zoomInToProducerSite = (coordinates: [number, number]) => {
    setViewState({
      ...viewState,
      longitude: coordinates[0],
      latitude: coordinates[1],
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 1000,
      zoom: 10,
    });

    setProducerHoverInfo(null);
  };

  const [viewState, setViewState] = useState<any>({
    ...mapInitialLocation,
    pitch: is3DSelected ? 75 : 0,
    zoom: 6,
    transitionInterpolator: new FlyToInterpolator(),
    transitionDuration: 1000,
  });

  useEffect(() => {
    setViewState({
      ...viewState,
      pitch: is3DSelected ? 75 : 0,
      transitionDuration: 800,
      transitionInterpolator: new FlyToInterpolator(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is3DSelected]);

  return (
    <MapContainer>
      <DeckGL
        viewState={viewState}
        controller={true}
        layers={layers}
        onViewStateChange={(evt: any) => setViewState(evt.viewState)}
      >
        <ReactMapGL
          mapStyle={MapStyle.light}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        />
        {producerHoverInfo && producerHoverInfo?.object && (
          <div
            id="producer-hover-info-box"
            style={{
              position: "absolute",
              border: `1px solid ${colors.new.grey[500]}`,
              zIndex: 5,
              left: producerHoverInfo.x + 5,
              top: producerHoverInfo.y + 5,
              padding: 8,
              background: `${colors.new.background_light[500]}`,
              borderRadius: 10,
            }}
          >
            <Flex
              textAlign="left"
              ml={0}
              mb={1}
              style={{ color: colors.new.text.blue[500] }}
              fontWeight="bold"
            >
              <Link to={`/h/pois/${producerHoverInfo?.object?.topoSiteId}`}>
                <Text>
                  <b>{producerHoverInfo?.object?.poiName}</b>
                </Text>
              </Link>
            </Flex>

            <Text>{producerHoverInfo?.object?.poiAddress}</Text>
            <Text>{producerHoverInfo?.object?.id}</Text>
            <Text>Lat: {producerHoverInfo?.object?.coordinates[1]}</Text>
            <Text>Long: {producerHoverInfo?.object?.coordinates[0]}</Text>
          </div>
        )}

        {receiverHoverObject && (
          <div
            id="receiver-hover-info-box"
            style={{
              position: "absolute",
              border: `1px solid ${colors.new.grey[500]}`,
              zIndex: 5,
              left: receiverHoverInfo?.x + 5,
              top: receiverHoverInfo?.y + 5,
              padding: 8,
              background: `${colors.new.background_light[500]}`,
              borderRadius: 10,
            }}
          >
            <Flex
              textAlign="left"
              ml={0}
              mb={1}
              style={{ color: colors.new.text.blue[500] }}
              fontWeight="bold"
            >
              <Link to={`/h/receivers/${receiverHoverObject?.topoSiteId}`}>
                <Text>
                  <b>{receiverHoverObject?.poiName}</b>
                </Text>
              </Link>
            </Flex>

            <Text>{receiverHoverObject?.poiAddress}</Text>
            <Text>{receiverHoverObject?.id}</Text>
            <Text>Lat: {receiverHoverObject?.coordinates[1]}</Text>
            <Text>Long: {receiverHoverObject?.coordinates[0]}</Text>
          </div>
        )}

        {hoverActivitiesInfo && hoverActivitiesInfo?.object && (
          <div
            style={{
              position: "absolute",
              border: `1px solid ${colors.new.grey[500]}`,
              zIndex: 1,
              pointerEvents: "none",
              left: hoverActivitiesInfo?.x + 5,
              top: hoverActivitiesInfo?.y + 5,
              padding: 8,
              background: `${colors.new.background_light[500]}`,
              borderRadius: 10,
            }}
          >
            <Text>
              <b>From: </b>
              {hoverActivitiesInfo?.object?.producerName}
            </Text>
            <Text>
              <b>To: </b>
              {hoverActivitiesInfo?.object?.receiverName}
            </Text>
            <Text>{hoverActivitiesInfo?.object?.poiAddress}</Text>
            <Text>{hoverActivitiesInfo?.object?.id}</Text>
            <Text>
              Activities count: {hoverActivitiesInfo?.object?.activitiesCount}
            </Text>
            <Text>Waste codes: {hoverActivitiesInfo?.object?.wasteCodes}</Text>
            <Text>
              Total weight: {hoverActivitiesInfo?.object?.weightInKgs / 1000}t
            </Text>
          </div>
        )}
      </DeckGL>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  position: relative;
  height: 100%;
`;

export default memo(SitesMap);
