import {
  Box,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { ICurrentUser, Role } from "../../../../graphql/queries/getCurrentUser";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { FieldValues, useForm } from "react-hook-form";
import { useRef, useState } from "react";
import DangerButton from "../../../../ui/DangerButton";
import TrText from "../../../../i18n/TrText";

interface IAddUserProps {
  currentUser: ICurrentUser | undefined;
  onCreateInvitation: (
    email: string,
    name: string,
    roles: Role[]
  ) => Promise<void>;
}

const AddUser = ({ onCreateInvitation, currentUser }: IAddUserProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [stashedEmail, setStashedEmail] = useState("");
  const [stashedName, setStashedName] = useState("");
  const [stashedRoles, setStashedRoles] = useState<Role[]>([]);
  const cancelRef = useRef(null);

  const onSubmit = async (values: FieldValues) => {
    if (!currentUser) {
      return;
    }
    // check to see if the invitee email address domain matches the current users email domain
    const email = values.email;
    const emailDomain = email.split("@")[1];
    const currentUserEmailDomain = currentUser.email.split("@")[1];
    if (emailDomain !== currentUserEmailDomain) {
      setStashedEmail(email);
      setStashedName(values.name);
      setStashedRoles([...values.roles]);
      onOpen();
    } else await onCreateInvitation(values.email, values.name, values.roles);
  };

  const doInviteAnyway = async () => {
    setIsLoading(true);
    await onCreateInvitation(stashedEmail, stashedName, stashedRoles);
    onClose();
    setIsLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={4}>
          <FormControl isRequired isInvalid={errors.name ? true : false}>
            <FormLabel htmlFor="inviteeName">
              <TrText message="table_headings.name" />:
            </FormLabel>
            <Input
              id="inviteeName"
              placeholder="Enter the name of the user"
              {...register("name", { required: "Required" })}
            />
            {errors?.name?.message && (
              <FormErrorMessage>
                <TrText message="add_user.name_placeholder" />
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            mt={4}
            isRequired
            isInvalid={errors.email ? true : false}
          >
            <FormLabel htmlFor="inviteeEmail">
              <TrText message="table_headings.email" />:
            </FormLabel>
            <Input
              type="email"
              id="inviteeEmail"
              placeholder="Enter the email address of the user"
              {...register("email", { required: "Required" })}
            />
            <FormErrorMessage>
              <TrText message="add_user.email_required" />
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={errors.roles ? true : false}>
            <FormLabel htmlFor="inviteeRoles">
              <TrText message="table_headings.roles" />:
            </FormLabel>
            <Box display="flex" justifyContent="center">
              <Checkbox
                id="inviteeRoles"
                {...register("roles", {
                  validate: (value) => value.length > 0,
                })}
                value={"admin"}
              >
                <TrText message="roles.admin" />
              </Checkbox>
              <Checkbox
                ml={4}
                id="inviteeRoles"
                {...register("roles", {
                  validate: (value) => value.length > 0,
                })}
                value={"member"}
                defaultChecked
              >
                <TrText message="roles.member" />
              </Checkbox>
              <Checkbox
                ml={4}
                id="inviteeRoles"
                {...register("roles", {
                  validate: (value) => value.length > 0,
                })}
                value={"uploader"}
              >
                <TrText message="roles.uploader" />
              </Checkbox>
            </Box>
            <FormErrorMessage>
              {errors.roles && <TrText message="add_user.roles_required" />}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box p={4} display="flex" flexDirection="row" justifyContent="flex-end">
          <ButtonPrimary isLoading={isSubmitting} type="submit">
            <TrText message="add_user.send_invite" />
          </ButtonPrimary>
        </Box>
      </form>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <TrText message="app.warning" />
            </AlertDialogHeader>

            <AlertDialogBody>
              <TrText message="add_user.outside_email" />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                disabled={isLoading}
                ref={cancelRef}
                onClick={onClose}
                mr={3}
              >
                <TrText message="app.cancel" />
              </Button>
              <DangerButton isLoading={isLoading} onClick={doInviteAnyway}>
                <TrText message="add_user.send_invite" />
              </DangerButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddUser;
