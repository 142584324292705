import dayjs from "dayjs";
/**
 * Returns date in "1 Apr 2020" format for displaying in the UI
 * */
export const formatDateDMMMYYYY = (date: string): string => {
  if (typeof date === "string" && dayjs(date).isValid()) {
    return dayjs(date).format("D MMM YYYY");
  } else {
    return "";
  }
};
