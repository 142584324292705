import { gql } from "@apollo/client";
import { IFilters } from "../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

export const RECEIVING_SITES_SUMMARY_QUERY = gql`
  query GetReceivingSitesSummary(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $carrierName: String
    $poiIds: [String!]
    $receiverPoiIds: [String!]
    $supplierIds: [String!]
    $receiverTopoPoiId: String
    $poiGroupIds: [String!]
    $producerAddress: String
    $wasteCodes: [String!]
    $hasIssues: Boolean
    $orderBy: String
    $orderDirection: String
    $includeActualWeight: Boolean
    $includeAssumedWeight: Boolean
    $includeEstimatedWeight: Boolean
  ) {
    receiverSitesSummary(
      first: $first
      last: $last
      after: $after
      before: $before
      startDate: $startDate
      endDate: $endDate
      wasteCodes: $wasteCodes
      hasIssues: $hasIssues
      carrierName: $carrierName
      producerAddress: $producerAddress
      poiIds: $poiIds
      receiverPoiIds: $receiverPoiIds
      supplierIds: $supplierIds
      receiverTopoPoiId: $receiverTopoPoiId
      poiGroupIds: $poiGroupIds
      orderBy: $orderBy
      orderDirection: $orderDirection
      includeActualWeight: $includeActualWeight
      includeAssumedWeight: $includeAssumedWeight
      includeEstimatedWeight: $includeEstimatedWeight
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          topoSiteId
          operatorName
          name
          address
          permit
          totalWeight
          landfillWeight
          recoveryWeight
          unknownWeight
          primaryDistanceKms
          primaryDistanceMiles
          primaryCo2Kgs
          receiverValidationErrors
          carrierValidationErrors
          movementCount
          divertedFromLandfillWeight
          primaryCo2Kgs
          receiverValidationErrors
          carrierValidationErrors
          activityCount
          landfillFatePercentage
          unknownFatePercentage
          landfillDiversionFatePercentage
          validationPercentage
          distancePerMovementKms
          distancePerMovementMiles
          primaryCo2PerMovementKgs
        }
      }
    }
  }
`;

export interface IReceivingSitesSummary {
  id: string;
  topoSiteId: string;
  operatorName: string;
  name: string;
  address: string;
  permit: string;
  totalWeight: number;
  landfillWeight: number;
  divertedFromLandfillWeight: number;
  recoveryWeight: number;
  unknownWeight: number;
  primaryDistanceKms: number;
  primaryDistanceMiles: number;
  primaryCo2Kgs: number;
  receiverValidationErrors: number;
  carrierValidationErrors: number;
  movementCount: number;
  activityCount: number;
  landfillFatePercentage: number;
  unknownFatePercentage: number;
  landfillDiversionFatePercentage: number;
  validationPercentage: number;
  distancePerMovementKms: number;
  distancePerMovementMiles: number;
  primaryCo2PerMovementKgs: number;
}

export interface IReceivingSitesSummaryResult {
  receiverSitesSummary: {
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      node: IReceivingSitesSummary;
    }>;
  };
}

export type IReceivingSitesSummaryParams = Omit<
  IFilters,
  "startDate" | "endDate"
> & {
  first: number;
  after: string | null;
  orderBy: string;
  orderDirection: string;
};
