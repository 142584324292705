import { useState } from "react";
import { Stack, Input, Box } from "@chakra-ui/react";

import { ValueChangeType } from "./../SimpleFilter";
import Checkbox from "./Checkbox";

interface IPoiValues {
  id: string;
  address: string;
  name: string;
}

interface ISelectionListProps {
  uniquePois: IPoiValues[];
  onValueChange: ({ id, isChecked }: ValueChangeType) => void;
  poisValues: string[];
}

const SitesSelectionList = ({
  uniquePois,
  poisValues,
  onValueChange,
}: ISelectionListProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const allValuesChecked = poisValues.length === uniquePois.length;

  const isIndeterminate = poisValues.length > 0 && !allValuesChecked;

  return (
    <Stack direction="column" px={1} mt={0}>
      <div
        style={{
          position: "absolute",
          background: "#fff",
          width: "88%",
          zIndex: 2,
          marginTop: "-8px",
        }}
      >
        <Box py={2} borderBottom={"1px dashed gray"}>
          <Checkbox
            key="all"
            isChecked={allValuesChecked}
            isIndeterminate={isIndeterminate}
            name={"Select all sites"}
            onChange={() => {
              onValueChange({
                id: "all",
                isChecked: allValuesChecked,
              });
            }}
          />
        </Box>

        <Box py={2}>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search site by name or address"
          />
        </Box>
      </div>

      <div style={{ marginTop: 100 }}>
        {uniquePois &&
          uniquePois
            .filter(
              (poi) =>
                poi.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                poi.address.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((poi) => {
              return (
                <Checkbox
                  key={poi.id}
                  id={poi.id}
                  isChecked={poisValues.includes(poi.id)}
                  name={poi.name}
                  label={poi.address}
                  onChange={({ id, isChecked }) => {
                    onValueChange({
                      id,
                      isChecked,
                    });
                  }}
                />
              );
            })}
      </div>
    </Stack>
  );
};

export default SitesSelectionList;
