import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import { colors } from "../../ui/theme";

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const CardHeader = (props: Props) => {
  const { children, style } = props;

  return (
    <Container p={4} style={{ ...style }}>
      {children}
    </Container>
  );
};

const Container = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.new.grey[500]};
`;

export default CardHeader;
