import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex, Box, Accordion } from "@chakra-ui/react";

import SortingIcon from "./../../../../components/SortingIcon";
import { RespondType } from "./../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "./../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import InputBox from "./components/InputBox";
import {
  CARRIERS_SUMMARY_QUERY,
  ICarriersSummaryResult,
  ICarriersSummary,
  ICarriersSummaryParams,
} from "../../../../graphql/queries/getCarriersSummary";
import { SiteRow } from "./components/SiteRow";
import { colors } from "../../../../ui/theme";
import LoadingData from "../../../../components/LoadingData";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

const isColumnAllowedForSorting = (column: string) => {
  return ["movement_count", "total_weight", "activity_count"].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Carriers = () => {
  const filters = useFiltersFromQueryParams();

  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("activity_count");

  // Validation
  const [validationValue, setValidationValue] = useState<number | undefined>(
    undefined
  );
  const [
    validationValueThresholdSelected,
    setValidationValueThresholdSelected,
  ] = useState<number | undefined>(undefined);

  // Landfill
  const [landfillValue, setLandfillValue] = useState<number | undefined>(
    undefined
  );
  const [landfillValueThresholdSelected, setLandfillValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // Recovery/Diverted
  const [divertedValue, setDivertedValue] = useState<number | undefined>(
    undefined
  );
  const [divertedValueThresholdSelected, setDivertedValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // Unknown
  const [unknownValue, setUnknownValue] = useState<number | undefined>(
    undefined
  );
  const [unknownValueThresholdSelected, setUnknownValueThresholdSelected] =
    useState<number | undefined>(undefined);

  // distance per movement
  const [distancePerMovementValue, setDistancePerMovementValue] = useState<
    number | undefined
  >(undefined);
  const [
    distancePerMovementThresholdSelected,
    setDistancePerMovementThresholdSelected,
  ] = useState<number | undefined>(undefined);

  // CO2 per movement
  const [co2PerMovementValue, setCo2PerMovementValue] = useState<
    number | undefined
  >(undefined);
  const [co2PerMovementThresholdSelected, setCo2PerMovementThresholdSelected] =
    useState<number | undefined>(undefined);

  const { data: currentUserResult, loading: loadingUser } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const {
    data: carriersSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<ICarriersSummaryResult, ICarriersSummaryParams>(
    CARRIERS_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "activity_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const totalCount = carriersSummaryData?.carriersSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const loading = loadingData || loadingUser;

  const carriers: ICarriersSummary[] =
    carriersSummaryData === undefined
      ? []
      : carriersSummaryData.carriersSummary.edges.map((edge) => edge.node);

  const validationValueChange = (value: number) => setValidationValue(value);

  const landfillValueChange = (value: number) => setLandfillValue(value);

  const divertedValueChange = (value: number) => setDivertedValue(value);

  const unknownValueChange = (value: number) => setUnknownValue(value);

  const distancePerMovementonChange = (value: number) =>
    setDistancePerMovementValue(value);

  const co2PerMovementonChange = (value: number) =>
    setCo2PerMovementValue(value);

  const updateWithNewSelections = () => {
    setValidationValueThresholdSelected(validationValue);
    setLandfillValueThresholdSelected(landfillValue);
    setDivertedValueThresholdSelected(divertedValue);
    setUnknownValueThresholdSelected(unknownValue);
    setDistancePerMovementThresholdSelected(distancePerMovementValue);
    setCo2PerMovementThresholdSelected(co2PerMovementValue);
  };

  const isApplyButtonDisabled = () => {
    if (
      validationValueThresholdSelected === validationValue &&
      distancePerMovementThresholdSelected === distancePerMovementValue &&
      co2PerMovementThresholdSelected === co2PerMovementValue &&
      landfillValueThresholdSelected === landfillValue &&
      divertedValueThresholdSelected === divertedValue &&
      unknownValueThresholdSelected === unknownValue
    ) {
      return true;
    } else return false;
  };

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage || loading;

  const isNextPageDisabled =
    !carriersSummaryData?.carriersSummary?.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: carriersSummaryData?.carriersSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: carriersSummaryData?.carriersSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (
      carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage]);

  return (
    <>
      <Flex direction="column" w="100%" mb={8}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          px={4}
          mb={8}
        >
          <Flex w="40%" h={74} alignItems="center">
            <H1>
              <TrText message="carriers_comparison.title" />
            </H1>
          </Flex>

          {carriersSummaryData && totalPages > 0 && (
            <PaginationNavigation
              id="compare-carriers"
              previousButtonName="previous page"
              nextButtonName="next page"
              onChange={onRowSelectChange}
              currentPageCounter={currentPageCounter}
              totalPages={totalPages}
              isPreviousPageDisabled={isPreviousPageDisabled}
              isNextPageDisabled={isNextPageDisabled}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
              isLoading={loading}
            />
          )}
        </Flex>

        <Flex
          px={4}
          py={4}
          mb={4}
          bg={colors.new.background_dark[500]}
          color={colors.new.text.light[500]}
          borderRadius={10}
        >
          <Flex w="28%">
            <ButtonPrimary
              onClick={updateWithNewSelections}
              isDisabled={isApplyButtonDisabled()}
            >
              <TrText message="app.apply_changes" />
            </ButtonPrimary>
          </Flex>

          <Box textAlign="center" w="9%" />

          <Box textAlign="center" w="9%" />

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={validationValue}
              onChange={validationValueChange}
              prefix=">"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={landfillValue}
              onChange={landfillValueChange}
              prefix="<"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={divertedValue}
              onChange={divertedValueChange}
              prefix=">"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={unknownValue}
              onChange={unknownValueChange}
              prefix="<"
              suffix="%"
              min={0}
              max={100}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={distancePerMovementValue}
              onChange={distancePerMovementonChange}
              prefix="<"
              min={0}
            />
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            justifyContent="center"
            alignItems="center"
          >
            <InputBox
              value={co2PerMovementValue}
              onChange={co2PerMovementonChange}
              prefix="<"
              min={0}
              step={0.01}
            />
          </Flex>
        </Flex>

        <Flex px={4} mb={4}>
          <Flex w="28%" justifyContent="flex-start" alignItems="center">
            <Text>
              <TrText message="table_headings.carrier_name" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="carrier_name"
                ariaLabel="sort by carrier name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="carrier_name"
                ariaLabel="sort by carrier name descending"
              />
            </SortingBox>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.activities" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="activity_count"
                ariaLabel="sort by activity count ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="activity_count"
                ariaLabel="sort by activity count descending"
              />
            </SortingBox>
          </Flex>
          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.weight" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight descending"
              />
            </SortingBox>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.validation" />
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.landfill" />
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.landfill_diversion" />
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.unknown" />
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.distance_per_movement" />
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            w="9%"
          >
            <Text>
              CO<sub>2</sub>/<TrText message="table_headings.movement" />
            </Text>
          </Flex>
        </Flex>

        <Card>
          {loading ? (
            <LoadingData />
          ) : (
            <Accordion allowToggle>
              {carriers.map((carrier) => {
                return (
                  <SiteRow
                    carrier={carrier}
                    currentUser={currentUserResult!.currentUser}
                    key={`${carrier.name}-${carrier.totalWeight}`}
                    validationValueThresholdSelected={
                      validationValueThresholdSelected === validationValue
                        ? validationValueThresholdSelected
                        : undefined
                    }
                    landfillValueThresholdSelected={
                      landfillValueThresholdSelected === landfillValue
                        ? landfillValueThresholdSelected
                        : undefined
                    }
                    divertedValueThresholdSelected={
                      divertedValueThresholdSelected === divertedValue
                        ? divertedValueThresholdSelected
                        : undefined
                    }
                    unknownValueThresholdSelected={
                      unknownValueThresholdSelected === unknownValue
                        ? unknownValueThresholdSelected
                        : undefined
                    }
                    distancePerMovementThresholdSelected={
                      distancePerMovementThresholdSelected ===
                      distancePerMovementValue
                        ? distancePerMovementThresholdSelected
                        : undefined
                    }
                    co2PerMovementThresholdSelected={
                      co2PerMovementThresholdSelected === co2PerMovementValue
                        ? co2PerMovementThresholdSelected
                        : undefined
                    }
                  />
                );
              })}
            </Accordion>
          )}
        </Card>
      </Flex>
    </>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Carriers);
