import { Dispatch, SetStateAction } from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { MdArrowBack } from "react-icons/md";

import GhostButton from "../../../../ui/GhostButton";
import Text from "../../../../ui/Text";
import Tile from "../../../../ui/Tile";
import { formatToDecimalPlaces } from "../../../../aux/numbers";
import TotalMaterialWeight from "./components/TotalMaterialWeight";
import TotalPrimaryDistance from "./components/TotalPrimaryDistance";
import TotalPrimaryTransportCO2 from "./components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "./../../../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "./../../../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "./../../../../components/TotalUnknownMaterial";
import H1 from "../../../../ui/H1";
import { colors } from "../../../../ui/theme";
import TrText from "../../../../i18n/TrText";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import { ISuppliersSummary } from "../../../../graphql/queries/getSuppliersSummary";

type Props = {
  supplierSelected: ISuppliersSummary;
  setSupplierSelected: Dispatch<SetStateAction<ISuppliersSummary | null>>;
};

const marginValue = 4;

export const SingleSupplierView = ({
  setSupplierSelected,
  supplierSelected: supplier,
}: Props) => {
  const { data: currentUserResult } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const currentUser = currentUserResult?.currentUser;

  const totalMaterialWeight = supplier?.totalWeight || 0;
  const landfillPercentage = supplier?.landfillFatePercentage || 0;
  const divertedFromLandfillPercentage =
    supplier?.landfillDiversionFatePercentage || 0;
  const unknownPercentage = supplier?.unknownFatePercentage || 0;

  return (
    <Flex mt={4} direction="column">
      <Box mb={4} ml={-5}>
        <GhostButton onClick={() => setSupplierSelected(null)}>
          <Flex direction="row" align="center" justify="flex-start">
            <Icon
              w={6}
              h={6}
              mr={4}
              as={MdArrowBack}
              color={colors.new.text.blue[500]}
            />
            <Text style={{ textDecoration: "underline" }}>
              <TrText message="app.go_back" />
            </Text>
          </Flex>
        </GhostButton>
      </Box>

      <Flex my={4}>
        <H1>{supplier?.supplierName}</H1>
      </Flex>

      <Flex justify="stretch" align="center" w="100%">
        <Box w="25%" mr={marginValue}>
          {supplier && (
            <Tile
              title="overview.validated_card_title"
              iconName="truck_with_tick"
              bottomText={
                <TrText
                  message="overview.in_total_activities"
                  options={{
                    amount: supplier?.movementCount
                      ? supplier.movementCount
                      : "...",
                  }}
                />
              }
              value={
                supplier.movementCount
                  ? `${formatToDecimalPlaces(
                      ((supplier.movementCount -
                        supplier?.receiverValidationErrors) /
                        supplier.movementCount) *
                        100,
                      1
                    )}%`
                  : "No data"
              }
            />
          )}
        </Box>

        <Box w="25%" ml={marginValue} mr={marginValue}>
          {supplier && <TotalMaterialWeight weight={supplier?.totalWeight} />}
        </Box>

        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalPrimaryDistance
            miles={supplier.primaryDistanceMiles}
            kms={supplier.primaryDistanceKms}
            currentUser={currentUser}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalPrimaryTransportCO2 kgs={supplier.primaryCo2Kgs} />
        </Box>
      </Flex>

      <Flex justify="stretch" align="center" w="100%" direction="row" mt="20px">
        <Box w="25%" mr={marginValue}>
          <Tile
            title="overview.movements_card_title"
            bottomText="overview.in_total"
            iconName="just_track"
            value={String(supplier?.movementCount)}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalDivertedFromLandfill
            totalWeight={totalMaterialWeight}
            divertedFromLandfillPercentage={divertedFromLandfillPercentage}
            isLoading={false}
          />
        </Box>
        <Box w="25%" ml={marginValue} mr={marginValue}>
          <TotalMaterialLandfill
            totalWeight={supplier.totalWeight}
            landfillPercentage={landfillPercentage}
            isLoading={false}
          />
        </Box>
        <Box w="25%" ml={marginValue}>
          <TotalUnknownMaterial
            totalWeight={supplier.totalWeight}
            unknownPercentage={unknownPercentage}
            isLoading={false}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
