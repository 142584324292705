import { useState, useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import Select from "../../../ui/Select";
import Card from "../../../ui/Card";
import H2 from "../../../ui/H2";
import Label from "../../../ui/Label";
import { colors } from "../../../ui/theme";
import RecentUploads from "./components/RecentUploads";
import UploadBox from "./components/UploadBox";
import NoLogo from "../../../components/NoLogo";
import CustomerLogo from "./../../../components/CustomerLogo";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../graphql/queries/getCurrentUser";
import { IAccountData } from "../../../graphql/queries/getAvailableUploadAccounts";
import TrText from "../../../i18n/TrText";

const NewUpload = () => {
  // Once the user selects organisation to upload for, we store its id
  const [selectedUploadAccount, setSelectedUploadAccount] = useState<
    IAccountData | null | undefined
  >();
  // All the orgs the user can upload for
  const [potentialUploadAccounts, setPotentialUploadAccounts] = useState<
    IAccountData[] | null
  >(null);

  const { data: userData } = useQuery<ICurrentUserResult>(GET_CURRENT_USER, {
    fetchPolicy: "network-only", // only network, we want up to date data
  });

  // We check for which organisation the user belongs to.
  // Needs further work - can user/member belong to multiple orgs?
  // This is not handled yet
  const userAccountId = userData?.currentUser?.availableUploadAccounts[0]?.id;

  useEffect(() => {
    const potentialUploadAccounts =
      userData?.currentUser.availableUploadAccounts;

    // This sets up all the organisations that the user can upload for
    // We use this to populate selection box
    if (potentialUploadAccounts) {
      setPotentialUploadAccounts(potentialUploadAccounts);
    }
  }, [userData]);

  return (
    <Flex direction={["column", "column", "column", "column", "row"]}>
      <Flex direction="column" mr={4} minWidth={400}>
        <H2>
          <TrText message="upload.title" />
        </H2>

        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={4}
          p={8}
          border={`1px solid ${colors.new.grey[500]}`}
          borderRadius={10}
        >
          <Flex direction="column" mb={4} w="100%">
            <Box mb={2}>
              <Label forHtml="select-organisation">
                <TrText message="upload.select_organisation" />
              </Label>
            </Box>
            <Select
              id="select-organisation"
              placeholder="upload.select_organisation"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedUploadAccount(() => {
                  return potentialUploadAccounts?.filter(
                    (org) => org.id === event.target.value
                  )[0];
                });
              }}
            >
              {potentialUploadAccounts?.map((org) => {
                return (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                );
              })}
            </Select>
          </Flex>

          <Flex
            mb={12}
            minH={240}
            w="100%"
            justifyContent="center"
            alignItems="center"
          >
            {selectedUploadAccount ? (
              <CustomerLogo
                src={selectedUploadAccount.logoUrl}
                alt={selectedUploadAccount.name}
              />
            ) : (
              <NoLogo />
            )}
          </Flex>

          <Card>
            {userData && userAccountId && (
              <UploadBox
                isDisabled={!selectedUploadAccount ? true : false}
                selectedUploadAccountId={selectedUploadAccount?.id || ""}
                uploadingAccountId={userAccountId}
              />
            )}
          </Card>
        </Flex>
      </Flex>

      <Flex direction="column" w="100%">
        <RecentUploads />
      </Flex>
    </Flex>
  );
};

export default NewUpload;
