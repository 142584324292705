import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import Tile from "../../../../../ui/Tile";

const TotalPrimaryTransportCO2 = ({
  kgs,
  isLoading,
}: {
  kgs: number;
  isLoading: boolean;
}) => (
  <Tile
    title="overview.primary_co2_card_title"
    bottomText="overview.in_total"
    iconName="co2"
    popoverText="overview.primary_co2_popover"
    value={
      isLoading
        ? undefined
        : kgs === 0
        ? `0t`
        : `${formatToDecimalPlaces(kgs / 1000, 1)}t`
    }
  />
);

export default TotalPrimaryTransportCO2;
