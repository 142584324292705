import { formatToDecimalPlaces } from "../../aux/numbers";
import Tile from "../../ui/Tile";

const TotalDivertedFromLandfill = ({
  totalWeight,
  isLoading,
  divertedFromLandfillPercentage,
}: {
  totalWeight: number;
  isLoading: boolean;
  divertedFromLandfillPercentage: number;
}) => {
  // Divide by 100, since we receive a number like 65.8% and we want 0.658
  // Divide by 1000 to get tonnes
  const tonnes = formatToDecimalPlaces(
    (totalWeight * Number(divertedFromLandfillPercentage.toFixed(2))) /
      100 /
      1000,
    1
  );

  const calculateValue = ((totalWeight: number, tonnes: string): string => {
    if (totalWeight <= 0) {
      return `0t`;
    }
    if (
      divertedFromLandfillPercentage < 0 ||
      divertedFromLandfillPercentage > 100
    ) {
      return `0t`;
    } else {
      return `${tonnes}t (${divertedFromLandfillPercentage.toFixed(2)}%)`;
    }
  })(totalWeight, tonnes);

  return (
    <Tile
      title="overview.diverted_from_landfill_title"
      bottomText="overview.in_total"
      iconName="recycling_bin"
      popoverText="overview.diverted_from_landfill_popover"
      value={isLoading ? undefined : calculateValue}
    />
  );
};

export default TotalDivertedFromLandfill;
