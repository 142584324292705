import { gql } from "@apollo/client";

import { ICurrentUser } from "../queries/getCurrentUser";

export const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvater($userId: ID!, $avatar: Upload!) {
    updateUserAvatar(input: { userId: $userId, avatar: $avatar }) {
      errors
      user {
        id
        avatarUrl
      }
    }
  }
`;

export interface IUpdateUserAvatarParams {
  userId: string;
  avatar: Blob;
}

export interface IUpdateUserAvatarResponse {
  updateUserAvatar: {
    errors?: string[];
    user: ICurrentUser | null;
  };
}
