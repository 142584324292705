import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";

import SortingIcon from "./../../../../components/SortingIcon";
import {
  OrderByType,
  RespondType,
} from "./../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "./../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import {
  CARRIERS_SUMMARY_QUERY,
  ICarriersSummaryResult,
  ICarriersSummary,
  ICarriersSummaryParams,
} from "../../../../graphql/queries/getCarriersSummary";
import { CarrierRow } from "./components/CarrierRow";
import LoadingData from "../../../../components/LoadingData";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import { SingleCarrierView } from "./SingleCarrierView";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "movement_count",
    "total_weight",
    "activity_count",
    "primary_co2_per_movement_kgs",
    "validation_percentage",
    "landfill_fate_percentage",
    "landfill_diversion_fate_percentage",
    "unknown_fate_percentage",
    "distance_per_movement_kms",
    "distance_per_movement_miles",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Carriers = () => {
  const filters = useFiltersFromQueryParams();
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const distanceUnits = window.localStorage.getItem("distanceUnits") as
    | "miles"
    | "kilometers"
    | undefined;

  const displayDistanceColumnm = () => {
    if (distanceUnits === "kilometers") {
      return "distance_per_movement_kms";
    } else if (distanceUnits === "miles") {
      return "distance_per_movement_miles";
    } else return undefined;
  };

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("activity_count");

  const { data: currentUserResult, loading: loadingUser } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const [carrierSelected, setCarrierSelected] =
    useState<ICarriersSummary | null>(null);

  const exportUrl = getExportURL("carriers", {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy)
        ? orderBy
        : "activity_count",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  });

  const {
    data: carriersSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<ICarriersSummaryResult, ICarriersSummaryParams>(
    CARRIERS_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "activity_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
    }
  );
  const totalCount = carriersSummaryData?.carriersSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const loading = loadingData || loadingUser;

  const carriers: ICarriersSummary[] =
    carriersSummaryData === undefined
      ? []
      : carriersSummaryData.carriersSummary.edges.map((edge) => edge.node);

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage || loading;

  const isNextPageDisabled =
    !carriersSummaryData?.carriersSummary?.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: carriersSummaryData?.carriersSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: carriersSummaryData?.carriersSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (
      carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [carriersSummaryData?.carriersSummary?.pageInfo.hasPreviousPage]);

  return (
    <Flex direction="column" w="100%" p={8} mb={8}>
      {!carrierSelected && (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            px={4}
            mb={8}
          >
            <Flex w="40%" h={74} alignItems="center">
              <H1>
                <TrText message="analytics_carriers.title" />
              </H1>
            </Flex>

            <Flex
              flex="1"
              mr={10}
              h={74}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <ButtonPrimary as="a" href={exportUrl}>
                Export
              </ButtonPrimary>
            </Flex>

            {carriersSummaryData && totalPages > 0 && (
              <PaginationNavigation
                id="compare-carriers"
                previousButtonName="previous page"
                nextButtonName="next page"
                onChange={onRowSelectChange}
                currentPageCounter={currentPageCounter}
                totalPages={totalPages}
                isPreviousPageDisabled={isPreviousPageDisabled}
                isNextPageDisabled={isNextPageDisabled}
                onPreviousPageClick={onPreviousPageClick}
                onNextPageClick={onNextPageClick}
                isLoading={loading}
              />
            )}
          </Flex>

          <Flex px={4} mb={4}>
            <Flex w="28%" justifyContent="flex-start" alignItems="center">
              <Text>
                <TrText message="table_headings.carrier_name" />
              </Text>
              {/* <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="carrier_name"
                  ariaLabel="sort by carrier name ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="carrier_name"
                  ariaLabel="sort by carrier name descending"
                />
              </SortingBox> */}
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="10%">
              <Text>
                <TrText message="table_headings.activities" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="activity_count"
                  ariaLabel="sort by activity count ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="activity_count"
                  ariaLabel="sort by activity count descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="10%">
              <Text>
                <TrText message="table_headings.weight" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.validation" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.landfill" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              w="9%"
            >
              <Text>
                <TrText message="table_headings.landfill_diversion" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.unknown" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.distance_per_movement" />
              </Text>
              {distanceUnits && (
                <SortingBox>
                  <SortingIcon
                    direction="asc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} ascending`}
                  />
                  <SortingIcon
                    direction="desc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} descending`}
                  />
                </SortingBox>
              )}
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                CO<sub>2</sub>/<TrText message="table_headings.movement" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement kg ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement in kg descending"
                />
              </SortingBox>
            </Flex>
          </Flex>
        </>
      )}
      {carrierSelected ? (
        <SingleCarrierView
          carrier={carrierSelected}
          setCarrierSelected={setCarrierSelected}
          currentUser={currentUserResult!.currentUser}
        />
      ) : (
        <Card>
          {loading ? (
            <LoadingData />
          ) : (
            <Flex direction="column">
              {carriers.map((carrier) => {
                return (
                  <CarrierRow
                    carrier={carrier}
                    key={`${carrier.name}-${carrier.totalWeight}`}
                    setCarrierSelected={setCarrierSelected}
                    distanceUnits={distanceUnits}
                  />
                );
              })}
            </Flex>
          )}
        </Card>
      )}
    </Flex>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Carriers);
