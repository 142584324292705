import styled from "@emotion/styled";
import { Flex, Box, Icon } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

import Text from "../../../../../ui/Text";
import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import { IReceivingSitesSummary } from "../../../../../graphql/queries/getReceivingSitesSummary";
import { colors } from "../../../../../ui/theme";
import Link from "../../../../../ui/Link";

type Props = {
  site: IReceivingSitesSummary;
  distanceUnits: "miles" | "kilometers" | undefined;
};

export const ReceiverRow = (props: Props) => {
  const { site, distanceUnits } = props;

  const totalMaterialWeight = site?.totalWeight || 0;

  const validationPctFormatted = `${formatToDecimalPlaces(
    site?.validationPercentage,
    1
  )}%`;

  const landfillPctFormatted = `${formatToDecimalPlaces(
    site?.landfillFatePercentage,
    1
  )}%`;

  const divertedPctFormatted = `${formatToDecimalPlaces(
    site?.landfillDiversionFatePercentage,
    1
  )}%`;

  const unknownPctFormatted = `${formatToDecimalPlaces(
    site?.unknownFatePercentage,
    1
  )}%`;

  const distancePerMovementKmsFormatted = `${formatToDecimalPlaces(
    site?.distancePerMovementKms,
    2
  )} kms`;
  const distancePerMovementMilesFormatted = `${formatToDecimalPlaces(
    site?.distancePerMovementMiles,
    2
  )} miles`;

  const co2PerMovementFormatted = `${formatToDecimalPlaces(
    site?.primaryCo2PerMovementKgs,
    3
  )}kg`;

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      w="100%"
      _notLast={{
        borderBottom: "1px solid #e2e8f0",
      }}
    >
      <Flex w="14%" textAlign="left" alignItems="center" pr={2}>
        <Text>{site.operatorName}</Text>
      </Flex>
      <Flex
        w="14%"
        textAlign="left"
        justifyContent="space-between"
        alignItems="center"
      >
        {site?.topoSiteId && site?.topoSiteId !== "Unknown" && (
          <Flex fontWeight="bold" style={{ color: colors.new.text.blue[500] }}>
            <Link to={`/h/receivers/${site?.topoSiteId}`}>
              <Text>{site.name}</Text>
            </Link>
          </Flex>
        )}

        {site?.topoSiteId && site?.topoSiteId !== "Unknown" && (
          <Box ml={2}>
            <Icon
              w={5}
              h={5}
              as={FaCheckCircle}
              color={colors.new.green[500]}
            />
          </Box>
        )}
      </Flex>

      <Cell w="9%">
        <Text>{site.movementCount}</Text>
      </Cell>

      <Cell w="9%">
        <Text>
          {totalMaterialWeight === 0
            ? `0kg`
            : `${formatToDecimalPlaces(totalMaterialWeight / 1000, 3)}t`}
        </Text>
      </Cell>

      {/* Validation */}
      <Cell w="9%">
        <Text>{validationPctFormatted}</Text>
      </Cell>

      {/* Landfill */}
      <Cell w="9%">
        <Text>{landfillPctFormatted}</Text>
      </Cell>

      {/* Diverted */}
      <Cell w="9%">
        <Text>{divertedPctFormatted}</Text>
      </Cell>

      {/* Unknown */}
      <Cell w="9%">
        <Text>{unknownPctFormatted}</Text>
      </Cell>

      {/* DistancePerMovement */}
      <Cell w="9%">
        <Text>
          {distanceUnits === "miles"
            ? distancePerMovementMilesFormatted
            : distancePerMovementKmsFormatted}
        </Text>
      </Cell>

      {/* CO2 per movement */}
      <Cell w="9%">
        <Text>{co2PerMovementFormatted}</Text>
      </Cell>
    </Flex>
  );
};

const Cell = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 32px;
`;
