import mapboxgl, { Map } from "mapbox-gl";

/**
 * Hardcoding the Mapbox key here for now, since it's baked into the frontend JS anyway.
 */

mapboxgl.accessToken =
  "pk.eyJ1IjoiY29kYm9kIiwiYSI6ImNrNTZtYzBiNzBoZGczZW4xcXQ5emhmNTEifQ.Ij2lPbn4xF1eeAxXhON0Hg";

const isMapboxSupported = mapboxgl.supported();

export { mapboxgl, Map, isMapboxSupported };
