import _ from "lodash";

/**
 * Check if the two arrays values are all the same
 * @param {string[]} arrayParams - The list of ids in the URL
 * @param {string[]} arrayValues - The list of ids selected in the filter
 */

function areTwoArraysEqual(
  arrayParams: (string | null)[],
  arrayValues: string[]
) {
  const diff = _.differenceWith(arrayParams, arrayValues, _.isEqual);

  // If any is not an array then not equal
  if (!Array.isArray(arrayParams) || !Array.isArray(arrayValues)) {
    return false;
    // If their lengths are different then not equal
  } else if (arrayParams.length !== arrayValues.length) {
    return false;
  } else if (diff.length === 0) {
    return true;
  } else {
    return false;
  }
}

export default areTwoArraysEqual;
