import {
  ArrowUpIcon,
  CheckIcon,
  InfoIcon,
  RepeatIcon,
  WarningIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { Flex, Spinner, useToast } from "@chakra-ui/react";

import {
  IMovementUpload,
  MOVEMENTS_UPLOADS_QUERY,
  SINGLE_MOVEMENTS_UPLOAD_QUERY,
  UploadStatusType,
} from "../../../graphql/queries/getMovementsUploads";
import Text from "../../../ui/Text";
import DangerButton from "../../../ui/DangerButton";
import { useMutation } from "@apollo/client";
import {
  IUpdateUploadDeletedParams,
  IUpdateUploadResponse,
  UPDATE_UPLOAD_DELETED,
} from "../../../graphql/mutations/updateUpload";
import { useState } from "react";

const statusToIcon: { [key in UploadStatusType]: React.ReactNode } = {
  waiting_for_upload: <ArrowUpIcon h={6} w={6} color="green.500" mr={4} />,
  queued_for_processing: <InfoIcon h={6} w={6} color="green.500" mr={4} />,
  processing: <RepeatIcon h={6} w={6} color="green.500" mr={4} />,
  failed: <WarningIcon h={6} w={6} color="red.500" mr={4} />,
  completed: <CheckIcon h={6} w={6} color="green.500" mr={4} />,
  completed_with_warnings: <CheckIcon h={6} w={6} color="green.500" mr={4} />,
};

const ActionButton = ({ upload }: { upload: IMovementUpload }) => {
  const toast = useToast();
  const [updateUpload] = useMutation<
    IUpdateUploadResponse,
    IUpdateUploadDeletedParams
  >(UPDATE_UPLOAD_DELETED, {
    refetchQueries: [
      { query: MOVEMENTS_UPLOADS_QUERY },
      { query: SINGLE_MOVEMENTS_UPLOAD_QUERY, variables: { id: upload.id } },
    ],
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const setDeleted = async (upload: IMovementUpload, deleted: boolean) => {
    setIsUpdating(true);
    await updateUpload({
      variables: {
        id: upload.id,
        deleted,
      },
    });
    setIsUpdating(false);
    if (deleted) {
      // is it a permanent delete?
      if (upload.status === "failed") {
        toast({
          title: `${upload.originalFilename} has been removed`,
        });
      } else {
        toast({
          title: `${upload.originalFilename} has been removed`,
          description: `The movement data in ${upload.originalFilename} has been marked as deleted. You can undo this action by clicking the restore button.`,
        });
      }
    } else {
      toast({
        title: `${upload.originalFilename} has been restored`,
        description: `The movement data in ${upload.originalFilename} has been restored.`,
      });
    }
  };

  if (
    ["failed", "completed", "completed_with_warnings"].includes(upload.status)
  ) {
    if (upload.deleted) {
      return (
        <DangerButton
          onClick={() => setDeleted(upload, false)}
          isDisabled={isUpdating}
          leftIcon={isUpdating ? <Spinner /> : <RepeatIcon />}
        >
          Restore
        </DangerButton>
      );
    } else {
      return (
        <DangerButton
          onClick={() => setDeleted(upload, true)}
          isDisabled={isUpdating}
          leftIcon={isUpdating ? <Spinner /> : <DeleteIcon />}
        >
          Delete file
        </DangerButton>
      );
    }
  }
  return null;
};

interface IErrorSummaryProps {
  heading: string;
  messages: string[];
  upload: IMovementUpload;
}

const ErrorSummary = ({ heading, messages, upload }: IErrorSummaryProps) => (
  <Flex w="100%" justifyContent="space-between" alignItems="center" mb={8}>
    <Flex
      bg={upload.status === "completed" ? "#f0f5ef" : "#f7eff1"}
      borderRadius="5px"
      w="100%"
      mr={upload.status === "completed" ? 8 : 0}
    >
      <Flex p={4} direction="row" alignItems="center">
        {statusToIcon[upload.status]}
        <Flex direction="column" flexGrow={1}>
          <Text>{heading}</Text>
          {upload.status !== "completed" &&
            messages.map((message, index) => (
              <Text key={index}>{message}</Text>
            ))}
        </Flex>
      </Flex>
    </Flex>
    {upload.permissions.canDelete && <ActionButton upload={upload} />}
  </Flex>
);

export default ErrorSummary;
