import NoLogo from "../NoLogo";

type Props = {
  src?: string;
  alt: string;
  width?: number;
};

const CustomerLogo = (props: Props) => {
  const { src, alt, width } = props;
  const actualWidth = width || 200;
  if (src) {
    return <img width={actualWidth} src={src} alt={alt} />;
  }
  return <NoLogo />;
};

export default CustomerLogo;
