import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";

import SortingIcon from "../../../../components/SortingIcon";
import {
  OrderByType,
  RespondType,
} from "../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import { SupplierRow } from "./components/SupplierRow";
import LoadingData from "../../../../components/LoadingData";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import {
  SUPPLIERS_SUMMARY_QUERY,
  ISuppliersSummaryParams,
  ISuppliersSummaryResult,
  ISuppliersSummary,
} from "../../../../graphql/queries/getSuppliersSummary";
import { SingleSupplierView } from "./SingleSupplierView";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "movement_count",
    "total_weight",
    "supplier_name",
    "primary_co2_per_movement_kgs",
    "validation_percentage",
    "landfill_fate_percentage",
    "landfill_diversion_fate_percentage",
    "unknown_fate_percentage",
    "distance_per_movement_kms",
    "distance_per_movement_miles",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Suppliers = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const distanceUnits = window.localStorage.getItem("distanceUnits") as
    | "miles"
    | "kilometers"
    | undefined;

  const displayDistanceColumnm = () => {
    if (distanceUnits === "kilometers") {
      return "distance_per_movement_kms";
    } else if (distanceUnits === "miles") {
      return "distance_per_movement_miles";
    } else return undefined;
  };

  const filters = useFiltersFromQueryParams();

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("movement_count");

  const exportUrl = getExportURL("suppliers", {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy)
        ? orderBy
        : "movement_count",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  });

  const {
    data: supliersSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<ISuppliersSummaryResult, ISuppliersSummaryParams>(
    SUPPLIERS_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "movement_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const totalCount = supliersSummaryData?.suppliersSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const loading = loadingData;

  const suppliers: ISuppliersSummary[] =
    supliersSummaryData === undefined
      ? []
      : supliersSummaryData.suppliersSummary.edges.map((edge) => edge.node);

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !supliersSummaryData?.suppliersSummary?.pageInfo.hasPreviousPage || loading;

  const isNextPageDisabled =
    !supliersSummaryData?.suppliersSummary?.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: supliersSummaryData?.suppliersSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: supliersSummaryData?.suppliersSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  const [supplierSelected, setSupplierSelected] =
    useState<ISuppliersSummary | null>(null);

  useEffect(() => {
    if (
      supliersSummaryData?.suppliersSummary?.pageInfo.hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [supliersSummaryData?.suppliersSummary?.pageInfo.hasPreviousPage]);

  return (
    <>
      <Flex direction="column" w="100%" mb={8} p={8} pt={8}>
        {!supplierSelected && (
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            px={4}
            mb={8}
          >
            <Flex w="40%" h={74} alignItems="center">
              <H1>
                <TrText message="analytics_suppliers.title" />
              </H1>
            </Flex>

            <Flex
              flex="1"
              mr={10}
              h={74}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <ButtonPrimary as="a" href={exportUrl}>
                Export
              </ButtonPrimary>
            </Flex>

            {supliersSummaryData && totalPages > 0 && (
              <PaginationNavigation
                id="compare-receivers"
                isLoading={loading}
                previousButtonName="previous page"
                nextButtonName="next page"
                onChange={onRowSelectChange}
                currentPageCounter={currentPageCounter}
                totalPages={totalPages}
                isPreviousPageDisabled={isPreviousPageDisabled}
                isNextPageDisabled={isNextPageDisabled}
                onPreviousPageClick={onPreviousPageClick}
                onNextPageClick={onNextPageClick}
              />
            )}
          </Flex>
        )}
        {!supplierSelected && (
          <Flex px={4} mb={4}>
            <Flex w="28%" alignItems="center" justifyContent="flex-start">
              <Text>
                <TrText message="table_headings.supplier" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="supplier_name"
                  ariaLabel="sort by supplier name ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="supplier_name"
                  ariaLabel="sort by supplier name descending"
                />
              </SortingBox>
            </Flex>

            <Flex
              textAlign="center"
              w="9%"
              alignItems="center"
              justifyContent="center"
            >
              <Text>
                <TrText message="table_headings.activities" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="movement_count"
                  ariaLabel="sort by movement count ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="movement_count"
                  ariaLabel="sort by movement count descending"
                />
              </SortingBox>
            </Flex>
            <Flex
              textAlign="center"
              w="9%"
              alignItems="center"
              justifyContent="center"
            >
              <Text>
                <TrText message="table_headings.weight" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="total_weight"
                  ariaLabel="sort by total weight descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.validation" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="validation_percentage"
                  ariaLabel="sort by validation percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.landfill" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_fate_percentage"
                  ariaLabel="sort by landfill percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              w="9%"
            >
              <Text>
                <TrText message="table_headings.landfill_diversion" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="landfill_diversion_fate_percentage"
                  ariaLabel="sort by landfill_diversion_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.unknown" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="unknown_fate_percentage"
                  ariaLabel="sort by unknown_fate_percentage descending"
                />
              </SortingBox>
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                <TrText message="table_headings.distance_per_movement" />
              </Text>
              {distanceUnits && (
                <SortingBox>
                  <SortingIcon
                    direction="asc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} ascending`}
                  />
                  <SortingIcon
                    direction="desc"
                    onClick={handleSortingSelection}
                    column={displayDistanceColumnm() as OrderByType}
                    ariaLabel={`sort by ${displayDistanceColumnm()} descending`}
                  />
                </SortingBox>
              )}
            </Flex>

            <Flex justifyContent="center" alignItems="center" w="9%">
              <Text>
                CO<sub>2</sub>/<TrText message="table_headings.movement" />
              </Text>
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement kg ascending"
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column="primary_co2_per_movement_kgs"
                  ariaLabel="sort by primary co2 per movement in kg descending"
                />
              </SortingBox>
            </Flex>
          </Flex>
        )}

        {supplierSelected ? (
          <SingleSupplierView
            supplierSelected={supplierSelected}
            setSupplierSelected={setSupplierSelected}
          />
        ) : (
          <Card>
            {loadingData ? (
              <LoadingData />
            ) : (
              <Flex direction="column">
                {suppliers.map((supplier) => {
                  return (
                    <SupplierRow
                      supplier={supplier}
                      setSupplierSelected={setSupplierSelected}
                      key={supplier.id}
                      distanceUnits={distanceUnits}
                    />
                  );
                })}
              </Flex>
            )}
          </Card>
        )}
      </Flex>
    </>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Suppliers);
