import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { Flex } from "@chakra-ui/react";

import SortingIcon from "./../../../../components/SortingIcon";
import {
  OrderByType,
  RespondType,
} from "./../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "./../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import {
  PRODUCER_SITES_SUMMARY_QUERY,
  IProducerSitesSummaryResult,
  IProducerSitesSummary,
  IProducerSitesSummaryParams,
} from "../../../../graphql/queries/getProducerSitesSummary";
import { SiteRow } from "./components/SiteRow";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";
import LoadingData from "../../../../components/LoadingData";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "total_weight",
    "movement_count",
    "activity_count",
    "poi_name",
    "poi_address",
    "landfill_diversion_fate_percentage",
    "unknown_fate_percentage",
    "landfill_fate_percentage",
    "validation_percentage",
    "primary_co2_per_movement_kgs",
    "distance_per_movement_kms",
    "distance_per_movement_miles",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Sites = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const distanceUnits = window.localStorage.getItem("distanceUnits") as
    | "miles"
    | "kilometers"
    | undefined;

  const displayDistanceColumnm = () => {
    if (distanceUnits === "kilometers") {
      return "distance_per_movement_kms";
    } else if (distanceUnits === "miles") {
      return "distance_per_movement_miles";
    } else return undefined;
  };

  const filters = useFiltersFromQueryParams();

  const [orderDirection, setOrderDirection] = useQueryParam(
    "orderDirection",
    StringParam
  );
  const [orderBy, setOrderBy] = useQueryParam("orderBy", StringParam);

  const sitesFilter = {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy) ? orderBy : "total_weight",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  };

  const {
    data: sitesSummaryData,
    loading,
    fetchMore,
  } = useQuery<IProducerSitesSummaryResult, IProducerSitesSummaryParams>(
    PRODUCER_SITES_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...sitesFilter,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const exportUrl = getExportURL("sites", sitesFilter);

  const sites: IProducerSitesSummary[] =
    sitesSummaryData === undefined
      ? []
      : sitesSummaryData.producerSitesSummary.edges.map((edge) => edge.node);

  const handleSortingSelection = (data: RespondType) => {
    setOrderBy(data.orderBy);
    setOrderDirection(data.orderDirection);
  };
  const totalCount = sitesSummaryData?.producerSitesSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !sitesSummaryData?.producerSitesSummary?.pageInfo.hasPreviousPage ||
    loading;

  const isNextPageDisabled =
    !sitesSummaryData?.producerSitesSummary.pageInfo.hasNextPage || loading;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before: sitesSummaryData?.producerSitesSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after: sitesSummaryData?.producerSitesSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (
      sitesSummaryData?.producerSitesSummary?.pageInfo.hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [sitesSummaryData?.producerSitesSummary?.pageInfo.hasPreviousPage]);

  return (
    <Flex direction="column" w="100%" px={8} pt={8}>
      <Flex direction="column" w="100%" mb={8}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          px={4}
          mb={8}
        >
          <Flex w="40%" h={74} alignItems="center">
            <H1>
              <TrText message="producers_table.title" />
            </H1>
          </Flex>
          <Flex
            flex="1"
            mr={10}
            h={74}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ButtonPrimary as="a" href={exportUrl}>
              Export
            </ButtonPrimary>
          </Flex>
          {sitesSummaryData && totalPages > 0 && (
            <PaginationNavigation
              id="analytics-sites"
              previousButtonName="previous page"
              nextButtonName="next page"
              onChange={onRowSelectChange}
              currentPageCounter={currentPageCounter}
              totalPages={totalPages}
              isPreviousPageDisabled={isPreviousPageDisabled}
              isNextPageDisabled={isNextPageDisabled}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
              isLoading={loading}
            />
          )}
        </Flex>

        <Flex px={4} mb={4}>
          <Flex w="28%" alignItems="center">
            <Text>
              <TrText message="table_headings.name" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="poi_name"
                ariaLabel="sort by site name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="poi_name"
                ariaLabel="sort by site name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.activities" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.weight" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.validation" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="validation_percentage"
                ariaLabel="sort by validation percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="validation_percentage"
                ariaLabel="sort by validation percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.landfill" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="landfill_fate_percentage"
                ariaLabel="sort by landfill percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="landfill_fate_percentage"
                ariaLabel="sort by landfill percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.landfill_diversion" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="landfill_diversion_fate_percentage"
                ariaLabel="sort by landfill_diversion_fate_percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="landfill_diversion_fate_percentage"
                ariaLabel="sort by landfill_diversion_fate_percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.unknown" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="unknown_fate_percentage"
                ariaLabel="sort by unknown_fate_percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="unknown_fate_percentage"
                ariaLabel="sort by unknown_fate_percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              <TrText message="table_headings.distance_per_movement" />
            </Text>
            {distanceUnits && (
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column={displayDistanceColumnm() as OrderByType}
                  ariaLabel={`sort by ${displayDistanceColumnm()} ascending`}
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column={displayDistanceColumnm() as OrderByType}
                  ariaLabel={`sort by ${displayDistanceColumnm()} descending`}
                />
              </SortingBox>
            )}
          </Flex>

          <Flex w="9%" justifyContent="center" alignItems="center">
            <Text>
              CO<sub>2</sub>/<TrText message="table_headings.movement" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="primary_co2_per_movement_kgs"
                ariaLabel="sort by primary co2 per movement kg ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="primary_co2_per_movement_kgs"
                ariaLabel="sort by primary co2 per movement in kg descending"
              />
            </SortingBox>
          </Flex>
        </Flex>

        <Card>
          {loading ? (
            <LoadingData />
          ) : (
            <Flex direction="column">
              {sites.map((site) => {
                return (
                  <SiteRow
                    site={site}
                    key={site.customerSiteId}
                    distanceUnits={distanceUnits}
                  />
                );
              })}
            </Flex>
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Sites);
