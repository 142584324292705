import { Table, Td, Th, Thead, Tr } from "@chakra-ui/react";

const MissingColumns = ({ columns }: { columns: string[] }) => (
  <>
    {columns.length > 0 && (
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Missing Column(s)</Th>
          </Tr>
        </Thead>
        <tbody>
          {columns.map((column) => (
            <Tr key={column}>
              <Td>{column}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    )}
  </>
);

export default MissingColumns;
