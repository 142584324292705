import styled from "@emotion/styled";
import { colors } from "../../ui/theme";

const Loading = () => {
  return <D />;
};

const colour = colors.new.primary[500];

const D = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${colour};
  color: ${colour};
  box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
    10014px 0 0 0 ${colour};
  animation: dotTyping 1.5s infinite linear;
  margin: 12px;
  margin-left: 16px;

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
    33.333% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
    50% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px -10px 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
    66.667% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
    83.333% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px -10px 0 0 ${colour};
    }
    100% {
      box-shadow: 9984px 0 0 0 ${colour}, 9999px 0 0 0 ${colour},
        10014px 0 0 0 ${colour};
    }
  }
`;

export default Loading;
