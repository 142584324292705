import dayjs, { Dayjs } from "dayjs";

/**
 * Returns date in YYYY-MM-DD format for GraphQL query
 * */
export const formatDate = (date: Date | Dayjs): string => {
  if (date instanceof Date || dayjs(date).isValid()) {
    return dayjs(date).format("YYYY-MM-DD");
  } else {
    return "";
  }
};
