import { ResponsiveSankey } from "@nivo/sankey";

export type SankeyDataType = {
  nodes: { id: string; nodeColor?: string }[];
  links: { source: string; target: string; value: number }[];
};

type Props = {
  data: SankeyDataType | null;
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
export const MaterialFlowSankey = ({ data }: Props) => {
  if (data) {
    return (
      <ResponsiveSankey
        data={data}
        margin={{ top: 40, right: 160, bottom: 40, left: 160 }}
        align="justify"
        colors={{ scheme: "category10" }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: "color",
          modifiers: [["darker", 0.8]],
        }}
        nodeBorderRadius={3}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="inside"
        labelOrientation="horizontal"
        labelPadding={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
      />
    );
  } else return <p>No data</p>;
};
