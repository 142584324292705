import { memo, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import {
  Flex,
  Box,
  ButtonGroup,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { MaterialFlowSankey, SankeyDataType } from "./Sankey";
import SitesMap from "./components/SitesMap";
import {
  IMovementsGeoSummaryResult,
  MOVEMENTS_GEO_SUMMARY_QUERY,
} from "../../../../graphql/queries/getMovementsGeoData";
import {
  READ_ACTIVITIES_COUNT,
  IActivitiesCountResult,
} from "../../../../graphql/queries/getMovementsSummary";
import TrText from "../../../../i18n/TrText";
import { isWebGLContextSupported } from "../../../../aux/webgl";
import two_d_icon from "./../../../../assets/images/icons/2d.svg";
import two_d_icon_white from "./../../../../assets/images/icons/2d_white.svg";
import three_d_icon from "./../../../../assets/images/icons/3d.svg";
import three_d_icon_white from "./../../../../assets/images/icons/3d_white.svg";
import Label from "../../../../ui/Label";
import CardHeader from "../../../../components/CardHeader";
import Card from "../../../../ui/Card";
import CardContent from "../../../../components/CardContent";
import { colors } from "../../../../ui/theme";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import Link from "../../../../ui/Link";
import Text from "../../../../ui/Text";

export type ProducerSiteMapDataType = {
  coordinates: [number, number];
  poiAddress: string;
  poiName: string;
  topoSiteId: string;
};
const MapView = () => {
  // This is managing the state of the bottom drawer
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filters = useFiltersFromQueryParams();

  const { data: activitiesGeoData } = useQuery<IMovementsGeoSummaryResult>(
    MOVEMENTS_GEO_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: filters,
    }
  );

  const { data: activityCountData, loading: loadingActivityCountData } =
    useQuery<IActivitiesCountResult>(READ_ACTIVITIES_COUNT, {
      fetchPolicy: "cache-and-network",
      variables: filters,
    });

  const [is3DSelected, setIs3DSelected] = useState(false);

  const [siteSelected, setSiteSelected] =
    useState<ProducerSiteMapDataType | null>(null);
  const [sankeyData, setSankeyData] = useState<SankeyDataType | null>(null);

  const onDrawerClose = () => {
    onClose();
    setSiteSelected(null);
  };

  const onProducerSiteClick = (site: ProducerSiteMapDataType) => {
    // Constructing data for the sankey diagram
    const filteredActivities =
      activitiesGeoData?.movementsGeoSummary.filter(
        (i) => i.producerId === site.topoSiteId && i.weightInKgs > 0
      ) || [];

    if (filteredActivities.length === 0) {
      // nothing to show
      return;
    }

    onOpen(); // open bottom drawer

    const nodes =
      filteredActivities?.map((i) => {
        return {
          id: i.receiverName,
          nodeColor: "hsl(267, 70%, 50%)",
        };
      }) || [];

    // We need to add producer node
    nodes.push({
      id: filteredActivities[0].producerName,
      nodeColor: "hsl(267, 70%, 50%)",
    });

    const links = filteredActivities?.map((i) => {
      return {
        source: i.producerName,
        target: i.receiverName,
        value: i.weightInKgs,
      };
    });

    // Set up sankey data in local state
    setSankeyData({
      nodes,
      links,
    });

    setSiteSelected(site);
  };

  return (
    <>
      <Flex w={"100%"} minH="calc(100vh - 72px)">
        <Box w="100%" display="flex" flexDirection="column">
          <Flex
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            position="absolute"
            top="92px"
            left="140px"
            w="220px"
            zIndex={3}
          >
            <Card>
              <CardHeader style={{ background: "#FFF" }}>
                {!loadingActivityCountData
                  ? activityCountData?.movementsSummary?.activityCount
                  : ""}
                {loadingActivityCountData ? "..." : ""}{" "}
                <TrText message="nav.activities" />
              </CardHeader>
              <CardContent style={{ background: "#F5F7F7" }}>
                <Flex alignItems="center" justifyContent="flex-start" w="100%">
                  <ProducerIcon />
                  <TrText message="map_view.producers" />
                </Flex>
                <Flex alignItems="center" justifyContent="flex-start" w="100%">
                  <ReceiverIcon />
                  <TrText message="map_view.receivers" />
                </Flex>
              </CardContent>
            </Card>
          </Flex>
          <Flex
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            position="absolute"
            top="92px"
            right={8}
            w="150px"
            zIndex={3}
          >
            <ButtonGroup size="sm" isAttached variant="outline">
              <Flex direction="column" alignItems="center">
                <Label forHtml="2d-button">
                  <b>2D</b>
                </Label>
                <Button
                  id="2d-button"
                  bg={!is3DSelected ? "#637d7d" : "white"}
                  _hover={{ bg: !is3DSelected ? "#637d7d" : "white" }}
                  _active={{ bg: !is3DSelected ? "#637d7d" : "white" }}
                  _disabled={{
                    bg: !is3DSelected ? "#637d7d" : "white",
                    cursor: "not-allowed",
                  }}
                  disabled={!is3DSelected ? true : false}
                  mr="-px"
                  onClick={() => setIs3DSelected(false)}
                  w="50px"
                  h="40px"
                  borderTopLeftRadius="10px"
                  borderBottomLeftRadius="10px"
                  borderTopRightRadius="0px"
                  borderBottomRightRadius="0px"
                  boxShadow="rgba(0, 0, 0, 0.08) 0px 2px 20px"
                >
                  {is3DSelected ? (
                    <img
                      width="16px"
                      height="16px"
                      src={two_d_icon}
                      alt="2D icon"
                    />
                  ) : (
                    <img
                      width="16px"
                      height="16px"
                      src={two_d_icon_white}
                      alt="2D icon"
                    />
                  )}
                </Button>
              </Flex>

              <Flex direction="column" alignItems="center">
                <Label forHtml="3d-button">
                  <b>3D</b>
                </Label>
                <Button
                  id="3d-button"
                  bg={is3DSelected ? "#637d7d" : "white"}
                  _hover={{ bg: is3DSelected ? "#637d7d" : "white" }}
                  _active={{ bg: is3DSelected ? "#637d7d" : "white" }}
                  _disabled={{
                    bg: is3DSelected ? "#637d7d" : "white",
                    cursor: "not-allowed",
                  }}
                  mr="-px"
                  onClick={() => setIs3DSelected(true)}
                  disabled={is3DSelected ? true : false}
                  w="50px"
                  h="40px"
                  borderTopLeftRadius="0px"
                  borderBottomLeftRadius="0px"
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                  boxShadow="rgba(0, 0, 0, 0.08) 0px 2px 20px"
                >
                  {is3DSelected ? (
                    <img
                      width="21px"
                      height="21px"
                      src={three_d_icon_white}
                      alt="3D icon"
                    />
                  ) : (
                    <img
                      width="21px"
                      height="21px"
                      src={three_d_icon}
                      alt="3D icon"
                    />
                  )}
                </Button>
              </Flex>
            </ButtonGroup>
          </Flex>

          {isWebGLContextSupported() ? (
            <SitesMap
              activitiesData={activitiesGeoData}
              is3DSelected={is3DSelected}
              onProducerSiteClick={onProducerSiteClick}
            />
          ) : (
            <Box p={4}>
              <TrText message="overview.webgl_not_supported" />
            </Box>
          )}
        </Box>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={onDrawerClose}
        size="lg"
      >
        <DrawerContent
          maxW="calc(100vw - 160px) !important"
          left="140px !important"
          right="20px !important"
          borderTopRadius="10px"
          bg="#F5F7F7"
          boxShadow="rgba(0, 0, 0, 0.08) 0px 2px 20px"
        >
          <DrawerCloseButton size="sm" />

          <DrawerBody p={8}>
            <Card>
              <CardHeader>
                <Flex w="100%" justifyContent="space-between">
                  <TrText message="map_view.drawer_title" />
                  <Link to={`/h/pois/${siteSelected?.topoSiteId}`}>
                    <Text style={{ color: colors.new.text.blue[500] }}>
                      <b>{siteSelected?.poiName ?? ""}</b>
                    </Text>
                  </Link>
                </Flex>
              </CardHeader>
              <CardContent>
                <Flex w="100%" h={300} maxH="30vh">
                  <MaterialFlowSankey data={sankeyData} />
                </Flex>
              </CardContent>
            </Card>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const ProducerIcon = styled.div`
  width: 14px;
  height: 14px;
  background: ${colors.new.primary[500]};
  margin-right: 12px;
`;
const ReceiverIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background: rgb(62, 102, 251);
  margin-right: 12px;
`;

export default memo(MapView);
