import { DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IUser } from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import DangerButton from "../../../../ui/DangerButton";

const RemoveUserButton = (props: {
  user: IUser;
  onRemoveUser: (userId: string) => Promise<void>;
}) => {
  const { user, onRemoveUser } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = useRef(null);

  const okRemoveUser = async () => {
    setIsLoading(true);
    await onRemoveUser(user.id);
    onClose();
    setIsLoading(false);
  };

  return (
    <>
      <DangerButton ml={4} leftIcon={<DeleteIcon />} onClick={onOpen}>
        <TrText message="app.remove" />
      </DangerButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <TrText
                message="add_user.remove_user"
                options={{ name: user.name }}
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              <TrText message="add_user.confirm_remove" />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                isDisabled={isLoading}
                ref={cancelRef}
                onClick={onClose}
                mr={3}
              >
                <TrText message="app.cancel" />
              </Button>
              <DangerButton isLoading={isLoading} onClick={okRemoveUser}>
                <TrText message="app.remove" />
              </DangerButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RemoveUserButton;
