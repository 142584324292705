import { useState, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";

import SortingIcon from "../../../../components/SortingIcon";
import {
  OrderByType,
  RespondType,
} from "../../../../components/SortingIcon/SortingIcon";
import PaginationNavigation from "../../../../components/PaginationNavigation";
import H1 from "../../../../ui/H1";
import Card from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import {
  RECEIVING_SITES_SUMMARY_QUERY,
  IReceivingSitesSummaryResult,
  IReceivingSitesSummary,
  IReceivingSitesSummaryParams,
} from "../../../../graphql/queries/getReceivingSitesSummary";
import { ReceiverRow } from "./components/ReceiverRow";
import LoadingData from "../../../../components/LoadingData";
import TrText from "../../../../i18n/TrText";
import { useFiltersFromQueryParams } from "../../../../components/SimpleFilter/components/SimpleFilterSummary/SimpleFilterSummary";
import ButtonPrimary from "../../../../ui/ButtonPrimary";
import { getExportURL } from "../../../../aux/export";

const isColumnAllowedForSorting = (column: string) => {
  return [
    "movement_count",
    "total_weight",
    "receiver_poi_name",
    "receiver_operator_name",
    "landfill_diversion_fate_percentage",
    "unknown_fate_percentage",
    "landfill_fate_percentage",
    "validation_percentage",
    "primary_co2_per_movement_kgs",
    "distance_per_movement_kms",
    "distance_per_movement_miles",
  ].includes(column);
};
const isDirectionAllowedForSorting = (direction: string) => {
  return ["asc", "desc"].includes(direction);
};

const Receivers = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [currentPageCounter, setCurrentPageCounter] = useState<number>(1);

  const distanceUnits = window.localStorage.getItem("distanceUnits") as
    | "miles"
    | "kilometers"
    | undefined;

  const displayDistanceColumnm = () => {
    if (distanceUnits === "kilometers") {
      return "distance_per_movement_kms";
    } else if (distanceUnits === "miles") {
      return "distance_per_movement_miles";
    } else return undefined;
  };

  const filters = useFiltersFromQueryParams();

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("movement_count");

  const exportUrl = getExportURL("receivers", {
    ...filters,
    orderBy:
      orderBy && isColumnAllowedForSorting(orderBy)
        ? orderBy
        : "activity_count",
    orderDirection:
      orderDirection && isDirectionAllowedForSorting(orderDirection)
        ? orderDirection
        : "desc",
  });

  const {
    data: receivingSitesSummaryData,
    loading: loadingData,
    fetchMore,
  } = useQuery<IReceivingSitesSummaryResult, IReceivingSitesSummaryParams>(
    RECEIVING_SITES_SUMMARY_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        first: rowsPerPage,
        after: null,
        ...filters,
        orderBy:
          orderBy && isColumnAllowedForSorting(orderBy)
            ? orderBy
            : "movement_count",
        orderDirection:
          orderDirection && isDirectionAllowedForSorting(orderDirection)
            ? orderDirection
            : "desc",
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const totalCount =
    receivingSitesSummaryData?.receiverSitesSummary?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const sites: IReceivingSitesSummary[] =
    receivingSitesSummaryData === undefined
      ? []
      : receivingSitesSummaryData.receiverSitesSummary.edges.map(
          (edge) => edge.node
        );

  const handleSortingSelection = (props: RespondType) => {
    setOrderBy(props.orderBy);
    setOrderDirection(props.orderDirection);
  };

  // PaginationNavigation handlers
  const onRowSelectChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPageCounter(1);
  };
  const isPreviousPageDisabled =
    !receivingSitesSummaryData?.receiverSitesSummary?.pageInfo
      .hasPreviousPage || loadingData;

  const isNextPageDisabled =
    !receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.hasNextPage ||
    loadingData;

  const onPreviousPageClick = () => {
    fetchMore({
      variables: {
        first: null,
        last: rowsPerPage,
        before:
          receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.startCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter - 1);
  };

  const onNextPageClick = () => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        after:
          receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.endCursor,
      },
    });

    setCurrentPageCounter(currentPageCounter + 1);
  };

  useEffect(() => {
    if (
      receivingSitesSummaryData?.receiverSitesSummary?.pageInfo
        .hasPreviousPage === false
    ) {
      // clear counter when we run a new query
      setCurrentPageCounter(1);
    }
  }, [
    receivingSitesSummaryData?.receiverSitesSummary?.pageInfo.hasPreviousPage,
  ]);

  return (
    <>
      <Flex direction="column" w="100%" mb={8} p={8} pt={8}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          px={4}
          mb={8}
        >
          <Flex w="40%" h={74} alignItems="center">
            <H1>
              <TrText message="analytics_receivers.title" />
            </H1>
          </Flex>

          <Flex
            flex="1"
            mr={10}
            h={74}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ButtonPrimary as="a" href={exportUrl}>
              Export
            </ButtonPrimary>
          </Flex>

          {receivingSitesSummaryData && totalPages > 0 && (
            <PaginationNavigation
              id="compare-receivers"
              isLoading={loadingData}
              previousButtonName="previous page"
              nextButtonName="next page"
              onChange={onRowSelectChange}
              currentPageCounter={currentPageCounter}
              totalPages={totalPages}
              isPreviousPageDisabled={isPreviousPageDisabled}
              isNextPageDisabled={isNextPageDisabled}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
            />
          )}
        </Flex>

        <Flex px={4} mb={4}>
          <Flex w="14%" alignItems="center" justifyContent="flex-start">
            <Text>
              <TrText message="table_headings.operator" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="receiver_operator_name"
                ariaLabel="sort by receiver operator name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="receiver_operator_name"
                ariaLabel="sort by receiver operator name descending"
              />
            </SortingBox>
          </Flex>
          <Flex w="14%" alignItems="center" justifyContent="flex-start">
            <Text>
              <TrText message="table_headings.receiver_site_name" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="receiver_poi_name"
                ariaLabel="sort by receiver name ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="receiver_poi_name"
                ariaLabel="sort by receiver name descending"
              />
            </SortingBox>
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              <TrText message="table_headings.activities" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="movement_count"
                ariaLabel="sort by movement count descending"
              />
            </SortingBox>
          </Flex>

          <Flex
            textAlign="center"
            w="9%"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              <TrText message="table_headings.weight" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="total_weight"
                ariaLabel="sort by total weight descending"
              />
            </SortingBox>
          </Flex>

          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.validation" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="validation_percentage"
                ariaLabel="sort by validation percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="validation_percentage"
                ariaLabel="sort by validation percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.landfill" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="landfill_fate_percentage"
                ariaLabel="sort by landfill percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="landfill_fate_percentage"
                ariaLabel="sort by landfill percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            w="9%"
          >
            <Text>
              <TrText message="table_headings.landfill_diversion" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="landfill_diversion_fate_percentage"
                ariaLabel="sort by landfill_diversion_fate_percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="landfill_diversion_fate_percentage"
                ariaLabel="sort by landfill_diversion_fate_percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.unknown" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="unknown_fate_percentage"
                ariaLabel="sort by unknown_fate_percentage ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="unknown_fate_percentage"
                ariaLabel="sort by unknown_fate_percentage descending"
              />
            </SortingBox>
          </Flex>

          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              <TrText message="table_headings.distance_per_movement" />
            </Text>
            {distanceUnits && (
              <SortingBox>
                <SortingIcon
                  direction="asc"
                  onClick={handleSortingSelection}
                  column={displayDistanceColumnm() as OrderByType}
                  ariaLabel={`sort by ${displayDistanceColumnm()} ascending`}
                />
                <SortingIcon
                  direction="desc"
                  onClick={handleSortingSelection}
                  column={displayDistanceColumnm() as OrderByType}
                  ariaLabel={`sort by ${displayDistanceColumnm()} descending`}
                />
              </SortingBox>
            )}
          </Flex>

          <Flex justifyContent="center" alignItems="center" w="9%">
            <Text>
              CO<sub>2</sub>/<TrText message="table_headings.movement" />
            </Text>
            <SortingBox>
              <SortingIcon
                direction="asc"
                onClick={handleSortingSelection}
                column="primary_co2_per_movement_kgs"
                ariaLabel="sort by primary co2 per movement kg ascending"
              />
              <SortingIcon
                direction="desc"
                onClick={handleSortingSelection}
                column="primary_co2_per_movement_kgs"
                ariaLabel="sort by primary co2 per movement in kg descending"
              />
            </SortingBox>
          </Flex>
        </Flex>

        <Card>
          {loadingData ? (
            <LoadingData />
          ) : (
            <Flex direction="column">
              {sites.map((site) => {
                return (
                  <ReceiverRow
                    key={site.id}
                    site={site}
                    distanceUnits={distanceUnits}
                  />
                );
              })}
            </Flex>
          )}
        </Card>
      </Flex>
    </>
  );
};

const SortingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export default memo(Receivers);
