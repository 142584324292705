import { Badge, Box, Table, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IUser, Role } from "../../../../graphql/queries/getCurrentUser";
import TrText from "../../../../i18n/TrText";
import Text from "../../../../ui/Text";
import RemoveUserButton from "./RemoveUserButton";
import UpdateUserRolesButton from "./UpdateUserRolesButton";

interface IManageUsersProps {
  users: IUser[];
  onRemoveUser: (userId: string) => Promise<void>;
  onUpdateUserRoles: (userId: string, roles: Role[]) => Promise<void>;
}

const ManageUsers = (props: IManageUsersProps) => {
  const { users, onRemoveUser, onUpdateUserRoles } = props;

  return (
    <>
      <Box p={4}>
        {users && users.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <TrText message="table_headings.name" />
                </Th>
                <Th>
                  <TrText message="table_headings.email" />
                </Th>
                <Th>
                  <TrText message="table_headings.roles" />
                </Th>
                <Th>
                  <TrText message="table_headings.actions" />
                </Th>
              </Tr>
            </Thead>
            <tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>
                    {user.roles.map((role, index) => (
                      <Badge
                        key={`${role}-${index}`}
                        ml={4}
                        colorScheme="green"
                      >
                        {role}
                      </Badge>
                    ))}
                  </Td>
                  <Td>
                    <UpdateUserRolesButton
                      user={user}
                      onUpdateUserRoles={onUpdateUserRoles}
                    />
                    <RemoveUserButton user={user} onRemoveUser={onRemoveUser} />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Text>
            <TrText message="add_user.no_other_users" />
          </Text>
        )}
      </Box>
    </>
  );
};

export default ManageUsers;
