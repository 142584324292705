import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { ICellWarningDetail } from "../../../graphql/queries/getMovementsUploads";
import TrText from "../../../i18n/TrText";

export interface IUICellWarningDetail extends ICellWarningDetail {
  column: string;
}
const CellWarnings = ({
  warnings,
  maxRows,
}: {
  warnings: IUICellWarningDetail[];
  maxRows: number;
}) => {
  return (
    <>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>
              <TrText message="table_headings.row" />
            </Th>
            <Th>
              <TrText message="table_headings.column" />
            </Th>
            <Th>
              <TrText message="table_headings.message" />
            </Th>
            <Th>
              <TrText message="table_headings.value" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {warnings.slice(0, maxRows).map((w) => (
            <Tr key={`${w.row}-${w.column}-${w.value}-${w.message}`}>
              <Td>{w.row}</Td>
              <Td>{w.column}</Td>
              <Td>{w.message}</Td>
              <Td>{w.value}</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          {warnings.length > maxRows && (
            <Tr>
              <Td colSpan={4}>
                <TrText
                  message="upload.warnings_count"
                  options={{ count: warnings.length - maxRows }}
                />
              </Td>
            </Tr>
          )}
        </Tfoot>
      </Table>
    </>
  );
};

export default CellWarnings;
