import styled from "@emotion/styled";
import { Flex, Link } from "@chakra-ui/react";

import Text from "../../../../../ui/Text";
import { formatToDecimalPlaces } from "../../../../../aux/numbers";
import { ICarriersSummary } from "../../../../../graphql/queries/getCarriersSummary";
import { colors } from "../../../../../ui/theme";
import { Dispatch, SetStateAction } from "react";

type Props = {
  carrier: ICarriersSummary;
  setCarrierSelected: Dispatch<SetStateAction<ICarriersSummary | null>>;
  distanceUnits: "miles" | "kilometers" | undefined;
};

export const CarrierRow = (props: Props) => {
  const { carrier, setCarrierSelected, distanceUnits } = props;

  const totalMaterialWeight = carrier?.totalWeight || 0;

  const validationPctFormatted = `${formatToDecimalPlaces(
    carrier?.validationPercentage,
    1
  )}%`;

  const landfillPctFormatted = `${formatToDecimalPlaces(
    carrier?.landfillFatePercentage,
    1
  )}%`;

  const divertedPctFormatted = `${formatToDecimalPlaces(
    carrier?.landfillDiversionFatePercentage,
    1
  )}%`;

  const unknownPctFormatted = `${formatToDecimalPlaces(
    carrier?.unknownFatePercentage,
    1
  )}%`;

  const distancePerMovementKmsFormatted = `${formatToDecimalPlaces(
    carrier?.distancePerMovementKms,
    2
  )} kms`;
  const distancePerMovementMilesFormatted = `${formatToDecimalPlaces(
    carrier?.distancePerMovementMiles,
    2
  )} miles`;

  const co2PerMovementFormatted = `${formatToDecimalPlaces(
    carrier?.primaryCo2PerMovementKgs,
    3
  )}kg`;

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      w="100%"
      _notLast={{
        borderBottom: "1px solid #e2e8f0",
      }}
    >
      <Flex w="100%">
        <Flex w="28%" textAlign="left" alignItems="center">
          <Link
            style={{ color: colors.new.text.blue[500] }}
            fontWeight="bold"
            onClick={() => setCarrierSelected(carrier)}
          >
            <Text>{carrier.name}</Text>
          </Link>
        </Flex>

        <Cell w="10%">
          <Text>{carrier.movementCount}</Text>
        </Cell>

        <Cell w="10%">
          <Text>
            {totalMaterialWeight === 0
              ? `0kg`
              : `${formatToDecimalPlaces(totalMaterialWeight / 1000, 3)}t`}
          </Text>
        </Cell>

        {/* Validation */}
        <Cell w="9%">
          <Text>{validationPctFormatted}</Text>
        </Cell>

        {/* Landfill */}
        <Cell w="9%">
          <Text>{landfillPctFormatted}</Text>
        </Cell>

        {/* Diverted */}
        <Cell w="9%">
          <Text>{divertedPctFormatted}</Text>
        </Cell>

        {/* Unknown */}
        <Cell w="9%">
          <Text>{unknownPctFormatted}</Text>
        </Cell>

        {/* DistancePerMovement */}
        <Cell w="9%">
          <Text>
            {distanceUnits === "miles"
              ? distancePerMovementMilesFormatted
              : distancePerMovementKmsFormatted}
          </Text>
        </Cell>

        {/* CO2 per movement */}
        <Cell w="9%">
          <Text>{co2PerMovementFormatted}</Text>
        </Cell>
      </Flex>
    </Flex>
  );
};

const Cell = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 32px;
`;
