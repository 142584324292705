import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { Icon } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";

import Text from "../../ui/Text";
import LocationChart from "./LocationChart";
import GhostButton from "../../ui/GhostButton";
import { colors } from "../../ui/theme";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../graphql/queries/getCurrentUser";
import TrText from "../../i18n/TrText";
import H1 from "../../ui/H1";
import Tile from "../../ui/Tile";
import { formatToDecimalPlaces } from "../../aux/numbers";
import TotalMaterialWeight from "../Analytics/containers/Sites/components/TotalMaterialWeight";
import TotalPrimaryDistance from "../Analytics/containers/Sites/components/TotalPrimaryDistance";
import TotalPrimaryTransportCO2 from "../Analytics/containers/Sites/components/TotalPrimaryTransportCO2";
import TotalDivertedFromLandfill from "../../components/TotalDivertedFromLandfill";
import TotalMaterialLandfill from "../../components/TotalMaterialLandfill";
import TotalUnknownMaterial from "../../components/TotalUnknownMaterial";
import Card from "../../ui/Card";
import {
  IReceivingSitesSummary,
  IReceivingSitesSummaryResult,
  RECEIVING_SITES_SUMMARY_QUERY,
} from "../../graphql/queries/getReceivingSitesSummary";
import {
  GET_SINGLE_PERMITTED_SITE_QUERY,
  ISinglePermittedSiteResult,
} from "../../graphql/queries/getSinglePermittedSite";

const ReceiverPoi = () => {
  let { poiId } = useParams<{ poiId: string }>();
  const history = useHistory();

  const { data: permittedSiteData } = useQuery<
    ISinglePermittedSiteResult,
    { topoSiteId: string }
  >(GET_SINGLE_PERMITTED_SITE_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      topoSiteId: poiId,
    },
  });

  const { data: receivingSiteSummaryData, loading: loadingData } = useQuery<
    IReceivingSitesSummaryResult,
    { receiverTopoPoiId: string }
  >(RECEIVING_SITES_SUMMARY_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      receiverTopoPoiId: poiId,
    },
  });

  const permittedSite = permittedSiteData?.permittedSite;
  const poi: IReceivingSitesSummary | undefined =
    receivingSiteSummaryData?.receiverSitesSummary.edges[0]?.node;

  const { data: currentUserResult } =
    useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const currentUser = currentUserResult?.currentUser;

  const totalMaterialWeight = poi?.totalWeight || 0;
  const totalPrimaryMiles = poi?.primaryDistanceMiles || 0;
  const totalPrimaryKms = poi?.primaryDistanceKms || 0;
  const totalPrimaryCo2Kgs = poi?.primaryCo2Kgs || 0;
  const divertedFromLandfillPercentage =
    poi?.landfillDiversionFatePercentage || 0;
  const landfillPercentage = poi?.landfillFatePercentage || 0;
  const unknownPercentage = poi?.unknownFatePercentage || 0;

  return (
    <>
      <Flex
        align="flex-start"
        justify="flex-start"
        direction={["column"]}
        p={8}
      >
        <Box mb={4} ml={-5}>
          <GhostButton onClick={history.goBack}>
            <Flex direction="row" align="center" justify="flex-start">
              <Icon
                w={6}
                h={6}
                mr={4}
                as={MdArrowBack}
                color={colors.new.text.blue[500]}
              />
              <Text style={{ textDecoration: "underline" }}>
                <TrText message="app.go_back" />
              </Text>
            </Flex>
          </GhostButton>
        </Box>

        <Flex mb={4}>
          <H1>{loadingData ? "..." : permittedSite?.poiName}</H1>
        </Flex>

        <Flex justify="stretch" align="center" w="100%">
          <Box w="25%" mr={4}>
            <Tile
              title="overview.validated_card_title"
              iconName="truck_with_tick"
              bottomText={
                <TrText
                  message="overview.in_total_activities"
                  options={{
                    amount: poi?.activityCount ? poi.activityCount : "...",
                  }}
                />
              }
              value={
                poi?.activityCount
                  ? `${formatToDecimalPlaces(
                      ((poi?.activityCount - poi?.receiverValidationErrors) /
                        poi?.activityCount) *
                        100,
                      1
                    )}%`
                  : "No data"
              }
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalMaterialWeight weight={totalMaterialWeight} />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalPrimaryDistance
              miles={totalPrimaryMiles}
              kms={totalPrimaryKms}
              currentUser={currentUser}
            />
          </Box>
          <Box w="25%" ml={4}>
            <TotalPrimaryTransportCO2 kgs={totalPrimaryCo2Kgs} />
          </Box>
        </Flex>

        <Flex
          justify="stretch"
          align="center"
          w="100%"
          direction="row"
          mt="20px"
        >
          <Box w="25%" mr={4}>
            <Tile
              title="overview.movements_card_title"
              bottomText="overview.in_total"
              iconName="just_track"
              value={String(poi?.movementCount)}
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalDivertedFromLandfill
              totalWeight={totalMaterialWeight}
              divertedFromLandfillPercentage={divertedFromLandfillPercentage}
              isLoading={loadingData}
            />
          </Box>
          <Box w="25%" ml={4} mr={4}>
            <TotalMaterialLandfill
              totalWeight={totalMaterialWeight}
              landfillPercentage={landfillPercentage}
              isLoading={loadingData}
            />
          </Box>
          <Box w="25%" ml={4}>
            <TotalUnknownMaterial
              totalWeight={totalMaterialWeight}
              unknownPercentage={unknownPercentage}
              isLoading={loadingData}
            />
          </Box>
        </Flex>

        <Flex w="100%" mb={4} mt="20px">
          <Flex w="50%" mr={4}>
            <Card>
              <table>
                <tbody>
                  <TR>
                    <TH>Address</TH>
                    <TD>
                      {permittedSite?.poiAddress
                        ?.split(",")
                        .map((p: string) => {
                          return <p key={p}>{p}</p>;
                        })}
                    </TD>
                  </TR>
                  <TR>
                    <TH>Country</TH>
                    <TD>{permittedSite?.countryName ?? "--"}</TD>
                  </TR>
                  <TR>
                    <TH>Regulator</TH>
                    <TD>{permittedSite?.regulatorName ?? "--"}</TD>
                  </TR>
                  <TR>
                    <TH>Operator</TH>
                    <TD>{permittedSite?.organisation?.name ?? "--"}</TD>
                  </TR>
                  <TR>
                    <TH>Permits</TH>
                    <TD>
                      {(permittedSite?.permits || []).map((permit: string) => {
                        return <p key={permit}>{permit}</p>;
                      })}
                    </TD>
                  </TR>
                  <TR>
                    <TH>Site type</TH>
                    <TD>
                      {permittedSite?.poiType ? permittedSite.poiType : "--"}
                    </TD>
                  </TR>
                  <TR>
                    <TH>Site category</TH>
                    <TD>
                      {permittedSite?.poiCategory
                        ? permittedSite.poiCategory
                        : "--"}
                    </TD>
                  </TR>
                  <TR>
                    <TH>Lat/long</TH>
                    <TD>
                      {Number(permittedSite?.poiLat).toFixed(5)},{" "}
                      {Number(permittedSite?.poiLng).toFixed(5)}
                    </TD>
                  </TR>
                </tbody>
              </table>
            </Card>
          </Flex>
          <Flex w="50%" ml={4}>
            <Box w={["100%"]}>
              {permittedSite && (
                <LocationChart
                  longitude={permittedSite?.poiLng}
                  latitude={permittedSite?.poiLat}
                  name={permittedSite?.poiName}
                  address={permittedSite?.poiAddress}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const TH = styled.th`
  text-align: left;
  width: 30%;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 24px;
  vertical-align: baseline;
`;
const TD = styled.td`
  text-align: left;
  vertical-align: baseline;
`;

const TR = styled.tr`
  border-bottom: 1px solid ${colors.new.grey[500]};
`;

export default ReceiverPoi;
