import { useRef } from "react";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import {
  Flex,
  Tooltip,
  Box,
  PopoverContent,
  Popover,
  Portal,
  PopoverTrigger,
  PopoverBody,
} from "@chakra-ui/react";
import { ChevronUpIcon, QuestionOutlineIcon } from "@chakra-ui/icons";

import TrText from "../../i18n/TrText";
import { colors } from "../../ui/theme";
import Avatar from "../../ui/Avatar";
import Text from "../../ui/Text";
import Icons from "../Icons";
import Logo from "../Logo";
import { IconName } from "../Icons/Icons";
import { MenuItem } from "./MenuItem";
import {
  GET_CURRENT_USER,
  ICurrentUserResult,
} from "../../graphql/queries/getCurrentUser";
import { screensVisibleToUser } from "../../aux/userUtils";
import power from "./../../assets/images/icons/power.svg";
import { logoutEndpoint, docsUrl } from "./../../api";

type Props = {
  closingSimpleFilter: () => void;
};

const MainNavigation = (props: Props) => {
  // We want to make sure that when we navigate away
  // we then close the SimpleFilter
  const { closingSimpleFilter } = props;

  const initRef = useRef();

  const location = useLocation();

  const isSelected = (str: string) => {
    return location.pathname.includes(str);
  };

  const { data } = useQuery<ICurrentUserResult>(GET_CURRENT_USER);

  const visibility = screensVisibleToUser(data?.currentUser);
  const userName = data?.currentUser?.name;
  const avatarUrl = data?.currentUser?.avatarUrl;

  const menuItems = [
    // {
    //   toolTip: 'Dashboard',
    //   icon: 'dashboard',
    //   selected: location.pathname === '/h',
    //   to: '/h',
    //   visible: visibility.isDashboardVisible,
    // },
    {
      toolTip: <TrText message="nav.analytics" />,
      icon: "analytics",
      selected: isSelected("analytics"),
      to: "/h/analytics",
      visible: visibility.isAnalyticsVisible,
      displayText: <TrText message="nav.analytics" />,
    },
    {
      toolTip: <TrText message="nav.compare" />,
      icon: "comparison",
      selected: isSelected("comparison"),
      to: "/h/comparison",
      visible: visibility.isComparisonVisible,
      displayText: <TrText message="nav.compare" />,
    },
    {
      toolTip: <TrText message="nav.directory" />,
      icon: "directory",
      selected: isSelected("directory"),
      to: "/h/directory",
      visible: visibility.isDirectoryVisible,
      displayText: <TrText message="nav.directory" />,
    },
    {
      toolTip: <TrText message="nav.upload" />,
      icon: "upload",
      selected: isSelected("upload"),
      to: "/h/uploads",
      visible: visibility.isUploadVisible,
      displayText: <TrText message="nav.upload" />,
    },
  ];

  const visibleMenuItems = menuItems.filter((menuItem) => menuItem.visible);

  return (
    <Flex
      as="nav"
      id="main-navigation"
      align="flex-start"
      justify="space-between"
      direction={["column"]}
      wrap="nowrap"
      bg={colors.new.background_dark[500]}
      style={{ minWidth: 120, minHeight: "100vh" }}
    >
      <Flex align="center" justify="center" direction={["column"]} w="100%">
        <Link to="/" style={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: 72 }}
          >
            <Logo />
          </Box>
        </Link>
        {visibleMenuItems.map((menuItem) => (
          <Flex
            key={menuItem.icon}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <MenuItem
              to={menuItem.to}
              search={location.search}
              isSelected={menuItem.selected}
              key={menuItem.icon}
              displayText={menuItem.displayText}
              closingSimpleFilter={closingSimpleFilter}
            >
              <Tooltip label={menuItem.toolTip} placement="right">
                <span>
                  <Icons name={menuItem.icon as IconName} />
                </span>
              </Tooltip>
            </MenuItem>
          </Flex>
        ))}
      </Flex>

      <Flex align="center" justify="center" direction="column" w="100%" pb={4}>
        <a href={docsUrl()}>
          <QuestionOutlineIcon mb="10" w="6" h="6" color={colors.white[500]} />
        </a>
        <ChevronUpIcon w="8" h="8" color={colors.white[500]} />

        <Popover
          closeOnBlur={true}
          placement="right-end"
          // @ts-ignore
          initialFocusRef={initRef}
        >
          {() => (
            <>
              <PopoverTrigger>
                <Box _hover={{ cursor: "pointer" }}>
                  <Avatar fullName={userName} url={avatarUrl} />
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  bg={colors.new.background_dark[500]}
                  color={colors.new.primary[500]}
                  borderBottomRightRadius="10px"
                  borderTopRadius="10px"
                  w="200px"
                  ml="8"
                  mb="8"
                  style={{
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <PopoverBody padding={0}>
                    <Flex
                      px={4}
                      alignItems="center"
                      h="35px"
                      borderBottom={`1px solid ${colors.new.background_light[500]}`}
                    >
                      <Link to="/h/settings/profile">
                        <Text>
                          <b>
                            <TrText message="nav.settings" />
                          </b>
                        </Text>
                      </Link>
                    </Flex>
                    <LogoutButton
                      onClick={() => {
                        window.location.href = logoutEndpoint();
                      }}
                    >
                      <img
                        width="18px"
                        src={power}
                        alt="log out icon"
                        style={{ marginRight: 12 }}
                      />
                      <Text>
                        <b>
                          <TrText message="nav.log_out" />
                        </b>
                      </Text>
                    </LogoutButton>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      </Flex>
    </Flex>
  );
};

export default MainNavigation;

const LogoutButton = styled.button`
  width: 100%;
  background-color: ${colors.new.blue[500]};
  color: #fff;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  border-bottom-right-radius: 10px;
`;
