import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  useQueryParam,
  StringParam,
  ArrayParam,
  BooleanParam,
} from "use-query-params";

import {
  UNIQUE_VALUES_QUERY,
  IUniqueValues,
} from "../../graphql/queries/getMovementsSummary";
import {
  GET_POI_GROUPS,
  IPoiGroupResult,
} from "../../graphql/queries/getPoiGroups";
import areTwoArraysEqual from "./aux/areTwoArraysEqual";
import { FilterType } from "./components/LoadFilters/LoadFilters";
import SimpleFilterContainer from "./components/SimpleFilterContainer";

export type ValueChangeType = {
  id: string;
  isChecked: boolean;
};

type Props = {
  toggleSimpleFilter: () => void;
};

export type PoisValuesType = { [key: string]: boolean };

const SimpleFilter = (props: Props) => {
  const { toggleSimpleFilter } = props;

  const { data: uniqueValues } = useQuery<IUniqueValues>(UNIQUE_VALUES_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const uniquePoiIds = uniqueValues?.movementsSummary.uniqueProducerPois;
  const uniqueProducerPois = uniqueValues?.movementsSummary.uniqueProducerPois;
  const uniqueWasteCodes =
    uniqueValues?.movementsSummary?.uniqueWasteCodes || [];
  const uniqueSuppliers = uniqueValues?.movementsSummary?.uniqueSuppliers || [];
  const uniqueCarriers = uniqueValues?.movementsSummary?.uniqueCarriers || [];
  const uniqueReceiverPois =
    uniqueValues?.movementsSummary?.uniqueReceiverPois || [];

  const { data: groupsData } = useQuery<IPoiGroupResult>(GET_POI_GROUPS, {
    fetchPolicy: "cache-and-network",
  });
  const poiGroups = groupsData?.poiGroups;

  // Define URL params for date selection
  const [startDate, setStartDate] = useQueryParam("startDate", StringParam);
  const [endDate, setEndDate] = useQueryParam("endDate", StringParam);
  const [startDateStringValue, setStartDateStringValue] = useState<
    string | null
  >(null);
  const [endDateStringValue, setEndDateStringValue] = useState<string | null>(
    null
  );

  const [wc, setWc] = useQueryParam("wc", ArrayParam);
  // @ts-ignore
  const [wasteCodeValues, setWasteCodeValues] = useState<string[] | []>(() => {
    if (Array.isArray(wc)) {
      // This handles the case when the user reloads the app and the URL already has waste codes
      return [...wc.filter((i) => i !== null)];
    } else return [];
  });

  const onWasteCodeValueChange = ({
    id: wasteCode,
    isChecked,
  }: ValueChangeType) => {
    // Deal with select/diselect all case
    if (wasteCode === "all" && uniqueWasteCodes) {
      if (wasteCodeValues.length) {
        setWasteCodeValues([]);
      } else {
        setWasteCodeValues(uniqueWasteCodes.map((i) => i.code));
      }
      // waste code is an individual ID here
    } else {
      // Add waste code
      if (isChecked) {
        setWasteCodeValues([...wasteCodeValues, wasteCode]);
      } else {
        // Remove waste code
        setWasteCodeValues(
          wasteCodeValues.filter((code: string) => code !== wasteCode)
        );
      }
    }
  };

  const [producerPoiId, setProducerPoiId] = useQueryParam(
    "producerPoiId",
    StringParam
  );
  const [producerPoiIdValue, setProducerPoiIdValue] = useState<string>(() => {
    if (producerPoiId) {
      return producerPoiId;
    } else {
      return "all";
    }
  });

  // "pois" keeps poi IDs values currently applied to the filter, stored in URL
  // "pois" undefined means "all" sites are selected
  const [pois, setPois] = useQueryParam("pois", ArrayParam);

  // "poisValues" keeps poi IDs as selected by the user in SimpleFilter, but not applied yet
  // @ts-ignore
  const [poisValues, setPoisValues] = useState<string[] | []>(() => {
    if (Array.isArray(pois)) {
      // This handles the case when the user reloads the app and the URL already has poi IDs
      return [...pois.filter((i) => i !== null)];
    } else return [];
  });

  const onProducerPoiIdValueChange = ({ id, isChecked }: ValueChangeType) => {
    // Deal with select/diselect all case
    if (id === "all" && uniquePoiIds) {
      if (poisValues.length) {
        setPoisValues([]);
      } else {
        setPoisValues(uniquePoiIds.map((i) => i.poiId));
      }
      // poidId is an individual ID here
    } else {
      // Add poi ID
      if (isChecked) {
        setPoisValues([...poisValues, id]);
      } else {
        // Remove poi ID
        setPoisValues(
          poisValues.filter((existingId: string) => existingId !== id)
        );
      }
    }
  };

  // "rPois" keeps receiver poi IDs values currently applied to the filter, stored in URL
  // "rPois" undefined means "all" sites are selected
  const [receiverPoiIds, setReceiverPoiIds] = useQueryParam(
    "rPois",
    ArrayParam
  );

  // "receiversValues" keeps poi IDs as selected by the user in SimpleFilter, but not applied yet
  // @ts-ignore
  const [receiversValues, setReceiversValues] = useState<string[] | []>(() => {
    if (Array.isArray(receiverPoiIds)) {
      // This handles the case when the user reloads the app and the URL already has receiver poi IDs
      return [...receiverPoiIds.filter((i) => i !== null)];
    } else return [];
  });

  const onReceiverIdValueChange = ({
    id: receiverId,
    isChecked,
  }: ValueChangeType) => {
    // Deal with select/diselect all case
    if (receiverId === "all" && uniqueReceiverPois) {
      if (receiversValues.length) {
        setReceiversValues([]);
      } else {
        setReceiversValues(uniqueReceiverPois.map((i) => i.receiverId));
      }
      // receiverId is an individual ID here
    } else {
      // Add receiver poi ID
      if (isChecked) {
        setReceiversValues([...receiversValues, receiverId]);
      } else {
        // Remove receiver poi ID
        setReceiversValues(
          receiversValues.filter(
            (existingId: string) => existingId !== receiverId
          )
        );
      }
    }
  };

  // "poiGroupIds" keeps group IDs values currently applied to the filter, stored in URL
  // "poiGroupIds" undefined means "all" groups are selected
  const [poiGroupId, setPoiGroupId] = useQueryParam("poiGroupIds", ArrayParam);

  // "poiGroupIdValues" keeps group IDs as selected by the user in SimpleFilter, but not applied yet
  // @ts-ignore
  const [poiGroupIdValues, setPoiGroupIdValues] = useState<string[]>(() => {
    if (Array.isArray(poiGroupId)) {
      // This handles the case when the user reloads the app and the URL already has poi IDs
      return [...poiGroupId.filter((i) => i !== null)];
    } else return [];
  });

  const onGroupIdValueChange = ({
    id: groupId,
    isChecked,
  }: ValueChangeType) => {
    // Add group ID
    if (isChecked) {
      setPoiGroupIdValues([...poiGroupIdValues, groupId]);
    } else {
      // Remove group ID
      setPoiGroupIdValues(
        poiGroupIdValues.filter((id: string) => id !== groupId)
      );
    }
  };

  // "supplierIds" keeps poi IDs values currently applied to the filter, stored in URL
  // "pois" undefined means "all" sites are selected
  const [supplierIds, setSupplierIds] = useQueryParam(
    "supplierIds",
    ArrayParam
  );

  // "supplierIdValues" keeps poi IDs as selected by the user in SimpleFilter, but not applied yet
  // @ts-ignore
  const [supplierIdValues, setSupplierIdValues] = useState<string[] | []>(
    () => {
      if (Array.isArray(supplierIds)) {
        // This handles the case when the user reloads the app and the URL already has poi IDs
        return [...supplierIds.filter((i) => i !== null)] as string[];
      } else return [];
    }
  );

  const onSupplierIdValueChange = ({
    id: supplierId,
    isChecked,
  }: ValueChangeType) => {
    // Deal with select/diselect all case
    if (supplierId === "all" && uniqueSuppliers) {
      if (supplierIdValues.length) {
        setSupplierIdValues([]);
      } else {
        setSupplierIdValues(uniqueSuppliers.map((i) => i.supplierId));
      }
      // poidId is an individual ID here
    } else {
      // Add poi ID
      if (isChecked) {
        setSupplierIdValues([...supplierIdValues, supplierId]);
      } else {
        // Remove poi ID
        setSupplierIdValues(
          supplierIdValues.filter((id: string) => id !== supplierId)
        );
      }
    }
  };

  const [carrierName, setCarrierName] = useQueryParam(
    "carrierName",
    StringParam
  );
  const [carrierNameValue, setCarrierNameValue] = useState<string>(() => {
    if (carrierName) {
      return carrierName;
    } else {
      return "all";
    }
  });

  const [hasIssues, setHasIssues] = useQueryParam("hasIssues", BooleanParam);
  const [hasIssuesValue, setHasIssuesValue] = useState<string>(() => {
    if (hasIssues === null || hasIssues === undefined) {
      return "all";
    } else {
      return hasIssues ? "true" : "false";
    }
  });

  const onClearAll = () => {
    onDateRangeClear();
    onWasteCodeClear();
    onProducerPoiIdClear();
    onPoiIdsClear();
    onReceiverIdsClear();
    onSupplierIdsClear();
    onGroupIdsClear();
    onCarrierNameClear();
    onHasIssuesClear();
  };

  const onDateRangeClear = () => {
    setStartDate(undefined);
    setStartDateStringValue(null);
    setEndDate(undefined);
    setEndDateStringValue(null);
  };

  const onProducerPoiIdClear = () => {
    setProducerPoiId(undefined);
    setProducerPoiIdValue("all");
  };

  const onPoiIdsClear = () => {
    setPois(undefined);
    setPoisValues([]);
  };

  const onReceiverIdsClear = () => {
    setReceiverPoiIds(undefined);
    setReceiversValues([]);
  };

  const onGroupIdsClear = () => {
    setPoiGroupId(undefined);
    setPoiGroupIdValues([]);
  };

  const onSupplierIdsClear = () => {
    setSupplierIds(undefined);
    setSupplierIdValues([]);
  };

  const onCarrierNameClear = () => {
    setCarrierName(undefined);
    setCarrierNameValue("all");
  };

  const onHasIssuesClear = () => {
    setHasIssues(undefined);
    setHasIssuesValue("all");
  };

  const onWasteCodeClear = () => {
    setWc(undefined);
    setWasteCodeValues([]);
  };

  // This updates local state with params coming from user filter
  // It is then ready to be applied by "onApply" function
  const applyFilter = (filter: FilterType) => {
    if (filter?.params?.startDate && filter?.params?.endDate) {
      setStartDateStringValue(filter.params.startDate);
      setEndDateStringValue(filter.params.endDate);
    } else {
      setStartDateStringValue(null);
      setEndDateStringValue(null);
    }

    if (filter?.params?.poiIds && filter?.params?.poiIds?.length > 0) {
      setPoisValues(filter.params.poiIds);
    } else {
      setPoisValues([]);
    }

    if (
      filter?.params?.receiverPoiIds &&
      filter?.params?.receiverPoiIds?.length > 0
    ) {
      setReceiversValues(filter.params.receiverPoiIds);
    } else {
      setReceiverPoiIds([]);
    }

    if (
      filter?.params?.supplierIds &&
      filter?.params?.supplierIds?.length > 0
    ) {
      setSupplierIdValues(filter.params.supplierIds);
    } else {
      setSupplierIdValues([]);
    }

    if (
      filter?.params?.poiGroupIds &&
      filter?.params?.poiGroupIds?.length > 0
    ) {
      setPoiGroupIdValues(filter.params.poiGroupIds);
    } else {
      setPoiGroupIdValues([]);
    }

    if (filter?.params?.carrierName) {
      setCarrierNameValue(filter?.params?.carrierName);
    } else {
      setCarrierNameValue("all");
    }

    if (filter?.params?.wasteCodes) {
      setWasteCodeValues(filter.params.wasteCodes);
    } else {
      setWasteCodeValues([]);
    }

    if (
      filter?.params?.hasIssues === true ||
      filter?.params?.hasIssues === false
    ) {
      setHasIssuesValue(filter?.params?.hasIssues ? "true" : "false");
    }
  };

  const onApply = () => {
    if (startDateStringValue) {
      setStartDate(startDateStringValue);
    } else {
      setStartDate(undefined);
    }
    if (endDateStringValue) {
      setEndDate(endDateStringValue);
    } else {
      setEndDate(undefined);
    }

    switch (producerPoiIdValue) {
      case "all":
        onProducerPoiIdClear();
        break;
      default:
        setProducerPoiId(producerPoiIdValue);
        break;
    }

    if (supplierIdValues.length === uniqueSuppliers?.length) {
      setSupplierIds(undefined);
      setSupplierIdValues([]);
    } else {
      // Otherwise update supplier ids in URL
      setSupplierIds([...supplierIdValues]);
    }

    switch (poiGroupIdValues) {
      default:
        setPoiGroupId(poiGroupIdValues);
        break;
    }

    switch (carrierNameValue) {
      case "all":
        onCarrierNameClear();
        break;
      default:
        setCarrierName(carrierNameValue);
        break;
    }

    switch (hasIssuesValue) {
      case "all":
        onHasIssuesClear();
        break;
      default:
        setHasIssues(hasIssuesValue === "true");
        break;
    }

    // pois
    // All sites are selected, so we clear pois param in URL
    if (poisValues.length === uniquePoiIds?.length) {
      setPois(undefined);
      setPoisValues([]);
    } else {
      // Otherwise update poi ids in URL
      setPois([...poisValues]);
    }

    // receiver pois
    // All sites are selected, so we clear pois param in URL
    if (receiversValues.length === uniqueReceiverPois?.length) {
      setReceiverPoiIds(undefined);
      setReceiversValues([]);
    } else {
      // Otherwise update poi ids in URL
      setReceiverPoiIds([...receiversValues]);
    }

    // waste codes
    // All waste codes are selected, so we clear wc param in URL
    if (wasteCodeValues.length === uniqueWasteCodes?.length) {
      setWc(undefined);
      setWasteCodeValues([]);
    } else {
      // Otherwise update waste codes in URL
      setWc([...wasteCodeValues]);
      setWasteCodeValues([...wasteCodeValues]);
    }
  };

  const hasDateRangeChanged = () => {
    // Check if there is a difference between "startDate/endDate" in params
    // and string values od date selector
    if (startDateStringValue === null && startDate === undefined) {
      return false;
    } else if (endDateStringValue === null && endDate === undefined) {
      return false;
    } else if (
      startDateStringValue === startDate &&
      endDateStringValue === endDate
    ) {
      return false;
    } else {
      return true;
    }
  };

  const hasWasteCodeChanged = () => {
    // Check if there is a difference between "wasteCode" in params
    // and "wasteCodeValues"
    if (!wc && wasteCodeValues.length === 0) {
      return false;
    } else if (wc?.length !== wasteCodeValues.length) {
      return true;
    } else if (areTwoArraysEqual(wc, wasteCodeValues)) {
      return false;
    } else return true;
  };

  const hasCarrierNameChanged = () => {
    if (
      carrierNameValue === carrierName ||
      (carrierNameValue === "all" && carrierName === undefined)
    ) {
      return false;
    } else return true;
  };

  const hasIssuesChanged = () =>
    (hasIssuesValue === "true") !== hasIssues ||
    (hasIssuesValue === "all" &&
      (hasIssues !== undefined || hasIssues !== null));

  const hasSiteAddressChanged = () => {
    if (
      producerPoiIdValue === producerPoiId ||
      (producerPoiIdValue === "all" && producerPoiId === undefined)
    ) {
      return false;
    } else return true;
  };

  const hasPoisChanged = () => {
    if (!pois && poisValues.length === 0) {
      return false;
    } else if (pois?.length !== poisValues.length) {
      return true;
    } else if (areTwoArraysEqual(pois, poisValues)) {
      return false;
    } else return true;
  };

  const hasSupplierIdsChanged = () => {
    if (!supplierIds && supplierIdValues.length === 0) {
      return false;
    } else if (supplierIds?.length !== supplierIdValues.length) {
      return true;
    } else if (areTwoArraysEqual(supplierIds, supplierIdValues)) {
      return false;
    } else return true;
  };

  // Logic for enabling/disabling Apply button
  const isApplyDisabled = () => {
    return !(
      hasDateRangeChanged() ||
      hasCarrierNameChanged() ||
      hasWasteCodeChanged() ||
      hasSiteAddressChanged() ||
      hasPoisChanged() ||
      hasSupplierIdsChanged() ||
      hasIssuesChanged()
    );
  };

  const onClearAllSubmit = () => {
    onClearAll();
    toggleSimpleFilter();
  };

  const onNewFilteringSubmit = () => {
    onApply();
    toggleSimpleFilter();
  };

  return (
    <SimpleFilterContainer
      poiGroups={poiGroups}
      toggleSimpleFilter={toggleSimpleFilter}
      applyFilter={applyFilter}
      startDate={startDate}
      endDate={endDate}
      setStartDateStringValue={setStartDateStringValue}
      setEndDateStringValue={setEndDateStringValue}
      poiGroupIdValues={poiGroupIdValues}
      onGroupIdValueChange={onGroupIdValueChange}
      uniqueProducerPois={uniqueProducerPois || []}
      poisValues={poisValues}
      onProducerPoiIdValueChange={onProducerPoiIdValueChange}
      uniqueSuppliers={uniqueSuppliers}
      supplierIdValues={supplierIdValues}
      onSupplierIdValueChange={onSupplierIdValueChange}
      uniqueCarriers={uniqueCarriers}
      carrierNameValue={carrierNameValue}
      setCarrierNameValue={setCarrierNameValue}
      uniqueReceiverPois={uniqueReceiverPois}
      receiversValues={receiversValues}
      onReceiverIdValueChange={onReceiverIdValueChange}
      uniqueWasteCodes={uniqueWasteCodes}
      wasteCodeValues={wasteCodeValues}
      onWasteCodeValueChange={onWasteCodeValueChange}
      hasIssuesValue={hasIssuesValue}
      setHasIssuesValue={setHasIssuesValue}
      isApplyDisabled={isApplyDisabled}
      onClearAllSubmit={onClearAllSubmit}
      onNewFilteringSubmit={onNewFilteringSubmit}
    />
  );
};

export default SimpleFilter;
