import { Switch, Route, Redirect } from "react-router-dom";

import SitesSearch from "./containers/SitesSearch";
import SingleSiteView from "./containers/SingleSiteView";

const SitesDirectory = () => {
  return (
    <>
      <Switch>
        <Route exact={true} path="/h/directory" key="/h/directory">
          <SitesSearch />
        </Route>
        <Route exact={true} path="/h/directory/site/:siteId" key="/h/directory">
          <SingleSiteView />
        </Route>
        <Route>
          <Redirect to="/h/directory" />
        </Route>
      </Switch>
    </>
  );
};

export default SitesDirectory;
