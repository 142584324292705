import { validationPercentage } from "../../../../../aux/validationPercentage";
import { IMovementsSummary } from "../../../../../graphql/queries/getMovementsSummary";
import TrText from "../../../../../i18n/TrText";
import Tile from "../../../../../ui/Tile";

const ValidatedPrimaryMovements = ({
  movementsSummary,
  isLoading,
}: {
  movementsSummary?: IMovementsSummary;
  isLoading: boolean;
}) => (
  <Tile
    title="overview.validated_card_title"
    popoverText="overview.validated_popover"
    iconName="truck_with_tick"
    bottomText={
      <TrText
        message="overview.in_total_amount"
        options={{
          amount: movementsSummary?.activityCount
            ? movementsSummary.activityCount
            : "...",
        }}
      />
    }
    value={isLoading ? undefined : validationPercentage(movementsSummary)}
  />
);

export default ValidatedPrimaryMovements;
