import { useState } from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { Input as I, Flex, IconButton } from "@chakra-ui/react";

import { colors, selectors } from "../theme";
import translate from "../../i18n/translate";

type Props = {
  id: string;
  placeholder: string;
  value: string;
  valueSelectedForQuery: string | undefined;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClear: () => void;
};

const Input = (props: Props) => {
  const {
    placeholder,
    id,
    onChange,
    value,
    valueSelectedForQuery,
    onSubmit,
    onClear,
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <StyledInput alignItems="center" isFocused={isFocused}>
      {valueSelectedForQuery && (
        <Badge>
          <Flex mr={2}>{valueSelectedForQuery}</Flex>
          <Flex
            height="6"
            justifyContent="center"
            alignItems="center"
            borderRadius="6px"
          >
            <IconButton
              aria-label="close icon"
              icon={
                <svg
                  height="14"
                  width="14"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              }
              onClick={onClear}
              size="sm"
              w={2}
              h={6}
              borderRadius="2px"
              backgroundColor={colors.new.grey[500]}
              _hover={{
                background: "#FFBDAD",
                cursor: "pointer",
                color: "#DE350B",
              }}
            />
          </Flex>
        </Badge>
      )}
      <I
        id={id}
        h="36px"
        flex="1"
        placeholder={translate(placeholder)}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
        onFocus={() => setIsFocused(true)}
        border="none"
        _focus={{
          outline: "none",
        }}
        onBlur={() => setIsFocused(false)}
      />
    </StyledInput>
  );
};

const StyledInput = styled(Flex, { shouldForwardProp: isPropValid })`
  background-color: #fff;
  color: #716352;
  box-shadow: ${selectors.control.boxShadow};
  border: 2px solid
    ${(props) => (props.isFocused ? colors.new.outline[500] : "#cccbc8")};
  padding: 0px;
  border-radius: 6px;
  height: 40px;
  transition: border 0.5s;
`;

const Badge = styled(Flex)`
  background-color: ${colors.new.grey[500]};
  color: #716352;
  padding: 0px 0px 0px 8px;
  font-size: 13.6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 8px;
  margin-right: 4px;
  margin-top: 1px;
  height: 25px;
`;

export default Input;
