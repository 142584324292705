import { Stack, RadioGroup, Radio } from "@chakra-ui/react";
import TrText from "../../../i18n/TrText";
import RadioLabel from "../../../ui/RadioLabel";

import Text from "../../../ui/Text";

interface ISelectionListValue {
  value: string;
  title?: string;
  label: string;
}

interface ISelectionListProps {
  values?: ISelectionListValue[];
  onChange: (value: string) => void;
  selectedValue: string;
}

const SelectionList = ({
  values,
  selectedValue,
  onChange,
}: ISelectionListProps) => {
  return (
    <RadioGroup
      onChange={(v) => {
        onChange(v);
      }}
      value={selectedValue}
    >
      <Stack direction="column" px={1}>
        <Radio key="all" colorScheme="green" value="all">
          <Text style={{ marginLeft: 8 }}>
            <TrText message="filter.all" />
          </Text>
        </Radio>

        {values &&
          values.map((value) => {
            return (
              <Radio key={value.value} colorScheme="green" value={value.value}>
                <RadioLabel title={value.title} label={value.label} />
              </Radio>
            );
          })}
      </Stack>
    </RadioGroup>
  );
};

export default SelectionList;
