import { ReactNode } from "react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  children: ReactNode;
  to: string;
  state?: object;
};

const Link = (props: Props) => {
  const { to, children, state } = props;

  return (
    <ChakraLink
      as={RouterLink}
      to={{
        pathname: to,
        state: state,
      }}
    >
      {children}
    </ChakraLink>
  );
};

export default Link;
