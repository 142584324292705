import { ReactNode, ChangeEvent } from "react";
import { Select as ChakraSelect } from "@chakra-ui/react";

import { colors } from "./../theme";
import translate from "../../i18n/translate";

type Props = {
  children: ReactNode;
  placeholder?: string;
  defaultValue?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  id: string;
};

const Select = (props: Props) => {
  const { placeholder, children, onChange, id, defaultValue } = props;

  return (
    <ChakraSelect
      id={id}
      onChange={onChange}
      bg={colors.new.text.light[500]}
      placeholder={placeholder ? translate(placeholder) : ""}
      defaultValue={defaultValue}
      style={{
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 20px",
        border: "2px solid #cccbc8",
      }}
      _focus={{
        border: `2px solid ${colors.new.outline[500]}`,
      }}
    >
      {children}
    </ChakraSelect>
  );
};

export default Select;
