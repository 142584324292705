import { useRef, useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";

import {
  isLongitudeValid,
  isLatitudeValid,
} from "./../../aux/validateCoordinates";
import { mapboxgl, isMapboxSupported } from "./../../services/mapbox";
import Text from "./../../ui/Text";

const LocationChart = (props) => {
  const [canDrawMap, setCanDrawMap] = useState(true);

  let wasteMapRef = useRef(null);
  const { produceLon, produceLat, receiverLon, receiverLat, routeGeojson } =
    props;
  let map;

  useEffect(() => {
    if (isMapboxSupported) {
      if (
        isLongitudeValid(produceLon) &&
        isLatitudeValid(produceLat) &&
        isLatitudeValid(receiverLat) &&
        isLongitudeValid(receiverLon)
      ) {
        // All coordinates are correct
        // Render map, both markers and geo
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [produceLon, produceLat],
          zoom: 8,
        });

        // render producer marker
        new mapboxgl.Marker()
          .setLngLat([produceLon, produceLat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `Producer: Lon: ${produceLon} Lat: ${produceLat}`
            )
          )
          .addTo(map);

        // render receiver marker
        new mapboxgl.Marker({ color: "red" })
          .setLngLat([receiverLon, receiverLat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `Receiver: Lon: ${receiverLon} Lat: ${receiverLat}`
            )
          )
          .addTo(map);

        map.fitBounds(
          [
            [produceLon, produceLat],
            [receiverLon, receiverLat],
          ],
          { padding: 100 }
        );

        // Render path between points: either geojson or straight line
        // Trying to guard against malformed geojson
        if (typeof routeGeojson === "string" && routeGeojson.length) {
          let route;
          try {
            route = JSON.parse(routeGeojson);

            map.on("load", function () {
              map.addSource("georoute", {
                type: "geojson",
                data: route,
              });

              map.addLayer({
                id: "georoute",
                type: "line",
                source: "georoute",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#aaa",
                  "line-width": 6,
                },
              });
            });
          } catch (error) {
            // On error we want to plot straight line
            map.on("load", function () {
              map.addSource("route", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: [
                      [produceLon, produceLat],
                      [receiverLon, receiverLat],
                    ],
                  },
                },
              });

              map.addLayer({
                id: "route",
                type: "line",
                source: "route",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#888",
                  "line-width": 8,
                },
              });
            });
          }
        } else {
          // No geojson, fallback to straight line
          map.on("load", function () {
            map.addSource("route", {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [produceLon, produceLat],
                    [receiverLon, receiverLat],
                  ],
                },
              },
            });

            map.addLayer({
              id: "route",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#888",
                "line-width": 8,
              },
            });
          });
        }
      } else if (isLongitudeValid(produceLon) && isLatitudeValid(produceLat)) {
        // Only producer location is valid
        // Render map and producer location only, no geo
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [produceLon, produceLat],
          zoom: 8,
        });

        new mapboxgl.Marker()
          .setLngLat([produceLon, produceLat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `Producer: Lon: ${produceLon} Lat: ${produceLat}`
            )
          )
          .addTo(map);
      } else if (
        isLongitudeValid(receiverLon) &&
        isLatitudeValid(receiverLat)
      ) {
        // Only receiver location is valid
        // Render map and receiver location only, no geo
        // eslint-disable-next-line
        map = new mapboxgl.Map({
          container: wasteMapRef,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [receiverLon, receiverLat],
          zoom: 8,
        });

        new mapboxgl.Marker()
          .setLngLat([receiverLon, receiverLat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `Receiver: Lon: ${receiverLon} Lat: ${receiverLat}`
            )
          )
          .addTo(map);
      } else {
        // No useful location data
        setCanDrawMap(false);
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [produceLon, produceLat, receiverLon, receiverLat, map]);

  if (canDrawMap && isMapboxSupported) {
    return (
      <div
        ref={(elem) => (wasteMapRef = elem)}
        style={{
          height: "100%",
          borderRadius: "10px",
        }}
      />
    );
  } else if (!canDrawMap) {
    // There is no location data for both producer and receiver,
    // can't draw anything
    // TODO A bit more useful UI should go here.
    return (
      <Flex
        justifyContent="center"
        alignItems={"center"}
        h="100%"
        flexDirection={"column"}
        flex={1}
      >
        <Text>No location data available.</Text>
      </Flex>
    );
  } else {
    return <Text>Map is not supported in this browser.</Text>;
  }
};

export default LocationChart;
