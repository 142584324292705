import { Flex } from "@chakra-ui/react";

import Text from "./../../../../ui/Text";
import { FilterType } from "./LoadFilters";

type Props = {
  filter: FilterType;
};

export const LineItem = ({ filter }: Props) => {
  const { params } = filter;

  const showSitesStatus = () => {
    if (params?.poiIds?.length && params?.poiIds?.length > 0) {
      return `Sites: ${params?.poiIds?.length}; `;
    } else {
      return "Sites: all; ";
    }
  };

  const showGroupsStatus = () => {
    if (params?.poiGroupIds?.length && params?.poiGroupIds?.length > 0) {
      return `Groups: ${params?.poiGroupIds?.length}; `;
    } else {
      return "Groups: all; ";
    }
  };

  const showSuppliers = () => {
    if (params?.supplierIds?.length && params?.supplierIds?.length > 0) {
      return `Suppliers: ${params?.supplierIds?.length}; `;
    } else {
      return "Suppliers: all; ";
    }
  };

  const showCarrierStatus = () => {
    if (params?.carrierName) {
      return `Carrier: ${params?.carrierName}; `;
    } else {
      return "Carrier: all; ";
    }
  };

  const showMaterialStatus = () => {
    if (params?.wasteCodes?.length && params?.wasteCodes?.length > 0) {
      return `Materials: ${params?.wasteCodes?.length}; `;
    } else {
      return "Materials: all; ";
    }
  };

  const showIssuesStatus = () => {
    if (params.hasIssues === undefined) {
      return "";
    } else if (params?.hasIssues === true) {
      return "With issues: yes";
    } else if (params?.hasIssues === false) {
      return "With issues: no";
    }
  };

  return (
    <>
      <Flex>
        <Text>
          <b>{filter?.name}</b>
        </Text>
      </Flex>
      {params?.startDate && params?.endDate && (
        <Flex fontSize="sm">
          From {params?.startDate} to {params?.endDate}
        </Flex>
      )}
      <Flex fontSize="sm">
        {showSitesStatus()}
        {showGroupsStatus()}
        {showSuppliers()}
        {showCarrierStatus()}
        {showMaterialStatus()}
        {showIssuesStatus()}
      </Flex>
    </>
  );
};
