import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import { colors } from "../../../../../ui/theme";

type Props = {
  value: number | undefined;
  onChange: (val: number) => void;
  prefix: ">" | "<";
  suffix?: "%" | "";
  min: number;
  max?: number;
  step?: number;
};

const InputBox = ({
  value,
  onChange,
  prefix,
  suffix,
  min,
  max,
  step,
}: Props) => {
  const formatValue = (val: string) => {
    if (suffix) {
      return `${prefix} ` + val + ` ${suffix}`;
    } else {
      return `${prefix} ` + val;
    }
  };
  const parseValue = (val: string) =>
    val.replace(`${prefix} `, "").replace(`${suffix} `, "");

  return (
    <NumberInput
      step={step}
      min={min}
      max={max}
      value={value ? formatValue(String(value)) : undefined}
      onChange={(valueString) => onChange(Number(parseValue(valueString)))}
      color={colors.new.text.dark[500]}
      w="110px"
      size="md"
    >
      <NumberInputField bg={"white"} />
      <NumberInputStepper>
        <NumberIncrementStepper children="+" />
        <NumberDecrementStepper children="-" />
      </NumberInputStepper>
    </NumberInput>
  );
};
export default InputBox;
