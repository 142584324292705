import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { colors } from "../../../../ui/theme";
import Label from "../../../../ui/Label";

type Props = {
  value: string;
  onChange: (val: string) => void;
  min?: string;
  max?: string;
  name: string;
  labelText: string;
  isDisabled: boolean;
};

const DatePicker = ({
  value,
  onChange,
  min,
  max,
  name,
  labelText,
  isDisabled,
}: Props) => {
  return (
    <Flex direction="column">
      <Label forHtml={name}>{labelText}</Label>
      <StyledInput
        type="date"
        disabled={isDisabled}
        name={name}
        id={name}
        min={min}
        max={max}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </Flex>
  );
};

const StyledInput = styled.input`
  border-radius: 10px;
  background: ${colors.new.background_light[500]};
  padding: 10px;
  /* Visible in the full-colour space */
  box-shadow: 0px 0px 0px 2px ${colors.new.outline[500]};

  &:hover {
    cursor: pointer;
  }

  &:active {
    /* Visible in the full-colour space */
    box-shadow: 0px 0px 0px 2px ${colors.new.outline[500]};

    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  &:focus {
    /* Visible in the full-colour space */
    box-shadow: 0px 0px 0px 2px ${colors.new.outline[500]};

    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  &:disabled {
    cursor: not-allowed;
    /* Visible in the full-colour space */
    box-shadow: 0px 0px 0px 2px transparent;

    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }
`;

export default DatePicker;
